import { useParams } from "react-router-dom";
import { LOCALE } from "../../common";
import PageLayout from "../../components/Layout/PageLayout";
import TestingResultsList from "../../components/TestResults/TestingResultsList";


const { PAGE_TITLES } = LOCALE;

const TestingResults = () => {
	const {id} = useParams();
	return (
		<PageLayout
			spaceProps={{ style: { maxWidth: "1000px", width: "100%" } }}
			pageTitle={PAGE_TITLES.TESTING_RESULTS}
		>
			<TestingResultsList id={id} />
		</PageLayout>
	);
};

export default TestingResults;
