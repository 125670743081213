import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Form, Popconfirm, Select, Space, Table, Typography} from 'antd';
import {useSelector} from 'react-redux';
import {LOCALE} from '../../common';
import {EduSelectors} from '../../store/EducationStore';
import ModalForm from '../UX/ModalForm';

const {COMMON, EDUCATION, SETTINGS, MESSAGES} = LOCALE;
const {Text, Title} = Typography;

const LinkedTypes = ({types, onCreate, onDelete, onDeleteAll, loading}) => {
  const programTypes = useSelector(EduSelectors.programTypes);

  const ProgramTypeSelect = () => {
    return (
      <Form.Item name={'trainingProgramTypeId'} label={EDUCATION.PROGRAM_TYPE}>
        <Select>
          {programTypes.map((type) => (
            <Select.Option key={type.id} value={type.id}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  const columns = [
    {
      dataIndex: 'trainingProgramTypeName',
      align: 'center',
      title: COMMON.TITLE,
    },
    {
      dataIndex: 'trainingProgramTypeId',
      align: 'center',
      title: (
        <ModalForm title={'Привязать новый тип программ'} formItems={<ProgramTypeSelect />} onOk={onCreate}>
          <Button type="primary" icon={<PlusOutlined />} />
        </ModalForm>
      ),
      render: (id) => (
        <Popconfirm title={MESSAGES.CONFIRM} onConfirm={() => onDelete(id)}>
          <Button danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Title level={2}>{SETTINGS.ASSIGNED_PROGRAM_TYPES}</Title>
      <Table pagination={false} columns={columns} dataSource={types} rowKey="trainingProgramTypeId" loading={loading} />
      <Popconfirm onConfirm={onDeleteAll} title={MESSAGES.CONFIRM}>
        <Button style={{marginTop: 12}} danger type="primary">
          Отвязать от всех
        </Button>
      </Popconfirm>
    </>
  );
};

export default LinkedTypes;
