import {message} from 'antd';
import {LOCALE} from '../../common';
const {MESSAGES} = LOCALE;
const UseMessage = () => {
  const showError = (e = MESSAGES.ERROR) => {
    message.error(e instanceof Object ? e.message : e);
  };

  const showSuccess = (text = MESSAGES.SUCCESS) => {
    message.success(text);
  };
  return {showSuccess, showError};
};

export default UseMessage;
