import {Space, Table, Typography} from 'antd';
import {useSelector} from 'react-redux';
import {AdminSelectors} from '../../store/AdminStore';
import {LOCALE, paramsToObject} from '../../common';
import {Link, useSearchParams} from 'react-router-dom';

const ListenersTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = paramsToObject(searchParams);

  const loading = useSelector(AdminSelectors.loading);
  const listeners = useSelector(AdminSelectors.listeners);

  return (
    <Table
      loading={loading}
      bordered
      scroll={{x: 'auto'}}
      size="middle"
      rowKey="id"
      dataSource={listeners.data}
      pagination={{
        total: listeners.totalCount,
        current: Number(filters.page ?? 1),
        pageSize: Number(filters.limit ?? 10),
        onChange: (page, limit) =>
          setSearchParams({...filters, page, limit, offset: (page - 1) * limit}, {replace: true}),
        onShowSizeChange: (page, limit) =>
          setSearchParams({...filters, page, limit, offset: (page - 1) * limit}, {replace: true}),
        pageSizeOptions: [10, 20, 50, 100, 250, 500],
      }}>
      <Table.Column title={LOCALE.COMMON.SURNAME} dataIndex="surname" align="center" />
      <Table.Column title={LOCALE.COMMON.NAME} dataIndex="firstname" align="center" />
      <Table.Column title={LOCALE.COMMON.SECOND_NAME} dataIndex="secondname" align="center" />
      <Table.Column
        title={LOCALE.COMMON.CONTRACT_NUMBER}
        dataIndex="contractNumber"
        align="center"
        render={(contract, record) => {
          return (
            <Link to={`/registry/students/${record.contractId}`} className="link">
              {contract}
            </Link>
          );
        }}
      />

      <Table.Column title={LOCALE.COMMON.PROFESSION} dataIndex="profession" align="center" />
      <Table.Column
        title={LOCALE.COMMON.EDUCATION_PROGRAM}
        dataIndex="studentTrainings"
        align="center"
        width={500}
        render={(studentTrainings = [], record) => {
          return (
            <Space wrap>
              {studentTrainings.map((trainging) => (
                <Typography>{trainging.trainingProgramName}</Typography>
              ))}
            </Space>
          );
        }}
      />
    </Table>
  );
};

export default ListenersTable;
