import {useNavigate} from 'react-router-dom';
import PageLayout from '../../components/Layout/PageLayout';
import UseMessage from '../../components/hooks/useMessage';
import {LOCALE} from '../../common';
import {Button, Card, Col, Form, Row, Typography} from 'antd';
import CustomForm from '../../components/UX/CustomForm';
import CardItem from '../../components/Users/Item';
import {MaskedInput} from 'antd-mask-input';
import {useWatch} from 'antd/lib/form/Form';
import useDebounce from '../../components/hooks/useDebounce';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {PublicAPI} from '../../store/PublicStore/methods';
import {AdminAPI} from '../../store/AdminStore/methods';

const {COMMON, PLACEHOLDERS, SYSTEM} = LOCALE;

const useDebouncedCallback = (callback, delay, dependencies) => {
  const timeout = useRef(null);

  return useCallback(
    (...args) => {
      if (timeout.current != null) {
        clearTimeout(timeout.current);
      }
      timeout.current = window.setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay, ...dependencies]
  );
};

const NewUser = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {showError, showSuccess} = UseMessage();
  const email = useWatch('login', form);
  const [emailExists, setEmailExists] = useState(false);

  const dispatch = useDispatch();

  const PageTitle = () => {
    return <>Новый пользователь</>;
  };

  const checkUserEmail = useDebouncedCallback(
    (email) => {
      dispatch(PublicAPI.isLoginExist(email)).then((res) => {
        setEmailExists(res?.payload?.isLoginExists);
      });
    },
    300,
    []
  );

  useEffect(() => {
    if (!email) return;
    setEmailExists(false);
    checkUserEmail(email);
  }, [email]);

  const onSubmit = (data) => {
    dispatch(AdminAPI.users.create(data))
      .then((res) => {
        showSuccess();
        navigate(`/users/${res?.payload?.id}`);
      })
      .catch((err) => {
        showError(err);
        console.log(err);
      });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <PageLayout pageTitle={<PageTitle />}>
      <CustomForm layout={{labelCol: {span: 12}}} onOk={onSubmit} formInstance={form} showButtons={false}>
        <Card style={{height: '100%', maxWidth: 650}}>
          <CardItem
            name="surname"
            label={COMMON.SURNAME}
            placeholder={PLACEHOLDERS.SURNAME}
            componentProps={{required: true}}
          />
          <CardItem name="firstname" label={COMMON.NAME} placeholder={PLACEHOLDERS.NAME} />
          <CardItem name="secondname" label={COMMON.SECOND_NAME} placeholder={PLACEHOLDERS.SECOND_NAME} />
          <CardItem
            name="phone"
            label={COMMON.PHONE}
            placeholder={PLACEHOLDERS.PHONE}
            Сomponent={MaskedInput}
            componentProps={{mask: '+{7} (000) 000-00-00'}}
          />
          <CardItem name="phoneExt" label={COMMON.PHONE_EXT} placeholder="308" />
          <CardItem name="login" label={COMMON.EMAIL} placeholder={PLACEHOLDERS.EMAIL} />
          {emailExists && <Typography.Text type="danger">Данный email уже существует</Typography.Text>}
          <Row justify="center" gutter={[16, 24]} style={{paddingTop: 24}}>
            <Col>
              <Button onClick={form.submit} disabled={emailExists} type="primary">
                {SYSTEM.SAVE}
              </Button>
            </Col>
            <Col>
              <Button onClick={handleCancel}>{SYSTEM.CANCEL}</Button>
            </Col>
          </Row>
        </Card>
      </CustomForm>
    </PageLayout>
  );
};

export default NewUser;
