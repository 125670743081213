import {Form, Input, Select} from 'antd';
import React from 'react';
import {CUSTOMER_TYPES, LOCALE} from '../../common';
import CustomerSelect from './CustomerSelect';

const {Item} = Form;
const {COMMON, CUSTOMER, SYSTEM, DOCUMENTS} = LOCALE;
const CustomersFilters = ({form}) => {
  return (
    <>
      <Item labelAlign="left" name="customerTypeId" label={CUSTOMER.CUSTOMER_TYPES}>
        <CustomerSelect placeholder="Индивидуальный предприниматель" />
      </Item>
      <Item labelAlign="left" name="complexName" label={COMMON.ORGANIZATION_NAME}>
        <Input placeholder="ООО «ЕВРАЗ»" />
      </Item>
      <Item labelAlign="left" name="inn" label={DOCUMENTS.INN}>
        <Input placeholder="7839334215" />
      </Item>
      <Item labelAlign="left" name="ogrn" label={DOCUMENTS.OGRN}>
        <Input placeholder="1027297561374" />
      </Item>
      <Item labelAlign="left" name="bik" label={DOCUMENTS.BIK}>
        <Input placeholder="042406718" />
      </Item>
      <Item labelAlign="left" name="email" label={COMMON.EMAIL}>
        <Input placeholder="dmitry@mail.ru" />
      </Item>
      <Item labelAlign="left" name="phone" label={COMMON.PHONE}>
        <Input placeholder="+7 (222) 222-22-22" />
      </Item>
      <Item labelAlign="left" name="contractNumber" label={LOCALE.REGISTRY.CONTRACT_NUMBER}>
        <Input placeholder="2020-11Д" />
      </Item>
      <Item labelAlign="left" name="userChangeQueryActive" label={CUSTOMER.CUSTOMER_CHANGE_QUERY}>
        <Select placeholder="Отсутствует">
          <Select.Option value={'none'}>{SYSTEM.NONE}</Select.Option>
          <Select.Option value={'queryActive'}>{CUSTOMER.QUERY_ACTIVE}</Select.Option>
        </Select>
      </Item>
    </>
  );
};

export default CustomersFilters;
