import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Typography} from 'antd';
import {arrayMove} from '@dnd-kit/sortable';

import {LOCALE} from '../../common';
import {EduActions, EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';
import UseMessage from '../../components/hooks/useMessage';
import PageLayout from '../../components/Layout/PageLayout';
import PresentationFormItem from '../../components/LearningMaterials/PresentationFormItem';
import EdumaterialsPhaseLink from '../../components/LearningMaterials/EdumaterialsPhaseLink';
import Norms from '../../components/SystemContentControl/Norms';
import SotrableList from '../../components/UX/SotrableList';
import ModalForm from '../../components/UX/ModalForm';

const {PAGE_TITLES, EDUCATION} = LOCALE;
const {Title} = Typography;

const Presentation = () => {
  const dispatch = useDispatch();
  const {id, materialId} = useParams();
  const {showError, showSuccess} = UseMessage();
  const presentaion = useSelector(EduSelectors.selectedMaterial);
  const loading = useSelector(EduSelectors.loading);
  const navigate = useNavigate();
  const [items, setItems] = useState([]);

  const fetchPresentation = (presId) => {
    dispatch(EduMaterialsAPI.presentations.getById(presId)).catch(() => {
      navigate('*');
    });
  };

  useEffect(() => {
    dispatch(EduMaterialsAPI.presentations.getById(id))
      .then((res) => {
        if (items.length === 0) {
          setItems(res.payload.slides);
        }
      })
      .catch(() => {
        navigate(-1);
      });
    return () => {
      dispatch(EduActions.clearSelectedMaterial());
    };
  }, [items.length, id]);

  const handleEditPresentation = (values) => {
    const formData = new FormData();
    formData.append('eduName', values.eduName);

    values.slidesContent.map((slide) => formData.append('slidesContent[]', slide.originFileObj));

    dispatch(EduMaterialsAPI.presentations.update({id, data: formData}))
      .then(() => {
        showSuccess();
        fetchPresentation(id);
      })
      .catch((e) => showError(e));
  };

  const updateSlidesList = () => {
    const data = {
      slidesSorting: items.map((item, index) => ({
        id: item.id,
        sort: index + 1,
      })),
    };

    dispatch(EduMaterialsAPI.presentations.updateSlidesList({data, id}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const handleDeleteSlide = (slide) => {
    dispatch(EduMaterialsAPI.presentations.deleteSlide({id, slideId: slide}))
      .then(() => {
        showSuccess();
        fetchPresentation(id);
      })
      .catch((e) => showError(e));
  };

  const handleDragEnd = (event) => {
    const {active, over} = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <PageLayout pageTitle={PAGE_TITLES.PRESENTATION + `: ${presentaion?.eduName ? presentaion.eduName : '...'}`}>
      <ModalForm
        onOk={handleEditPresentation}
        initialValues={presentaion}
        formItems={<PresentationFormItem />}
        title={PAGE_TITLES.PRESENTATION}>
        <Button type="primary">Редактировать</Button>
      </ModalForm>
      <EdumaterialsPhaseLink id={id} refetch={fetchPresentation} />

      <Title level={2}>{EDUCATION.SLIDES}</Title>
      <SotrableList
        items={items}
        loading={loading}
        listProps={{bordered: true}}
        dragEnd={handleDragEnd}
        deleteSlide={handleDeleteSlide}
      />
      <Button type="primary" onClick={updateSlidesList}>
        Сохранить новый порядок слайдов
      </Button>
      <Norms id={materialId} itemType="educationMaterial" />
    </PageLayout>
  );
};

export default Presentation;
