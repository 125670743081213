export const analyticFilters = {
  trainingProgramType: 'Типы программ обучения',
  month: 'Месяц',
  counter: 'Тип счетчика',
  contractStatus: {primary: 'Дата первички', secondary: 'Дата вторички'},
  new: 'Первый договор у пользователя',
  repeat: 'Повторный договор',
  adv: 'Источник рекламы',
  call: 'Источник обзвон',
  agent: 'Источник агент',
  other: 'Источник другое',
  pik: 'Источник ПИК',
  manual: 'Источник ручной',
  exclude: 'Источник исключить',
};
