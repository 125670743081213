import {Form, Input, Radio, Select, Switch} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {EduSelectors} from '../../store/EducationStore';
import React, {useEffect} from 'react';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';
import {TEXT} from '../../common/ru_RU';
import {PROMOCODE_TYPES} from '../../common';
import StringDatePicker from '../UX/StringDatePicker';

const {Item} = Form;

const PromocodesFilters = ({form}) => {
  const dispatch = useDispatch();
  const programTypes = useSelector(EduSelectors.programTypes);
  const promocodeTypeId = Form.useWatch('promocodeTypeId', form);
  const isOneTimePromocodeFilterSelected = promocodeTypeId === PROMOCODE_TYPES[1].value;

  useEffect(() => {
    dispatch(EduMaterialsAPI.programTypes.getAll());
  }, []);

  useEffect(() => {
    if (isOneTimePromocodeFilterSelected) form.setFieldValue('isUsed', 'all');
  }, [isOneTimePromocodeFilterSelected, form]);

  return (
    <>
      {/* <Item name="withPrices" label="Вместе с ценами и типами программ обучения" valuePropName="checked">
        <Switch />
      </Item> */}
      <Item name="promocodeTypeId" label="Тип промокода">
        <Select placeholder="Одноразовый" allowClear>
          {PROMOCODE_TYPES.map((promocode) => (
            <Select.Option key={promocode?.value} value={promocode?.value}>
              {promocode?.name}
            </Select.Option>
          ))}
        </Select>
      </Item>
      {isOneTimePromocodeFilterSelected && (
        <Item name="isUsed" label="Промокод использован">
          <Radio.Group disabled={!isOneTimePromocodeFilterSelected}>
            <Radio value="all">Все</Radio>
            <Radio value={true}>Да</Radio>
            <Radio value={false}>Нет</Radio>
          </Radio.Group>
        </Item>
      )}
      <Item name="codeOrNote" label="Часть кода или примечания">
        <Input placeholder="WINTER20" />
      </Item>
      <Item name="trainingProgramTypeId" label={TEXT.PAGE_TITLES.PROGRAM_TYPES}>
        <Select placeholder="АЗ" allowClear>
          {programTypes.map((programType) => (
            <Select.Option key={programType?.id} value={programType?.id}>
              {programType?.name}
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Item name="isActualOnDate" label="Актуальные на дату">
        <StringDatePicker format="DD.MM.YYYY" />
      </Item>
      <Item name="isNotActualOnDate" label="Не актуальные на дату">
        <StringDatePicker format="DD.MM.YYYY" />
      </Item>

      <Item name="contractNumber" label="Промокод который можно использовать для договора">
        <Input placeholder="1027297561374" />
      </Item>
    </>
  );
};

export default PromocodesFilters;
