import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Space, Table, Typography} from 'antd';

import {LOCALE} from '../../common';
import ModalForm from '../UX/ModalForm';
import PhaseForm from './PhaseForm';

const {COMMON, EDUCATION} = LOCALE;

const PhasesTable = ({data, loading, addPhase, deletePhase, updatePhase}) => {
  const columns = [
    {
      title: COMMON.TITLE,
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '№',
      align: 'center',
      dataIndex: 'number',
    },
    {
      title: EDUCATION.EXAM_DURTAION,
      align: 'center',
      dataIndex: 'maxExamDuration',
    },
    {
      title: 'Требуется экзамен',
      align: 'center',
      dataIndex: 'needExam',
      render: (needExam, record) => {
        return <Typography.Text>{needExam ? 'Да' : 'Нет'}</Typography.Text>;
      },
    },
    {
      title: (
        <ModalForm
          title={EDUCATION.NEW_PHASE}
          initialValues={{number: 0}}
          formItems={<PhaseForm />}
          modalProps={{confirmLoading: loading}}
          onOk={addPhase}>
          <Button type="primary" icon={<PlusOutlined />} />
        </ModalForm>
      ),
      dataIndex: 'id',
      align: 'center',
      render: (id, record) => {
        return (
          <Space>
            <ModalForm
              title={EDUCATION.CHANGE_PHASE}
              initialValues={record}
              onOk={(values) => updatePhase(id, values)}
              formItems={<PhaseForm />}>
              <Button icon={<EditOutlined />} />
            </ModalForm>
            <Button icon={<DeleteOutlined />} onClick={() => deletePhase(id)} />
          </Space>
        );
      },
    },
  ];
  return <Table pagination={false} size="middle" dataSource={data} columns={columns} loading={loading} rowKey="id" />;
};

export default PhasesTable;
