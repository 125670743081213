import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {AutoComplete, Input} from 'antd';

import useDebounce from '../hooks/useDebounce';
import {PublicAPI} from '../../store/PublicStore/methods';

const CustomAutoComplete = ({type, optionsMap = undefined, onSelectOption = () => {}, ...props}) => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const searchOptions = useDebounce((e) => {
    dispatch(PublicAPI.suggestAutocomplete({type, query: e})).then(({payload}) => setOptions(payload.data));
  }, 300);

  return (
    <AutoComplete
      onSearch={searchOptions}
      onSelect={(value) => onSelectOption(options.find((opt) => opt.value === value))}
      options={typeof optionsMap === 'function' ? optionsMap(options) : options}
      {...props}
      notFoundContent="Ничего не найдено"
    />
  );
};

export default CustomAutoComplete;
