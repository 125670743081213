export const TEXT = {
  MENU_ITEMS: {
    REGISTRY: 'Реестр',
    HOME: 'Главная',
    CUSTOMERS: 'Заказчики',
    PROMOCODES: 'Промокоды',
    USERS: 'Пользователи',
    ADMINS: 'Администраторы',
    LISTENERS: 'Слушатели',
    OPERATORS: 'Операторы',
    PROGRAM_TYPES: 'Типы программ обучения',
    PROGRAMS: 'Программы обучения',
    PRESENTATIONS: 'Презентации',
    QUESTIONS: 'Вопросы',
    ALGORYTHM: 'Алгоритм подбора программ',
    SETTINGS: 'Настройки',
    TEMPLATES: 'Шаблоны',
    ANALYTICS: 'Аналитика',
    MORE: 'Дополнительно',
    COMMERCIAL_PROPOSES: 'Коммерческие предложения',
    REPORTS: 'Отчеты ФИС',
    JOBTITLE: 'Должности заказчиков',

    LEARNING_MATERIALS: 'Учебные материалы',
  },

  PAGE_TITLES: {
    CHANGE_PAYMENT: 'Изменение счета',
    REGISTRY: 'Реестр',
    HOME: 'Главная',
    CUSTOMERS: 'Заказчики',
    LISTENERS: 'Слушатели',
    ADMINS: 'Администраторы',
    DELIVERY_INFO: 'Информация о доставке',
    PROMOCODER_INFO: 'Информация о промокодах',
    LISTENERS_OF_CONTRACT: 'Слушатели по договору',
    LISTENERS_FOR_ADD: 'Запросы на добавление слушателей',
    CUSTOMER_CHANGE: 'Изменение заказчика',
    CUSTOMER_INFO: 'Информация о заказчике',
    USERS_INFO: 'Информация о пользователях',
    USER_INFO: 'Информация о пользователе',
    OPERATORS: 'Операторы',
    OPERATORS_ANALYTICS: 'Аналитика работы операторов',
    TESTING_RESULTS: 'Результаты тестирования',
    CONTRACT: 'Договор',
    COMMERCIAL_PROPOSES: 'Коммерческие предложения',
    COMMERCIAL_PROPOSE_INFO: 'Информация о коммерческом предложении',
    FIS_REPORTS: 'Отчеты ФИС',
    CUSTOMERSJOBTITLE: 'Должности заказчиков',

    PROGRAM_TYPES: 'Типы программ обучения',
    PROGRAM_TYPE_INFO: 'Тип программы обучения',
    PROGRAMS: 'Информация о программах обучения',
    PROGRAM: 'Программа обучения',
    NEW_PROGRAM: 'Новая программа обучения',

    LEARNING_MATERIALS: 'Учебные материалы',
    LECTIONS: 'Лекции',
    LECTION: 'Лекция',
    VIDEOS: 'Видео',
    PRESENTATIONS: 'Презентации',
    PRESENTATION: 'Презентация',
    ADLINKS: 'Рекламные ссылки',
    OTHER: 'Другое',

    QUESTIONS: 'Вопросы для тестирования',
    EDIT_QUESTION: 'Редактирование вопроса',

    ANALYTICS: 'Аналитика',

    COMPANY_SETTINGS: 'Настройки компании',
    COMMISSION_MEMBER: 'Редактирование члена комиссии',

    DIPLOM: 'Информация о дипломе',
  },

  USER: {
    MAIN_INFO: 'Основная информация',
    BONUSES_INFO: 'Информация о бонусах',
    ACTIVITY_INFO: 'Информация о активности',

    CHANGE_USER_PASSWORD: 'Сбросить пароль',
    EMAIL_ACTIVE: 'Почта активирована',
    NO_DOCS: 'Нет документов',
  },

  DELIVERY: {
    DELIVERY_TYPE: 'Тип доставки',
    NO_DELIVERY: 'Без доставки',
    EXPRESS_DELIVERY: 'Экспресс доставки',
    RUSSIAN_POST: 'Почта России',

    TRACK_NUMBER: 'Трек-номер',
    DELIVERY_DATE: 'Дата отправки',
    DELIVERY_REGION: 'Регион доставки',
    NEW_TRACK_NUMBER: 'Новый трек-номер',
    EDIT_TRACK_NUMBER: 'Редактировать трек-номер',
    SEND_LAST_TRACK_NUMBER: 'Отправить последний трек-номер на почту',
    IS_CURRENT: 'Текущий?',
  },

  SYSTEM: {
    LOGOUT: 'Выйти',

    SITE_TITLE: 'Академия безопасности',
    FILTERS: 'Фильтры',
    SUBMIT_FILTERS: 'Применить',
    RESET_FILTERS: 'Сбросить фильтры',
    MAIN_FILTERS: 'Основные фильтры',

    FROM: 'с',
    TO: 'по',
    DATES: 'Даты',

    YES: 'Да',
    NO: 'Нет',

    DEADSOULS: 'Мертвые души',

    OPERATORS: 'Операторы',

    SAVE: 'Сохранить',
    ACCEPT: 'Принять',
    CANCEL: 'Отмена',
    BACK: 'Назад',
    DELETE: 'Удалить',
    NONE: 'Отсутствует',
    UPLOAD: 'Загрузить',
    CREATE: 'Создать',
    EDIT: 'Редактировать',
    COPY: 'Копировать',

    ACTIVATED: 'Активирован',
    NOT_ACTIVATED: 'Не активирован',

    PASSED: 'Сдан',
    FAILED: 'Провален',
    NOT_PASSED: 'Не сдан',

    SETTINGS: 'Настройки',
    DOWNLOAD: 'Скачать',
    DOWNLOAD_READY: 'Файл готов к скачиванию',
    DOWNLOAD_FAILED: 'Не удалось скачать файл',

    LINKED_PHASES: 'Привязанные модули',
    REMOVE_ALL: 'Удалить все',

    DRAG_UPLOD: 'Нажмите или перетащите файл для загрузки',
  },

  PLACEHOLDERS: {
    EMAIL: 'expample@mail.ru',
    LINK: 'http://link-example.com',
    NAME: 'Иван',
    SURNAME: 'Петров',
    SECOND_NAME: 'Викторович',
    PHONE: '+7 999 999-99-99',
    TITLE: 'Название...',
    FULL_TITLE: 'Полное наименование...',
    FULL_NAME: 'Иванов А.С',
    PROGRAM: 'Программа обучения...',
    PROFESSION: 'Строитель',
    NO_PERIODS: 'Периоды отсутствуют',
  },

  COMMON: {
    NAME: 'Имя',
    SURNAME: 'Фамилия',
    SECOND_NAME: 'Отчество',
    FULL_NAME: 'ФИО',
    LINK: 'Ссылка',
    FILE: 'Файл',
    EMAIL: 'Email',
    PHONE: 'Номер телефона',
    PHONE_SHORT: `Телефон`,
    PHONE_EXT: 'Доб. номер',
    PROFESSION: 'Должность',
    PROFESSION_ROD: 'Должность (род. падеж)',
    ADDRESS: 'Адрес',
    BIRTHDAY: 'Дата рождения',
    NATIONALITY: 'Гражданство',
    JOB_PLACE: 'Место работы',
    NOTE: 'Комментарий',
    BANK: 'Банк',
    SIGN: 'Подпись',
    PHOTO: 'Фото',
    DIPLOM: 'Диплом',
    WORKING_HOURS: 'Рабочий день (часы)',
    OFFICE_ACCESS: 'Доступ вне офиса',
    PERCENTAGE: 'Проценты',
    MONTH: 'Месяц',
    CONTRACT_NUMBER: 'Номер договора',
    EDUCATION_PROGRAM: 'Программа обучения',

    LEGAL_ADDRESS: 'Юридический адрес',
    POST_ADDRESS: 'Почтовый адрес',
    NSS_ABBR_DIRECTOR: 'Ф.И.О. Руководителя',

    BONUSES: 'Бонусы',
    BONUSES_INACTIVE: 'Неактивные бонусы',
    AVAIBLE_BONUSES: 'Бонусы доступны',

    PASSPORT_SERIAL: 'Серия паспорта',
    PASSPORT_ISSUED: 'Выдан',
    PASSPORT_ISSUED_DATE: 'Дата выдачи',
    PASSPORT_NUMBER: 'Номер паспорта',
    ORGANIZATION_NAME: 'Наименование',
    ACTING_ON_BASIS: 'На основании',
    BASIS_NUMBER: 'Номер документа',
    BASIS_DATE: 'Дата документа',

    TITLE: 'Сокр. название',
    FULL_TITLE: 'Полное название',
    DESCRIPTION: 'Описание',

    DISCOUNT: 'Скидка',
    PRICE: 'Цена',
    PRICES: 'Цены',

    AVAILABILITY: 'Доступен',
    NOT_AVAILABLE: 'Не доступен',
    AVAILABLE: 'Доступен',
  },

  PROMOCODES: {
    CODE: 'Код',
    PROMOCODE_TYPE: 'Тип промокода',
    NEW_PROMOCODE: 'Новый промокод',
    REDACT_PROMOCODE: 'Редактирование промокода',
    DISCOUNT_SIZE: 'Размер скидки (₽)',
    AVAILABILITY_OF_PRICES: "Добавление цен доступно после создания промокода с типом 'Фикисрованная цена' ",
  },

  CUSTOMER: {
    CUSTOMER_CHANGE_QUERY: 'Запрос на изменение заказчика',
    QUERY_ACTIVE: 'Есть запрос',
    QUERY_NOT_ACTIVE: 'Неактивный запрос',
    CUSTOMER_TYPES: 'Тип заказчика',
    NEW_CUSTOMER: 'Добавление заказчика',
    IS_BUDGET: 'Бюджетная организация',
    BUDGET_TYPE: 'Тип бюджета',
    POST_ADDRESS: 'Адрес доставки документов',
    INFORMATION: 'Основная информация',
    DIRECTOR: 'Руководитель',
    DIRECTOR_PROFESSION: 'Должность руководителя',
  },

  MESSAGES: {
    ERROR: 'Ошибка',
    SUCCESS: 'Успех',
    REQUIRE: 'Пожалуйста, заполните поле',
    INVALID: 'Поле имеет неверный формат',
    INVALID_URL: 'Неверный формат url',
    CONFIRM: 'Подтвердите действие',
  },

  DOCUMENTS: {
    INN: 'ИНН',
    OKVED: 'ОКВЭД',
    KPP: 'КПП',
    OGRN: 'ОГРН',
    BIK: 'БИК',
    SNILS: 'СНИЛС',
    CONTRACT: 'Договор',
    BILL: 'Счет',
    ADMISSION_ORDER: 'Приказ о зачислении',
    ACT: 'Акт',
    EXPELL_ORDER: 'Приказ об отчислении',
    PROTOCOL: 'Протокол',
    CERTIFICATE: 'Сертификат',
    CORRESPONDER_ACCOUNT: 'Корреспондентский счет',
    BANK: 'Название банка',
    BANK_ACCOUNT: 'Расчетный счет',
    PERSONAL_ACCOUNT: 'Лицевой счет',
    DEPARTMENT_TITLE: 'Наим. тер-го органа Федерального казначейства',
    TREASURY_ACCOUNT: 'Казначейский счет',
    MAIN_ACCOUNT: 'Единый казначейский счет (ЕКС)',
    NATIONALITY_NAME: 'Гражданство',

    LICENSE_NUMBER: 'Номер лицензии',
    LICENSE_DATE: 'Даты выдачи лицензии',
    LICENSE_ISSUED: 'Лицензия выдана',
    LICENSE_END_DATE: 'Даты окончания лицензии',
  },

  REGISTRY: {
    POST_REPORT: 'Отчет об отправке',
    SHOW_CHANGE_QUERIES: 'Показать запросы на изменение',
    MINTRUD_REPORT: 'Отчет Минтруда',
    EISOT_REPORT: 'Отчет ЕИСОТ',
    DOWNLOAD_MINTRUD_NUMBERS: 'Загрузить номера Минтруда в протоколы',
    UPLOAD_PAYMENT_DOCUMENTS: 'Загрузить платежные данные',

    ACITVE: 'Активный',
    ARCHIVE: 'Архивный',

    CONTRACT: 'Договор',
    CONTRACT_DATE: 'Дата договора',
    CONTRACT_NUMBER: 'Номер договора',
    CONTRACT_NUMBER_SYMB: '№ договора',
    CONTRACT_DUPLICATES: 'Дубликаты договора',

    SECONDARY_DOCS: 'Вторичная документация',

    SOURCE: 'Источник',

    SUM: 'Сумма',
    IS_PAYED: 'Факт оплаты',
    PAYED_NOT_DONE: 'Оплачены более 2мес назад, но не завершены',
    PAID: 'Оплачен',
    NOT_PAID: 'Не оплачен',
    DEBT: 'Задолженность',
    LETTER_OF_GUARANTEE: 'Гарантийное письмо',
    SIGNED_CONTRACT_SCAN: 'Скан подписанного договора',

    CIRTIFIED: 'Аттестован',
    NOT_CIRTIFIED: 'Не аттестован',

    DELIVERY: 'Доставка',

    MANUAL_ADDRESS: 'Ручной ввод адреса',

    NOT_APOINTED: 'Не назначен',

    DOCUMENTS: 'Документы',
    FOLDER: 'Папка',
    PRINTED: 'Распечатано',
    MINTRUD_REGISTRY: 'Реестр Минтруда',

    ACT: 'Акт',
    PROTOCOOL: 'Протокол',

    MAKE_ARCHIVED: 'В архив',
    MAKE_ACTIVE: 'В активные',

    DISPATCH: {
      ISPOSTED: 'Отправлено',
      ONESIDE: 'Одна сторона',
      ANOTHERCONTRACTS: 'Доп. договора',
      LICENSE: 'Лицензия',
      ACCREDITATION: 'Аккредитация',
      COPYCARDPK: 'Копия удостоверения по ПК',
      PROGRAM: 'Программа',
      SHEETDIAGRAMGIFT: 'Лист-схема в подарок',
      CALENDAR: 'Календарь',
      NOTIFICATIONMAIL: 'Письмо уведомление',
      MANUALELB: 'Пособие по ЭлБ',
      MANUALOPP: 'Пособие по ОПП',
      MANUALRNV: 'Пособие по РнВ',
      MANUALPTM: 'Пособие ПТМ',
      JOURNALGO: 'Журнал по ГОиЧС',
      JOURNALPTM: 'Журнал ПТМ',
    },

    COLUMNS: {
      NUMBER: 'Номер договора',
      PRIMARYDATE: 'Дата договора',
      RECIPIENT: 'Получатель',
      WISHES: 'Пож.',
      CUSTOMER: 'Заказчик',
      NOTE: 'Замечания',
      OPERATOR: 'Оператор',
      SOURCE: 'Источник',
      DELIVERYADDRESS: 'Адрес доставки',
      STUDENTTRAININGS: 'Кол-во позиций',
      PROMOCODE: 'Промокод',
      ISPAYED: 'Факт оплаты',
      COST: 'Стоимость договора',
      PAYEDDATE: 'Дата оплаты',
      CREDIT: 'Долг',
      EXAMRESULT: 'Результат обучения',
      SECONDARYDATE: 'Вторичная дата',
      SECONDARYACTDATE: 'Вторичная дата',
      DELIVERY: 'Доставка',
      PROGRAMS: 'Программа обучения',
      PRINT: 'Примечание',
      POSTDATAREPORT: 'Отправление',
      AVAILABILITYOFDOCUMENTS: 'Наличие документов',
      USEREMAILFORLOGINAS: 'Личный кабинет пользователя',
    },
  },

  LISTENERS: {
    CHECK_REASON: 'Причина проверки',
    PAPERWORK: 'Оформление документов',
    SEND_TO_ANOTHER_CABINET: 'Перенести в другой ЛК',
    SEND_PASSWORDS_TO_EMAIL: 'Разослать логины и пароли на email слушателей',
    TRANSFER_IN_CURRENT_DATE: 'Перенос договора в текущую дату',
    UPDATE_PRIMARY_INOVICE: 'Переформирование первичной документации по договору',
    SEND_PRIMARY_INOVICE: 'Отправить первичные документы по договору на почту',
    SEND_SECONDARY_INOVICE: 'Отправить вторичные документы по договору на почту',
    REMOVE_SECONDARY_INOVICE: 'Удалить вторичную документацию',
    CREATE_SECONDARY_INOVICE: 'Сформировать вторичные документы',
    TESTING_RESULTS: 'Результаты тестирования по договору',
    INFO_BONUSES: 'Показать информацию о движении бонусов',
    CHANGE_WRITREOFF_BONUSES: 'Изменить кол-во списанных бонусов',
    BLANK_NUMBER: 'Номер бланка',
    COURSE_NUMBER: 'Номер курса',
    BONUSES_WRITEOFF_AMOUNT: 'Кол-во бонусов для списания',
    ADD_STUDENT: 'Добавить студента',
    ADD_PROGRAM: 'Добавить программу обучения',
    NEW_STUDENT: 'Новый студент',

    CHANGE_QUERY: 'Запрос на изменение слушателя',
    DIPLOMA_QUERY: 'Запрос на проверку диплома',
    CHANGE_ON: 'Предлагается заменить на',

    FORMATS: {
      WORD: 'в формате DOCX',
      PDF: 'в формате PDF',
    },

    //change query labels
    SURNAME: 'Фамилия',
    FIRSTNAME: 'Имя',
    SECONDNAME: 'Отчество',
    JOBPLACE: 'Место работы',
    PROFESSION: 'Должность',
    EMAIL: 'Email',
    SNILS: 'СНИЛС',
    BIRTHDAY: 'Дата рождения',
  },

  DATES: {
    TYPE_DATE: 'Укажите дату',
    TODAY: 'Сегодня',
    LAST_THREE_DAYS: 'Последние три дня',
    DATES_FROM: 'Даты с',
    FROM: 'с',
    TO: 'по',
    START_ACTION: 'Начало действия',
    END_ACTION: 'Конец действия',

    MIN_EDUCATION: 'Минимальный срок обучения в днях',

    LAST_ENTER_DATE: 'Дата последнего входа',
    LAST_CHANGE_PASSWORD_DATE: 'Дата последнего изменения пароля',
  },

  EDUCATION: {
    PROGRAM: 'Программа обучения',
    HOURS: 'Кол-во часов',
    PHASES: 'Модули',
    PHASE: 'Модуль',
    NEW_PHASE: 'Новый модуль',
    CHANGE_PHASE: 'Изменение модуля',
    EXAM_DURTAION: 'Длительность экзамена (сек)',

    QUESTIONS_EXAM_COUNT: 'Общее количество вопросов в экзамене',
    QUESTIONS_TEST_COUNT: 'Общее количество вопросов в тестe',
    ANSWERS_TO_PASS: 'Ответов на прохождение экзамена',
    ANSWERS_TO_PASS_TEST: 'Ответов на прохождение теста',

    FIRST_FOUR_PRICES: 'Цены (Первые 4 по кол-ву чел.)',
    NO_PRICES: 'Нет данных о ценах',
    NORMATIVE_DOCUMENT: 'Норм. обоснование',
    CARD_VALIDITY: 'Срок действия удостоверения в годах (0 - бессрочно)',
    ENDLESS: '(0 - бессрочно)',
    INSPECTOR: 'Кто проверяет',
    ONLY_FOR_ALGORITHM: 'Только для алгоритма подбора программ',

    PEOPLE_COUNT: 'Кол-во чел',
    CARD_TYPE: 'Тип удостоверения',

    DESCRIPTION_LINK: 'Ссылка на описание',
    EDUPLAN_LINK: 'Ссылка на план обучения',

    C_ID: 'ID программы в 1С',

    NEW_PROGRAM_TYPE: 'Новый тип программ',
    ADD_TO_PHASE: 'Привязать к модулю',
    PHASE_LINKING: 'Привязка к модулю',

    PROGRAM_TYPE: 'Тип программы',
    SECTOR: 'Сфера деятельности',
    SHORT: 'Сокр. название',

    SHOW_IN_KP: 'Отображать в КП',
    SHOW_IN_LIST: 'Не показывать общем списке',
    IS_EISOT: 'ЕИСОТ',
    NEED_SNILS: 'Требуется СНИЛС и дата рождения',
    NEED_INN: 'Требуется ИНН',
    NEED_DAT_NAME: 'Требуется ФИО в дательном падеже',
    NEED_DIPLOMA_FOR_ALL: 'Требуется диплом',
    NEED_DIPLOMA_FOR_TESTS: 'Требуется диплом для тестов',
    CAN_MULTI_SELECT: 'Разрешено выбрать несколько программ одного типа',
    CONCLUSION_CERTIFICATE_ID: 'Решение комиссии',
    CONCLUSION_PROTOCOL_ID: 'Заключение комиссии для протокола',

    EXAM_DATE: 'Дата сдачи',
    IS_EXAM_FINISHED: 'Аттестован',
    EXAM: 'Экзамен',
    DIPLOM: 'Диплом',

    SLIDES: 'Слайды',
    UPLOAD_SLIDES: 'Загрузить слайды',

    LECTURES: 'Лекции',
    LECTURE: 'Лекция',
    PREVIEW_CURRENT: 'Предпросмотр существующей',
    VIDEOS: 'Видео',
    VIDEO: 'Видео',
    PRESENTATIONS: 'Презентации',
    PRESENTATION: 'Презентация',
    ADLINK: 'Рекламная ссылка',
  },

  QUESTIONS: {
    QUESTION_TEXT: 'Текст вопроса',
    IS_RIGHT: 'Верный ответ?',
    NEW_QUESTION: 'Новые вопрос',

    NEW_ANSWER: 'Новый ответ',
    EDIT_ANSWER: 'Редактирование ответа',
    ANSWERS: 'Ответы',
    ANSWER_TEXT: 'Текст ответа',
  },

  SETTINGS: {
    SYSTEM: 'Настройки системы',
    ORDERS: 'Приказы',
    COMPANY_INFO: 'Информация о компании',
    COMMISSION_MEMBERS: 'Члены комиссии',
    BONUSES_PERCENT: 'Процент начисления бонусов',
    DEADSOULS_CHECK: 'Дата проверки "мертвых душ"',
    DEADSOULS_MAIL: 'Дата проверки почты "мертвых душ"',
    BITRIX_SYNC_CONTACTS: 'Синхронизация контактов Битрикс',
    BITRIX_SYNC_COMPANIES: 'Синхронизация компаний Битрикс',
    BITRIX_SYNC_LASTDATE: 'Дата последней синхорнизации Битрикс',
    MAIL_SYNC_DATE: 'Дата синхронизации почты',
    NEW_WORKDAY_TIME: 'Время начала нового рабочего дня',
    EMAIL_NOTIFICATION: 'Email для уведомлений администраторов-операторов',
    OFFICE_IP: 'IP-адрес офиса для доступа операторам',
    ROISTAT: 'Роистат',
    SKKHOST: 'СКК хост',
    SKKPRIVATEKEY: 'Приватный ключ для подключения к СКК',
    MINTRUD: 'Показывать кнопки мин. труда',

    COMPANY_NAME: 'Наименование компании',
    COMPANY_FULLNAME: 'Полное наименование компании',
    COMPANY_SIGN: 'Печать компании',
    COMPANY_LOGO: 'Логотип компании',
    COMPANY_PHONE: 'Телефон компании',
    COMPANY_EMAIL: 'Email компании',
    COMPANY_SITE: 'Сайт компании',
    COMPANY_EDUCATION_SITE: 'Сайт обучения компании',

    DIRECTOR_PROFESSION: 'Должность руководителя',
    DIRECTOR_PROFESSION_ROD: 'Должность руководителя (род. падеж)',
    DIRECTOR_SIGN: 'Подпись руководителя',

    SECRETARY: 'Секретарь',
    SECRETARY_SIGN: 'Подпись секретарь',
    PERIOD_SELECTION: 'Выбор периода',
    CREATE_PERIOD: 'Создать период',
    ORDER_DATE: 'Дата приказа',
    ORDER_NUMBER: 'Номер приказа',
    NEW_ORDER: 'Новый приказ',
    EDIT_ORDER: 'Редактирование приказа',
    IS_CHAIRMAN: 'Председатель комиссии?',
    ASSIGN_DATE: 'Дата назначения',
    ASSIGNED_PROGRAM_TYPES: 'Привязанные типы программ',
    ASSIGN_PROGRAM_TYPE: 'Привязать тип программы обучения',
    NEW_COMMISSION_MEMBER: 'Новый член комиссии',

    COMMERCIAL_NUMBER: 'Номер предложения',
    KP_COMMENT: 'Комментарий в шаблон КП',
  },
};
