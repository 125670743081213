import React from "react";
import { LOCALE } from "../../common";
import PageLayout from "../../components/Layout/PageLayout";
import ProgramTypesTable from "../../components/Programs/ProgramTypesTable";

const { PAGE_TITLES } = LOCALE;

const ProgramTypes = () => {
	return (
		<PageLayout pageTitle={PAGE_TITLES.PROGRAM_TYPES}>
			<ProgramTypesTable />
		</PageLayout>
	);
};

export default ProgramTypes;
