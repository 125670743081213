const notableTypes = {
  /** @var string Тип сущности Договор */
  contract: '739e059c-621d-497e-801d-147927ba350c',
  /** @var string Тип сущности Заказчик */
  customer: '708af9c6-35b4-4c32-8f22-4970ff5cbb3e',
  /** @var string Тип сущности Пользователь */
  user: '6629de24-954d-41b4-a2d4-9cd5628bd338',
  /** @var string Тип сущности Диплом */
  diploma: '835f339a-241e-4936-b3f3-def67d4bf254',
};

export default notableTypes;
