import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Col, Typography} from 'antd';

import {LOCALE} from '../../common';
import UseMessage from '../../components/hooks/useMessage';
import PageLayout from '../../components/Layout/PageLayout';
import PhasesTable from '../../components/Programs/PhasesTable';
import ProgramForm from '../../components/Programs/ProgramForm';
import {EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';

const {PAGE_TITLES, EDUCATION} = LOCALE;

const Program = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const loading = useSelector(EduSelectors.loading);
  const programInfo = useSelector(EduSelectors.selectedProgram);

  const {showError, showSuccess} = UseMessage();

  useEffect(() => {
    dispatch(EduMaterialsAPI.programs.getById(id)).catch((err) => {
      showError(err);
      navigate(-1);
    });
  }, [id]);

  const updateProgram = (programId, values) => {
    const data = {...values};

    Object.keys(data).forEach((key) => (data[key] === undefined || data[key] === null) && delete data[key]);

    dispatch(EduMaterialsAPI.programs.update({id: programId, data}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const addPhase = (values) => {
    dispatch(EduMaterialsAPI.phases.create({id, data: values}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const updatePhase = (phaseId, values) => {
    dispatch(EduMaterialsAPI.phases.update({id: phaseId, data: values}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const deletePhase = (phaseId) => {
    dispatch(EduMaterialsAPI.phases.delete(phaseId))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  return (
    <PageLayout pageTitle={PAGE_TITLES.PROGRAM}>
      <ProgramForm onOk={(values) => updateProgram(id, values)} data={programInfo} />
      <Typography.Title>{EDUCATION.PHASES}</Typography.Title>
      <Col xxl={{span: 14}} sm={{span: 22}}>
        <PhasesTable
          updatePhase={updatePhase}
          loading={loading}
          data={programInfo.phases}
          addPhase={addPhase}
          deletePhase={deletePhase}
        />
      </Col>
    </PageLayout>
  );
};

export default Program;
