import React, {useEffect, useState} from 'react';
import {Button, Form, Select, Space, Typography} from 'antd';
import {useDispatch} from 'react-redux';
import {AdminAPI} from '../../store/AdminStore/methods';
import {CUSTOMER_TYPES} from '../../common';
import dayjs from 'dayjs';
import UseMessage from '../hooks/useMessage';

export const ChangeCustomerForm = ({customerId, contractId, onSubmit = () => {}}) => {
  const [customers, setCustomers] = useState(null);
  const {showError, showSuccess} = UseMessage();

  const [loading, setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(customerId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (customerId) {
      setLoading(true);
      dispatch(AdminAPI.customers.getById({id: customerId, ext: true}))
        .then((res) => {
          dispatch(AdminAPI.users.getById({id: res?.payload?.data?.userId, ext: true})).then((res) => {
            setCustomers(res?.payload?.customers);

            setLoading(false);
          });
        })
        .catch(() => setLoading(false));
    }
  }, [customerId]);

  const getCustomerTitle = (customer) => {
    return customer?.customerTypeId === CUSTOMER_TYPES[1].id
      ? `${customer.surname} ${customer?.seconName || ''} ${customer.name}`
      : customer?.organizationName;
  };

  const getCustomerSubtitle = (customer) => {
    return customer?.customerTypeId === CUSTOMER_TYPES[1].id
      ? ''
      : `/ ${customer?.directorSurname} ${customer?.directorName} ${customer?.directorSecondName}`;
  };

  const handleSubmit = () => {
    setLoading(true);
    dispatch(AdminAPI.customers.replaceContractCustomer({contractId, customerId: selectedCustomer}))
      .then(() => {
        setLoading(false);
        showSuccess('Заказчик успешно изменен');
        onSubmit();
      })
      .catch((e) => {
        showError(e);
        setLoading(false);
      });
  };

  return (
    <Space direction="vertical" size="large" style={{width: '100%'}}>
      <Select
        value={selectedCustomer}
        onChange={setSelectedCustomer}
        disabled={!customers}
        loading={loading || !customers}
        style={{width: '100%'}}>
        {customers?.map((customer) => (
          <Select.Option key={customer.id} value={customer.id}>
            <Typography style={{fontWeight: 'bold'}}>{getCustomerTitle(customer)}</Typography>
            <Typography>
              Создан: {dayjs(customer?.['created_at']).format('DD.MM.YYYY')} {getCustomerSubtitle(customer)}
            </Typography>
          </Select.Option>
        ))}
      </Select>
      <Button onClick={handleSubmit} disabled={loading || selectedCustomer === customerId} type="primary">
        Изменить заказчика
      </Button>
    </Space>
  );
};
