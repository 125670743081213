import {Card, Col, Row} from 'antd';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {LOCALE} from '../../common';
import UseMessage from '../../components/hooks/useMessage';
import PageLayout from '../../components/Layout/PageLayout';
import PricesTable from '../../components/Programs/PricesTable';
import ProgramTypesForm from '../../components/Programs/ProgramTypesForm';
import CustomForm from '../../components/UX/CustomForm';
import {EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';

const {PAGE_TITLES, COMMON} = LOCALE;
const {showError, showSuccess} = UseMessage();

const ProgramType = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const typeInfo = useSelector(EduSelectors.selectedType);
  const prices = useSelector(EduSelectors.typePrices);
  const loading = useSelector(EduSelectors.loading);

  const updateType = useCallback(
    (id, values) => {
      const form = values;

      form.conclusionCertificateId = values.conclusionCertificateId ? values.conclusionCertificateId : null;
      form.conclusionProtocolId = values.conclusionProtocolId ? values.conclusionProtocolId : null;

      delete form.cardType;

      dispatch(EduMaterialsAPI.programTypes.update({id, form}))
        .then(() => {
          showSuccess();
          navigate(-1);
        })
        .catch((e) => showError(e));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(EduMaterialsAPI.programTypes.getById(id)).catch((e) => {
      navigate(-1);
      showError(e);
    });
  }, []);

  return (
    <PageLayout>
      <Row style={{width: '100%'}} justify="space-around" gutter={[16, 24]}>
        <Col xl={11} sm={24}>
          <Card
            title={`${PAGE_TITLES.PROGRAM_TYPE_INFO}: ${typeInfo?.name ? typeInfo.name : '...'}`}
            style={{height: '100%'}}>
            <CustomForm
              loading={loading}
              layout={{labelCol: {span: 9}}}
              onOk={(values) => updateType(id, values)}
              onCancel={() => navigate(-1)}
              initialValues={typeInfo}>
              <ProgramTypesForm fullName={typeInfo?.fullName} />
            </CustomForm>
          </Card>
        </Col>
        <Col xl={12} sm={24}>
          <Card title={COMMON.PRICES} style={{height: '100%'}}>
            <PricesTable data={prices} loading={loading} id={typeInfo?.id} />
          </Card>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default ProgramType;
