import {Form, Switch} from 'antd';
import {InboxOutlined} from '@ant-design/icons';
import Upload from 'antd/es/upload/Upload';

const HandMadeDocumentForm = () => {
  return (
    <>
      <Form.Item name="file" valuePropName="file" getValueFromEvent={({file}) => file} noStyle>
        <Upload.Dragger
          maxCount={1}
          accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          beforeUpload={() => false}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Нажмите или перетащите файл в данную область для загрузки</p>
          {/*<p className="ant-upload-hint">Поддерживает загрузку нескольких файлов</p>*/}
        </Upload.Dragger>
      </Form.Item>

      {/*<Form.Item name="isChecked" label="Проверен" valuePropName="checked">*/}
      {/*  <Switch />*/}
      {/*</Form.Item>*/}
    </>
  );
};

export default HandMadeDocumentForm;
