import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AutoComplete, Button, Form} from 'antd';
import {CloseCircleFilled} from '@ant-design/icons';

import {AdminAPI} from '../../store/AdminStore/methods';
import TextArea from 'antd/es/input/TextArea';
import {AdminSelectors} from '../../store/AdminStore';

const PartFields = ({field, index, remove, mainParts, mainPartOptions}) => {
  const [additionalParts, setAdditionalParts] = useState([]);

  const dispatch = useDispatch();
  const loading = useSelector(AdminSelectors.additionalPartsLoading);

  const additionalPartOptions = additionalParts.map((mainPart) => ({value: mainPart.title, label: mainPart.title}));

  const filterOptions = (inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

  const mainPartValidator = (rule, value) => {
    return value.split(/\s+/).filter((word) => word !== '').length >= 3
      ? Promise.resolve()
      : Promise.reject(new Error('Основная часть должна содержать минимум 3 части'));
  };

  const getAdditionalParts = (value) => {
    const mainPart = mainParts.find((part) => part.name === value);

    dispatch(AdminAPI.systemContentControl.getAdditionalPartList(mainPart?.uuid)).then((res) => {
      setAdditionalParts(res.payload.data);
    });
  };

  return (
    <div style={{position: 'relative'}}>
      <Form.Item name={[field.name, 'mainPartName']} rules={[{required: true, max: 500, validator: mainPartValidator}]}>
        <AutoComplete
          placeholder="Основная часть"
          options={mainPartOptions}
          onSelect={getAdditionalParts}
          filterOption={filterOptions}>
          <TextArea style={{height: 60}} />
        </AutoComplete>
      </Form.Item>
      <Form.Item name={[field.name, 'additionalPartName']} rules={[{max: 500}]}>
        <AutoComplete
          placeholder="Дополнительная часть"
          loading={loading}
          options={additionalPartOptions}
          filterOption={filterOptions}
        />
      </Form.Item>
      <Form.Item name={[field.name, 'contentNormComment']} rules={[{max: 700}]}>
        <TextArea placeholder="Комментарий" style={{height: 60}} />
      </Form.Item>
      {index > 0 && (
        <Button
          danger
          type="text"
          icon={<CloseCircleFilled />}
          onClick={() => remove(field.name)}
          style={{position: 'absolute', top: -15, right: -15}}
        />
      )}
    </div>
  );
};

export default PartFields;
