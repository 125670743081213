import dayjs from 'dayjs';

export const getValidDate = (date) => {
  if (!date) {
    return null;
  } else if (dayjs(date, 'DD.MM.YYYY').isValid()) {
    return date;
  } else {
    return dayjs(date).format('DD.MM.YYYY');
  }
};
