import {createSlice} from '@reduxjs/toolkit';
import {EduMaterialsAPI} from './methods';
import {AdminAPI} from '../AdminStore/methods';

const initialState = {
  materials: [],
  loading: false,
  uploadForm: {},
  selectedMaterial: {},
  programTypes: [],
  typePrices: [],
  selectedType: null,
  programs: [],
  presentation: {},
  selectedProgram: {
    phases: [],
  },
  materialsTotalCount: 0,
  materialPhases: [],
  phasesLoading: false,
  materialsLoading: false,
  video: null,
};

const eduMaterials = createSlice({
  name: 'eduMaterials',
  initialState,
  reducers: {
    changeFormUploadField: (state, action) => {
      const {key, value} = action.payload;
      state.uploadForm[key] = value;
    },

    clearUploadForm: (state) => {
      state.uploadForm = {};
    },
    selectProgram: (state, action) => {
      state.selectedProgram = state.programs.find((program) => program.id === action.payload);
    },
    changeRedactFormField: (state, action) => {
      const {key, value} = action.payload;
      state.redactForm[key] = value;
    },

    clearSelectedMaterial: (state) => {
      state.selectedMaterial = null;
    },
  },
  extraReducers: {
    //lectures
    [EduMaterialsAPI.lectures.getAll.pending]: (state, action) => {
      state.materialsLoading = true;
    },
    [EduMaterialsAPI.lectures.getAll.fulfilled]: (state, action) => {
      state.materials = action.payload.data;
      state.materialsTotalCount = action.payload.totalCount;
      state.materialsLoading = false;
    },

    [EduMaterialsAPI.lectures.getAll.rejected]: (state, action) => {
      state.materialsLoading = false;
      state.materials = [];
    },
    [EduMaterialsAPI.lectures.create.pending]: (state, action) => {
      state.loading = true;
    },

    [EduMaterialsAPI.lectures.create.fulfilled]: (state, action) => {
      state.loading = false;
    },

    [EduMaterialsAPI.lectures.create.rejected]: (state, action) => {
      state.loading = false;
    },

    [EduMaterialsAPI.lectures.getById.fulfilled]: (state, action) => {
      state.selectedMaterial = action.payload;
      state.loading = false;
    },

    [EduMaterialsAPI.lectures.getById.pending]: (state, action) => {
      state.loading = true;
    },

    [EduMaterialsAPI.lectures.update.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.lectures.update.fulfilled]: (state, action) => {
      state.loading = false;
    },

    //videos
    [EduMaterialsAPI.videos.getAll.pending]: (state, action) => {
      state.materialsLoading = true;
    },
    [EduMaterialsAPI.videos.getAll.fulfilled]: (state, action) => {
      state.materialsTotalCount = action.payload.totalCount;
      state.materials = action.payload.data;
      state.materialsLoading = false;
    },
    [EduMaterialsAPI.videos.getAll.rejected]: (state, action) => {
      state.materialsLoading = false;
      state.materials = [];
    },
    [EduMaterialsAPI.videos.getById.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.videos.getById.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedMaterial = action.payload;
    },
    [EduMaterialsAPI.videos.getById.rejected]: (state, action) => {
      state.loading = false;
      state.selectedMaterial = action.payload;
    },

    //adlinks
    [EduMaterialsAPI.adlinks.getAll.pending]: (state, action) => {
      state.materialsLoading = true;
    },
    [EduMaterialsAPI.adlinks.getAll.fulfilled]: (state, action) => {
      state.materialsTotalCount = action.payload.totalCount;
      state.materials = action.payload.data;
      state.materialsLoading = false;
    },
    [EduMaterialsAPI.adlinks.getAll.rejected]: (state, action) => {
      state.materials = [];
      state.materialsLoading = false;
    },
    [EduMaterialsAPI.adlinks.getById.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.adlinks.getById.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedMaterial = action.payload;
    },
    [EduMaterialsAPI.adlinks.getById.rejected]: (state, action) => {
      state.loading = false;
      state.selectedMaterial = action.payload;
    },

    //other
    [EduMaterialsAPI.other.getAll.pending]: (state, action) => {
      state.materialsLoading = true;
    },
    [EduMaterialsAPI.other.getAll.fulfilled]: (state, action) => {
      state.materialsTotalCount = action.payload.totalCount;
      state.materials = action.payload.data;
      state.materialsLoading = false;
    },
    [EduMaterialsAPI.other.getAll.rejected]: (state, action) => {
      state.materials = [];
      state.materialsLoading = false;
    },
    [EduMaterialsAPI.other.getById.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.other.getById.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedMaterial = action.payload;
    },
    [EduMaterialsAPI.other.getById.rejected]: (state, action) => {
      state.loading = false;
      state.selectedMaterial = action.payload;
    },

    //presentations
    [EduMaterialsAPI.presentations.getAll.pending]: (state, action) => {
      state.materialsLoading = true;
    },
    [EduMaterialsAPI.presentations.getAll.fulfilled]: (state, action) => {
      state.materialsTotalCount = action.payload.totalCount;
      state.materials = action.payload.data;
      state.materialsLoading = false;
    },

    [EduMaterialsAPI.presentations.getAll.rejected]: (state, action) => {
      state.materials = [];
      state.materialsLoading = false;
    },
    [EduMaterialsAPI.presentations.getById.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.presentations.getById.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedMaterial = action.payload;
    },
    [EduMaterialsAPI.presentations.getById.rejected]: (state, action) => {
      state.loading = false;
      state.selectedMaterial = action.payload;
    },

    //link phases
    [EduMaterialsAPI.linkingPhases.getLinkedPhases.pending]: (state, action) => {
      state.phasesLoading = true;
    },
    [EduMaterialsAPI.linkingPhases.getLinkedPhases.rejected]: (state, action) => {
      state.phasesLoading = false;
    },
    [EduMaterialsAPI.linkingPhases.getLinkedPhases.fulfilled]: (state, action) => {
      state.phasesLoading = false;
      state.materialPhases = action.payload;
    },

    [EduMaterialsAPI.linkingPhases.addMaterialToPhase.pending]: (state, action) => {
      state.phasesLoading = true;
    },
    [EduMaterialsAPI.linkingPhases.addMaterialToPhase.rejected]: (state, action) => {
      state.phasesLoading = false;
    },
    [EduMaterialsAPI.linkingPhases.addMaterialToPhase.fulfilled]: (state, action) => {
      state.phasesLoading = false;
    },

    [EduMaterialsAPI.linkingPhases.removeLearningMaterialformAllPhases.pending]: (state, action) => {
      state.phasesLoading = true;
    },
    [EduMaterialsAPI.linkingPhases.removeLearningMaterialformAllPhases.rejected]: (state, action) => {
      state.phasesLoading = false;
    },
    [EduMaterialsAPI.linkingPhases.removeLearningMaterialformAllPhases.fulfilled]: (state, action) => {
      state.phasesLoading = false;
    },

    [EduMaterialsAPI.linkingPhases.removeLearningMaterialformPhase.pending]: (state, action) => {
      state.phasesLoading = true;
    },
    [EduMaterialsAPI.linkingPhases.removeLearningMaterialformPhase.rejected]: (state, action) => {
      state.phasesLoading = false;
    },
    [EduMaterialsAPI.linkingPhases.removeLearningMaterialformPhase.fulfilled]: (state, action) => {
      state.phasesLoading = false;
    },

    //program types

    [EduMaterialsAPI.programTypes.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.programTypes.getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.programTypes = action.payload;
    },
    [EduMaterialsAPI.programTypes.getAll.rejected]: (state, action) => {
      state.loading = false;
    },

    [EduMaterialsAPI.programTypes.getById.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.programTypes.getById.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedType = action.payload;
      state.typePrices = action.payload.prices ?? [];
    },

    [EduMaterialsAPI.programTypes.update.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.programTypes.update.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedType = action.payload.data;
      state.typePrices = action.payload.data.prices ?? [];
    },
    [EduMaterialsAPI.programTypes.update.rejected]: (state, action) => {
      state.loading = false;
    },

    [EduMaterialsAPI.programTypes.create.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.programTypes.create.fulfilled]: (state, action) => {
      state.loading = false;
      state.programTypes = [...state.programTypes, action.payload.data].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }

        return 0;
      });
    },
    [EduMaterialsAPI.programTypes.create.rejected]: (state, action) => {
      state.loading = false;
    },

    [EduMaterialsAPI.programTypes.delete.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.programTypes.delete.fulfilled]: (state, action) => {
      state.loading = false;
      state.programTypes = state.programTypes.filter((programType) => programType.id !== action.meta.arg);
    },
    [EduMaterialsAPI.programTypes.delete.rejected]: (state, action) => {
      state.loading = false;
    },

    //prices
    [EduMaterialsAPI.prices.create.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.prices.create.fulfilled]: (state, action) => {
      state.loading = false;
      state.typePrices = [...state.typePrices, action.payload];
    },
    [EduMaterialsAPI.prices.create.rejected]: (state, action) => {
      state.loading = false;
    },

    [EduMaterialsAPI.prices.delete.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.prices.delete.fulfilled]: (state, action) => {
      state.loading = false;
      state.typePrices = state.typePrices.filter((el) => el.id !== action.meta.arg);
    },
    [EduMaterialsAPI.prices.delete.rejected]: (state, action) => {
      state.loading = false;
    },

    [EduMaterialsAPI.prices.update.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.prices.update.fulfilled]: (state, action) => {
      state.loading = false;
      state.typePrices = state.typePrices.map((price) => (price.id === action.meta.arg.id ? action.payload : price));
    },
    [EduMaterialsAPI.prices.update.rejected]: (state, action) => {
      state.loading = false;
    },

    //programs
    [EduMaterialsAPI.programs.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.programs.getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.programs = action.payload;
    },
    [EduMaterialsAPI.programs.getAll.rejected]: (state, action) => {
      state.loading = false;
      state.programs = [];
    },

    [EduMaterialsAPI.programs.delete.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.programs.delete.fulfilled]: (state, action) => {
      state.loading = false;
      state.programs = state.programs.filter((program) => program.id !== action.meta.arg);
    },
    [EduMaterialsAPI.programs.delete.rejected]: (state, action) => {
      state.loading = false;
    },

    [EduMaterialsAPI.programs.getById.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.programs.getById.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedProgram = action.payload;
    },
    [EduMaterialsAPI.programs.getById.rejected]: (state, action) => {
      state.loading = false;
    },

    [EduMaterialsAPI.programs.getByType.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.programs.getByType.fulfilled]: (state, action) => {
      state.programs = [...action.payload];
      state.loading = false;
    },
    [EduMaterialsAPI.programs.getByType.rejected]: (state, action) => {
      state.loading = false;
    },

    [EduMaterialsAPI.programs.update.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.programs.update.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedProgram = action.payload;
    },
    [EduMaterialsAPI.programs.update.rejected]: (state, action) => {
      state.loading = false;
    },

    //phases
    [EduMaterialsAPI.phases.create.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.phases.create.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedProgram.phases = [...state.selectedProgram?.phases, {...action.meta.arg.data, ...action.payload}];
    },
    [EduMaterialsAPI.phases.create.rejected]: (state, action) => {
      state.loading = false;
    },

    [EduMaterialsAPI.phases.delete.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.phases.delete.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedProgram.phases = state.selectedProgram.phases.filter((phase) => phase.id !== action.meta.arg);
    },
    [EduMaterialsAPI.phases.delete.rejected]: (state, action) => {
      state.loading = false;
    },

    [EduMaterialsAPI.phases.update.pending]: (state, action) => {
      state.loading = true;
    },
    [EduMaterialsAPI.phases.update.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedProgram.phases = state.selectedProgram.phases.map((phase) =>
        phase.id === action.meta.arg.id ? {id: action.meta.arg.id, ...action.meta.arg.data} : phase
      );
    },
    [EduMaterialsAPI.phases.update.rejected]: (state, action) => {
      state.loading = false;
    },

    //content actuality
    [AdminAPI.systemContentControl.checkActualityList.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.systemContentControl.checkActualityList.fulfilled]: (state, action) => {
      const questionsWithNorms = action.payload.map((norm) => norm.itemId);
      state.materials = state.materials.map((material) => {
        if (questionsWithNorms.includes(material.educationMaterialId)) {
          const {contentExists, isAllNormsActual} = action.payload.find(
            (norm) => norm.itemId === material.educationMaterialId
          );
          return {...material, contentExists, isAllNormsActual};
        }
        return material;
      });
      state.loading = false;
    },
    [AdminAPI.systemContentControl.checkActualityList.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const EduActions = eduMaterials.actions;
export const EduSelectors = {
  //materials
  materials: (state) => state.education.materials,
  materialsTotalCount: (state) => state.education.materialsTotalCount,
  loading: (state) => state.education.loading,
  materialsLoading: (state) => state.education.materialsLoading,
  uploadForm: (state) => state.education.uploadForm,
  selectedMaterial: (state) => state.education.selectedMaterial,
  materialPhases: (state) => state.education.materialPhases,
  phasesLoading: (state) => state.education.phasesLoading,
  //types
  programTypes: (state) => state.education.programTypes,
  selectedType: (state) => state.education.selectedType,
  typePrices: (state) => state.education.typePrices,
  //programs
  programs: (state) => state.education.programs,
  selectedProgram: (state) => state.education.selectedProgram,
};

export default eduMaterials.reducer;
