import {createSlice} from '@reduxjs/toolkit';
import {PublicAPI} from './methods';

const initialState = {
  title: '',
  site: '',
};

const publicSlice = createSlice({
  name: 'public',
  initialState,
  reducers: {},
  extraReducers: {
    [PublicAPI.companySettings.pending]: (state) => {},

    [PublicAPI.companySettings.fulfilled]: (state, action) => {
      state.title = action.payload.data.title;
      state.site = action.payload.data.site;
    },
    [PublicAPI.companySettings.rejected]: (state, action) => {},
  },
});

export const PublicSelectors = {
  title: (state) => state.publicStore.title,
  site: (state) => state.publicStore.site,
};

export default publicSlice.reducer;
