import {Button, Divider, Image, Space, Typography} from 'antd';
import {DeleteOutlined, FilePdfOutlined} from '@ant-design/icons';

const FilePreview = ({uploadedImages, onDelete}) => {
  return (
    <>
      <Divider orientation="left">Предпросмотр</Divider>
      <Image.PreviewGroup>
        {uploadedImages &&
          uploadedImages.map((image) => (
            <div
              key={image.uid}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                margin: '20px 24px',
              }}>
              <Space>
                {image.type === 'image/jpeg' || image.type === 'image/png' ? (
                  <Image height={30} key={image.uid} src={URL.createObjectURL(image.originFileObj)} />
                ) : (
                  <FilePdfOutlined style={{fontSize: 20}} />
                )}
                <div style={{maxWidth: '350px'}}>
                  <Typography.Paragraph ellipsis style={{margin: 0}}>
                    {image.originFileObj.name}
                  </Typography.Paragraph>
                </div>
              </Space>
              <Button
                size="small"
                onClick={() => onDelete(image.uid)}
                type="primary"
                icon={<DeleteOutlined />}
                danger
              />
            </div>
          ))}
      </Image.PreviewGroup>
    </>
  );
};

export default FilePreview;
