import React, {useEffect} from 'react';
import {Table, Typography, Button} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {EditOutlined} from '@ant-design/icons';

import UseMessage from '../hooks/useMessage';

import {compare, LOCALE} from '../../common';
import {AdminSelectors} from '../../store/AdminStore';
import {AdminAPI} from '../../store/AdminStore/methods';

import OperatorsEditingForm from './OperatorsEditingForm';
import ModalForm from '../UX/ModalForm';

const {Text} = Typography;
const {COMMON} = LOCALE;

const OperatorsTable = () => {
  const dispatch = useDispatch();
  const operators = useSelector(AdminSelectors.operators);
  const isLoading = useSelector(AdminSelectors.loading);

  const {showError, showSuccess} = UseMessage();

  useEffect(() => {
    dispatch(AdminAPI.operator.getAll());
  }, []);

  const dataSource = operators.filter((item) => item.id !== 'notApointed');

  const sendChanging = (id) => (data) => {
    dispatch(AdminAPI.operator.editOperator({admin: id, data}))
      .then(() => showSuccess('Оператор успешно изменен'))
      .catch((err) => showError(err.message));
  };

  return (
    <>
      <Table
        pagination={{size: 'small'}}
        scroll={{x: 'auto'}}
        rowKey={'id'}
        dataSource={dataSource}
        loading={isLoading}>
        <Table.Column
          title={COMMON.SURNAME}
          dataIndex="surname"
          align="center"
          sorter={(a, b) => compare(a.surname, b.surname)}
        />
        <Table.Column title={COMMON.NAME} dataIndex="firstname" align="center" />
        <Table.Column title={COMMON.SECOND_NAME} dataIndex="secondname" align="center" />
        <Table.Column
          title={COMMON.EMAIL}
          dataIndex="email"
          align="center"
          sorter={(a, b) => compare(a.fatherName, b.fatherName)}
        />
        <Table.Column
          title={COMMON.AVAILABILITY}
          dataIndex="isAvailable"
          align="center"
          width={140}
          render={(available) =>
            available ? (
              <Text type="success">{COMMON.AVAILABLE}</Text>
            ) : (
              <Text type="danger">{COMMON.NOT_AVAILABLE}</Text>
            )
          }
        />
        <Table.Column
          title={COMMON.OFFICE_ACCESS}
          dataIndex="officeOnlyAccess"
          align="center"
          width={180}
          render={(available) =>
            available ? (
              <Text type="success">{COMMON.AVAILABLE}</Text>
            ) : (
              <Text type="danger">{COMMON.NOT_AVAILABLE}</Text>
            )
          }
        />
        <Table.Column
          dataIndex="id"
          align="center"
          render={(id, record) => {
            return (
              <ModalForm
                title="Редактирование оператора"
                initialValues={record}
                formItems={<OperatorsEditingForm />}
                onOk={sendChanging(id)}>
                <Button icon={<EditOutlined />} />
              </ModalForm>
            );
          }}
        />
      </Table>
    </>
  );
};

export default OperatorsTable;
