import {DeleteOutlined} from '@ant-design/icons';
import {Button, List, Modal, Popconfirm, Space, Typography} from 'antd';
import React, {useState} from 'react';
import {LOCALE} from '../common';

const {SYSTEM, MESSAGES} = LOCALE;
const {Text} = Typography;

const PhasesList = ({phases, onDelete, onDeleteAll, loading}) => {
  const [open, setOpen] = useState(false);

  const show = () => {
    setOpen(true);
  };

  const hide = () => setOpen(false);

  const handleCancel = () => {
    hide();
  };

  return (
    <>
      <Modal
        title={SYSTEM.LINKED_PHASES}
        open={open}
        onOk={handleCancel}
        footer={
          <Space>
            <Popconfirm loading={loading} title={MESSAGES.CONFIRM} onConfirm={onDeleteAll}>
              <Button danger>{SYSTEM.REMOVE_ALL}</Button>
            </Popconfirm>
            <Button loading={loading} type="primary" onClick={hide}>
              OK
            </Button>
          </Space>
        }
        onCancel={handleCancel}>
        <List
          bordered
          dataSource={phases}
          loading={loading}
          renderItem={(linkedPhase) => {
            return (
              <List.Item>
                <Space
                  align="center"
                  style={{
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>
                  <Text>
                    <b>{linkedPhase.trainingProgramName}</b>
                    {` : ${linkedPhase.phaseName}`}
                  </Text>
                  <Button onClick={() => onDelete(linkedPhase.phaseId)} danger icon={<DeleteOutlined />} />
                </Space>
              </List.Item>
            );
          }}
        />
      </Modal>
      <Button onClick={show}>{SYSTEM.LINKED_PHASES}</Button>
    </>
  );
};

export default PhasesList;
