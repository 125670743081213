import dayjs from 'dayjs';
import {LOCALE} from '../../common';
import DatePicker from './DatePicker';

const StringDatePicker = ({
  value,
  onChange,
  format,
  shouldFormat = true,
  placeholder = LOCALE.DATES.TYPE_DATE,
  ...other
}) => {
  let date = undefined;
  if (value) {
    date = shouldFormat && dayjs(value, format).isValid() ? dayjs(value, format) : dayjs(value);
  }

  const onChangeHandle = (e, dateString) => {
    if (dateString) {
      if (format) onChange(dayjs(dateString, format).format('DD.MM.YYYY'));
      else onChange(dayjs(dateString).format('DD.MM.YYYY'));
    } else onChange('');
  };

  return <DatePicker value={date} format={format} onChange={onChangeHandle} placeholder={placeholder} {...other} />;
};

export default StringDatePicker;
