import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Checkbox, Form, Input, InputNumber, Select} from 'antd';

import {LOCALE, SECTORS} from '../../common';
import {EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';
import CustomForm from '../UX/CustomForm';

const {Item} = Form;
const {EDUCATION, COMMON, PLACEHOLDERS, DATES} = LOCALE;

const layout = {
  labelCol: {sm: {span: 10}, xxl: {span: 6}},
  wrapperCol: {sm: {span: 12}, xxl: {span: 8}},
};

const ProgramForm = ({data, onOk}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const types = useSelector(EduSelectors.programTypes);

  useEffect(() => {
    dispatch(EduMaterialsAPI.programTypes.getAll());
  }, []);

  return (
    <CustomForm onCancel={() => navigate(-1)} layout={layout} onOk={onOk} initialValues={data}>
      <Item name="trainingProgramTypeId" label={EDUCATION.PROGRAM_TYPE} rules={[{required: true}]}>
        <Select
          filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
          showSearch
          placeholder="Оператор БПЛА">
          {types.map((type) => (
            <Select.Option key={type.id} value={type.id}>
              {type.fullName}
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Item name="sectorId" label={EDUCATION.SECTOR}>
        <Select placeholder="Производство">
          {SECTORS.map((type) => (
            <Select.Option key={type.value} value={type.value}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Item name="name" label={EDUCATION.SHORT} rules={[{required: true}]}>
        <Input placeholder={PLACEHOLDERS.PROGRAM} />
      </Item>
      <Item name="text" label={COMMON.FULL_TITLE} rules={[{required: true}]}>
        <Input placeholder={PLACEHOLDERS.PROGRAM} />
      </Item>
      <Item name="hoursCount" label={EDUCATION.HOURS} rules={[{required: true}]}>
        <InputNumber min={0} placeholder={0} />
      </Item>
      <Item name="expire" label={EDUCATION.CARD_VALIDITY} rules={[{required: true}]}>
        <InputNumber min="0" step="1" placeholder={0} />
      </Item>
      <Item name="minDay" label={DATES.MIN_EDUCATION + ' :'} rules={[{required: true}]}>
        <InputNumber min={0} placeholder={0} />
      </Item>
      <Item name="examQuestionCount" label={EDUCATION.QUESTIONS_EXAM_COUNT} rules={[{required: true}]}>
        <InputNumber min={0} placeholder={0} />
      </Item>
      <Item name="testQuestionCount" label={EDUCATION.QUESTIONS_TEST_COUNT} rules={[{required: true}]}>
        <InputNumber min={0} placeholder={0} />
      </Item>
      <Item name="examCorrectQuestionCount" label={EDUCATION.ANSWERS_TO_PASS} rules={[{required: true}]}>
        <InputNumber min={1} placeholder={0} />
      </Item>
      <Item name="testCorrectQuestionCount" label={EDUCATION.ANSWERS_TO_PASS_TEST} rules={[{required: true}]}>
        <InputNumber min={1} placeholder={0} />
      </Item>
      <Item name="url" label={EDUCATION.DESCRIPTION_LINK}>
        <Input placeholder={PLACEHOLDERS.LINK} />
      </Item>
      <Item name="educationPlanLink" label={EDUCATION.EDUPLAN_LINK}>
        <Input placeholder={PLACEHOLDERS.LINK} />
      </Item>
      <Item name="identifier1C" label={EDUCATION.C_ID}>
        <Input />
      </Item>
      <Item name="inspector" label={EDUCATION.INSPECTOR}>
        <Input.TextArea rows={3} style={{resize: 'none'}} placeholder={PLACEHOLDERS.FULL_NAME} />
      </Item>
      <Item name="isHide" label={EDUCATION.SHOW_IN_LIST} valuePropName="checked">
        <Checkbox />
      </Item>
    </CustomForm>
  );
};

export default ProgramForm;
