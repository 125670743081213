import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import React, { useState } from "react";

function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file) {
	const isJpgOrPng =
		file.type === "image/jpg" ||
		file.type === "image/png" ||
		file.type === "image/jpeg";
	if (!isJpgOrPng) {
		message.error("Вы можете загружать только JPG/PNG файлы!");
	}
	return false;
}

const ImageBase64Upload = ({ value, onChange, name = "avatar", ...props }) => {
	const [loading, setLoading] = useState(false);

	const handleChange = async (info) => {
		setLoading(true);
		// return getBase64(info.file.originFileObj, (value) => {
		//   onChange(value);
		//   setLoading(false);
		// });
		if (info.file.status === "uploading") {
			onChange("");
			setLoading(true);
			return;
		}
		if (info.file) {
			// Get this url from response in real world.
			getBase64(info.file, (value) => {
				// onChange(value.split(",")[1]);
				onChange(value);
				setLoading(false);
			});
		}
	};

	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Выберите файл</div>
		</div>
	);
	return (
		<Upload
			listType='picture-card'
			className='avatar-uploader'
			showUploadList={false}
			beforeUpload={beforeUpload}
			onChange={handleChange}
			{...props}
		>
			{!loading && value ? (
				<img src={value} alt='Подпись' style={{ width: "100%" }} />
			) : (
				uploadButton
			)}
		</Upload>
	);
};

export default ImageBase64Upload;
