import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Col, Row, Space, Tag, Typography} from 'antd';

import {CUSTOMER_TYPES} from '../../common';
import {loginAsUser} from '../../utils/logInAsUser';
import styles from './CustomerInfoLink.module.css';
import UseMessage from '../hooks/useMessage';
import {CopyOutlined} from '@ant-design/icons';

const CustomerInfoLink = ({customer}) => {
  const dispatch = useDispatch();
  const {showSuccess} = UseMessage();
  let tooltipTitle = '';

  const {customerTypeId, customerTypeName, organizationName, surname, firstname} = customer;

  const copyValueToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    showSuccess(`Номер договора скопиравн в буфер обмена`);
  };

  if (customerTypeId === CUSTOMER_TYPES[0].id) {
    tooltipTitle = (
      <Link to={`/customer/${customer.id}`} component={Typography.Link}>
        <Typography.Paragraph className={styles.customer_link}>{organizationName}</Typography.Paragraph>
      </Link>
    );
  } else if (customerTypeId === CUSTOMER_TYPES[1].id) {
    tooltipTitle = (
      <Link to={`/customer/${customer.id}`} component={Typography.Link}>
        <Typography.Paragraph className={styles.customer_link}>
          {surname} {firstname}
        </Typography.Paragraph>
      </Link>
    );
  } else {
    tooltipTitle = (
      <Link to={`/customer/${customer.id}`} component={Typography.Link}>
        <Typography.Paragraph className={styles.customer_link}>{organizationName}</Typography.Paragraph>
      </Link>
    );
  }

  return (
    <Space wrap size="small" style={{marginBottom: '15px'}}>
      <Col>
        <Row>{tooltipTitle}</Row>
        <Row gutter={[10, 10]}>
          {customer.contracts.map((contract) => (
            <>
              <Link
                key={contract.id}
                to={`/registry/students/${contract.id}`}
                component={Typography.Link}
                style={contract?.isPayedOnline === true ? {textDecoration: 'underline'} : undefined}
                className="link">
                {contract.number}
                {contract.inArchiveDate ? ' (арх.)' : null}
                &nbsp;&nbsp;
              </Link>
              <Tag
                onClick={() => {
                  copyValueToClipboard(contract.number);
                }}
                style={{cursor: 'pointer'}}>
                <CopyOutlined />
              </Tag>
            </>
          ))}

          <span>&nbsp;&nbsp;</span>

          <Typography.Link onClick={loginAsUser(dispatch, customer.userEmail)} className="link">
            (Личный кабинет)
          </Typography.Link>
        </Row>
      </Col>
      {/* <Tooltip title={tooltipTitle}>
        <Button icon={<InfoCircleOutlined />} type="link" style={{color: '#222'}} />
      </Tooltip> */}
    </Space>
  );
};

export default CustomerInfoLink;
