import {useEffect} from 'react';
import {Button, Form, Space, Table} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';

import {AdminAPI} from '../../store/AdminStore/methods';
import {AdminSelectors} from '../../store/AdminStore';

import {DatePicker} from '../../components/UX';

const OperatorAnalytics = () => {
  const dispatch = useDispatch();
  const operatorsAnalytics = useSelector(AdminSelectors.operatorsAnalytics);
  const loading = useSelector(AdminSelectors.loading);
  const onFinish = ({date}) => {
    const data = {
      startDate: date[0].$d,
      finalDate: date[1].$d,
    };

    dispatch(AdminAPI.operatorsAnalytics.getOperatorsAnalytics(data));
  };

  // console.log(dayjs().date(1));

  const endDate = dayjs();
  const startDate = dayjs().date(1);
  const initialValue = {date: [startDate, endDate]};

  useEffect(() => {
    dispatch(
      AdminAPI.operatorsAnalytics.getOperatorsAnalytics({
        startDate: initialValue.date[0],
        finalDate: initialValue.date[1],
      })
    );
  }, []);

  const sortByValue = (name) => (a, b) => a[name] - b[name];

  const filters = operatorsAnalytics.map((operator) => ({
    text: operator.fullname,
    value: operator.id,
  }));

  const dataSource = operatorsAnalytics.map((analytic) => ({
    key: analytic.id,
    ...analytic,
    ...analytic.programTypes,
  }));

  const programTypes = operatorsAnalytics.reduce((accumulator, operator) => {
    for (let programType in operator.programTypes) {
      if (!accumulator.includes(programType)) {
        accumulator.push(programType);
      }
    }
    return accumulator;
  }, []);

  return (
    <>
      <Form name="analytics" autoComplete="off" initialValues={initialValue} onFinish={onFinish}>
        <Space size="large" align="top">
          <Form.Item name="date" label="Выберите дату" rules={[{required: true, message: 'Пожалуйста, выберите дату'}]}>
            <DatePicker.RangePicker style={{width: '100%'}} format={'DD.MM.YYYY'} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Отправить
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <Table dataSource={dataSource} scroll={{x: true}} loading={loading}>
        <Table.Column
          title="ФИО Оператора"
          dataIndex="operatorName"
          align="center"
          fixed="left"
          filters={filters}
          onFilter={(value, record) => value === record.id}
        />
        {programTypes.map((programType, index) => (
          <Table.Column
            key={index}
            title={programType}
            dataIndex={programType}
            align="center"
            sorter={sortByValue(programType)}
          />
        ))}
        <Table.Column title="Договор простой" dataIndex="simple" align="center" sorter={sortByValue('simple')} />
        <Table.Column
          title="Договор (мульти) простой"
          dataIndex="multiSimple"
          align="center"
          sorter={sortByValue('multiSimple')}
        />
        <Table.Column title="Договор сложный" dataIndex="complex" align="center" sorter={sortByValue('complex')} />
        <Table.Column
          title="Договор (мульти) сложный"
          dataIndex="multiComplex"
          align="center"
          sorter={sortByValue('multiComplex')}
        />
        <Table.Column title="Почта России" dataIndex="russianPost" align="center" sorter={sortByValue('russianPost')} />
        <Table.Column
          title="Время договоров в минутах"
          dataIndex="workTime"
          align="center"
          sorter={sortByValue('workTime')}
        />
        <Table.Column
          title="Поправочный коэффициент"
          dataIndex="correctionFactor"
          align="center"
          sorter={sortByValue('correctionFactor')}
        />
        <Table.Column
          title="Общее рабочее время в минутах"
          dataIndex="totalWorkTime"
          align="center"
          sorter={sortByValue('totalWorkTime')}
        />
        <Table.Column
          title="Расчетный коээфициент приоритета договоров"
          dataIndex="contractPriorityRatio"
          align="center"
          sorter={sortByValue('contractPriorityRatio')}
        />
        {/*<Table.Column*/}
        {/*  title="Кол-во контрактов"*/}
        {/*  dataIndex="contracts_count"*/}
        {/*  align="center"*/}
        {/*  sorter={sortByValue('contracts_count')}*/}
        {/*/>*/}
        {/*<Table.Column title="Итого/ руб." dataIndex="totalPrice" align="center" sorter={sortByValue('totalPrice')} />*/}
      </Table>
    </>
  );
};

export default OperatorAnalytics;
