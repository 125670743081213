import {Input, Form, Switch, Col} from 'antd';

const UsersFilter = () => {
  return (
    <Col span={15}>
      <Form.Item name="surname" label="Фамилия">
        <Input placeholder="Иванов" />
      </Form.Item>
      <Form.Item name="firstname" label="Имя">
        <Input placeholder="Иван" />
      </Form.Item>
      <Form.Item name="secondname" label="Отчество">
        <Input placeholder="Иванович" />
      </Form.Item>
      <Form.Item name="email" label="Электронная почта">
        <Input placeholder="ivanov@mail.ru" />
      </Form.Item>
      <Form.Item name="phone" label="Номер телефона">
        <Input placeholder="+7 (222) 222-22-22" />
      </Form.Item>
      <Form.Item name="contractNumber" label="Номер договора">
        <Input placeholder="2019-40Д" />
      </Form.Item>
      <Form.Item name="withDeleted" label="Учитывать удаленных пользователей" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Col>
  );
};

export default UsersFilter;
