import {useEffect} from 'react';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Select} from 'antd';
import {PROMOCODE_TYPES} from '../../common';
import {RegistySelectors} from '../../store/RegistryStore';
import {useParams} from 'react-router-dom';

const PromoCodeModal = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const promoCodes = useSelector(RegistySelectors.promoCodes);
  const isLoading = useSelector(RegistySelectors.loading);

  useEffect(() => {
    dispatch(RegistyAPI.promocode.getAll({withPrices: true, contractId: id}));
  }, []);

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const promoCodesOptions = promoCodes.map((promoCode) => {
    const multiPrices = promoCode.pricesWithTrainingProgramTypes
      ? promoCode.pricesWithTrainingProgramTypes.replaceAll(', ', ' руб. ')
      : '0';
    const label =
      promoCode.promocodeTypeId === PROMOCODE_TYPES[2].value
        ? `${promoCode.code} - ${promoCode.promocodeTypeName} - ${multiPrices} руб.`
        : `${promoCode.code} - ${promoCode.promocodeTypeName} - ${promoCode.discount} руб.`;
    return {
      value: promoCode.code,
      label,
    };
  });

  return (
    <>
      <Form.Item label="Промокод:" name="promocode">
        <Select
          showSearch
          placeholder="ZIMA - 400 руб."
          filterOption={filterOption}
          options={promoCodesOptions}
          loading={isLoading}
        />
      </Form.Item>
    </>
  );
};

export default PromoCodeModal;
