import {Button, Col, Form, Input, Row, Spin, Typography} from 'antd';
import {MaskedInput} from 'antd-mask-input';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LOCALE} from '../../common';
import {AdminSelectors} from '../../store/AdminStore';
import {AdminAPI} from '../../store/AdminStore/methods';
import UseMessage from '../hooks/useMessage';
import CustomForm from '../UX/CustomForm';
import ImageBase64Upload from '../UX/ImageBase64Upload';
import StringDatePicker from '../UX/StringDatePicker';
import PeriodSelect from './PeriodSelect';

const {SETTINGS, COMMON, DOCUMENTS} = LOCALE;

const Item = ({children, ...props}) => {
  return (
    <Form.Item rules={[{required: true}]} {...props}>
      {children}
    </Form.Item>
  );
};

const CompanyInfo = () => {
  const dispatch = useDispatch();
  const {showSuccess, showError} = UseMessage();
  const [valuesChanged, setValuesChanged] = useState(false);
  const companyInfo = useSelector(AdminSelectors.companyInfo);
  const loading = useSelector(AdminSelectors.loading);
  const selectedInfo = useSelector(AdminSelectors.selectedCompanyInfo);
  const periods = companyInfo.map((settings) => {
    return {dateFrom: settings.fromDate, id: settings.id};
  });

  const fetchInfo = () => {
    dispatch(AdminAPI.companyInfo.getAll());
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleOk = (values) => {
    if (periods.length === 0) createPeriod(values);
    else updateInfo(values);
    setValuesChanged(false);
  };

  const updateInfo = (values) => {
    dispatch(AdminAPI.companyInfo.update({id: selectedInfo.id, data: values}))
      .then(() => showSuccess('Данные успешно обновлены'))
      .catch((e) => showError(e));
  };

  const changePeridod = (value) => {
    dispatch(AdminAPI.companyInfo.getById(value));
  };

  const createPeriod = (values) => {
    dispatch(
      AdminAPI.companyInfo.create({
        ...values,
        fromDate: dayjs().format().toString(),
      })
    )
      .then(() => {
        showSuccess('Период успешно создан');
        fetchInfo();
      })
      .catch((e) => showError(e));
  };

  const onChange = () => {
    if (!valuesChanged) setValuesChanged(true);
  };
  //formsubmit || createnew

  return (
    <>
      <CustomForm
        initialValues={selectedInfo}
        onOk={handleOk}
        disabled={loading}
        onValuesChange={onChange}
        loading={loading}
        layout={{labelCol: {span: 10}}}>
        <Item label={SETTINGS.PERIOD_SELECTION}>
          <Row wrap={false} gutter={[8]}>
            <Col flex={1}>
              <PeriodSelect value={selectedInfo?.id} onChange={changePeridod} periods={periods} />
            </Col>
            <Col>
              <Button onClick={() => createPeriod(selectedInfo)} type="primary">
                {SETTINGS.CREATE_PERIOD}
              </Button>
            </Col>
          </Row>
        </Item>
        <Item required name="title" label={SETTINGS.COMPANY_NAME}>
          <Input />
        </Item>
        <Item required name="fullTitle" label={SETTINGS.COMPANY_FULLNAME}>
          <Input />
        </Item>
        <Item required name="companyLogo" label={SETTINGS.COMPANY_LOGO}>
          <ImageBase64Upload />
        </Item>
        <Item required name="companySign" label={SETTINGS.COMPANY_SIGN}>
          <ImageBase64Upload />
        </Item>
        <Item required name="legalAddress" label={COMMON.LEGAL_ADDRESS}>
          <Input />
        </Item>
        <Item required name="postAddress" label={COMMON.POST_ADDRESS}>
          <Input />
        </Item>
        <Item required name="director" label={COMMON.NSS_ABBR_DIRECTOR}>
          <Input />
        </Item>
        <Item required name="directorProfession" label={SETTINGS.DIRECTOR_PROFESSION}>
          <Input />
        </Item>
        <Item required name="directorProfessionRod" label={SETTINGS.DIRECTOR_PROFESSION_ROD}>
          <Input />
        </Item>
        <Item required name="directorSign" label={SETTINGS.DIRECTOR_SIGN}>
          <ImageBase64Upload />
        </Item>
        <Item required name="document" label={COMMON.ACTING_ON_BASIS}>
          <Input />
        </Item>
        <Item required name="inn" label={DOCUMENTS.INN}>
          <Input />
        </Item>
        <Item required name="kpp" label={DOCUMENTS.KPP}>
          <Input />
        </Item>
        <Item required name="bank" label={DOCUMENTS.BANK}>
          <Input />
        </Item>
        <Item required name="bik" label={DOCUMENTS.BIK}>
          <Input />
        </Item>
        <Item required name="rAccount" label={DOCUMENTS.BANK_ACCOUNT}>
          <Input />
        </Item>
        <Item required name="kAccount" label={DOCUMENTS.CORRESPONDER_ACCOUNT}>
          <Input />
        </Item>
        <Item required name="secretary" label={SETTINGS.SECRETARY}>
          <Input />
        </Item>
        <Item required name="secretarySign" label={SETTINGS.SECRETARY_SIGN}>
          <ImageBase64Upload />
        </Item>
        <Item required name="phone" label={SETTINGS.COMPANY_PHONE}>
          <MaskedInput mask={'+{7} (000) 000-00-00'} />
        </Item>
        <Item required name="email" label={SETTINGS.COMPANY_EMAIL}>
          <Input />
        </Item>
        <Item required name="site" label={SETTINGS.COMPANY_SITE}>
          <Input />
        </Item>
        <Item required name="eduSite" label={SETTINGS.COMPANY_EDUCATION_SITE}>
          <Input />
        </Item>
        <Item required name="licenceNumber" label={DOCUMENTS.LICENSE_NUMBER}>
          <Input />
        </Item>
        <Item required name="licenceIssueDate" label={DOCUMENTS.LICENSE_DATE}>
          <StringDatePicker format={'DD.MM.YYYY'} />
        </Item>
        <Item required name="licenceIssue" label={DOCUMENTS.LICENSE_ISSUED}>
          <Input />
        </Item>
        <Item required={false} name="licenceEndDate" label={DOCUMENTS.LICENSE_END_DATE}>
          <Typography>{selectedInfo?.licenceEndDate}</Typography>
        </Item>
        <Item required name="orderDate" label={SETTINGS.ORDER_DATE}>
          <StringDatePicker format={'DD.MM.YYYY'} />
        </Item>
        <Item required name="orderNumber" label={SETTINGS.ORDER_NUMBER}>
          <Input />
        </Item>
      </CustomForm>
    </>
  );
};

export default CompanyInfo;
