import {Form, Input, InputNumber, Select, Switch} from 'antd';
import {CheckOutlined} from '@ant-design/icons';
import {ADMIN_RIGHTS_LIST} from '../../common';
import {useWatch} from 'antd/lib/form/Form';

const AdminForm = ({editing}) => {
  const passwordValidator = ({getFieldValue}) => ({
    validator(_, value) {
      if (getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Пароли не совпадают!'));
    },
  });

  const adminRights = useWatch('adminRights');

  return (
    <>
      <Form.Item name="login" label="Электронная почта" rules={[{required: true, type: 'email'}]}>
        <Input placeholder="nilina@example.net" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Пароль"
        rules={[{required: !editing, message: 'Слишком короткий пароль!', min: 6}]}>
        <Input.Password placeholder="gArPneGuTy7HwX0" />
      </Form.Item>
      <Form.Item
        name="password_confirmation"
        label="Повторение пароля"
        dependencies={['password']}
        rules={[{required: !editing, message: 'Пожалуйста подтвердите пароль!', min: 6}, passwordValidator]}>
        <Input.Password placeholder="gArPneGuTy7HwX0" />
      </Form.Item>
      <Form.Item name="isOperator" label="Является оператором" valuePropName="checked">
        <Switch checkedChildren={<CheckOutlined />} />
      </Form.Item>
      <Form.Item name="surname" label="Фамилия" rules={[{required: true}]}>
        <Input placeholder="Соловьёв" />
      </Form.Item>
      <Form.Item name="firstname" label="Имя" rules={[{required: true}]}>
        <Input placeholder="Егор" />
      </Form.Item>
      <Form.Item name="secondname" label="Отчество" rules={[{required: true}]}>
        <Input placeholder="Александрович" />
      </Form.Item>
      <Form.Item name="isAvailable" label="Можно распределять договора" valuePropName="checked">
        <Switch checkedChildren={<CheckOutlined />} />
      </Form.Item>
      <Form.Item name="workHours" label="Рабочих часов в рабочем дне" rules={[{required: true}]}>
        <InputNumber min={0} max={24} placeholder="12" />
      </Form.Item>
      {editing && (
        <>
          <Form.Item name="adminRights" label="Права администратора">
            <Select mode="multiple" placeholder="Перенос договоров с аккаунта на аккаунт">
              {ADMIN_RIGHTS_LIST.map((adminRight) => (
                <Select.Option key={adminRight.id} value={adminRight.id}>
                  {adminRight.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label=" ">{adminRights?.length !== 0 && `Кол-во активных прав: ${adminRights?.length}`}</Form.Item>
          <Form.Item name="percentage" label="Корректировка распределения работы">
            <InputNumber
              min={0}
              placeholder="100"
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace('%', '')}
            />
          </Form.Item>
          <Form.Item name="officeOnlyAccess" label="Доступен только в офисе" valuePropName="checked">
            <Switch checkedChildren={<CheckOutlined />} />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default AdminForm;
