import React, {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {ReloadOutlined} from '@ant-design/icons';
import {Button, Collapse, Form, Space, Typography} from 'antd';

import {LOCALE, paramsToObject} from '../../common';

const {Text, Paragraph} = Typography;

//allowClearFields = массив строк, если ключ values там находится, он не будет проверятся на null / undefinded / ' '

const FilterPanel = ({
  resetFilters,
  initialFilters,
  children,
  replaceParams = true,
  formLayout,
  filterLabels = {},
  params = {},
  allowClearFields = [],
  name,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const isFilterPanelOpen = localStorage.getItem('isFilterPanelOpen');
  const [activePanel, setActivePanel] = useState(isFilterPanelOpen ?? ['1']);

  const labelList = [];

  for (const key in initialFilters) {
    if (!filterLabels[key]) {
      continue;
    }
    if (initialFilters[key] !== null && initialFilters[key] !== 'null') {
      if (typeof filterLabels[key] === 'object') {
        labelList.push(filterLabels[key][initialFilters[key]]);
      } else if (initialFilters[key]) {
        labelList.push(filterLabels[key]);
      }
    }
  }

  const onThisFinish = (values) => {
    Object.keys(values).forEach((key) => {
      if (!(allowClearFields.length !== 0 && allowClearFields.includes(key))) {
        if (values[key] === undefined || values[key] === ' ' || values[key] === null) {
          delete values[key];
        }
      } else {
        if (values[key] === undefined) {
          values[key] = null;
        }
      }
    });

    setSearchParams((searchParams) => {
      const prevParams = {};
      searchParams.forEach((value, key) => {
        prevParams[key] = value;
        if (values[key] === null || values[key] === undefined) {
          delete prevParams[key];
        }
      });
      Object.keys(values).forEach((key) => {
        if (values[key] === null || values[key] === undefined) {
          delete values[key];
        }
      });
      return {...prevParams, ...params, ...values};
    });
    //TODO способ ниже не работает как надо
    // setSearchParams({...searchParams.entries(), ...params, ...values}, {replace: replaceParams});
    if (name) {
      localStorage.setItem(name, JSON.stringify(values));
    }
  };

  const onReset = () => {
    form.setFieldsValue(resetFilters);
    resetFilters && Object.keys(resetFilters).map((key) => searchParams.delete(key));
    setSearchParams(searchParams);
    if (name) {
      localStorage.setItem(name, JSON.stringify(resetFilters));
    }
  };

  const childrenWithProps = children && React.cloneElement(children, {form: form});

  const panelButton = (e) => {
    e.stopPropagation();
    onReset();
  };

  const Header = () => {
    return (
      <div style={{width: '100%', display: 'flex', alignItems: 'top'}}>
        <Text style={{flex: '1 0 auto'}}>{LOCALE.SYSTEM.FILTERS}</Text>
        <Paragraph type="secondary" ellipsis={{rows: 2}} style={{marginBottom: 0, marginTop: 0, flex: '1 0 90%'}}>
          {labelList.join(', ')}
        </Paragraph>
        <div style={{flex: '1 0 auto'}}>
          <Button size="small" type="primary" danger onClick={panelButton} icon={<ReloadOutlined />} />
        </div>
      </div>
    );
  };

  return (
    <Collapse
      collapsible={!children && 'disabled'}
      style={{userSelect: 'none'}}
      activeKey={activePanel}
      onChange={(key) => {
        setActivePanel(key);
        localStorage.setItem('isFilterPanelOpen', key[1]);
      }}>
      <Collapse.Panel key={['1']} forceRender={true} header={<Header />}>
        <Form form={form} initialValues={initialFilters} onFinish={onThisFinish} labelAlign="left" {...formLayout}>
          {childrenWithProps}
          <Form.Item noStyle>
            <Space style={{marginTop: '12px'}}>
              <Button type="primary" onClick={form.submit} htmlType="submit">
                {LOCALE.SYSTEM.SUBMIT_FILTERS}
              </Button>

              <Button danger type="primary" onClick={onReset}>
                {LOCALE.SYSTEM.RESET_FILTERS}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};

export default FilterPanel;
