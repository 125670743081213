export const registryFilters = {
  inArchive: {[true]: 'Архивный', [false]: 'Активный'},
  dateType: {primary: 'Договор', secondary: 'Вторичная документация'},
  date: {today: 'Сегодня', three: 'Последние три дня', custom: 'Пользовательские даты'},
  contractNumberFilter: 'Номер договора',
  sourceFilter: 'Источник',
  costFilter: 'Сумма',
  isPayed: 'Факт оплаты',
  isExamFinishedFilter: 'Аттестован',
  isNeedDiplomCheck: 'Проверка диплома',
  deliveryFilter: 'Доставка',
  programFilter: 'Программы обучения',
  deadSoulFilter: 'Мертвые души',
  operatorFilter: 'Операторы',
  payedNotDoneFilter: 'Оплачены более 2мес назад, но не завершены',
  manualAddressFilter: 'Ручной ввод адреса',
  duplicateFilter: 'Дубликаты договора',
  isStudentChangeQueryActive: 'Запрос на изменение слушателя',
};
