import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {LOCALE, PROMOCODE_TYPES, paramsToObject, removeEmptyFields} from '../../common';

import FilterPanel from '../../components/FilterPanel';
import PageLayout from '../../components/Layout/PageLayout';
import PromocodesTable from '../../components/Promocodes/PromocodesTable';
import PromocodesFilters from '../../components/Promocodes/PromocodesFilters';

import {AdminAPI} from '../../store/AdminStore/methods';

const {PAGE_TITLES} = LOCALE;

const defaultFilters = {
  withPrices: true,
  offset: 0,
  limit: 10,
  page: 1,
  promocodeTypeId: null,
  codeOrNote: null,
  trainingProgramTypeId: null,
  isActualOnDate: null,
  isNotActualOnDate: null,
  isUsed: 'all',
  contractId: null,
  contractNumber: null,
};

const layout = {
  labelCol: {xxl: {span: 7}, xl: {span: 9}, xs: {span: 10}},
  wrapperCol: {xxl: {span: 10}, xl: {span: 8}, xs: {span: 8}},
};

const Promocodes = () => {
  const [searchParams] = useSearchParams();

  const savedFilters = localStorage.getItem('promocodesFilters');

  const initialFilters = {...defaultFilters, ...JSON.parse(savedFilters), ...paramsToObject(searchParams)};

  const dispatch = useDispatch();

  const getAllPromocodes = (filters = defaultFilters) => {
    if (filters.isUsed === 'all') {
      delete filters.isUsed;
    }
    if (filters.promocodeTypeId !== PROMOCODE_TYPES[1].value) {
      delete filters.isUsed;
    }

    const clearFilters = removeEmptyFields(filters);

    dispatch(AdminAPI.promocodes.getAll(clearFilters));
  };

  useEffect(() => {
    getAllPromocodes(initialFilters);
  }, [initialFilters]);

  return (
    <PageLayout pageTitle={PAGE_TITLES.PROMOCODER_INFO}>
      <FilterPanel
        initialFilters={initialFilters}
        resetFilters={defaultFilters}
        formLayout={layout}
        allowClearFields={['promocodeTypeId']}
        name="promocodesFilters">
        <PromocodesFilters />
      </FilterPanel>
      <PromocodesTable />
    </PageLayout>
  );
};

export default Promocodes;
