import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Skeleton, Space, Tag, Typography} from 'antd';
import dayjs from 'dayjs';

import {LOCALE} from '../../common';
import PageLayout from '../../components/Layout/PageLayout';
import ListenersAcionsPanel from '../../components/Listeners/ListenersAcionsPanel';
import ListenersForAddTable from '../../components/Listeners/ListenersForAddTable';
import ListenersTable from '../../components/Listeners/ListenersTable';

import {RegistySelectors} from '../../store/RegistryStore';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import tipText from '../../utils/tipText';

const {PAGE_TITLES} = LOCALE;
const ListenersInfo = () => {
  const {id} = useParams();
  const dispatch = useDispatch();

  const docNumber = useSelector(RegistySelectors.contractNumber);
  const listeners = useSelector(RegistySelectors.listeners);
  const listenerFullInfo = useSelector(RegistySelectors.listenerFullInfo);

  useEffect(() => {
    dispatch(RegistyAPI.listeners.fullInformation(id));
  }, []);

  const listenersNumber = listeners.length;
  const listenersCommonTrainings = listeners.reduce((acc, curr) => {
    return acc.concat(curr.studentTrainings);
  }, []);
  const listenersCommonTrainingsNumber = listenersCommonTrainings.length;
  const listenersTrainingsNumber = listenersCommonTrainings.reduce((acc, curr) => {
    if (acc.includes(curr.trainingProgramId)) {
      return acc;
    } else {
      acc.push(curr.trainingProgramId);
      return acc;
    }
  }, []).length;

  const listenersVariables = ['человек', 'человека', 'человек'];
  const listenersTrainingsVariables = ['программа', 'программы', 'програм'];
  const listenersCommonTrainingsVariables = ['позиция', 'позиции', 'позиций'];

  const pageTitle = listenerFullInfo?.color ? (
    <Space align="start" size="middle" wrap={true}>
      <Typography style={{whiteSpace: 'normal'}}>
        Слушатели по Договору №{docNumber} от {dayjs(listenerFullInfo.primaryDocUpdateDate).format('DD.MM.YYYY')}
      </Typography>
      <Tag
        color={listenerFullInfo.color === 'white' ? null : listenerFullInfo.color}
        style={{fontSize: '30px', padding: '6px'}}>
        {listenerFullInfo.contractStatus.name}
      </Tag>
    </Space>
  ) : (
    <Skeleton.Input size="large" style={{width: '500px'}} block />
  );

  return (
    <PageLayout pageTitle={pageTitle} spaceProps={{size: 'large'}}>
      <ListenersAcionsPanel />
      <Typography.Title level={2}>
        {PAGE_TITLES.LISTENERS_OF_CONTRACT}{' '}
        <Typography.Text style={{fontSize: '20px'}}>
          {tipText(listenersNumber, listenersVariables)},{' '}
          {tipText(listenersTrainingsNumber, listenersTrainingsVariables)},{' '}
          {tipText(listenersCommonTrainingsNumber, listenersCommonTrainingsVariables)}
        </Typography.Text>
      </Typography.Title>
      {listenerFullInfo?.wishes ? (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Typography.Title level={5}>Пожелания:</Typography.Title>
          <Typography.Text>{listenerFullInfo.wishes}</Typography.Text>
        </div>
      ) : null}
      <ListenersTable />
      <Typography.Title level={2}>{PAGE_TITLES.LISTENERS_FOR_ADD}</Typography.Title>
      <ListenersForAddTable />
    </PageLayout>
  );
};

export default ListenersInfo;
