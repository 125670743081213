import {Table} from 'antd';
import {useSelector} from 'react-redux';

import {AdminSelectors} from '../../store/AdminStore';

const AnalyticsTable = () => {
  const analytic = useSelector(AdminSelectors.analytic);
  const loading = useSelector(AdminSelectors.loading);

  const dates = analytic.length ? Object.keys(analytic[0])?.slice(0, -2) : [];

  const cellProps = (value) => {
    return {
      style: {color: value ? 'red' : 'rgba(0,0,0,.25)'},
    };
  };

  return (
    <Table
      loading={loading}
      bordered
      scroll={{x: 'auto'}}
      size="small"
      rowKey="name"
      className="analytic"
      dataSource={analytic}
      pagination={false}>
      <Table.Column dataIndex="name" title="Название програмы" align="center" fixed />
      {dates.map((date) => (
        <Table.Column
          key={date}
          dataIndex={date}
          title={date}
          align="center"
          className="analytic__column"
          onCell={(record) => cellProps(record[date])}
        />
      ))}
      <Table.Column
        dataIndex="Всего за месяц"
        title="Всего за месяц"
        align="center"
        className="analytic__column"
        onCell={(record) => cellProps(record['Всего за месяц'])}
      />
    </Table>
  );
};

export default AnalyticsTable;
