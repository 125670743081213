import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Button, DatePicker, Form, Radio, Select, Space, Typography} from 'antd';
import dayjs from 'dayjs';

import {DELIVERY_TYPE, LOCALE} from '../../common';
import {RegistyAPI} from '../../store/RegistryStore/methods';

import UseMessage from '../hooks/useMessage';

const {SYSTEM, DELIVERY} = LOCALE;
const DeliveryForm = ({data}) => {
  const {id} = data;

  const initialValues = {...data, deliveredTimeCheck: data.deliveredTimeCheck ? dayjs(data.deliveredTimeCheck) : null};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const deliveryTypeId = Form.useWatch(['deliveryType', 'id'], form);

  const {showError, showSuccess} = UseMessage();

  const sendTrack = () => {
    dispatch(RegistyAPI.trackNumbers.sendToEmail(id))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const updateDelivery = ({deliveryType, deliveredTimeCheck}) => {
    const data = {
      deliveryTypeId: deliveryType.id,
      deliveredTimeCheck: deliveredTimeCheck ? dayjs(deliveredTimeCheck).format('DD.MM.YYYY') : null,
    };

    if (deliveryType.deliveryRouteId) {
      data.deliveryRouteId = deliveryType.deliveryRouteId;
    }

    dispatch(RegistyAPI.delivery.update({id, data}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const deliveryRouteOptions = initialValues?.deliveryRoutes?.data.map((route) => ({
    value: route.id,
    label: `${route.deliveryRegion} ${route.city} срок доставки ${route.minDay}-${route.maxDay} раб.дн. ${route.cost} руб.`,
  }));

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const goBack = () => navigate(-1);

  return initialValues?.deliveryType?.id ? (
    <Form form={form} initialValues={initialValues} onFinish={updateDelivery} wrapperCol={{span: 19}}>
      <Typography style={{marginBottom: 8}}>{DELIVERY.DELIVERY_TYPE}</Typography>
      <Form.Item name={['deliveryType', 'id']}>
        <Radio.Group>
          <Space direction="vertical">
            {DELIVERY_TYPE.map((delivery) => (
              <Radio key={delivery.value} value={delivery.value}>
                {delivery.name}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      {deliveryTypeId === DELIVERY_TYPE[1].value ? (
        <Form.Item label={DELIVERY.DELIVERY_REGION} name={['deliveryType', 'deliveryRouteId']}>
          <Select
            showSearch
            placeholder="Мурманская область 2-3 дня 500 руб."
            filterOption={filterOption}
            options={deliveryRouteOptions}
          />
        </Form.Item>
      ) : null}
      <Form.Item label={DELIVERY.DELIVERY_DATE} name="deliveredTimeCheck">
        <DatePicker format="DD.MM.YYYY" />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" onClick={form.submit}>
            {SYSTEM.ACCEPT}
          </Button>
          <Button onClick={goBack}>{SYSTEM.CANCEL}</Button>
          <Button onClick={sendTrack}>{DELIVERY.SEND_LAST_TRACK_NUMBER}</Button>
        </Space>
      </Form.Item>
    </Form>
  ) : null;
};

export default DeliveryForm;
