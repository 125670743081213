import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

import {AdminSelectors} from '../../store/AdminStore';
import {AdminAPI} from '../../store/AdminStore/methods';
import PartFields from './PartFields';

const NormsForm = () => {
  const dispatch = useDispatch();

  const mainParts = useSelector(AdminSelectors.normsMainParts);
  const mainPartOptions = mainParts.map((mainPart) => ({value: mainPart.name, label: mainPart.name}));

  useEffect(() => {
    dispatch(AdminAPI.systemContentControl.getMainPartList());
  }, []);

  return (
    <Form.List name="norms">
      {(fields, {add, remove}) => (
        <>
          {fields.map((field, index) => (
            <PartFields
              key={field.key}
              field={field}
              index={index}
              remove={remove}
              mainPartOptions={mainPartOptions}
              mainParts={mainParts}
            />
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Добавить поле
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default NormsForm;
