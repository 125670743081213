import React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {LOCALE} from '../../common';
import UseMessage from '../../components/hooks/useMessage';
import PageLayout from '../../components/Layout/PageLayout';
import LectionFormItems from '../../components/LearningMaterials/LectionFormItems';
import CustomForm from '../../components/UX/CustomForm';
import {EduActions, EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';
import EdumaterialsPhaseLink from '../../components/LearningMaterials/EdumaterialsPhaseLink';
import Norms from '../../components/SystemContentControl/Norms';
const {PAGE_TITLES} = LOCALE;

const layout = {labelCol: {sm: {span: 6}, xl: {span: 4}}, wrapperCol: {xl: {span: 8}}};

const Lection = () => {
  const {id, materialId} = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const lecture = useSelector(EduSelectors.selectedMaterial);
  const loading = useSelector(EduSelectors.loading);

  const {showSuccess, showError} = UseMessage();

  const fetchLecture = () => {
    dispatch(EduMaterialsAPI.lectures.getById(id)).catch(() => {
      navigate('*');
    });
  };

  const updateLecture = ({eduName, file}) => {
    const formData = new FormData();
    formData.append('eduName', eduName);
    if (file) {
      formData.append('lectureContent', file, 'lectureContent');
    }

    dispatch(EduMaterialsAPI.lectures.update({id, data: formData}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const handleLinkPhase = (values) => {
    dispatch(EduMaterialsAPI.linkLearningMaterialToPhase({id, data: values}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchLecture();
    return dispatch(EduActions.clearSelectedMaterial());
  }, []);

  return (
    <PageLayout pageTitle={PAGE_TITLES.LECTION}>
      <CustomForm onCancel={goBack} onOk={updateLecture} loading={loading} initialValues={lecture} layout={layout}>
        <LectionFormItems initialValues={lecture} />
      </CustomForm>
      <EdumaterialsPhaseLink id={id} refetch={fetchLecture} />
      <Norms id={materialId} itemType="educationMaterial" />
    </PageLayout>
  );
};

export default Lection;
