import {Checkbox, Form, Input} from 'antd';
import {LOCALE} from '../../common';
import ImageBase64Upload from '../UX/ImageBase64Upload';
import StringDatePicker from '../UX/StringDatePicker';

const {Item} = Form;
const {COMMON, SETTINGS} = LOCALE;

const MemberItems = () => {
  return (
    <>
      <Item name="name" label={COMMON.FULL_NAME} rules={[{required: true}]}>
        <Input />
      </Item>
      <Item name="profession" label={COMMON.PROFESSION} rules={[{required: true}]}>
        <Input />
      </Item>
      <Item name="professionRod" label={COMMON.PROFESSION_ROD} rules={[{required: true}]}>
        <Input />
      </Item>
      <Item name="sign" label={COMMON.SIGN} rules={[{required: true}]}>
        <ImageBase64Upload />
      </Item>
      <Item name="photo" label={COMMON.PHOTO} rules={[{required: true}]}>
        <ImageBase64Upload />
      </Item>
      <Item name="fromDate" label={SETTINGS.ASSIGN_DATE} rules={[{required: true}]}>
        <StringDatePicker format={'DD.MM.YYYY'} shouldFormat={false} />
      </Item>
      <Item name="isChairman" label={SETTINGS.IS_CHAIRMAN} valuePropName="checked">
        <Checkbox />
      </Item>
    </>
  );
};

export default MemberItems;
