import {Tooltip} from 'antd';
import {CheckOutlined, ExclamationCircleFilled} from '@ant-design/icons';

const NormsActuality = ({contentExists, isAllNormsActual}) => {
  if (!contentExists) {
    return null;
  }

  return isAllNormsActual ? (
    <Tooltip title="Контент актуализирован">
      <CheckOutlined style={{color: '#52c41a', fontSize: '18px'}} />
    </Tooltip>
  ) : (
    <Tooltip title="Требуется актуализация контента">
      <ExclamationCircleFilled style={{color: '#f5222d', fontSize: '18px'}} />
    </Tooltip>
  );
};

export default NormsActuality;
