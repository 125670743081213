import {Row, Col, Form, Checkbox} from 'antd';

const {Item} = Form;

function CheckedFormItem({label, checkboxName, children, labelSpan, disabled, ...props}) {
  return (
    <Item style={{marginBottom: 12, width: '100%'}} {...props}>
      <Row align="middle">
        <Col flex={1}>
          <Item name={checkboxName} style={{marginBottom: 0}} valuePropName="checked">
            <Checkbox disabled={disabled}>{label}</Checkbox>
          </Item>
        </Col>
        {children && <Col span={13}>{children}</Col>}
      </Row>
    </Item>
  );
}

export default CheckedFormItem;
