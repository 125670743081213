import {useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Checkbox, Form, InputNumber, message, Radio} from 'antd';

import {LOCALE} from '../../common';
import {RegistySelectors} from '../../store/RegistryStore';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import PageLayout from '../../components/Layout/PageLayout';
import CustomForm from '../../components/UX/CustomForm';
import UseMessage from '../../components/hooks/useMessage';

const PaymentInfo = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {REGISTRY, SYSTEM} = LOCALE;
  const paymentInfo = useSelector(RegistySelectors.scoreInfo);
  const isLoading = useSelector(RegistySelectors.loading);
  const navigate = useNavigate();
  const {showError} = UseMessage();

  const fetchPaymentInfo = useCallback(() => {
    dispatch(RegistyAPI.score.getByContractId({id})).catch((e) => {
      showError('Ошибка получения информации');
      navigate('-1');
    });
  }, [id, dispatch]);

  useEffect(() => {
    fetchPaymentInfo();
  }, [fetchPaymentInfo]);

  const handleCancel = () => navigate(-1);

  const handleOk = (values) => {
    values.credit = values.credit.toString();
    dispatch(RegistyAPI.score.update({id, ...values}))
      .then(() => {
        message.success(LOCALE.MESSAGES.SUCCESS).then(() => navigate('/'));
      })
      .catch((e) => message.error(e.message));
  };

  return (
    <PageLayout pageTitle={LOCALE.PAGE_TITLES.CHANGE_PAYMENT}>
      <CustomForm onOk={handleOk} initialValues={paymentInfo} onCancel={handleCancel} loading={isLoading}>
        <Form.Item name="credit" label={REGISTRY.DEBT}>
          <InputNumber style={{width: '25%'}} min={0} step={1} formatter={(value) => `${Number(value).toFixed(2)} ₽`} />
        </Form.Item>
        <Form.Item name="isPayed" label={REGISTRY.PAID}>
          <Radio.Group>
            <Radio value={true}>{SYSTEM.YES}</Radio>
            <Radio value={false}>{SYSTEM.NO}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="hasGarant" label={REGISTRY.LETTER_OF_GUARANTEE} valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item name="hasDocScan" label={REGISTRY.SIGNED_CONTRACT_SCAN} valuePropName="checked">
          <Checkbox />
        </Form.Item>
      </CustomForm>
    </PageLayout>
  );
};

export default PaymentInfo;
