import {createSlice} from '@reduxjs/toolkit';
import {AuthUrlWithReturnUrl, defaultRegistryColumns, LOCALE} from '../../common';
import {AdminAPI} from './methods';
import {getValidDate} from '../../utils/getValidDate';

const getOrUpdateColumns = (columns) => {
  if (!columns || Object.keys(columns).length === 0) {
    localStorage.setItem('registrySettings', JSON.stringify(defaultRegistryColumns));
    return defaultRegistryColumns;
  } else return columns;
};

const initialTheme = JSON.parse(localStorage.getItem('theme'));

const initialState = {
  adminInfo: null,
  loading: false,
  customersLoading: false,
  customerInfo: null,
  customersTableCount: 0,
  checkLoading: true,
  theme: initialTheme || 'light',
  admins: [],
  operators: [],
  customers: [],
  customersJobTitle: [],
  source: [],
  registryColumns: getOrUpdateColumns(JSON.parse(localStorage.getItem('registrySettings'))),
  companySettings: {},
  companyInfo: [],
  orders: [],
  memberInfo: null,
  memberTypes: [],
  promocodes: [],
  promocodesTotalCount: 0,
  promocodeInfo: {},
  promocodePrices: [],
  diplom: {},
  fetchError: false,
  analytic: [],
  operatorsAnalytics: [],
  users: [],
  listeners: [],
  usersTotalCount: 0,
  commercialProposes: [],
  commercialProposeInfo: {trainingProgramTypes: [], trainingProgramTypePrices: []},
  contentInfo: {
    contentUuid: '',
    contentExists: false,
    url: '',
    isAllNormsActual: false,
    norms: [],
  },
  userInfo: {
    id: '',
    firstname: '',
    secondname: '',
    surname: '',
    shortName: '',
    phone: '',
    phoneExt: null,
    email: '',
    bonuses: 0,
    bonusesNotActivated: 0,
    isActive: false,
    bonusesEnabled: false,
    isFeedbackMail: false,
    isActivationMail: false,
    customers: [],
  },
  notes: [],
  normHistory: [],
  isNormHistoryLoading: false,
  normsMainParts: [],
  additionalPartsLoading: false,
};

const admin = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setColumns: (state, action) => {
      state.registryColumns = action.payload;
    },
    clearCustomer: (state, action) => {
      state.customerInfo = null;
    },
    clearError: (state, action) => {
      state.fetchError = false;
    },
    clearDiplom: (state, action) => {
      state.diplom = {};
    },
  },
  extraReducers: {
    ///auth

    [AdminAPI.auth.checkAccess.pending]: (state, action) => {
      state.checkLoading = true;
    },
    [AdminAPI.auth.checkAccess.rejected]: (state) => {
      state.checkLoading = false;
      const url = AuthUrlWithReturnUrl();
      document.location.replace(url);
    },
    [AdminAPI.auth.checkAccess.fulfilled]: (state, action) => {
      state.checkLoading = false;
      state.adminInfo = action.payload.data;
    },

    ///admins
    [AdminAPI.admins.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.admins.getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.admins = action.payload.data;
    },
    [AdminAPI.admins.getAll.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.admins.signUp.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.admins.signUp.fulfilled]: (state, action) => {
      state.loading = false;
      state.admins = [...state.admins, action.payload];
    },
    [AdminAPI.admins.signUp.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.admins.editAdmin.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.admins.editAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.admins = state.admins.map((admin) => (admin.id === action.payload.id ? action.payload : admin));
    },
    [AdminAPI.admins.editAdmin.rejected]: (state, action) => {
      state.loading = false;
    },

    ///operator
    [AdminAPI.operator.getAll.pending]: (state) => {
      state.loading = true;
    },
    [AdminAPI.operator.getAll.fulfilled]: (state, action) => {
      state.operators = [...action.payload.data];
      state.loading = false;
    },
    [AdminAPI.operator.getAll.rejected]: (state) => {
      state.loading = false;
    },

    [AdminAPI.operator.editOperator.pending]: (state) => {
      state.loading = true;
    },
    [AdminAPI.operator.editOperator.fulfilled]: (state, action) => {
      state.operators = state.operators.map((operator) =>
        operator.id === action.meta.arg.admin ? {...operator, ...action.payload} : operator
      );
      state.loading = false;
    },
    [AdminAPI.operator.editOperator.rejected]: (state) => {
      state.loading = false;
    },

    ///customers

    [AdminAPI.customers.getInfo.fulfilled]: (state, action) => {
      state.customerInfo = action.payload;
    },

    [AdminAPI.customers.getAll.pending]: (state, action) => {
      state.customersLoading = true;
    },
    [AdminAPI.customers.getAll.fulfilled]: (state, action) => {
      state.customersLoading = false;
      state.customers = action.payload.data;
      state.customersTableCount = action.payload.totalCount;
    },
    [AdminAPI.customers.getAll.rejected]: (state, action) => {
      state.customersLoading = false;
    },

    [AdminAPI.customers.delete.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.customers.delete.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [AdminAPI.customers.delete.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.customers.getById.fulfilled]: (state, action) => {
      state.customerInfo = {
        ...action.payload.data,
        passportIssuedDate: getValidDate(action.payload.data.passportIssuedDate),
      };
    },
    [AdminAPI.customers.getCountByFilter.fulfilled]: (state, action) => {
      state.customerQueryCount = action.payload;
    },

    [AdminAPI.customers.update.fulfilled]: (state, action) => {
      state.customerInfo = {
        ...action.payload.data,
        passportIssuedDate: getValidDate(action.payload.data.passportIssuedDate),
      };
    },

    [AdminAPI.customersJobTitle.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.customersJobTitle.getAll.fulfilled]: (state, action) => {
      state.customersJobTitle = [...action.payload];
      state.loading = false;
    },
    [AdminAPI.customersJobTitle.getAll.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.customersJobTitle.create.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.customersJobTitle.create.fulfilled]: (state, action) => {
      state.customersJobTitle = [action.payload, ...state.customersJobTitle];
      state.loading = false;
    },
    [AdminAPI.customersJobTitle.create.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.customersJobTitle.deleteOne.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.customersJobTitle.deleteOne.fulfilled]: (state, action) => {
      state.customersJobTitle = state.customersJobTitle.filter((jobTitle) => jobTitle.id !== action.meta.arg);
      state.loading = false;
    },
    [AdminAPI.customersJobTitle.deleteOne.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.customersJobTitle.edit.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.customersJobTitle.edit.fulfilled]: (state, action) => {
      state.customersJobTitle = state.customersJobTitle.map((jobTitle) =>
        jobTitle.id === action.meta.arg.id ? action.payload : jobTitle
      );
      state.loading = false;
    },
    [AdminAPI.customersJobTitle.edit.rejected]: (state, action) => {
      state.loading = false;
    },

    ///promocodes

    [AdminAPI.promocodes.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.promocodes.getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.promocodes = action.payload.data;
      state.promocodesTotalCount = action.payload.totalCount;
    },
    [AdminAPI.promocodes.getAll.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.promocodes.getById.fulfilled]: (state, action) => {
      state.promocodeInfo = action.payload;
    },
    [AdminAPI.promocodes.prices.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.promocodes.prices.getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.promocodePrices = action.payload;
    },
    [AdminAPI.promocodes.prices.getAll.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.promocodes.delete.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.promocodes.delete.fulfilled]: (state, action) => {
      state.loading = false;
      state.promocodes = state.promocodes.filter((promocode) => promocode.id !== action.meta.arg);
    },
    [AdminAPI.promocodes.delete.rejected]: (state, action) => {
      state.loading = false;
    },
    ///users

    [AdminAPI.users.filter.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.users.filter.rejected]: (state, action) => {
      state.loading = false;
    },
    [AdminAPI.users.filter.fulfilled]: (state, action) => {
      state.users = action.payload.data;
      state.usersTotalCount = action.payload.totalCount;
      state.loading = false;
    },

    [AdminAPI.users.update.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.users.update.fulfilled]: (state, action) => {
      state.userInfo = action.payload;
      state.loading = false;
    },
    [AdminAPI.users.update.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.users.getById.pending]: (state, action) => {
      state.userInfo = undefined;
      state.loading = true;
    },
    [AdminAPI.users.getById.fulfilled]: (state, action) => {
      state.userInfo = action.payload;
      state.loading = false;
    },
    [AdminAPI.users.getById.rejected]: (state, action) => {
      state.userInfo = undefined;
      state.loading = false;
    },

    //listeners
    [AdminAPI.listeners.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.listeners.getAll.fulfilled]: (state, action) => {
      state.listeners = action.payload;
      state.loading = false;
    },
    [AdminAPI.listeners.getAll.rejected]: (state, action) => {
      state.loading = false;
    },

    ///settings
    [AdminAPI.companySettings.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.companySettings.getAll.fulfilled]: (state, action) => {
      state.companySettings = action.payload.data;
      state.loading = false;
    },

    [AdminAPI.companySettings.update.pending]: (state, action) => {
      state.loading = true;
    },

    [AdminAPI.companySettings.update.fulfilled]: (state, action) => {
      state.companySettings = action.payload.data;
      state.loading = false;
    },

    ///orders
    [AdminAPI.orders.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.orders.getAll.fulfilled]: (state, action) => {
      state.orders = action.payload.data;
      state.loading = false;
    },

    [AdminAPI.orders.update.pending]: (state, action) => {
      state.loading = true;
    },

    [AdminAPI.orders.update.fulfilled]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.orders.update.rejected]: (state, action) => {
      state.loading = false;
    },

    ///	commissionMembers:
    [AdminAPI.commissionMembers.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.commissionMembers.getAll.fulfilled]: (state, action) => {
      state.commissionMembers = action.payload.data;
      state.loading = false;
    },

    [AdminAPI.commissionMembers.update.pending]: (state, action) => {
      state.loading = true;
    },

    [AdminAPI.commissionMembers.update.fulfilled]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.commissionMembers.update.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.commissionMembers.getById.pending]: (state, action) => {
      state.loading = true;
    },

    [AdminAPI.commissionMembers.getById.fulfilled]: (state, action) => {
      state.loading = false;
      state.memberInfo = action.payload;
    },

    [AdminAPI.commissionMembers.getById.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.commissionMembers.getTypes.pending]: (state, action) => {
      state.loading = true;
    },

    [AdminAPI.commissionMembers.getTypes.fulfilled]: (state, action) => {
      state.loading = false;
      state.memberTypes = action.payload;
    },

    [AdminAPI.commissionMembers.getTypes.rejected]: (state, action) => {
      state.loading = false;
    },

    ///info

    [AdminAPI.companyInfo.getAll.pending]: (state, action) => {
      state.loading = true;
    },

    [AdminAPI.companyInfo.getAll.fulfilled]: (state, action) => {
      const data = action.payload.data;
      const lastAddedInfo = data[data.length - 1];
      state.companyInfo = data;
      if (lastAddedInfo) state.selectedCompanyInfo = lastAddedInfo;
      state.loading = false;
    },

    [AdminAPI.companyInfo.getById.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.companyInfo.getById.fulfilled]: (state, action) => {
      const data = action.payload;
      state.selectedCompanyInfo = data;
      state.loading = false;
    },

    ///diploms

    [AdminAPI.diploms.getById.pending]: (state, action) => {
      state.loading = true;
    },

    [AdminAPI.diploms.getById.fulfilled]: (state, action) => {
      state.loading = false;
      state.diplom = {...action.payload, acceptance: !!action.payload.acceptedDate};
    },

    [AdminAPI.diploms.getById.rejected]: (state, action) => {
      state.fetchError = true;
      state.loading = false;
    },

    [AdminAPI.diploms.create.pending]: (state, action) => {
      state.loading = true;
    },

    [AdminAPI.diploms.create.fulfilled]: (state, action) => {
      state.loading = false;
      state.diplom = action.payload;
    },

    [AdminAPI.diploms.create.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.diploms.update.pending]: (state, action) => {
      state.loading = true;
    },

    [AdminAPI.diploms.update.fulfilled]: (state, action) => {
      state.loading = false;
      state.diplom = {...action.payload, acceptance: !!action.payload.acceptedDate};
    },

    [AdminAPI.diploms.update.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.diploms.deleteFile.pending]: (state, action) => {
      state.loading = true;
    },

    [AdminAPI.diploms.deleteFile.fulfilled]: (state, action) => {
      state.loading = false;
      // state.diplom = action.payload;
    },

    [AdminAPI.diploms.deleteFile.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.operatorsAnalytics.getOperatorsAnalytics.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.operatorsAnalytics.getOperatorsAnalytics.fulfilled]: (state, action) => {
      state.operatorsAnalytics = [...action.payload.data];
      state.loading = false;
    },
    [AdminAPI.operatorsAnalytics.getOperatorsAnalytics.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.analytic.getAnalytic.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.analytic.getAnalytic.fulfilled]: (state, action) => {
      state.analytic = Object.keys(action.payload.data).map((key) => ({
        name: key,
        ...action.payload.data[key],
      }));
      state.loading = false;
    },
    [AdminAPI.analytic.getAnalytic.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.analytic.getAnalyticXLSX.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.analytic.getAnalyticXLSX.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [AdminAPI.analytic.getAnalyticXLSX.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.analytic.getAnalyticRating.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.analytic.getAnalyticRating.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [AdminAPI.analytic.getAnalyticRating.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.analytic.getAnalyticFull.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.analytic.getAnalyticFull.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [AdminAPI.analytic.getAnalyticFull.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.commercialProposes.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.commercialProposes.getAll.fulfilled]: (state, action) => {
      state.commercialProposes = action.payload;
      state.loading = false;
    },
    [AdminAPI.commercialProposes.getAll.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.commercialProposes.getById.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.commercialProposes.getById.fulfilled]: (state, action) => {
      state.commercialProposeInfo = action.payload.data;
      state.loading = false;
    },
    [AdminAPI.commercialProposes.getById.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.systemContentControl.checkActuality.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.systemContentControl.checkActuality.fulfilled]: (state, action) => {
      state.contentInfo = action.payload.data;
      state.loading = false;
    },
    [AdminAPI.systemContentControl.checkActuality.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.systemContentControl.create.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.systemContentControl.create.fulfilled]: (state, action) => {
      state.contentInfo = {
        contentUuid: action.payload.contentUuid,
        contentExists: true,
        url: action.meta.arg.contentUrl,
        isAllNormsActual: true,
        norms: action.meta.arg.norms.map((norm, index) => ({
          ...norm,
          isActual: true,
          normUuid: action.payload.normsUuids[index],
        })),
      };
      state.loading = false;
    },
    [AdminAPI.systemContentControl.create.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.systemContentControl.addNorm.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.systemContentControl.addNorm.fulfilled]: (state, action) => {
      state.contentInfo = {
        contentUuid: action.payload.json.content_uuid,
        contentExists: true,
        url: action.meta.arg.contentUrl,
        isAllNormsActual: true,
        norms: [
          ...state.contentInfo.norms,
          ...action.meta.arg.norms.map((norm, index) => ({
            ...norm,
            isActual: true,
            normUuid: action.payload.json.norms_uuids[index],
          })),
        ],
      };
      state.loading = false;
    },
    [AdminAPI.systemContentControl.addNorm.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.systemContentControl.getNormHistory.pending]: (state, action) => {
      state.isNormHistoryLoading = true;
    },
    [AdminAPI.systemContentControl.getNormHistory.fulfilled]: (state, action) => {
      state.normHistory = action.payload.data;
      state.isNormHistoryLoading = false;
    },
    [AdminAPI.systemContentControl.getNormHistory.rejected]: (state, action) => {
      state.isNormHistoryLoading = false;
    },

    [AdminAPI.systemContentControl.getMainPartList.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.systemContentControl.getMainPartList.fulfilled]: (state, action) => {
      state.normsMainParts = action.payload.data;
      state.loading = false;
    },
    [AdminAPI.systemContentControl.getMainPartList.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.systemContentControl.getAdditionalPartList.pending]: (state, action) => {
      state.additionalPartsLoading = true;
    },
    [AdminAPI.systemContentControl.getAdditionalPartList.fulfilled]: (state, action) => {
      state.additionalPartsLoading = false;
    },
    [AdminAPI.systemContentControl.getAdditionalPartList.rejected]: (state, action) => {
      state.additionalPartsLoading = false;
    },

    [AdminAPI.systemContentControl.unlinkNorm.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.systemContentControl.unlinkNorm.fulfilled]: (state, action) => {
      state.contentInfo = {
        ...state.contentInfo,
        norms: state.contentInfo.norms.filter((norm) => norm.normUuid !== action.meta.arg.normUuid),
      };
      state.loading = false;
    },
    [AdminAPI.systemContentControl.unlinkNorm.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.systemContentControl.actualize.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.systemContentControl.actualize.fulfilled]: (state, action) => {
      state.contentInfo = {
        ...state.contentInfo,
        norms: state.contentInfo.norms.map((norm) =>
          norm.normUuid === action.meta.arg.normUuid ? {...norm, isActual: true} : norm
        ),
      };
      state.loading = false;
    },
    [AdminAPI.systemContentControl.actualize.rejected]: (state, action) => {
      state.loading = false;
    },

    //notes
    [AdminAPI.note.getAll.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.note.getAll.fulfilled]: (state, action) => {
      state.notes = [...action.payload.data];
      state.loading = false;
    },
    [AdminAPI.note.getAll.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.note.create.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.note.create.fulfilled]: (state, action) => {
      state.notes = [...state.notes, action.payload];
      state.loading = false;
    },
    [AdminAPI.note.create.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.note.edit.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.note.edit.fulfilled]: (state, action) => {
      state.notes = state.notes.map((note) => (note.id === action.meta.arg.id ? action.payload : note));
      state.loading = false;
    },
    [AdminAPI.note.edit.rejected]: (state, action) => {
      state.loading = false;
    },

    [AdminAPI.note.deleteOne.pending]: (state, action) => {
      state.loading = true;
    },
    [AdminAPI.note.deleteOne.fulfilled]: (state, action) => {
      state.notes = state.notes.map((note) => (note.id === action.meta.arg ? action.payload : note));
      state.loading = false;
    },
    [AdminAPI.note.deleteOne.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const AdminActions = admin.actions;
export const AdminSelectors = {
  adminInfo: (state) => state.admin.adminInfo,
  registryColumns: (state) => state.admin.registryColumns,
  theme: (state) => state.admin.theme,
  loading: (state) => state.admin.loading,
  customersLoading: (state) => state.admin.customersLoading,
  fetchError: (state) => state.admin.fetchError,
  checkLoading: (state) => state.admin.checkLoading,
  customersCount: (state) => state.admin.customerQueryCount,
  customersTableCount: (state) => state.admin.customersTableCount,

  admins: (state) => state.admin.admins,

  users: (state) => state.admin.users,
  listeners: (state) => state.admin.listeners,
  usersTotalCount: (state) => state.admin.usersTotalCount,
  userInfo: (state) => state.admin.userInfo,

  operators: (state) => state.admin.operators,
  customers: (state) => state.admin.customers,
  customerInfo: (state) => state.admin.customerInfo,
  customersJobTitle: (state) => state.admin.customersJobTitle,
  source: (state) => state.admin.source,
  promocodes: (state) => state.admin.promocodes,
  promocodesTotalCount: (state) => state.admin.promocodesTotalCount,
  promocodeInfo: (state) => state.admin.promocodeInfo,
  promocodePrices: (state) => state.admin.promocodePrices,

  companySettings: (state) => state.admin.companySettings,
  companyInfo: (state) => state.admin.companyInfo,
  selectedCompanyInfo: (state) => state.admin.selectedCompanyInfo,
  orders: (state) => state.admin.orders,
  commissionMembers: (state) => state.admin.commissionMembers,
  memberInfo: (state) => state.admin.memberInfo,
  memberTypes: (state) => state.admin.memberTypes,

  diplom: (state) => state.admin.diplom,
  notes: (state) => state.admin.notes,

  operatorsAnalytics: (state) => state.admin.operatorsAnalytics,
  analytic: (state) => state.admin.analytic,

  commercialProposes: (state) => state.admin.commercialProposes,
  commercialProposeInfo: (state) => state.admin.commercialProposeInfo,

  contentInfo: (state) => state.admin.contentInfo,
  normHistory: (state) => state.admin.normHistory,
  isNormHistoryLoading: (state) => state.admin.isNormHistoryLoading,
  normsMainParts: (state) => state.admin.normsMainParts,
  additionalPartsLoading: (state) => state.admin.additionalPartsLoading,
};

export default admin.reducer;
