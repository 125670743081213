import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Form, Select, Switch} from 'antd';
import TextArea from 'antd/es/input/TextArea';

import {EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';

const QuestionsFilter = ({form}) => {
  const dispatch = useDispatch();
  const programTypes = useSelector(EduSelectors.programTypes);
  const programs = useSelector(EduSelectors.programs);
  const loading = useSelector(EduSelectors.loading);

  const phaseNumber = Form.useWatch('phaseNumber', form);
  const trainingProgramTypeId = Form.useWatch('trainingProgramTypeId', form);
  const program = Form.useWatch('trainingProgramId', form);

  const modules = programs.find((item) => item.id === program)?.phases ?? [];
  const name = programTypes.find((programType) => programType.id === trainingProgramTypeId)?.name ?? '';

  useEffect(() => {
    if (trainingProgramTypeId) {
      dispatch(EduMaterialsAPI.programs.getByType({filters: {trainingProgramTypeId, name}}));
      form.setFieldValue('trainingProgramId', null);
    } else {
      dispatch(EduMaterialsAPI.programs.getAll());
    }
  }, [trainingProgramTypeId]);

  useEffect(() => {
    dispatch(EduMaterialsAPI.programTypes.getAll());
  }, []);

  return (
    <Col span={15}>
      <Form.Item name="trainingProgramTypeId" label="Тип программы">
        <Select
          filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
          showSearch
          allowClear
          placeholder="БДД для водителей">
          {programTypes.map((programType) => (
            <Select.Option key={programType.id} value={programType.id}>
              {programType.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="trainingProgramId"
        label="Программа обучения"
        required={!!phaseNumber}
        rules={[{required: !!phaseNumber, message: 'Пожалуйста, укажите программу'}]}>
        <Select
          filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
          showSearch
          allowClear
          loading={loading}
          placeholder="БДД_01">
          {programs.map((program) => (
            <Select.Option key={program.id} value={program.id}>
              {program.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="phaseNumber" label="Модуль">
        <Select showSearch allowClear placeholder="Модуль 1">
          {modules.map((module) => (
            <Select.Option key={module.id} value={module.number}>
              {module.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="text" label="Текст вопроса">
        <TextArea placeholder="Какое должно быть минимальное расстояние...?" />
      </Form.Item>
      <Form.Item name="withDeleted" label="Отображать удаленные" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Col>
  );
};

export default QuestionsFilter;
