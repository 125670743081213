import React, {useState} from 'react';
import {Form, Modal} from 'antd';

import CustomForm from './CustomForm';

const ModalForm = ({
  onCancel = () => {},
  onOk = () => {},
  initialValues,
  formProps,
  formInstance,
  modalProps,
  title,
  children,
  closeOnOk = true,
  formItems,
}) => {
  const [form] = Form.useForm(formInstance);
  const [modalVisible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const handleCancel = () => {
    onCancel(form);
    hideModal();
  };

  const handleOk = (values) => {
    onOk(values);
    closeOnOk && hideModal();
  };

  const childrenWithHandler =
    children &&
    React.cloneElement(children, {
      onClick: showModal,
    });

  return (
    <>
      {childrenWithHandler || children}
      <Modal
        onCancel={handleCancel}
        onOk={form.submit}
        title={title}
        style={{textAlign: 'left'}}
        destroyOnClose
        open={modalVisible}
        {...modalProps}>
        <CustomForm
          formInstance={form}
          onOk={handleOk}
          showButtons={false}
          initialValues={initialValues}
          layout={{labelCol: {span: 8}, wrapperCol: 18}}
          {...formProps}>
          {formItems}
        </CustomForm>
      </Modal>
    </>
  );
};

export default ModalForm;
