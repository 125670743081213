import dayjs from "dayjs";
import React from "react";
import TimePicker from "./TimePicker";

const StringTimePicker = ({ value, onChange, format, ...other }) => {
	if (!format) format = "HH:mm:ss";
	let date = undefined;
	if (value) {
		date = dayjs(value, "HH:mm:ss");
	}
	const onChangeHandle = (_, dateString) => {
		if (dateString) onChange(dateString);
		else onChange("00:00:00");
	};
	return (
		<TimePicker
			value={date}
			format={format}
			onChange={onChangeHandle}
			placeholder={Text.chooseTime}
			{...other}
		/>
	);
};

export default StringTimePicker;
