import {Skeleton, Space} from 'antd';

const CommissionMemberLoading = () => {
  return (
    <Space direction="vertical" size={20} style={{width: '100%'}}>
      <Skeleton.Input active block />
      <Skeleton.Input active block />
      <Skeleton.Input active block />
      <Skeleton.Image active />
      <Skeleton.Image active />
      <Skeleton.Input active block style={{width: '50%'}} />
      <Space>
        <Skeleton.Input active />
        <Skeleton.Avatar active />
      </Space>
    </Space>
  );
};

export default CommissionMemberLoading;
