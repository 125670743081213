import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useSearchParams} from 'react-router-dom';
import {Button, Card, List, Table, Typography} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {AdminSelectors} from '../../store/AdminStore';
import {compare, LOCALE, paramsToObject} from '../../common';
import {AdminAPI} from '../../store/AdminStore/methods';

const CommercialProposesTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const commercialProposes = useSelector(AdminSelectors.commercialProposes);
  const isLoading = useSelector(AdminSelectors.loading);

  const {page = 1, offset = 0, limit = 10} = paramsToObject(searchParams);

  useEffect(() => {
    dispatch(AdminAPI.commercialProposes.getAll({offset, limit}));
  }, [page]);

  return (
    <Table
      bordered
      rowKey="id"
      dataSource={commercialProposes.data}
      loading={isLoading}
      scroll={{x: 'auto'}}
      pagination={{
        total: commercialProposes?.totalCount,
        current: Number(page),
        onChange: (page, limit) => setSearchParams({page, limit, offset: (page - 1) * limit}, {replace: true}),
        onShowSizeChange: (page, limit) => setSearchParams({page, limit, offset: (page - 1) * limit}, {replace: true}),
        pageSize: commercialProposes?.limit,
        size: 'small',
      }}>
      <Table.Column
        title={LOCALE.SETTINGS.COMMERCIAL_NUMBER}
        dataIndex="number"
        align="center"
        sorter={(a, b) => compare(a.number, b.number)}
      />
      <Table.Column title={LOCALE.SETTINGS.COMPANY_NAME} dataIndex="companyName" align="center" />
      <Table.Column title={LOCALE.COMMON.EMAIL} dataIndex="email" align="center" />
      <Table.Column
        title={LOCALE.PAGE_TITLES.PROGRAM_TYPES}
        dataIndex="trainingProgramTypes"
        align="center"
        render={(programTypes) => {
          return (
            <List
              size="small"
              style={{minWidth: '420px', margin: 'auto'}}
              grid={{column: 3}}
              dataSource={programTypes.slice(0, 3)}
              renderItem={(programType) => (
                <List.Item style={{margin: 0, padding: 0}} key={programType.id}>
                  <Card size="small" style={{width: '130px'}} title={programType.name}>
                    <Typography.Paragraph ellipsis={{rows: 3}}> {programType.fullName}</Typography.Paragraph>
                  </Card>
                </List.Item>
              )}
            />
          );
        }}
      />
      <Table.Column
        dataIndex="id"
        align="center"
        render={(id) => {
          return (
            <Link to={id}>
              <Button icon={<InfoCircleOutlined />} />
            </Link>
          );
        }}
      />
    </Table>
  );
};

export default CommercialProposesTable;
