import {Button, Form, Input, InputNumber, Modal, Space, Typography} from 'antd';
import CustomForm from '../UX/CustomForm';
import {LOCALE} from '../../common';
import styles from './WriteoffForm.module.css';
import {useForm} from 'antd/lib/form/Form';
import {useDispatch} from 'react-redux';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import PopConfirmButton from '../UX/PopConfirmButton';
import {DeleteOutlined} from '@ant-design/icons';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import UseMessage from '../hooks/useMessage';

const layout = {
  labelCol: {
    sm: {span: 11},
    xl: {
      span: 11,
    },
  },
};

const initialValues = {
  bonusCount: null,
  note: null,
};

export const ChangeWriteoffBounusesModal = ({open, close = () => {}, writeoffBounses, contractId}) => {
  const [form] = useForm();
  const {id} = useParams();
  const [isShowSpenBonuses, setisShowSpenBonuses] = useState(false);
  const [maxBonusesCount, setMaxBonusesCount] = useState(undefined);
  const {showError, showSuccess} = UseMessage();
  const dispatch = useDispatch();

  const handleCancelWriteoff = () => {
    dispatch(RegistyAPI.bonuses.cancelWriteOff(contractId))
      .then((res) => {
        if (res?.payload?.data?.abortUsingBonuses) {
          setisShowSpenBonuses(true);
          setMaxBonusesCount(Math.abs(res?.payload?.data?.abortUsingBonuses.amount));
        }
        showSuccess();
        dispatch(RegistyAPI.listeners.fullInformation(id));
      })
      .catch(() => showError());
  };

  const handleSumbitForm = (data) => {
    if (data?.bonusCount <= 0) {
      form.setFields([{name: 'bonusCount', errors: ['Значение не может меньше 1']}]);
      return;
    }
    dispatch(RegistyAPI.bonuses.changeWriteoffBonuses({...data, contractId}))
      .then(() => {
        dispatch(RegistyAPI.listeners.fullInformation(id));
        setisShowSpenBonuses(false);
        showSuccess();
        close();
      })
      .catch((e) => {
        showError(e?.message);
      });
  };
  const isHaveWriteoffBonuses = writeoffBounses !== undefined;

  return (
    <Modal
      onOk={isHaveWriteoffBonuses ? close : form.submit}
      width={'600px'}
      title="Изменение списанных бонусов"
      open={open}
      destroyOnClose
      onCancel={close}>
      {!isHaveWriteoffBonuses || isShowSpenBonuses ? (
        <>
          <Typography.Title level={3} style={{textAlign: 'center'}}>
            По договору нет списанных бонусов
          </Typography.Title>
          <CustomForm
            formInstance={form}
            initialValues={initialValues}
            onOk={handleSumbitForm}
            className={styles.root}
            layout={layout}
            showButtons={false}>
            <Form.Item
              name="bonusCount"
              required
              rules={[{required: true}]}
              label={LOCALE.LISTENERS.BONUSES_WRITEOFF_AMOUNT}>
              <InputNumber max={maxBonusesCount} className={styles.writeoffInputNumber} />
            </Form.Item>
            <Form.Item name="note" label={'Примечание'}>
              <Input.TextArea />
            </Form.Item>
          </CustomForm>
        </>
      ) : (
        <Space>
          <Typography>Кол-во списанных бонусов: {writeoffBounses?.amount || '0'}</Typography>
          <PopConfirmButton
            danger
            icon={<DeleteOutlined />}
            title="Подвердите действие"
            onClick={handleCancelWriteoff}
            style={{flexShrink: 0}}
            button={'Отменить списание'}
          />
        </Space>
      )}
    </Modal>
  );
};
