import { Button } from "antd";
import React from "react";
import { LOCALE } from "../../common";
import ProgramSelect from "../Programs/ProgramSelect";
import ModalForm from "../UX/ModalForm";

const { EDUCATION } = LOCALE;

const PhaseLinkingModal = ({ onOk, phaseItemName, programItemName }) => {
	return (
		<ModalForm
			onOk={onOk}
			formItems={
				<ProgramSelect
					phaseItemName={phaseItemName}
					programItemName={programItemName}
				/>
			}
			title={EDUCATION.PHASE_LINKING}
		>
			<Button >{EDUCATION.ADD_TO_PHASE}</Button>
		</ModalForm>
	);
};

export default PhaseLinkingModal;
