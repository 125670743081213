export const listenersFilters = {
  firstrname: 'Имя',
  secondname: 'Отчество',
  surname: 'Фамилия',
  courseNumber: 'Номер курса',
  trainingProgramId: 'Программа обучения',
  cardNumber: 'Номер удостоверения',
  jobPlace: 'Место работы',
  profession: 'Должность',
  SNILS: 'СНИЛС',
  contractStatusId: 'Статус договора',
  contractIsArchival: 'Архивный',
  contractNumber: 'Номер договора',
  withDeleted: 'Учитывать удаленных пользователей',
};
