import {Radio, Select} from 'antd';
import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {LOCALE, paramsToObject} from '../../common';
import {ROUTES} from '../../common/routes';
import FilterPanel from '../../components/FilterPanel';
import UseMessage from '../../components/hooks/useMessage';
import PageLayout from '../../components/Layout/PageLayout';
import AdlinkFormItems from '../../components/LearningMaterials/AdlinkFormItems';
import LearningMaterialsFilters, {learningResetFilters} from '../../components/LearningMaterials/LearningFilters';
import LectionFormItems from '../../components/LearningMaterials/LectionFormItems';
import MaterialsTable from '../../components/LearningMaterials/MaterialsTable';
import OtherFormItems from '../../components/LearningMaterials/OtherFormItems';
import PresentationFormItem from '../../components/LearningMaterials/PresentationFormItem';
import VideoFormItems from '../../components/LearningMaterials/VideoFormItems';
import {EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';
import {AdminAPI} from '../../store/AdminStore/methods';
import {educationMaterialsFilters} from '../../components/filters';

const {PAGE_TITLES} = LOCALE;

const layout = {
  labelCol: {xxl: {span: 6}, xs: {span: 4}},
  wrapperCol: {xl: {span: 8}, xxl: {span: 6}, xs: {span: 10}},
};

const LearningMaterials = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [pageData, setPageData] = useState({formItems: null, createHandler: null, deleteHandler: null});

  const dispatch = useDispatch();
  const materials = useSelector(EduSelectors.materials);
  const loading = useSelector(EduSelectors.materialsLoading);
  const materialsTotalCount = useSelector(EduSelectors.materialsTotalCount);

  const {showError, showSuccess} = UseMessage();

  const type = searchParams.get('type') || 'lections';
  const availableTypes = ['lections', 'videos', 'presentations', 'adlinks', 'other'];
  const savedFilters = localStorage.getItem('learningMaterialsFilters');
  const filters = {...JSON.parse(savedFilters), ...paramsToObject(searchParams)};

  const fetchMaterials = (type) => {
    const limit = filters.limit ? filters.limit : 10;
    const offset = filters.offset ? filters.offset : 0;

    delete filters['type'];
    delete filters['page'];

    const data = {...filters, limit, offset};
    switch (type) {
      case 'lections':
        return dispatch(EduMaterialsAPI.lectures.getAll(data))
          .then((res) => {
            const contentData = {idArray: res.payload.data.map((lecture) => lecture.educationMaterialId)};
            dispatch(AdminAPI.systemContentControl.checkActualityList(contentData));
          })
          .catch((e) => {
            showError(e.message);
          });

      case 'presentations':
        return dispatch(EduMaterialsAPI.presentations.getAll(data))
          .then((res) => {
            const contentData = {idArray: res.payload.data.map((presentation) => presentation.educationMaterialId)};
            dispatch(AdminAPI.systemContentControl.checkActualityList(contentData));
          })
          .catch((e) => {
            showError(e.message);
          });

      case 'videos':
        return dispatch(EduMaterialsAPI.videos.getAll(data))
          .then((res) => {
            const contentData = {idArray: res.payload.data.map((video) => video.educationMaterialId)};
            dispatch(AdminAPI.systemContentControl.checkActualityList(contentData));
          })
          .catch((e) => {
            showError(e.message);
          });

      case 'adlinks':
        return dispatch(EduMaterialsAPI.adlinks.getAll(data));

      case 'other':
        return dispatch(EduMaterialsAPI.other.getAll(data));

      default:
        return dispatch(EduMaterialsAPI.lectures.getAll(data))
          .then((res) => {
            const contentData = {idArray: res.payload.data.map((lecture) => lecture.educationMaterialId)};
            dispatch(AdminAPI.systemContentControl.checkActualityList(contentData));
          })
          .catch((e) => {
            showError(e.message);
          });
    }
  };

  const changeType = (e) => {
    setSearchParams((searchParams) => {
      const prevParams = {};
      searchParams.forEach((value, key) => {
        prevParams[key] = value;
      });
      return {...prevParams, ...filters, type: e.target.value, offset: 0, page: 1};
    });
  };

  const createLecture = (values) => {
    const formData = new FormData();
    formData.append('eduName', values.eduName);
    formData.append('lectureContent', values.file, 'lectureContent');
    dispatch(EduMaterialsAPI.lectures.create(formData))
      .then(() => {
        fetchMaterials(type);
        showSuccess();
      })
      .catch(() => showError('Ошибка'));
  };

  const deleteLecture = (id) => {
    dispatch(EduMaterialsAPI.lectures.delete(id))
      .then(() => {
        fetchMaterials(type);
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const createVideo = (values) => {
    dispatch(EduMaterialsAPI.videos.create(values))
      .then(() => {
        fetchMaterials(type);
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const deleteVideo = (id) => {
    dispatch(EduMaterialsAPI.videos.delete(id))
      .then(() => {
        fetchMaterials(type);
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const createAdlink = (values) => {
    dispatch(EduMaterialsAPI.adlinks.create(values))
      .then(() => {
        fetchMaterials(type);
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const deleteAdlink = (id) => {
    dispatch(EduMaterialsAPI.adlinks.delete(id))
      .then(() => {
        fetchMaterials(type);
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const createOther = (values) => {
    dispatch(EduMaterialsAPI.other.create(values))
      .then(() => {
        fetchMaterials(type);
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const deleteOther = (id) => {
    dispatch(EduMaterialsAPI.other.delete(id))
      .then(() => {
        fetchMaterials(type);
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const createPresentaion = (values) => {
    const formData = new FormData();
    formData.append('eduName', values.eduName);

    values.slidesContent.map((slide) => formData.append('slidesContent[]', slide.originFileObj));
    dispatch(EduMaterialsAPI.presentations.create(formData))
      .then(() => {
        fetchMaterials(type);
      })
      .catch((e) => showError(e));
  };

  const deletePresentation = (id) => {
    dispatch(EduMaterialsAPI.presentations.delete(id))
      .then(() => {
        fetchMaterials(type);
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  useEffect(() => {
    if (!availableTypes.includes(type)) {
      setSearchParams({type: 'lections'});
    }
    fetchMaterials(type);
    switch (type) {
      case 'lections':
        return setPageData({
          formItems: <LectionFormItems />,
          createHandler: createLecture,
          deleteHandler: deleteLecture,
          redirect: (id, materialId) => navigate(`lection/${id}/${materialId}`),
        });
      case 'videos':
        return setPageData({
          formItems: <VideoFormItems />,
          createHandler: createVideo,
          deleteHandler: deleteVideo,
          redirect: (id, materialId) => navigate(`video/${id}/${materialId}`),
        });
      case 'presentations':
        return setPageData({
          formItems: <PresentationFormItem />,
          createHandler: createPresentaion,
          deleteHandler: deletePresentation,
          redirect: (id, materialId) => navigate(`presentation/${id}/${materialId}`),
        });
      case 'adlinks':
        return setPageData({
          formItems: <AdlinkFormItems />,
          createHandler: createAdlink,
          deleteHandler: deleteAdlink,
          redirect: (id) => navigate(`adlink/${id}`),
        });
      case 'other':
        return setPageData({
          formItems: <OtherFormItems />,
          createHandler: createOther,
          deleteHandler: deleteOther,
          redirect: (id) => navigate(`other/${id}`),
        });
    }
  }, [type, searchParams]);

  return (
    <PageLayout pageTitle={`${PAGE_TITLES.LEARNING_MATERIALS}: ${PAGE_TITLES[type.toUpperCase()]}`}>
      <Radio.Group disabled={loading} onChange={changeType} defaultValue={type}>
        {availableTypes.map((item, index) => (
          <Radio key={index} value={item}>
            {PAGE_TITLES[item.toUpperCase()]}
          </Radio>
        ))}
      </Radio.Group>
      <FilterPanel
        formLayout={layout}
        resetFilters={learningResetFilters}
        initialFilters={filters}
        replaceParams={false}
        filterLabels={educationMaterialsFilters}
        name="learningMaterialsFilters">
        <LearningMaterialsFilters isVideo={type === 'videos'} />
      </FilterPanel>
      <MaterialsTable
        type={type}
        totalCount={materialsTotalCount}
        data={materials}
        loading={loading}
        addEditItems={pageData.formItems}
        createHandler={pageData.createHandler}
        redirect={pageData.redirect}
        deleteHandler={pageData.deleteHandler}
      />
    </PageLayout>
  );
};

export default LearningMaterials;
