import {Table, Typography} from 'antd';
import dayjs from 'dayjs';
import {bonusesType} from '../../common/bonusesType';

export const BonusesTable = ({bonuses = [], tableHeight}) => {
  const accrual = [
    bonusesType.ForFeedback,
    bonusesType.TestCharge,
    bonusesType.ExtraAgreedCharge,
    bonusesType.PersonalAccount,
  ];
  const writeOff = [bonusesType.Expiration, bonusesType.OTBMarketWriteOff, bonusesType.TransferToOtherEmail];

  return (
    <Table
      dataSource={bonuses}
      pagination={false}
      showHeader={false}
      size="middle"
      style={tableHeight && {maxHeight: tableHeight}}
      scroll={tableHeight && {y: tableHeight}}>
      <Table.Column
        dataIndex={'created_at'}
        width={100}
        render={(value) => <Typography style={{whiteSpace: 'nowrap'}}>{dayjs(value).format('DD.MM.YYYY')}</Typography>}
      />
      <Table.Column
        dataIndex={'contractNumber'}
        width={120}
        render={(value) => <Typography style={{whiteSpace: 'nowrap'}}>{value}</Typography>}
      />
      <Table.Column dataIndex={'bonusTypeName'} render={(value, record) => <Typography>{value}</Typography>} />
      <Table.Column
        dataIndex={'bonusTypeId'}
        width={150}
        render={(value, record) => {
          return (
            <>
              {record.activateDateTime !== null ? (
                record.amount <= 0 ? (
                  <Typography.Text type="danger"> {record.amount} Руб.</Typography.Text>
                ) : (
                  <Typography.Text type="success">{`+${record.amount}`} Руб.</Typography.Text>
                )
              ) : (
                <Typography.Text color="gray"> {record.amount} Руб.</Typography.Text>
              )}
            </>
          );
        }}
      />
      <Table.Column
        dataIndex={'sum'}
        render={(value) => {
          return (
            <>
              <Typography>{`Кол-во бонусов на момент операции: ${value}`}</Typography>
            </>
          );
        }}
      />
    </Table>
  );
};
