import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, Radio, Select, Space} from 'antd';
import dayjs from 'dayjs';

import {AdminSelectors} from '../../store/AdminStore';
import {LOCALE, http} from '../../common';
import {DatePicker} from '../UX';
import ModalForm from '../UX/ModalForm';

import UseMessage from '../hooks/useMessage';
import {AdminAPI} from '../../store/AdminStore/methods';

const {REGISTRY, SYSTEM, DATES} = LOCALE;

const MintrudReportModal = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const operators = useSelector(AdminSelectors.operators);
  const dateField = Form.useWatch('date', form);

  const {showError} = UseMessage();

  const handleDownloadPostReport = (values) => {
    let data;

    if (values.date === 'custom') {
      data = {
        date: values.date,
        dateFrom: dayjs(values.dates[0]).format('YYYY-MM-DD'),
        dateTo: dayjs(values.dates[1]).format('YYYY-MM-DD'),
        operatorId: values.operatorId,
      };
    } else {
      data = {
        date: values.date,
        operatorId: values.operatorId,
      };
    }

    dispatch(AdminAPI.documentation.mintrud(data))
      .then((response) => {
        const url = URL.createObjectURL(response.payload);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Отчет Минтруда.zip';
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
      })
      .catch((e) => showError('отсутствуют данные для реестра МинТруда за данный период'));
  };

  const PostReportItems = () => {
    return (
      <>
        <Form.Item rules={[{required: true}]} label={SYSTEM.DATES} name={'date'}>
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={'today'}>{DATES.TODAY}</Radio>
              <Radio value={'three'}>{DATES.LAST_THREE_DAYS}</Radio>
              <Radio value={'custom'}>
                <Form.Item rules={[{required: dateField === 'custom'}]} label={SYSTEM.DATES} name={'dates'}>
                  <DatePicker.RangePicker disabled={!dateField === 'custom'} allowClear={false} />
                </Form.Item>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={SYSTEM.OPERATORS} name="operatorId">
          <Select allowClear placeholder="Петров П.П.">
            {operators.map((operator) => (
              <Select.Option value={operator.id} key={operator.id}>
                {operator.shortName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </>
    );
  };
  return (
    <ModalForm
      closeOnOk={false}
      onOk={handleDownloadPostReport}
      title={REGISTRY.MINTRUD_REPORT}
      formInstance={form}
      formItems={<PostReportItems />}>
      <Button>{REGISTRY.MINTRUD_REPORT}</Button>
    </ModalForm>
  );
};

export default MintrudReportModal;
