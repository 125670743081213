import {useEffect} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Popconfirm, Space, Table} from 'antd';
import {DeleteOutlined, InfoCircleOutlined, PlusOutlined} from '@ant-design/icons';

import {LOCALE, paramsToObject} from '../../common';
import {EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';
import UseMessage from '../hooks/useMessage';
import SearchColumnFilter from '../UX/SearchColumnFilter';

const {COMMON, EDUCATION, MESSAGES} = LOCALE;

const ProgramsTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const programs = useSelector(EduSelectors.programs);
  const loading = useSelector(EduSelectors.loading);

  const {showSuccess, showError} = UseMessage();

  const {page = 1, limit = 10} = paramsToObject(searchParams);

  useEffect(() => {
    dispatch(EduMaterialsAPI.programs.getAll());
  }, []);

  const deleteProgram = (id) => {
    dispatch(EduMaterialsAPI.programs.delete(id))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const columns = [
    {
      title: 'Код',
      align: 'center',
      dataIndex: 'name',
      key: 'name',
      ...SearchColumnFilter('name'),
    },
    {
      title: 'Название',
      align: 'center',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: EDUCATION.HOURS,
      align: 'center',
      dataIndex: 'hoursCount',
      key: 'hoursCount',
    },
    {
      dataIndex: 'id',
      align: 'center',
      title: () => (
        <Link to={`/new-programs/`}>
          <Button type="primary" icon={<PlusOutlined />} />
        </Link>
      ),
      key: 'buttons',
      render: (id, type) => {
        return (
          <Space>
            <Link to={`/programs/${id}`}>
              <Button icon={<InfoCircleOutlined />} />
            </Link>
            <Popconfirm placement="topRight" title={MESSAGES.CONFIRM} onConfirm={() => deleteProgram(id)}>
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      bordered
      columns={columns}
      loading={loading}
      dataSource={programs}
      pagination={{
        showSizeChanger: true,
        pageSize: Number(limit),
        current: Number(page),
        onChange: (page, limit) => setSearchParams({page, limit}, {replace: true}),
        onShowSizeChange: (page, limit) => setSearchParams({page, limit}, {replace: true}),
      }}
    />
  );
};

export default ProgramsTable;
