import { Space, Typography } from "antd";
import React from "react";

const PageLayout = ({ pageTitle, children, spaceProps }) => {
	return (
		<Space
			direction='vertical'
			size={"small"}
			style={{
				width: "100%",
				height:'max-content'
			}}
			{...spaceProps}
		>
			{pageTitle && <Typography.Title level={2}>{pageTitle}</Typography.Title>}
			{children}
		</Space>
	);
};

export default PageLayout;
