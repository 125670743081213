import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import React from "react";
import { LOCALE } from "../../common";

const { SYSTEM } = LOCALE;

const Dragndrop = ({ descipton,fileList, draggerProps, onChange, onDrop }) => {
	return (
		<Dragger fileList={fileList} onChange={onChange} onDrop={onDrop} {...draggerProps}>
			<p className='ant-upload-drag-icon'>
				<InboxOutlined />
			</p>
			<p className='ant-upload-text'>{SYSTEM.DRAG_UPLOD}</p>
			<p className='ant-upload-hint'>{descipton}</p>
		</Dragger>
	);
};

export default Dragndrop;
