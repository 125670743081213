import {useState} from 'react';
import {useDispatch} from 'react-redux';
import UseMessage from '../hooks/useMessage';
import {Button, Modal} from 'antd';
import Dragndrop from '../UX/Dragndrop';
import {LOCALE, http} from '../../common';
import {AdminAPI} from '../../store/AdminStore/methods';

const {REGISTRY} = LOCALE;

const PaymentDocumentsModal = () => {
  const [visible, setVisible] = useState();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const {showSuccess, showError} = UseMessage();

  const handleCancel = () => setVisible(false);

  const showModal = () => setVisible(true);

  const uploadFiles = () => {
    setIsLoading(true);
    const formData = new FormData();
    if (!uploadedFile) {
      setIsLoading(false);
      return showError('Нет загруженного файла');
    }
    formData.append('report', uploadedFile);

    http
      .post(`admin/payment-documents`, formData, {responseType: 'blob'})
      .then((response) => {
        let fileName = response.headers['content-disposition'].split(';')[1].trim().split('=')[1];
        fileName = fileName.replace(new RegExp('"', 'g'), '');
        setUploadedFile(null);
        showSuccess();
        console.log(fileName);

        const link = document.createElement('a');
        link.href = URL.createObjectURL(response.data);
        link.download = fileName;
        link.click();
        link.remove();
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        showError(e);
      });
  };

  return (
    <>
      <Button onClick={showModal}>{REGISTRY.UPLOAD_PAYMENT_DOCUMENTS}</Button>
      <Modal
        title={REGISTRY.UPLOAD_PAYMENT_DOCUMENTS}
        onOk={uploadFiles}
        open={visible}
        onCancel={handleCancel}
        maskClosable={false}
        destroyOnClose
        cancelButtonProps={{loading: isLoading, disabled: isLoading}}
        okButtonProps={{loading: isLoading, disabled: isLoading}}>
        <Dragndrop
          onChange={(e) => setUploadedFile(e.file)}
          draggerProps={{
            beforeUpload: () => {
              return false;
            },
            maxCount: 1,
            multiple: false,
            style: {maxWidth: '500px', maxHeight: '200px', margin: 'auto', marginTop: '20px'},
            accept: '.xml',
          }}
        />
      </Modal>
    </>
  );
};

export default PaymentDocumentsModal;
