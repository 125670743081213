import {useLayoutEffect} from 'react';
import {Row, Skeleton, Space, Switch, Typography} from 'antd';
import {useDispatch, useSelector} from 'react-redux';

import {AUTH_URL, LOCALE} from '../../common';
import {AdminSelectors} from '../../store/AdminStore';
import {PublicSelectors} from '../../store/PublicStore';
import {AdminAPI} from '../../store/AdminStore/methods';
import {PublicAPI} from '../../store/PublicStore/methods';
import {NavLink} from 'react-router-dom';

const {Title, Link} = Typography;
const {SYSTEM, COMMON} = LOCALE;
const PageHeader = () => {
  const dispatch = useDispatch();
  const adminInfo = useSelector(AdminSelectors.adminInfo);
  const theme = useSelector(AdminSelectors.theme);
  const SITE_TITLE = useSelector(PublicSelectors.title);
  const site = useSelector(PublicSelectors.site);

  document.getElementsByTagName('title')[0].innerText = SITE_TITLE;

  useLayoutEffect(() => {
    dispatch(PublicAPI.companySettings());
  }, []);

  const toggleTheme = (checked) => {
    localStorage.setItem('theme', JSON.stringify(checked ? 'light' : 'dark'));
    window.location.reload();
  };

  const changeAviability = (checked) => {
    dispatch(AdminAPI.operator.availability({isAvailable: checked})).then(() => dispatch(AdminAPI.operator.getAll()));
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    document.location.replace(AUTH_URL);
  };

  return (
    <Row style={{textAlign: 'center', maxHeight: '50px', flexFlow: 'unset'}} justify="space-between" align="middle">
      {SITE_TITLE ? (
        <NavLink to={'/'}>
          <Typography.Link>
            <Title style={{margin: 0, fontWeight: 700}} level={3}>
              {SITE_TITLE}
            </Title>
          </Typography.Link>
        </NavLink>
      ) : (
        <div style={{maxHeight: '30px'}}>
          <Skeleton.Input style={{width: '300px'}} />
        </div>
      )}

      <Space size="large">
        <Typography.Text>{adminInfo.shortName}</Typography.Text>
        {adminInfo && adminInfo.isOperator && (
          <Switch
            defaultChecked={adminInfo?.isAvailable}
            checkedChildren={COMMON.AVAILABLE}
            unCheckedChildren={COMMON.NOT_AVAILABLE}
            onChange={changeAviability}
          />
        )}
        <Switch checkedChildren="☀️" unCheckedChildren="🌑" defaultChecked={theme === 'light'} onChange={toggleTheme} />
        <Link to="" onClick={logout}>
          {SYSTEM.LOGOUT}
        </Link>
      </Space>
    </Row>
  );
};

export default PageHeader;
