export const customersFilters = {
  customerTypeId: 'Тип заказчика',
  complexName: 'Имя',
  inn: 'ИНН',
  ogrn: 'ОГРН',
  bik: 'БИК',
  email: 'Электронная почта',
  phone: 'Номер телефона',
  contractNumber: 'Номер договора',
  userChangeQueryActive: 'Запрос на изменение слушателя',
};
