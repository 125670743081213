import {createAsyncThunk} from '@reduxjs/toolkit';
import {http} from '../../common';

const path = (str) => `admin/edu-program${str}`;
const materialsPath = (str) => `admin/edu-materials${str}`;
const typesPath = (str) => `admin/edu-program-type${str}`;

export const EduMaterialsAPI = {
  linkingPhases: {
    getLinkedPhases: createAsyncThunk('materials/getLinkedPhases', async (id) => {
      return (await http.get(materialsPath(`/${id}/get-phases-and-training-programs/`))).data;
    }),
    addMaterialToPhase: createAsyncThunk('materials/addToPhase', async ({id, phaseId}) => {
      return (await http.patch(materialsPath(`/${id}/add-to-phase/${phaseId}`))).data;
    }),
    removeLearningMaterialformPhase: createAsyncThunk('materials/removeFromOne', async ({id, phaseId}) => {
      return (await http.patch(materialsPath(`/${id}/untie-from-phase/${phaseId}`))).data;
    }),
    removeLearningMaterialformAllPhases: createAsyncThunk('materials/removeFromAll', async (id) => {
      return (await http.patch(materialsPath(`/${id}/untie-from-all-phases`))).data;
    }),
  },

  programTypes: {
    getAll: createAsyncThunk('program-types/getAll', async () => {
      return (await http.get(typesPath('/'))).data;
    }),
    getById: createAsyncThunk('program-types/getById', async (id) => {
      return (await http.get(typesPath('/' + id))).data;
    }),
    delete: createAsyncThunk('program-types/delete', async (id) => {
      return await http.delete(typesPath(`/${id}`));
    }),
    create: createAsyncThunk('program-types/create', async (data) => {
      return await http.post(typesPath(`/`), data);
    }),
    update: createAsyncThunk('program-types/update', async ({id, form}) => {
      return await http.put(typesPath(`/${id}`), form);
    }),
  },

  programs: {
    getAll: createAsyncThunk('programs/getAll', async () => {
      return (await http.put(path('/'))).data;
    }),
    create: createAsyncThunk('programs/create', async (data) => {
      return (await http.post(path('/'), data)).data;
    }),
    delete: createAsyncThunk('programs/delete', async (id) => {
      return (await http.delete(path('/' + id))).data;
    }),
    getById: createAsyncThunk('programs/getById', async (id) => {
      return (await http.get(path('/' + id))).data;
    }),
    update: createAsyncThunk('programs/update', async ({id, data}) => {
      return (await http.put(path(`/${id}`), data)).data;
    }),
    getByType: createAsyncThunk('programs/getByType', async (data) => {
      return (await http.put(`admin/edu-program`, data)).data;
    }),
  },

  phases: {
    create: createAsyncThunk('programs/phase/create', async ({id, data}) => {
      return (await http.post(path(`/${id}/phase`), data)).data;
    }),
    update: createAsyncThunk('programs/phase/update', async ({id, data}) => {
      return (await http.put(path(`/phase/${id}`), data)).data;
    }),
    delete: createAsyncThunk('programs/phase/delete', async (id) => {
      return (await http.delete(path(`/phase/${id}`))).data;
    }),
  },

  prices: {
    create: createAsyncThunk('prices/create', async (typeId) => {
      return (await http.post(typesPath(`/${typeId}/price`), {peopleCount: 0, cost: 0})).data;
    }),
    delete: createAsyncThunk('prices/delete', async (id) => {
      return (await http.delete(typesPath('/price/' + id))).data;
    }),
    update: createAsyncThunk('prices/update', async ({id, data}) => {
      return (await http.put(typesPath('/price/' + id), data)).data;
    }),
  },

  lectures: {
    create: createAsyncThunk('lecture/create', async (data) => {
      return (await http.post(materialsPath(`/lectures/`), data)).data;
    }),

    delete: createAsyncThunk('lecture/delete', async (id) => {
      return (await http.delete(materialsPath(`/lectures/` + id))).data;
    }),

    getAll: createAsyncThunk('lectures/getAll', async (data) => {
      return (await http.put(materialsPath(`/lectures/`), data)).data;
    }),
    getById: createAsyncThunk('lectures/getById', async (id) => {
      return (await http.get(materialsPath(`/lectures/` + id))).data;
    }),
    update: createAsyncThunk('lectures/update', async ({id, data}) => {
      return (await http.post(materialsPath(`/lectures/` + id), data)).data;
    }),
  },

  videos: {
    create: createAsyncThunk('videos/create', async (data) => {
      return (await http.post(materialsPath(`/videos/`), data)).data;
    }),

    delete: createAsyncThunk('videos/delete', async (id) => {
      return (await http.delete(materialsPath(`/videos/` + id))).data;
    }),

    getAll: createAsyncThunk('videos/getAll', async (data) => {
      return (await http.put(materialsPath(`/videos/`), data)).data;
    }),
    getById: createAsyncThunk('videos/getById', async (id) => {
      return (await http.get(materialsPath(`/videos/` + id))).data;
    }),
    update: createAsyncThunk('videos/update', async ({id, data}) => {
      return (await http.patch(materialsPath(`/videos/` + id), data)).data;
    }),
  },

  adlinks: {
    create: createAsyncThunk('ad-links/create', async (data) => {
      return (await http.post(materialsPath(`/ad-links/`), data)).data;
    }),

    delete: createAsyncThunk('ad-links/delete', async (id) => {
      return (await http.delete(materialsPath(`/ad-links/` + id))).data;
    }),

    getAll: createAsyncThunk('ad-links/getAll', async (data) => {
      return (await http.put(materialsPath(`/ad-links/`), data)).data;
    }),
    getById: createAsyncThunk('ad-links/getById', async (id) => {
      return (await http.get(materialsPath(`/ad-links/` + id))).data;
    }),
    update: createAsyncThunk('ad-links/update', async ({id, data}) => {
      return (await http.patch(materialsPath(`/ad-links/` + id), data)).data;
    }),
  },

  other: {
    create: createAsyncThunk('other/create', async (data) => {
      return (await http.post(materialsPath(`/otherEducationMaterials/`), data)).data;
    }),

    delete: createAsyncThunk('other/delete', async (id) => {
      return (await http.delete(materialsPath(`/otherEducationMaterials/` + id))).data;
    }),

    getAll: createAsyncThunk('other/getAll', async (data) => {
      return (await http.put(materialsPath(`/otherEducationMaterials/`), data)).data;
    }),
    getById: createAsyncThunk('other/getById', async (id) => {
      return (await http.get(materialsPath(`/otherEducationMaterials/` + id))).data;
    }),
    update: createAsyncThunk('other/update', async ({id, data}) => {
      return (await http.patch(materialsPath(`/otherEducationMaterials/` + id), data)).data;
    }),
  },

  presentations: {
    getAll: createAsyncThunk('presentations/getAll', async (data) => {
      return (await http.put(materialsPath(`/presentations/`), data)).data;
    }),
    getById: createAsyncThunk('presentations/getById', async (id) => {
      return (await http.get(materialsPath(`/presentations/` + id))).data;
    }),
    create: createAsyncThunk('presentations/create', async (data) => {
      return (await http.post(materialsPath(`/presentations/`), data)).data;
    }),
    update: createAsyncThunk('presentations/update', async ({id, data}) => {
      return (await http.post(materialsPath(`/presentations/${id}`), data)).data;
    }),
    delete: createAsyncThunk('presentations/delete', async (id) => {
      return (await http.delete(materialsPath(`/presentations/` + id))).data;
    }),
    deleteSlide: createAsyncThunk('presentations/deleteSlide', async ({id, slideId}) => {
      return (await http.delete(materialsPath(`/presentations/${id}/slides/${slideId}`))).data;
    }),
    updateSlidesList: createAsyncThunk('presentations/update/list', async ({data, id}) => {
      return (await http.put(`/admin/edu-materials/presentations/${id}/sort-slides`, data)).data;
    }),
  },
};
