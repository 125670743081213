import {Form, Select} from 'antd';
import {TEXT} from '../../common/ru_RU';
import {useEffect} from 'react';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import {useDispatch, useSelector} from 'react-redux';
import {RegistySelectors} from '../../store/RegistryStore';

const NewProgramForm = () => {
  const programs = useSelector(RegistySelectors.listenerPrograms);
  const programsLoading = useSelector(RegistySelectors.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RegistyAPI.listeners.getPrograms());
  }, []);

  return (
    <Form.Item name="trainingProgramId" label={TEXT.REGISTRY.COLUMNS.PROGRAMS}>
      <Select
        filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
        showSearch
        loading={programsLoading}
        style={{width: '100%'}}>
        {programs.map((program) => (
          <Select.Option key={program.id} value={program.id}>
            {program.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default NewProgramForm;
