import {useDispatch, useSelector} from 'react-redux';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Form, InputNumber, Popconfirm, Select, Space, Table} from 'antd';

import UseMessage from '../hooks/useMessage';
import ModalForm from '../UX/ModalForm';

import {LOCALE} from '../../common';
import {AdminAPI} from '../../store/AdminStore/methods';
import {EduSelectors} from '../../store/EducationStore';

const {COMMON, EDUCATION, MESSAGES} = LOCALE;

const PromocodePrices = ({id, data, loading}) => {
  const dispatch = useDispatch();
  const programTypes = useSelector(EduSelectors.programTypes);
  const programLoading = useSelector(EduSelectors.loading);

  const {showError, showSuccess} = UseMessage();

  const ModalItems = () => {
    const usedProgramTypes = data.map((price) => price.trainingProgramType.id) || [];

    return (
      <>
        <Form.Item label={EDUCATION.PROGRAM_TYPE} name="trainingProgramTypeId" rules={[{required: true}]}>
          <Select showSearch loading={programLoading} style={{width: '100%'}}>
            {programTypes.map((type) => (
              <Select.Option value={type.id} key={type.id} disabled={usedProgramTypes.includes(type.id)}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={COMMON.PRICE}>
          <Space>
            <Form.Item name="price" noStyle rules={[{required: true}]}>
              <InputNumber min={0} placeholder={0} />
            </Form.Item>
            ₽
          </Space>
        </Form.Item>
      </>
    );
  };

  const fetchPrices = () => {
    dispatch(AdminAPI.promocodes.prices.getAll(id));
  };

  const addNewPrice = (values) => {
    dispatch(AdminAPI.promocodes.prices.create({id, data: values}))
      .then(() => {
        showSuccess();
        fetchPrices();
      })
      .catch((e) => showError(e));
  };

  const handleDelete = (id) => {
    dispatch(AdminAPI.promocodes.prices.delete(id))
      .then(() => {
        showSuccess();
        fetchPrices();
      })
      .catch((e) => showError(e));
  };

  const handleEdit = (id, values) => {
    dispatch(AdminAPI.promocodes.prices.update({id, data: values}))
      .then(() => {
        showSuccess();
        fetchPrices();
      })
      .catch((e) => showError(e));
  };

  const columns = [
    {
      title: EDUCATION.PROGRAM_TYPE,
      dataIndex: 'trainingProgramType',
      align: 'center',
      render: (type) => {
        return <>{type.name}</>;
      },
    },
    {
      title: COMMON.PRICE,
      dataIndex: 'price',
      align: 'center',
      render: (value) => <>{value} ₽</>,
    },
    {
      title: () => (
        <ModalForm formItems={<ModalItems />} title={EDUCATION.PROGRAM_TYPE} onOk={addNewPrice}>
          <Button type="primary" icon={<PlusOutlined />} />
        </ModalForm>
      ),
      dataIndex: 'id',
      align: 'center',
      render: (id, record) => {
        return (
          <Space>
            <ModalForm
              formItems={<ModalItems />}
              initialValues={{
                trainingProgramTypeId: record.trainingProgramType.id,
                price: parseInt(record.price),
              }}
              onOk={(values) => handleEdit(id, values)}
              title={EDUCATION.PROGRAM_TYPE}>
              <Button icon={<EditOutlined />} />
            </ModalForm>
            <Popconfirm onConfirm={() => handleDelete(id)} title={MESSAGES.CONFIRM}>
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return <Table pagination={false} rowKey={'id'} columns={columns} dataSource={data} loading={loading} />;
};

export default PromocodePrices;
