import {UploadOutlined} from '@ant-design/icons';
import {Button, Form, Input, Space, Upload} from 'antd';

import {LOCALE} from '../../common';
import UseMessage from '../hooks/useMessage';

const {COMMON, PLACEHOLDERS, SYSTEM, EDUCATION} = LOCALE;
const LectionFormItems = ({initialValues}) => {
  const {showError} = UseMessage();

  return (
    <>
      <Form.Item name="eduName" label={COMMON.NAME} rules={[{required: true}]}>
        <Input placeholder={PLACEHOLDERS.TITLE} />
      </Form.Item>
      <Form.Item label={COMMON.FILE}>
        <Space align="start">
          <Form.Item name="file" required valuePropName="file" getValueFromEvent={({file}) => file} noStyle>
            <Upload
              maxCount={1}
              accept=".pdf"
              beforeUpload={(file) => {
                const isPDF = file.type === 'application/pdf';
                const isLargeSize = file.size / 1024 > 10000;
                if (!isPDF) {
                  showError(`${file.name} не является pdf файлом`);
                  return Upload.LIST_IGNORE;
                }
                if (isLargeSize) {
                  showError(`${file.name} превышает допустимый размер файла (10 МБ)`);
                  return Upload.LIST_IGNORE;
                }
                return false;
              }}>
              <Button icon={<UploadOutlined />}>{SYSTEM.UPLOAD}</Button>
            </Upload>
          </Form.Item>
          {initialValues && (
            <Button type="primary" onClick={() => window.open(initialValues.link)}>
              {EDUCATION.PREVIEW_CURRENT}
            </Button>
          )}
        </Space>
      </Form.Item>
    </>
  );
};

export default LectionFormItems;
