import {useDispatch, useSelector} from 'react-redux';
import {AdminAPI} from '../../store/AdminStore/methods';
import {AdminSelectors} from '../../store/AdminStore';
import {useEffect} from 'react';
import {Button, Popconfirm, Space, Table} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import UseMessage from '../hooks/useMessage';
import ModalForm from '../UX/ModalForm';
import JobTitleForm from './JobTitleForm';

const CustomersJobTitleTable = () => {
  const dispatch = useDispatch();
  const customerJobTittle = useSelector(AdminSelectors.customersJobTitle);
  const loading = useSelector(AdminSelectors.loading);
  const {showSuccess, showError} = UseMessage();

  useEffect(() => {
    dispatch(AdminAPI.customersJobTitle.getAll());
  }, []);

  const deleteJobTitle = (id) => {
    dispatch(AdminAPI.customersJobTitle.deleteOne(id))
      .then(() => showSuccess())
      .catch((err) => showError(err));
  };

  const addJobTitle = (data) => {
    dispatch(AdminAPI.customersJobTitle.create(data))
      .then(() => showSuccess())
      .catch((err) => showError(err));
  };

  const editJobTitle = (id) => (data) => {
    dispatch(AdminAPI.customersJobTitle.edit({id, data}))
      .then(() => showSuccess())
      .catch((err) => showError(err));
  };

  return (
    <Table loading={loading} dataSource={customerJobTittle} rowKey={'id'}>
      <Table.Column key="imenit" dataIndex="imenit" title="Должность" />
      <Table.Column key="rodit" dataIndex="rodit" title="Должность в родительском падеже" />
      <Table.Column
        key="id"
        dataIndex="id"
        title={() => {
          return (
            <ModalForm onOk={addJobTitle} formItems={<JobTitleForm />} title="Создать новую должность">
              <Button type="primary" icon={<PlusOutlined />} />
            </ModalForm>
          );
        }}
        render={(id, record) => {
          return (
            <Space>
              <Popconfirm title="Удалить должность?" okText="Да" onConfirm={() => deleteJobTitle(id)}>
                <Button danger icon={<DeleteOutlined />} />
              </Popconfirm>
              <ModalForm
                onOk={editJobTitle(id)}
                formItems={<JobTitleForm />}
                initialValues={record}
                title="Создать новую должность">
                <Button icon={<EditOutlined />} />
              </ModalForm>
            </Space>
          );
        }}
        width={50}
      />
    </Table>
  );
};

export default CustomersJobTitleTable;
