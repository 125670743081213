import React from "react";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import { AdminSelectors } from "../../store/AdminStore";

const DarkTheme = React.lazy(() => import("./DarkTheme"));
const LightTheme = React.lazy(() => import("./LightTheme"));

const ThemeProvider = ({ children }) => {
	const theme = useSelector(AdminSelectors.theme);
	return (
		<Suspense fallback={<span />}>
			{theme === "dark" ? (
				<DarkTheme>{children}</DarkTheme>
			) : (
				<LightTheme>{children}</LightTheme>
			)}
		</Suspense>
	);
};

export default ThemeProvider;
