import {AdminAPI} from '../store/AdminStore/methods';
import {CLIENT_URL} from '../common';
import {message} from 'antd';

export const loginAsUser = (dispatch, email) => () => {
  dispatch(AdminAPI.auth.loginAsUser(email))
    .then(({payload}) => {
      const url = new URL(CLIENT_URL);

      url.searchParams.append('accessToken', payload.data.accessToken);
      url.searchParams.append('accountId', payload.data.accountTypeId);
      url.searchParams.append('loginAsAdmin', 'true');

      document.location.replace(url);
    })
    .catch((err) => message.error(err.message));
};
