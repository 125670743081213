export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const SITE_TITLE = process.env.REACT_APP_SITE_TITLE;

export const CUSTOMER_TYPES = [
  {id: '9e55dbc1-6195-42c5-96ce-6a636df1970d', name: 'Юридическое лицо'},
  {id: '7bb29770-df89-44bb-a8cb-2e60b2d3a378', name: 'Физическое лицо'},
  {id: 'ecf30c09-82f4-4e90-99d0-52002e737942', name: 'ИП'},
];

export const QUESTION_TYPES = [
  {name: 'Несколько ответов', value: 'd062fd59-25dc-494c-b537-d72b99129265'},
  {name: 'Один ответ', value: '3d6284ae-2282-4ea4-abf9-b2d04da6b582'},
];

export const SOURCE_TYPE = [
  {name: 'Агент', value: '1f1c8f9a-f5a8-4d16-868b-c1d32c3463ff'},
  {name: 'Обзвон', value: '5b59bb5d-38de-45bb-939b-fde663aa0a03'},
  {name: 'Реклама', value: 'a0028c4f-3b16-4eba-ba3a-caff6191f08d'},
  {name: 'Другое', value: 'b1720fb1-91ec-4de5-ab1d-6ee6c3a6d4cb'},
  {name: 'Исключить', value: 'b4773589-8dcb-4f1d-ab28-7a1be83ece01'},
  {name: 'Ручной', value: '9ed56aa1-4858-4948-8308-a8e5d40a8ecf'},
  {name: 'ПИК', value: '33b18dad-eddf-4a8c-ade2-fc4c5f6327d7'},
];

export const DELIVERY_TYPE = [
  {name: 'Без доставки', value: '1bae6652-099f-406c-9991-5395376c34d1'},
  {name: 'Экспресс доставка', value: '2cef1a2b-02f9-4248-a485-d78d271ff0e9'},
  {name: 'Почта России', value: '4c07cac6-b54b-4e99-803f-c3deb78e795c'},
];

export const REGISTRY_STATUSES_ACTIVE = [
  {label: 'В процессе обучения', value: 'white'},
  {label: 'Готов к распечатке', value: 'yellow'},
  {label: 'Готов к отправке', value: 'blue'},
  {label: 'Должник', value: 'red'},
  {label: 'Не доставлен', value: 'problem'},
  {label: 'Доставлен', value: 'inplace'},
];

export const REGISTRY_CONTRACT_STATUS = [
  {label: 'Оформление документов', value: '75d6cd91-75e1-4d86-859a-315da37dcfda'},
  {label: 'Доставка документов', value: '9f22a9e7-2a35-476d-94de-84fb090fb2c7'},
  {label: 'Завершено', value: 'bf7178a6-bc66-4093-aa8a-16e1474c3403'},
  {label: 'Оплачено (Обучение не завершено)', value: 'bdd5d8b5-e5ee-4695-b8ca-37d5826b92eb'},
  {label: 'Не оплачено', value: '14894fce-15dd-4b8e-889c-6ddb03b6caa2'},
  {label: 'Создано ', value: 'b070fe90-3752-4503-a4e2-d4a9b80b779d'},
];

export const REGISTRY_STATUSES_ARCHIVE = [
  {label: 'Не доставлен', value: 'problem'},
  {label: 'Доставлен', value: 'inplace'},
  {label: 'Другое', value: 'other_archive'},
];

export const DEADSOULS_STATUSES = [
  {name: 'Отказался от обучения', value: '6fe6e6b5-588c-4a48-a9e2-9290006c985e'},
  {name: 'Повторная рассылка', value: '720ae24d-f3af-4516-a189-327ddb012328'},
  {name: 'Предоставлены дополнительные 14 дней', value: 'ce6c6fbe-bf21-471e-b011-8c1417486ed8'},
];

export const BUDGET_TYPE = [
  {id: '2e903c6e-89a7-45c8-be64-b79bb86cd57a', label: 'Региональный'},
  {id: '4ad428e0-8751-49ce-a413-b24fc33d2551', label: 'Федеральный'},
  {id: '75d6fd45-7398-4b6f-a04d-b0dfed1b34ac', label: 'Местный'},
];

export const BASIS_TYPES = [
  {name: 'Устава', value: '00395456-ae3f-4c65-9cf7-d615d480bdd2'},
  {name: 'Приказа', value: '1ba9efc0-436a-4de6-89da-76184b10b4d3'},
  {name: 'Доверенности', value: '26fe13e7-80be-4776-b1a9-f95e2443fc43'},
  {name: 'Распоряжения', value: '58b2326f-77d5-47a4-981f-401f35f85ea6'},
  {name: 'Положения', value: 'c55149ab-38f0-4d0e-95ef-4ae79e161e13'},
];

export const CARD_TYPES = [
  {name: 'РнВ с фото', value: '057f0192-aa2a-43bb-9f14-be659f09e611'},
  {name: 'Профессиональная переподготовка', value: '11dae8ac-4557-4d88-ad2a-4cf0803c2df3'},
  {name: 'Повышение квалификации', value: 'a3cc57f2-4b85-4132-a78a-e7d66a38a43d'},
  {name: 'Профессиональное обучение', value: 'adf84e14-9fcd-4845-b1fe-2bcc46f44ca4'},
  {name: 'РнВ проверка знаний', value: 'b0025deb-cf78-4f03-8199-491621adaeb9'},
  {name: 'Пластик ', value: 'dc24cd45-2e14-4c52-92b3-70e945d8bb18'},
  {
    name: 'Пластик с требованием СНИЛС гражданства ДР для реестра обученных лиц ФГИС СОУТ Минтруда России',
    value: '98d53e70-962a-462a-825a-ce130b582891',
  },
  {
    name: 'с ОКВЭД без удостоверений',
    value: '6964f7a5-7fcf-41b2-8f27-28bddb5ff23e',
  },
  {
    name: 'Без удостоверений',
    value: 'af903cbe-975e-4480-9316-02fc035559d3',
  },
];

export const SECTORS = [
  {name: 'Сельское хозяйство', value: '1922349c-60fc-4b1c-963e-c0b925f42d04'},
  {name: 'Лицензиат МЧС России', value: '50ebb1cd-dde4-4390-8d42-7b201fe84194'},
  {name: 'Коммунальные услуги', value: '68351b13-47ef-434b-bf37-2551e34cfd47'},
  {name: 'Торговля', value: '732f7835-3b93-45e0-9593-e0e5c837326c'},
  {name: 'Религия', value: '7f241a7b-0690-453d-8f38-df7b06ea9042'},
  {name: 'Учреждения (офисы)', value: '8293cbc6-5851-4d73-bcdc-f6f531a80a0e'},
  {name: 'Бытовое обслуживание', value: '911c18a1-496b-4a1f-9060-ff1040f4027e'},
  {name: 'Производство', value: '93d2cb18-d802-4840-9eee-48036d82575a'},
  {name: 'otb37.ru', value: 'bceed26c-633b-46d2-9741-ba61226fffa1'},
  {name: 'Органы правопорядка', value: 'c3b1e308-10c9-4363-9ad2-7a5505977b4f'},
  {name: 'Образование', value: 'd4dbb3aa-38df-4cc4-8609-07e6b97d47c6'},
  {name: 'Культура', value: 'dfa02406-2550-43a4-9ec1-d83bac9ad100'},
  {name: 'Здравоохранение', value: 'e3b1ef16-f899-45d1-9466-9381805b5cb4'},
];
//todo перевести все в объекты
export const PROMOCODE_TYPES = [
  {name: 'Скидка', value: '3e61c080-f10e-40a3-ab5c-bdf3086f2078'},
  {name: 'Одноразовый', value: '43752836-4dee-46f9-ac2e-c48a5ec7029d'},
  {name: 'Фиксированная цена', value: '76d3fb30-4fc2-4c28-b984-828b77870732'},
];

export const DOCUMENT_TYPES = {
  orderOfAdmission: {name: 'Приказ о зачислении', value: '21053bd1-520c-45fa-92b9-50913a70dd6f'},
  dismissalOrder: {name: 'Приказ об отчислени', value: '603ac014-dd52-4712-a1ef-0b793d67e91e'},
  certificate: {name: 'Удостоверение', value: '63ee9041-1e29-4c6e-9d3d-68b6be362797'},
  contract: {name: 'Договор', value: '97cff7a5-0180-45b5-b05d-91c8362089a6'},
  act: {name: 'Акт', value: '97e42b2e-c8e6-4823-a18c-3e70f96295b1'},
  account: {name: 'Счет', value: '9d449d1b-cefa-4322-83a3-3fe1b1daed9a'},
  protocol: {name: 'Протокол', value: 'd7ab34f0-343c-435e-a3f0-3fffa2458c5e'},
};

export const EDUCATION_LEVELS = {
  secondarySpecial: {
    name: 'Среднее профессиональное образование',
    value: '0ee26f56-2a9f-492e-9043-1aba73e57fff',
  },
  high: {
    name: 'Высшее образование',
    value: '79444c1b-50a7-4d96-8274-761b49d42806',
  },
  // repeat: {
  //   name: 'Повтор',
  //   value: '09b914ed-7f95-4033-a340-5a66e3c112d0',
  // },
};

export const CHECK_REASON = {
  another: {
    name: 'Очередная',
    value: '149ed530-5ef2-4376-9441-ac27bd8dccb1',
  },
  primary: {
    name: 'Первичная',
    value: '2c497ca5-5541-4502-9cb6-b694800f666d',
  },
  unscheduled: {
    name: 'Внеплановая',
    value: '5f1b1888-0f58-4813-8f58-10c11ec14cc7',
  },
  repeated: {
    name: 'Повторная',
    value: 'adc13ab2-4d58-4dbd-a16b-56fd091631a4',
  },
  extraordinar: {
    name: 'Внеочередная',
    value: 'efec6bcc-8352-42f5-a57b-01d1709cade1',
  },
};

export const ADMIN_RIGHTS = {
  companySettings: {
    id: '20b0e230-e0fb-49bf-8ab8-86fae2daab0e',
    name: 'Управление настройками компании',
  },
  autoPay: {id: '2dac2589-12d2-4d82-ae59-f242ec9fe9bb', name: 'Платежные данные'},
  changeOperatorInRegistry: {
    id: '581715e6-5627-4070-8fc6-49dd0ca6b41b',
    name: 'Назначение оператора к договору',
  },
  renewContract: {
    id: '71714c43-dd4c-44e0-a662-0d0506e94a22',
    name: 'Переформирование документации по договорам',
  },
  contractAnalytics: {
    id: '75e0691b-c3f9-4c60-a2ef-5aeeedfee010',
    name: 'Аналитика по договорам',
  },
  userModerating: {
    id: '765963cb-4fe4-4676-b225-d9cddf54a06f',
    name: 'Редактирование пользователей, назначение прав другим пользователям',
  },
  adminModerating: {id: 'ac905914-dfe3-4de6-812d-a85db7844ebd', name: 'Управление администраторами'},
  asUser: {
    id: '837be19a-28a1-417c-97a2-8c0746181489',
    name: 'Аутентификация в качестве пользователя',
  },
  contractTransfer: {
    id: '9860045f-7531-41e9-8990-84c5c244d074',
    name: 'Перенос договоров с аккаунта на аккаунт ',
  },
  manualContractTermination: {
    id: '99c3bcf1-d519-4049-8608-20519c2ebd4e',
    name: 'Ручное завершение договоров',
  },
  operatorManagement: {
    id: 'b53dc680-6ee6-4257-81ba-73904320ebdc',
    name: 'Управление операторами',
  },
  listenersUnloading: {
    id: 'b6ee03d5-08e7-4dce-82dd-6179ccd1b3bf',
    name: 'Выгрузка слушателей',
  },
  promoBonusesAccrual: {
    id: 'c47a51e8-74d1-4a12-87a9-6b7c010a5d02',
    name: 'Начисление бонусов по акции',
  },
  operatorWorkingAnalytics: {
    id: 'ce418342-8da4-4716-a78f-153473ccb81e',
    name: 'Аналитика работы операторов',
  },
  contractPromoCodesManagement: {
    id: 'e07f5096-d0d4-4614-847c-c10ad51400d6',
    name: ' Управление промокодами для договоров',
  },
  FISFRDOUnloading: {
    id: 'fccc7ed9-9922-45e4-b27a-72a68e84c9d1',
    name: 'Выгрузка для ФИС ФРДО',
  },
  menuShowRegistry: {
    id: '96464a3f-ec8b-422a-8ab8-f1922ef6075b',
    name: 'Отображать реестр в меню',
  },
  menuShowCustomers: {
    id: '807fc188-31e1-4f76-bdf1-a924ea3a8c0f',
    name: 'Отображать заказчиков в меню',
  },
  menuShowPromocodes: {
    id: '41693cda-a41d-4cad-bb30-66ad15fe1492',
    name: 'Отображать промокоды в меню',
  },
  menuShowUsers: {
    id: 'e195f6db-4ed8-4755-81c3-0a203972225c',
    name: 'Отображать пользователей в меню',
  },
  menuShowOperators: {
    id: '28b521e3-036a-4e37-8345-1a0289f4f87b',
    name: 'Отображать операторов в меню',
  },
  menuShowTrainingProgramTypes: {
    id: '276c78e6-6f1c-4fd6-92ba-596cb04221d4',
    name: 'Отображать типы программ обучения в меню',
  },
  menuShowTrainingPrograms: {
    id: 'a4ed8b00-5060-46e5-8545-5be5b0278b01',
    name: ' Отображать программы обучения в меню',
  },
  menuShowEducationMaterials: {
    id: 'd07c60a4-86e2-465a-838a-d5b3aadb65f4',
    name: 'Отображать обучающие материалы в меню',
  },
  menuShowQuestions: {
    id: 'cafa10a3-3a89-4f50-a274-572ad583aeb7',
    name: 'Отображать вопросы в меню',
  },
  menuShowCommercialOffers: {
    id: 'b07b12de-0604-4f08-b158-5fab9c177688',
    name: 'Отображать коммерческие предложения в меню',
  },
  menuShowAnalytic: {
    id: 'e3b33a20-e52f-46c7-bb1d-74f1385c7f60',
    name: 'Отображать аналитику в меню',
  },
  menuShowSettings: {
    id: '3e65e705-a184-442b-b06a-fdfd360912d0',
    name: 'Отображать настройки компании и другие настройки системы',
  },
  menuShowAdmins: {
    id: 'a82da95a-7d61-41f9-ae6a-a686f6af9440',
    name: 'Отображать админов в меню',
  },
  editCustomer: {
    id: '4216fd90-be68-4ee1-917d-e9f0903d2ddc',
    name: 'Редактирование заказчиков',
  },
  editTrainingProgramType: {
    id: 'a825cd45-3c72-42f0-94cd-ee7d3515bd4a',
    name: 'Редактирование типов программ обучения',
  },
  editTrainingProgram: {
    id: '498abf62-40ef-421c-87c1-27122178d99f',
    name: 'Редактирование программ обучения',
  },
  editEducationMaterials: {
    id: '1227a4a6-f27f-429f-a001-3110ff661655',
    name: 'Редактирование обучающих материалов',
  },
  editQuestions: {
    id: 'afe5a24a-0c46-4114-a3d5-b7f07fd9f2ea',
    name: 'Редактирование вопросов',
  },
  menuShowHeadPosts: {
    id: '3c408eb7-0fb7-48d8-8709-a48908802d35',
    name: 'Отображать должности заказчиков в меню',
  },
  editHeadPosts: {
    id: '866a76b8-71b2-4219-b0aa-6fdb1c139eba',
    name: 'Изменение должностей заказчиков',
  },
  bonusesAccrual: {
    id: 'c47a51e8-74d1-4a12-87a9-6b7c010a5d02',
    name: 'Начисление и списание бонусов',
  },
};

export const ADMIN_RIGHTS_LIST = Object.keys(ADMIN_RIGHTS).map((adminRight) => ADMIN_RIGHTS[adminRight]);
