import {Form, Input} from 'antd';

const JobTitleForm = () => {
  return (
    <>
      <Form.Item name="imenit" required label="Должность в именительном">
        <Input placeholder="архитектор" />
      </Form.Item>
      <Form.Item name="rodit" required label="Должность в родительном">
        <Input placeholder="архитектора" />
      </Form.Item>
    </>
  );
};

export default JobTitleForm;
