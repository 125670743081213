import {useSelector} from 'react-redux';
import {Link, useSearchParams} from 'react-router-dom';
import {InfoCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Table, Tag} from 'antd';

import {LOCALE, paramsToObject} from '../../common';
import {AdminSelectors} from '../../store/AdminStore';

const {COMMON, CUSTOMER} = LOCALE;
const CustomersTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = paramsToObject(searchParams);

  const customers = useSelector(AdminSelectors.customers);
  const customersTableCount = useSelector(AdminSelectors.customersTableCount);
  const loading = useSelector(AdminSelectors.customersLoading);

  const columns = [
    {
      title: COMMON.ORGANIZATION_NAME,
      dataIndex: 'complexName',
      align: 'center',
      fixed: true,
      render: (text, record) => {
        return (
          <>
            {record.changeQueryActive && (
              <Link to={`/customer/${record.id}`}>
                <Tag color="red">NEW</Tag>
              </Link>
            )}
            {text}
          </>
        );
      },
    },
    {
      title: CUSTOMER.CUSTOMER_TYPES,
      dataIndex: 'customerTypeName',
      align: 'center',
    },

    {
      title: COMMON.ADDRESS,
      dataIndex: 'postAddress',
      align: 'center',
    },

    {
      title: COMMON.PHONE,
      dataIndex: 'phone',
      align: 'center',
    },
    {
      title: COMMON.EMAIL,
      dataIndex: 'email',
      align: 'center',
    },
    {
      dataIndex: 'id',
      align: 'center',
      render: (id) => (
        <Link to={`/customer/${id}`}>
          <Button icon={<InfoCircleOutlined />} />
        </Link>
      ),
    },
  ];

  return (
    <Table
      loading={loading}
      pagination={{
        total: customersTableCount,
        current: Number(filters.page ?? 1),
        pageSize: Number(filters.limit ?? 10),
        onChange: (page, limit) =>
          setSearchParams({...filters, page, limit, offset: (page - 1) * limit}, {replace: true}),
        onShowSizeChange: (page, limit) =>
          setSearchParams({...filters, page, limit, offset: (page - 1) * limit}, {replace: true}),
      }}
      bordered
      scroll={{x: 'auto'}}
      columns={columns}
      size="large"
      rowKey={'id'}
      dataSource={customers}
    />
  );
};

export default CustomersTable;
