import {LOCALE} from '../../common';
import PageLayout from '../../components/Layout/PageLayout';
import ProgramsTable from '../../components/Programs/ProgramsTable';

const Programs = () => {
  return (
    <PageLayout pageTitle={LOCALE.PAGE_TITLES.PROGRAMS}>
      <ProgramsTable />
    </PageLayout>
  );
};

export default Programs;
