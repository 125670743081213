import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {LOCALE, paramsToObject} from '../../common';
import {AdminAPI} from '../../store/AdminStore/methods';
import PageLayout from '../../components/Layout/PageLayout';
import FilterPanel from '../../components/FilterPanel';
import ListenersTable from './Table';
import ListenersFilters from './Filters';
import {listenersFilters} from '../../components/filters/listeners';

const resetFilters = {
  limit: 10,
  offset: 0,
  page: 1,
  firstname: null,
  secondname: null,
  surname: null,
  courseNumber: null,
  trainingProgramId: null,
  cardNumber: null,
  jobPlace: null,
  profession: null,
  SNILS: null,
  email: null,
  contractNumber: null,
  contractStatusId: null,
  contractIsActive: true,
  contractInArchive: false,
  isDeleted: false,
  blankNumber: null,
};
const layout = {
  // labelCol: {
  //   span: 10,
  // },
  // wrapperCol: {
  //   span: 12,
  // },
};

const Listeners = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const savedFilters = localStorage.getItem('listenersFilters');
  const initialFilters = {...resetFilters, ...JSON.parse(savedFilters), ...paramsToObject(searchParams)};

  useEffect(() => {
    delete initialFilters.page;
    dispatch(AdminAPI.listeners.getAll(initialFilters));
  }, [searchParams]);

  return (
    <PageLayout pageTitle={LOCALE.PAGE_TITLES.LISTENERS}>
      <FilterPanel
        formLayout={layout}
        resetFilters={resetFilters}
        initialFilters={initialFilters}
        filterLabels={listenersFilters}
        name="listenersFilters">
        <ListenersFilters />
      </FilterPanel>
      <ListenersTable />
    </PageLayout>
  );
};

export default Listeners;
