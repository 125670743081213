import {Tabs} from 'antd';
import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {LOCALE} from '../../common';
import PageLayout from '../../components/Layout/PageLayout';
import CommissionMembers from '../../components/Settings/CommissionMembers';
import CompanyInfo from '../../components/Settings/CompanyInfo';
import OrdersTable from '../../components/Settings/OrdersTable';
import SystemSettings from '../../components/Settings/SystemSettings';

const {PAGE_TITLES, SETTINGS} = LOCALE;

const items = [
  {label: SETTINGS.SYSTEM, key: 'system-settings', children: <SystemSettings />},
  {label: SETTINGS.COMPANY_INFO, key: 'company-info', children: <CompanyInfo />},
  {label: SETTINGS.ORDERS, key: 'orders', children: <OrdersTable />},
  {label: SETTINGS.COMMISSION_MEMBERS, key: 'members', children: <CommissionMembers />},
];

const Settings = () => {
  const [params] = useSearchParams();
  const key = params.get('key');

  return (
    <PageLayout spaceProps={{style: {maxWidth: '1000px', width: '100%'}}} pageTitle={PAGE_TITLES.COMPANY_SETTINGS}>
      <Tabs defaultActiveKey={key} items={items} />
    </PageLayout>
  );
};

export default Settings;
