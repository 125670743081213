import {useDispatch, useSelector} from 'react-redux';
import {Button, Popconfirm, Table} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import UseMessage from '../hooks/useMessage';

import {compare, LOCALE} from '../../common';
import {RegistySelectors} from '../../store/RegistryStore';
import {RegistyAPI} from '../../store/RegistryStore/methods';

const {COMMON, MESSAGES, DOCUMENTS, LISTENERS} = LOCALE;

const ListenersForAddTable = () => {
  const dispatch = useDispatch();
  const listenersForAdd = useSelector(RegistySelectors.listenersForAdd);
  const loading = useSelector(RegistySelectors.loading);
  const {showError, showSuccess} = UseMessage();

  const handleAddQuery = (id) => {
    dispatch(RegistyAPI.listeners.update({id: id, data: {addQueryActive: false}}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const listenerColumn = (title, key, props = {}) => ({
    title: title,
    dataIndex: key,
    align: 'center',
    ...props,
  });

  const columns = [
    listenerColumn(COMMON.SURNAME, 'surname', {
      sorter: (a, b) => compare(a['firstname'], b['firstname']),
    }),
    listenerColumn(COMMON.NAME, 'firstname'),
    listenerColumn(COMMON.SECOND_NAME, 'secondname'),
    listenerColumn(COMMON.JOB_PLACE, 'jobPlace'),
    listenerColumn(COMMON.PROFESSION, 'profession'),
    listenerColumn(DOCUMENTS.SNILS, 'SNILS'),
    listenerColumn(COMMON.EMAIL, 'email'),
    listenerColumn(COMMON.BIRTHDAY, 'birthday'),
    {
      align: 'center',
      width: 50,
      dataIndex: 'id',
      render: (id, record) =>
        record.addQueryActive && (
          <Popconfirm placement="left" title={MESSAGES.CONFIRM} onConfirm={() => handleAddQuery(id)}>
            <Button type="primary" icon={<PlusOutlined />} />
          </Popconfirm>
        ),
    },
  ];

  return (
    <Table
      loading={loading}
      className={'disabled-hover'}
      pagination={{position: ['bottomRight']}}
      scroll={{x: 'max-content'}}
      dataSource={listenersForAdd}
      size="small"
      columns={columns}
      rowKey="id"
    />
  );
};

export default ListenersForAddTable;
