import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Typography} from 'antd';

import {AdminAPI} from '../../store/AdminStore/methods';
import {AdminSelectors} from '../../store/AdminStore';

import NormsForm from './NormsForm';
import NormsList from './NormsList';
import ModalForm from '../UX/ModalForm';
import UseMessage from '../hooks/useMessage';

const Norms = ({id, itemType}) => {
  const dispatch = useDispatch();
  const contentInfo = useSelector(AdminSelectors.contentInfo);

  const {showSuccess, showError} = UseMessage();

  useEffect(() => {
    dispatch(AdminAPI.systemContentControl.checkActuality(id));
  }, []);

  const createContent = (formValues) => {
    const data = {itemId: id, itemType, contentUrl: window.location.href, ...formValues};
    dispatch(AdminAPI.systemContentControl.create(data))
      .then((res) => showSuccess('Контент успешно создан'))
      .catch((err) => showError(err));
  };

  const addNorm = (formValues) => {
    const data = {itemId: id, ...formValues};
    dispatch(AdminAPI.systemContentControl.addNorm(data))
      .then((res) => showSuccess('Норма успешно добавлена'))
      .catch((err) => showError(err));
  };

  return (
    <>
      <Typography.Title level={2}>Нормы</Typography.Title>
      <NormsList itemId={id} norms={contentInfo.norms} />
      <div style={{display: 'flex', justifyContent: 'center', marginBottom: 30}}>
        <ModalForm
          formItems={<NormsForm />}
          title={contentInfo.contentExists ? 'Добавление нормы к СКК' : 'Создание записи о контенте'}
          initialValues={{norms: [{mainPartName: '', additionalPartName: ''}]}}
          onOk={contentInfo.contentExists ? addNorm : createContent}
          modalProps={{width: '630px'}}>
          <Button type="primary" style={{margin: 12}}>
            Добавить
          </Button>
        </ModalForm>
      </div>
    </>
  );
};

export default Norms;
