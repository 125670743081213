import { Button, Checkbox, Form } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { defaultRegistryColumns, LOCALE } from "../../common";
import { AdminActions, AdminSelectors } from "../../store/AdminStore";
import ModalForm from "../UX/ModalForm";
import { memo } from "react";

const { Item } = Form;
const ColumnSettings = () => {
	const dispatch = useDispatch();
	const registryColumns = useSelector(AdminSelectors.registryColumns);
	const objectKeys = Object.keys(defaultRegistryColumns);

	const handleSaveSettings = (values) => {
		localStorage.setItem("registrySettings", JSON.stringify(values));
		dispatch(AdminActions.setColumns(values));
	};

	const { COLUMNS } = LOCALE.REGISTRY;

	const ModalRender = memo(() => {
		return (
			<>
				{objectKeys.map((key) => (
					<Item
						name={key}
						key={key}
						valuePropName='checked'
						label={COLUMNS[key.toUpperCase()]}
						style={{ margin: "0px", marginBottom: "0px" }}
					>
						<Checkbox disabled={key === "number"} />
					</Item>
				))}
			</>
		);
	});

	return (
		<>
			<ModalForm
				initialValues={registryColumns}
				onOk={handleSaveSettings}
				title={LOCALE.SYSTEM.SETTINGS}
				formItems={<ModalRender />}
				modalProps={{ centered: true }}
				formProps={{layout: { labelCol: { span: 10 } } }}
			>
				<Button icon={<SettingOutlined />} />
			</ModalForm>
		</>
	);
};

export default ColumnSettings;
