import {Form, Radio} from 'antd';
import Input from 'antd/es/input/Input';

const OperatorsEditingForm = () => {
  return (
    <>
      <Form.Item label="Рабочий день (часы)" name="workHours">
        <Input />
      </Form.Item>
      <Form.Item label="Проценты" name="percentage">
        <Input />
      </Form.Item>
      <Form.Item label="Доступен" name="isAvailable">
        <Radio.Group>
          <Radio.Button value={true}>Да</Radio.Button>
          <Radio.Button value={false}>Нет</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Доступ вне офиса" name="officeOnlyAccess">
        <Radio.Group>
          <Radio.Button value={true}>Да</Radio.Button>
          <Radio.Button value={false}>Нет</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default OperatorsEditingForm;
