import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Image, Row, Space, Table, Typography} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';

import AnswerForm from './AnswerForm';
import ModalForm from '../UX/ModalForm';
import UseMessage from '../hooks/useMessage';

import {LOCALE} from '../../common';
import {QuestionsSelectors} from '../../store/QuestionsStore';
import {QuestionsAPI} from '../../store/QuestionsStore/methods';

const {QUESTIONS} = LOCALE;

const AnswersTable = () => {
  const {id} = useParams();

  const dispatch = useDispatch();
  const answers = useSelector(QuestionsSelectors.answers);
  const loading = useSelector(QuestionsSelectors.loading);

  const {showError, showSuccess} = UseMessage();

  useEffect(() => {
    dispatch(QuestionsAPI.answers.getByQuestion(id));
  }, []);

  const createAnswer = (values) => {
    dispatch(QuestionsAPI.answers.create({id, data: values}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const updateAnswer = (id, data) => {
    dispatch(QuestionsAPI.answers.update({id, data}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const deleteAnswer = (id) => {
    dispatch(QuestionsAPI.answers.delete(id))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const columns = [
    {
      title: QUESTIONS.ANSWER_TEXT,
      dataIndex: 'text',
      render: (text) => {
        const parser = new DOMParser();
        const content = parser.parseFromString(text, 'text/html');
        const images = content.getElementsByTagName('img');

        if (images.length > 0) {
          return (
            <Row>
              <Image src={images[0].src} />
              <p>{content.body.textContent}</p>
            </Row>
          );
        } else
          return (
            <Row>
              <p>{content.body.textContent}</p>
            </Row>
          );
      },
      key: 'id',
    },
    {
      title: QUESTIONS.IS_RIGHT,
      dataIndex: 'isRight',
      width: 150,
      align: 'center',
      render: (isRight) => {
        return isRight && <Typography.Text type="success">Верный</Typography.Text>;
      },
    },
    {
      dataIndex: 'id',
      width: 50,
      align: 'center',
      title: (
        <ModalForm
          onOk={createAnswer}
          initialValues={{isRight: false}}
          modalProps={{width: 600}}
          formProps={{align: 'vertical'}}
          formItems={<AnswerForm />}
          title={QUESTIONS.NEW_ANSWER}>
          <Button type="primary" icon={<PlusOutlined />} />
        </ModalForm>
      ),
      render: (id, record) => (
        <Space>
          <ModalForm
            initialValues={record}
            modalProps={{width: 600}}
            formProps={{align: 'vertical'}}
            formItems={<AnswerForm />}
            onOk={(values) => updateAnswer(id, values)}
            title={QUESTIONS.EDIT_ANSWER}>
            <Button type="primary" icon={<EditOutlined />} />
          </ModalForm>
          <Button onClick={() => deleteAnswer(id)} danger icon={<DeleteOutlined />} />
        </Space>
      ),
    },
  ];

  return <Table loading={loading} rowKey={'id'} columns={columns} dataSource={answers} />;
};

export default AnswersTable;
