import {InfoCircleOutlined} from '@ant-design/icons';
import {Button, Result, Skeleton, Space, Typography} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {LOCALE} from '../../common';
import DiplomForm from '../../components/Diploms/DiplomForm';
import PageLayout from '../../components/Layout/PageLayout';

import {AdminActions, AdminSelectors} from '../../store/AdminStore';
import Notes from '../../components/Notes';
import notableTypes from '../../common/notableTypes';
import {useEffect, useState} from 'react';
import {RegistySelectors} from '../../store/RegistryStore';
import {RegistyAPI} from '../../store/RegistryStore/methods';

const {PAGE_TITLES, SYSTEM} = LOCALE;

const Diploma = () => {
  const fetchError = useSelector(AdminSelectors.fetchError);
  const {studentId} = useParams();
  const [listener, setListener] = useState(undefined);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RegistyAPI.listeners.getById(studentId)).then((res) => setListener(res.payload));
  }, []);

  const onCancel = () => navigate(-1);

  const onCreateNew = () => {
    dispatch(AdminActions.clearError());
  };

  const listenerName = () => {
    if (listener === undefined) return <Skeleton.Input block active size="large" style={{width: '200px'}} />;
    else {
      if (listener) {
        return `${listener.surname} ${listener.firstname} ${listener.secondname || ''}`;
      } else return '';
    }
  };

  return (
    <PageLayout
      pageTitle={
        <>
          {PAGE_TITLES.DIPLOM}: {listenerName()}
        </>
      }>
      <Space direction="vertical">
        <div>
          <InfoCircleOutlined /> Для создания диплома требуется загрузить файлы
        </div>
      </Space>
      {!fetchError ? (
        <DiplomForm />
      ) : (
        <Result
          status={'error'}
          title="Не удалось получить информацию о дипломе"
          subTitle="Загрузить новый диплом?"
          extra={[
            <Button onClick={onCreateNew} type="primary">
              {SYSTEM.CREATE}
            </Button>,
            <Button onClick={onCancel}>{SYSTEM.CANCEL}</Button>,
          ]}
        />
      )}
      <Typography.Title level={3} style={{marginTop: '16px'}}>
        Замечания
      </Typography.Title>
      <Notes notableTypeId={notableTypes.diploma} maxHeight={400} />
    </PageLayout>
  );
};

export default Diploma;
