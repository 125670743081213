import {createAsyncThunk} from '@reduxjs/toolkit';
import {http} from '../../common';

const path = (str) => `admin/tests/questions${str}`;

export const QuestionsAPI = {
  getAll: createAsyncThunk('questions/getAll', async (data) => {
    return (await http.put(`admin/tests/questions`, data)).data;
  }),
  create: createAsyncThunk('question/create', async (data) => {
    return (await http.post(`admin/tests/questions`, data)).data;
  }),
  delete: createAsyncThunk('question/delete', async (id) => {
    return (await http.delete(`admin/tests/questions/${id}`)).data;
  }),
  getById: createAsyncThunk('question/getById', async (id) => {
    return (await http.get(`admin/tests/questions/${id}`)).data;
  }),
  filter: createAsyncThunk('questions/filter', async (data) => {
    return (await http.put('admin/tests/questions/filtered-list', data)).data;
  }),
  update: createAsyncThunk('question/update', async ({id, data}) => {
    return await http.put(`admin/tests/questions/${id}`, data);
  }),
  addToPhase: createAsyncThunk('question/phase-linking', async ({id, phaseId}) => {
    return await http.patch(`admin/tests/questions/${id}/add-to-phase/${phaseId}`);
  }),
  getPhases: createAsyncThunk('question/phases', async (id) => {
    return (await http.get(`admin/tests/questions/${id}/get-phases-and-training-programs`)).data;
  }),
  removePhase: createAsyncThunk('question/phases/remove', async ({id, phaseId}) => {
    return (await http.patch(path(`/${id}/untie-from-phase/${phaseId}`))).data;
  }),
  removeAllPhases: createAsyncThunk('question/phases/removeAll', async (id) => {
    return await http.patch(`admin/tests/questions/${id}/untie-from-all-phases`).data;
  }),

  answers: {
    getByQuestion: createAsyncThunk('answers/getByQuestion', async (id) => {
      return (await http.get(`admin/tests/questions/${id}/answer`)).data;
    }),
    create: createAsyncThunk('answers/create', async ({id, data}) => {
      return (await http.post(`admin/tests/questions/${id}/answer`, data)).data;
    }),
    delete: createAsyncThunk('answers/delete', async (id) => {
      return (await http.delete(`admin/tests/questions/answer/${id}`)).data;
    }),
    update: createAsyncThunk('answers/update', async ({id, data}) => {
      return (await http.put(`admin/tests/questions/answer/${id}`, data)).data;
    }),
  },
};
