import {useDispatch, useSelector} from 'react-redux';
import {RegistySelectors} from '../../store/RegistryStore';
import {useEffect} from 'react';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import {Button, Select, Space, Spin} from 'antd';

const AddNewProgramsForm = ({addProgram, addTrainingProgramsList}) => {
  const programs = useSelector(RegistySelectors.listenerPrograms);
  const programsLoading = useSelector(RegistySelectors.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RegistyAPI.listeners.getPrograms());
  }, []);

  const trainingList = addTrainingProgramsList.reduce((acc, current) => {
    const program = programs.find((elem) => elem.id === current);
    if (program) {
      return acc ? [...acc, program] : [];
    }
  }, []);

  return trainingList ? (
    trainingList?.map((training) => (
      <Space.Compact block key={training.id} style={{marginBottom: 12}}>
        <Select loading={programsLoading} style={{width: '100%'}} defaultValue={training.id}>
          <Select.Option disabled value={training.id}>
            {training.name}
          </Select.Option>
        </Select>
        <Button type="primary" onClick={() => addProgram({trainingProgramId: training.id})}>
          Добавить
        </Button>
      </Space.Compact>
    ))
  ) : (
    <Space align="center" direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
      <Spin size="large" />
    </Space>
  );
};

export default AddNewProgramsForm;
