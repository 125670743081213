import {Divider, Form, Input} from 'antd';
import React from 'react';
import {LOCALE} from '../../common';
import CheckedFormItem from '../UX/CheckedFormItem';
import StringDatePicker from '../UX/StringDatePicker';

const {Item} = Form;
const {LISTENERS} = LOCALE;
const ChangeQueryItems = ({changeQuery = {}, listener}) => {
  const studentChangeQuery = {...changeQuery};

  const names = Object.keys(studentChangeQuery);

  return names.map((name) => (
    <React.Fragment key={`${listener.id}_${name}`}>
      <CheckedFormItem checkboxName={['checkboxes', name]} label={LISTENERS[name.toUpperCase()]}>
        <Item name={name} noStyle>
          {name === 'birthday' ? (
            <StringDatePicker format={'DD.MM.YYYY'} style={{width: '100%'}} size="small" disabled />
          ) : (
            <Input size="small" disabled />
          )}
        </Item>
        <p style={{textAlign: 'center', margin: '6px 0'}}>{LISTENERS.CHANGE_ON}</p>
        <Item name={['changeQueryJson', name]} noStyle>
          {name === 'birthday' ? (
            <StringDatePicker format={'DD.MM.YYYY'} style={{width: '100%'}} size="small" />
          ) : (
            <Input size="small" />
          )}
        </Item>
      </CheckedFormItem>
      <Divider />
    </React.Fragment>
  ));
};

export default ChangeQueryItems;
