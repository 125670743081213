import {useEffect} from 'react';
import {Button, Table, Typography} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {EditOutlined, PlusOutlined} from '@ant-design/icons';

import ModalForm from '../UX/ModalForm';
import {ADMIN_RIGHTS, compare, LOCALE} from '../../common';
import {AdminAPI} from '../../store/AdminStore/methods';
import {AdminSelectors} from '../../store/AdminStore';
import AdminForm from './AdminForm';
import UseMessage from '../hooks/useMessage';

const AdminsTable = () => {
  const dispatch = useDispatch();
  const admins = useSelector(AdminSelectors.admins);
  const adminInfo = useSelector(AdminSelectors.adminInfo);
  const isLoading = useSelector(AdminSelectors.loading);
  const {showError} = UseMessage();

  useEffect(() => {
    dispatch(AdminAPI.admins.getAll());
  }, []);

  const isModerating = adminInfo.adminRights.includes(ADMIN_RIGHTS.adminModerating.id);

  const signUpAdmin = (values) => dispatch(AdminAPI.admins.signUp(values)).catch((err) => showError(err));
  const editAdmin = (adminId) => (data) => {
    dispatch(AdminAPI.admins.editAdmin({adminId, data})).catch((err) => showError(err));
  };
  return (
    <Table rowKey="id" dataSource={admins} loading={isLoading} scroll={{x: 'auto'}}>
      <Table.Column
        title={LOCALE.COMMON.SURNAME}
        dataIndex="surname"
        align="center"
        sorter={(a, b) => compare(a.surname, b.surname)}
      />
      <Table.Column title={LOCALE.COMMON.NAME} dataIndex="firstname" align="center" />
      <Table.Column title={LOCALE.COMMON.SECOND_NAME} dataIndex="secondname" align="center" />
      <Table.Column title={LOCALE.COMMON.EMAIL} dataIndex="login" align="center" />
      <Table.Column
        title={LOCALE.COMMON.AVAILABILITY}
        dataIndex="isAvailable"
        align="center"
        render={(available) =>
          available ? (
            <Typography.Text type="success">{LOCALE.COMMON.AVAILABLE}</Typography.Text>
          ) : (
            <Typography.Text type="danger">{LOCALE.COMMON.NOT_AVAILABLE}</Typography.Text>
          )
        }
      />
      <Table.Column
        title={
          <ModalForm
            title="Регистрация администратора"
            initialValues={{isOperator: false, isAvailable: false}}
            formItems={<AdminForm />}
            onOk={signUpAdmin}
            modalProps={{width: 700, centered: true}}
            formProps={{
              layout: {labelCol: {span: 10}},
              style: {overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'},
            }}>
            <Button type="primary" icon={<PlusOutlined />} disabled={!isModerating} />
          </ModalForm>
        }
        dataIndex="id"
        align="center"
        render={(id, record) => (
          <ModalForm
            title="Редактирование администратора"
            initialValues={record}
            formItems={<AdminForm editing />}
            onOk={editAdmin(id)}
            modalProps={{bodyStyle: {flex: 1}, width: '80vw', centered: true}}
            formProps={{
              layout: {labelCol: {span: 8}},
              style: {overflowY: 'auto', paddingBottom: 100, paddingRight: 20},
            }}>
            <Button icon={<EditOutlined />} disabled={!isModerating} />
          </ModalForm>
        )}
      />
    </Table>
  );
};

export default AdminsTable;
