import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Upload } from "antd";
import React from "react";
import { LOCALE } from "../../common";
import UseMessage from "../hooks/useMessage";

const { Item } = Form;
const { COMMON, PLACEHOLDERS, EDUCATION } = LOCALE;
const PresentationFormItem = () => {
	const beforeUpload = (info) => {
		return false;
	};

	const changeHandler = ({ fileList }) => {
		return fileList;
	};
	return (
		<>
			<Item name='eduName' label={COMMON.TITLE} rules={[{ required: true }]}>
				<Input placeholder={PLACEHOLDERS.TITLE} />
			</Item>

			<Item
				label={EDUCATION.SLIDES}
				name='slidesContent'
				rules={[{ required: true }]}
				valuePropName='fileList'
				getValueFromEvent={changeHandler}
			>
				<Upload multiple beforeUpload={beforeUpload} listType='picture' accept="file/pdf, image/jpeg, image/jpg, image/bmp, image/png">
					<Button icon={<UploadOutlined />}>{EDUCATION.UPLOAD_SLIDES}</Button>
				</Upload>
			</Item>
		</>
	);
};

export default PresentationFormItem;
