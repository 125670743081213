import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, List, Popconfirm, Space} from 'antd';
import dayjs from 'dayjs';

import {AdminAPI} from '../../store/AdminStore/methods';
import {AdminSelectors} from '../../store/AdminStore';

import ModalForm from '../UX/ModalForm';
import NoteForm from './noteForm';
import UseMessage from '../hooks/useMessage';

const Notes = ({notableTypeId, maxHeight}) => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const notes = useSelector(AdminSelectors.notes);
  const loading = useSelector(AdminSelectors.loading);
  const {showError, showSuccess} = UseMessage();

  useEffect(() => {
    dispatch(AdminAPI.note.getAll({limit: 99999, offset: 0, notableId: id, notableTypeId}));
  }, []);

  const createNote = (data) => {
    const payload = {notableId: id, notableTypeId, ...data};

    dispatch(AdminAPI.note.create(payload))
      .then(() => showSuccess())
      .catch((err) => showError(err));
  };

  const editNote = (noteId) => (data) => {
    const payload = {notableId: id, notableTypeId, ...data};

    dispatch(AdminAPI.note.edit({id: noteId, data: payload}))
      .then(() => showSuccess())
      .catch((err) => showError(err));
  };

  const deleteNote = (noteId) => {
    dispatch(AdminAPI.note.deleteOne(noteId))
      .then(() => showSuccess())
      .catch((err) => showError(err));
  };

  return (
    <div>
      {notes.length > 0 ? (
        <div style={{maxHeight: maxHeight, overflowY: 'auto'}}>
          <List
            size="small"
            split={false}
            loading={loading}
            dataSource={notes}
            header={
              <div style={{display: 'grid', gridTemplateColumns: '100px 100px 80px auto 80px', padding: 5}}>
                <div>ФИО оператора</div>
                <div>Дата создания</div>
                <div>Важный</div>
                <div>Текст</div>
                <div>
                  <ModalForm title="Создать замечание" onOk={createNote} formItems={<NoteForm />}>
                    <Button type="primary" icon={<PlusOutlined />} />
                  </ModalForm>
                </div>
              </div>
            }
            renderItem={(note) => {
              return (
                <div
                  key={note.id}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '100px 100px 80px auto 80px',
                    backgroundColor: note.deleted_at ? 'lightgray' : note.color,
                    padding: 5,
                  }}>
                  <div style={{wordWrap: 'break-word', maxWidth: '100px'}}>{note.adminFullName}</div>
                  <div>{dayjs(note.created_at).format('DD.MM.YYYY')}</div>
                  {note.important ? <div>Да</div> : <div>Нет</div>}
                  <div>{note.text}</div>
                  <>
                    {note.deleted_at ? (
                      <div>Удален {dayjs(note.deleted_at).format('DD.MM.YYYY')}</div>
                    ) : (
                      <Space>
                        <ModalForm
                          title="Создать замечание"
                          onOk={editNote(note.id)}
                          initialValues={note}
                          formItems={<NoteForm />}>
                          <Button disabled icon={<EditOutlined />} />
                        </ModalForm>
                        <Popconfirm title="Удалить замечание?" onConfirm={() => deleteNote(note.id)}>
                          <Button icon={<DeleteOutlined />} />
                        </Popconfirm>
                      </Space>
                    )}
                  </>
                </div>
              );
            }}
          />
        </div>
      ) : (
        <ModalForm title="Создать замечание" onOk={createNote} formItems={<NoteForm />}>
          <Button disabled type="primary">
            Создать замечание
          </Button>
        </ModalForm>
      )}
    </div>
  );
};

export default Notes;
