import {useDispatch, useSelector} from 'react-redux';
import {Button, Collapse, Empty, List, Space, Spin, Tooltip, Typography} from 'antd';
import {CheckOutlined, DeleteOutlined, ExclamationCircleFilled, HistoryOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';

import {AdminSelectors} from '../../store/AdminStore';
import {AdminAPI} from '../../store/AdminStore/methods';

import UseMessage from '../hooks/useMessage';
import PopConfirmButton from '../UX/PopConfirmButton';

const NormsList = ({itemId, norms}) => {
  const dispatch = useDispatch();
  const loading = useSelector(AdminSelectors.loading);
  const isNormHistoryLoading = useSelector(AdminSelectors.isNormHistoryLoading);
  const normHistory = useSelector(AdminSelectors.normHistory);

  const {showSuccess, showError} = UseMessage();

  const actualizeContent = (normUuid) => () => {
    dispatch(AdminAPI.systemContentControl.actualize({itemId, normUuid}))
      .then((res) => showSuccess('Контент актуализирован'))
      .catch((err) => showError(err));
  };

  const unlinkNorm = (normUuid) => () => {
    dispatch(AdminAPI.systemContentControl.unlinkNorm({itemId, normUuid}))
      .then((res) => showSuccess('Норма отвязана'))
      .catch((err) => showError(err));
  };

  const getNormHistory = (normUuid) => {
    return normUuid ? dispatch(AdminAPI.systemContentControl.getNormHistory(normUuid)) : null;
  };

  return norms ? (
    <Collapse accordion bordered={false} onChange={getNormHistory} collapsible="header">
      {norms.map((norm) => {
        const panelHeader = norm.additionalPartName
          ? `${norm.mainPartName} (${norm.additionalPartName})`
          : norm.mainPartName;

        return (
          <Collapse.Panel
            key={norm.normUuid}
            className="collapse-panel-custom"
            header={panelHeader}
            extra={
              <Space style={{marginLeft: '30px'}}>
                {!norm.isActual ? (
                  <>
                    <ExclamationCircleFilled style={{color: '#f5222d', fontSize: 18}} />
                    <Tooltip title="Актуализация контента">
                      <Button icon={<HistoryOutlined />} onClick={actualizeContent(norm.normUuid)} />
                    </Tooltip>
                  </>
                ) : (
                  <CheckOutlined style={{color: '#52c41a', fontSize: 18}} />
                )}
                <PopConfirmButton
                  title="Отвязать норму?"
                  icon={<DeleteOutlined />}
                  okText="Да"
                  danger
                  onClick={unlinkNorm(norm.normUuid)}
                />
              </Space>
            }>
            {norm.contentNormComment && (
              <Typography.Paragraph type="secondary">Комментарий: {norm.contentNormComment}</Typography.Paragraph>
            )}
            <List
              size="large"
              header="История нормы"
              loading={isNormHistoryLoading}
              dataSource={normHistory}
              style={{margin: '0px 28px', padding: '0px'}}
              renderItem={(norm, index) => {
                const parser = new DOMParser();
                const text = parser.parseFromString(norm.text, 'text/html').documentElement.textContent;
                return (
                  <List.Item key={index} style={{textAlign: 'justify', padding: '4px 16px'}}>
                    <Space direction="vertical">
                      <Typography.Link href={norm.url} target="_blank">
                        {text}
                      </Typography.Link>
                      <Space direction="vertical" size={2}>
                        <Typography.Paragraph>Дата: {norm.date}</Typography.Paragraph>
                        <Typography.Paragraph>Комментарий: {norm.comment}</Typography.Paragraph>
                      </Space>
                    </Space>
                  </List.Item>
                );
              }}
            />
          </Collapse.Panel>
        );
      })}
    </Collapse>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={loading ? <Spin /> : 'Нет данных'} />
  );
};

export default NormsList;
