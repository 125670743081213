import {
  AuditOutlined,
  BarChartOutlined,
  BookOutlined,
  ContactsOutlined,
  CustomerServiceOutlined,
  DatabaseOutlined,
  FilePptOutlined,
  HomeOutlined,
  IdcardOutlined,
  OrderedListOutlined,
  QuestionOutlined,
  SettingOutlined,
  SnippetsOutlined,
  SolutionOutlined,
  TagOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import {Badge, Menu} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {ADMIN_RIGHTS, LOCALE} from '../../common';
import {ROUTES} from '../../common/routes';
import {AdminSelectors} from '../../store/AdminStore';
import {RegistySelectors} from '../../store/RegistryStore';
import tipText from '../../utils/tipText';

const linkWithBadge = {
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
};

const Navigation = ({collapsed}) => {
  const customersCount = useSelector(AdminSelectors.customersCount);
  const contractsCount = useSelector(RegistySelectors.contractCount);
  const listenersCount = useSelector(RegistySelectors.listenersCount);
  const adminInfo = useSelector(AdminSelectors.adminInfo);
  
  const LinkLabel = (label, path) => <Link to={path}>{label}</Link>;

  const location = window.location;
  const routersKeys = Object.keys(ROUTES);

  const selectedKey = () => {
    const activeRoute = routersKeys.find(
      (key) => location.pathname === (key === 'registry' ? '/' : `/${ROUTES[key].path}`)
    );

    if (!activeRoute) return null;
    else return activeRoute;
  };

  const requests = ['запрос', 'запроса', 'запросов'];

  const haveAnalyticsAccess = adminInfo.adminRights.includes(ADMIN_RIGHTS.contractAnalytics.id);
  const haveOperatorsAccess =
    adminInfo.adminRights.includes(ADMIN_RIGHTS.operatorManagement.id) ||
    adminInfo.adminRights.includes(ADMIN_RIGHTS.operatorWorkingAnalytics.id);

  const displayRegistry = adminInfo.adminRights.includes(ADMIN_RIGHTS.menuShowRegistry.id);
  const displayCustomers = adminInfo.adminRights.includes(ADMIN_RIGHTS.menuShowCustomers.id);
  const displayPromocodes = adminInfo.adminRights.includes(ADMIN_RIGHTS.menuShowPromocodes.id);
  const displayUsers = adminInfo.adminRights.includes(ADMIN_RIGHTS.menuShowUsers.id);
  const displaySettings = adminInfo.adminRights.includes(ADMIN_RIGHTS.menuShowSettings.id);
  const displayHeadPosts = adminInfo.adminRights.includes(ADMIN_RIGHTS.menuShowHeadPosts.id);
  const displayTrainingPrograms = adminInfo.adminRights.includes(ADMIN_RIGHTS.menuShowTrainingPrograms.id);
  const displayTrainingProgramTypes = adminInfo.adminRights.includes(ADMIN_RIGHTS.menuShowTrainingProgramTypes.id);
  const displayEducationMaterials = adminInfo.adminRights.includes(ADMIN_RIGHTS.menuShowEducationMaterials.id);
  const displayQuestions = adminInfo.adminRights.includes(ADMIN_RIGHTS.menuShowQuestions.id);
  const displayCommercialProposal = adminInfo.adminRights.includes(ADMIN_RIGHTS.menuShowCommercialOffers.id);
  const displayAdmins = adminInfo.adminRights.includes(ADMIN_RIGHTS.menuShowAdmins.id);
  const displayReports = adminInfo.adminRights.includes(ADMIN_RIGHTS.FISFRDOUnloading.id);

  const iconSize = 16;
  const items = [
    displayRegistry
      ? {
          key: 'registry',
          label: (
            <Link to={'/'} style={linkWithBadge}>
              {LOCALE.MENU_ITEMS.REGISTRY}
              {contractsCount !== 0 && (
                <Badge
                  color="red"
                  count={contractsCount}
                  title={`${tipText(contractsCount, requests)} на проверку диплома`}
                />
              )}
              {listenersCount !== 0 && (
                <Badge
                  color="red"
                  count={listenersCount}
                  title={`${tipText(listenersCount, requests)} на изменения слушателей`}
                />
              )}
            </Link>
          ),
          icon: collapsed ? (
            <>
              <Badge color="red" count={contractsCount + listenersCount} offset={[5, 10]}>
                <AuditOutlined style={{fontSize: iconSize}} />
              </Badge>
            </>
          ) : (
            <AuditOutlined style={{fontSize: iconSize}} />
          ),
          title: contractsCount
            ? `${LOCALE.MENU_ITEMS.REGISTRY} (${tipText(contractsCount, requests)} на проверку диплома) и  (${tipText(
                listenersCount,
                requests
              )} на изменения слушателей) `
            : `${LOCALE.MENU_ITEMS.REGISTRY}`,
        }
      : null,
    displayCustomers
      ? {
          key: 'customers',
          label: (
            <Link to={'/customers'} style={linkWithBadge}>
              {LOCALE.MENU_ITEMS.CUSTOMERS}
              {/* {customersCount !== 0 && (
                <Badge
                  color="red"
                  count={customersCount}
                  title={`${tipText(customersCount, requests)} на изменение заказчика`}
                />
              )} */}
            </Link>
          ),
          icon: collapsed ? (
            <Badge color="red" count={customersCount} offset={[5, 10]}>
              <IdcardOutlined style={{fontSize: iconSize}} />
            </Badge>
          ) : (
            <IdcardOutlined style={{fontSize: iconSize}} />
          ),
          title: customersCount
            ? `${LOCALE.MENU_ITEMS.CUSTOMERS} (${tipText(customersCount, requests)} на изменение заказчика)`
            : `${LOCALE.MENU_ITEMS.CUSTOMERS}`,
        }
      : null,
    displayUsers
      ? {
          key: 'listeners',
          label: LinkLabel(LOCALE.MENU_ITEMS.LISTENERS, 'listeners'),
          icon: <ContactsOutlined style={{fontSize: iconSize}} />,
        }
      : null,
    displayPromocodes
      ? {
          key: 'promocodes',
          label: LinkLabel(LOCALE.MENU_ITEMS.PROMOCODES, 'promocodes'),
          icon: <TagOutlined style={{fontSize: iconSize}} />,
        }
      : null,
    displayUsers
      ? {
          key: 'users',
          label: LinkLabel(LOCALE.MENU_ITEMS.USERS, 'users'),
          icon: <TeamOutlined style={{fontSize: iconSize}} />,
        }
      : null,
    haveOperatorsAccess
      ? {
          key: 'operators',
          label: LinkLabel(LOCALE.MENU_ITEMS.OPERATORS, 'operators'),
          icon: <CustomerServiceOutlined style={{fontSize: iconSize}} />,
        }
      : null,
    displayAdmins
      ? {
          key: 'admins',
          label: LinkLabel(LOCALE.MENU_ITEMS.ADMINS, 'admins'),
          icon: <SolutionOutlined style={{fontSize: iconSize}} />,
        }
      : null,

    displayTrainingProgramTypes
      ? {
          key: 'program-types',
          label: LinkLabel(LOCALE.MENU_ITEMS.PROGRAM_TYPES, 'program-types'),
          icon: <OrderedListOutlined style={{fontSize: iconSize}} />,
        }
      : null,
    displayTrainingPrograms
      ? {
          key: 'programs',
          label: LinkLabel(LOCALE.MENU_ITEMS.PROGRAMS, 'programs'),
          icon: <FilePptOutlined style={{fontSize: iconSize}} />,
        }
      : null,
    displayEducationMaterials
      ? {
          key: '/learning-materials/',
          label: LinkLabel(LOCALE.MENU_ITEMS.LEARNING_MATERIALS, '/learning-materials/'),
          icon: <BookOutlined style={{fontSize: iconSize}} />,
        }
      : null,
    displayQuestions
      ? {
          key: 'questions',
          label: LinkLabel(LOCALE.MENU_ITEMS.QUESTIONS, 'questions'),
          icon: <QuestionOutlined style={{fontSize: iconSize}} />,
        }
      : null,
    displayCommercialProposal
      ? {
          key: 'commercial-proposes',
          label: LinkLabel(LOCALE.MENU_ITEMS.COMMERCIAL_PROPOSES, 'commercial-proposes'),
          icon: <SnippetsOutlined style={{fontSize: iconSize}} />,
        }
      : null,
    haveAnalyticsAccess
      ? {
          key: 'analytics',
          label: LinkLabel(LOCALE.MENU_ITEMS.ANALYTICS, 'analytics'),
          icon: <BarChartOutlined style={{fontSize: iconSize}} />,
        }
      : null,
    displayReports
      ? {
          key: 'reports',
          label: LinkLabel(LOCALE.MENU_ITEMS.REPORTS, 'reports'),
          icon: <AuditOutlined style={{fontSize: iconSize}} />,
        }
      : null,
    displayHeadPosts
      ? {
          key: 'jobTitle',
          label: LinkLabel(LOCALE.MENU_ITEMS.JOBTITLE, 'job_title'),
          icon: <DatabaseOutlined style={{fontSize: iconSize}} />,
        }
      : null,
    displaySettings
      ? {
          key: 'settings',
          label: LinkLabel(LOCALE.MENU_ITEMS.SETTINGS, 'settings'),
          icon: <SettingOutlined style={{fontSize: iconSize}} />,
        }
      : null,
  ];

  return <Menu style={{minHeight: '100%'}} mode="inline" items={items} defaultSelectedKeys={selectedKey()} />;
};

export default Navigation;
