import {memo} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Checkbox, DatePicker, Form, Input, Space, Switch, Table, Typography} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';

import {DELIVERY_TYPE, LOCALE} from '../../common';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import ModalForm from '../UX/ModalForm';
import UseMessage from '../hooks/useMessage';
import Link from 'antd/es/typography/Link';

const {DELIVERY, COMMON} = LOCALE;

const TracksTable = ({data, loading, fetchDelivery, deliveryTypeId}) => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const {showSuccess, showError} = UseMessage();

  const addTrackNumber = (data) => {
    dispatch(RegistyAPI.trackNumbers.create({id, data}))
      .then(() => {
        fetchDelivery();
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const deleteTrackNumber = (id) => () => {
    dispatch(RegistyAPI.trackNumbers.delete({id}))
      .then(() => {
        fetchDelivery();
        showSuccess('Трек номер успешно удален');
      })
      .catch((e) => showError(e));
  };

  const editTrackNumber = (id) => (data) => {
    dispatch(RegistyAPI.trackNumbers.edit({id, data}))
      .then(() => {
        fetchDelivery();
        showSuccess('Трек номер успешно изменен');
      })
      .catch((e) => showError(e));
  };

  const columnProps = (key, title, props, render) => ({dataIndex: key, title, render, align: 'center', ...props});

  const FormItems = memo(() => {
    return (
      <>
        <Form.Item required name="trackNumber" label={DELIVERY.TRACK_NUMBER}>
          <Input />
        </Form.Item>
        {/*<Form.Item name="trackNumberDate" label={DELIVERY.DELIVERY_DATE}>*/}
        {/*  <DatePicker format="DD-MM-YYYY" />*/}
        {/*</Form.Item>*/}
        <Form.Item name="note" label={COMMON.NOTE}>
          <TextArea style={{resize: 'none', height: '125px'}} />
        </Form.Item>
        <Form.Item required name="isCurrent" valuePropName="checked" label={DELIVERY.IS_CURRENT}>
          <Checkbox />
        </Form.Item>
      </>
    );
  });

  const columns = [
    columnProps('trackNumber', DELIVERY.TRACK_NUMBER, {width: 250}, (trackNumber) =>
      deliveryTypeId === DELIVERY_TYPE[2].value ? (
        <Link href={`https://www.pochta.ru/tracking?barcode=${trackNumber}`} target="_blank">
          {trackNumber}
        </Link>
      ) : (
        <Typography.Text>{trackNumber}</Typography.Text>
      )
    ),
    columnProps('note', COMMON.NOTE),
    columnProps('trackNumberDate', DELIVERY.DELIVERY_DATE),
    columnProps('isCurrent', DELIVERY.IS_CURRENT, {}, (val) => (
      <Switch checkedChildren="Да" unCheckedChildren="Нет" disabled checked={val} />
    )),
    {
      dataIndex: 'id',
      align: 'center',
      title: () => {
        return (
          <ModalForm
            title={DELIVERY.NEW_TRACK_NUMBER}
            onOk={addTrackNumber}
            modalProps={{confirmLoading: loading}}
            formItems={<FormItems />}>
            <Button type="primary" icon={<PlusOutlined />} />
          </ModalForm>
        );
      },
      width: 100,
      render: (id, render) => {
        const initialValue = {...render, trackNumberDate: dayjs(render.trackNumberDate)};

        return (
          <Space size={12}>
            <Button danger icon={<DeleteOutlined />} onClick={deleteTrackNumber(id)} />
            <ModalForm
              title={DELIVERY.EDIT_TRACK_NUMBER}
              onOk={editTrackNumber(id)}
              modalProps={{confirmLoading: loading}}
              initialValues={initialValue}
              formItems={<FormItems />}>
              <Button icon={<EditOutlined />} />
            </ModalForm>
          </Space>
        );
      },
    },
  ];

  return <Table pagination={false} bordered columns={columns} rowKey={'id'} dataSource={data} loading={loading} />;
};

export default TracksTable;
