import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';
import {Space} from 'antd';
import PhasesList from '../PhasesList';
import PhaseLinkingModal from './PhaseLinkingModal';
import UseMessage from '../hooks/useMessage';
import {EduSelectors} from '../../store/EducationStore';

export default function EdumaterialsPhaseLink({id, refetch}) {
  const dispatch = useDispatch();

  const loading = useSelector(EduSelectors.phasesLoading);
  const phases = useSelector(EduSelectors.materialPhases);
  const {showError, showSuccess} = UseMessage();

  useEffect(() => {
    getLinkedPhases(id);
  }, []);

  const addToPhase = (values) => {
    dispatch(EduMaterialsAPI.linkingPhases.addMaterialToPhase({id, phaseId: values.trainingProgramsPhaseId}))
      .then(() => {
        showSuccess();
        getLinkedPhases(id);
      })
      .catch((e) => showError(e));
  };

  const getLinkedPhases = (id) => {
    dispatch(EduMaterialsAPI.linkingPhases.getLinkedPhases(id)).catch((e) => showError(e));
  };

  const removePhase = (phaseId) => {
    dispatch(EduMaterialsAPI.linkingPhases.removeLearningMaterialformPhase({id, phaseId}))
      .then(() => {
        showSuccess();
        getLinkedPhases(id);
      })
      .catch((e) => showError(e));
  };
  const removeAllPhases = () => {
    dispatch(EduMaterialsAPI.linkingPhases.removeLearningMaterialformAllPhases(id))
      .then(() => {
        showSuccess();
        getLinkedPhases(id);
      })
      .catch((e) => showError(e));
  };

  return (
    <Space>
      <PhasesList loading={loading} phases={phases} onDelete={removePhase} onDeleteAll={removeAllPhases} />
      <PhaseLinkingModal phaseItemName={'trainingProgramsPhaseId'} onOk={addToPhase} />
    </Space>
  );
}
