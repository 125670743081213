import {Form, Input, InputNumber, Switch} from 'antd';

const PhaseForm = () => {
  return (
    <>
      <Form.Item label="Название" name="name" rules={[{required: true}]}>
        <Input autoFocus placeholder={'Название фазы...'} />
      </Form.Item>
      <Form.Item label="Номер" name="number" rules={[{required: true}]}>
        <InputNumber min={0} placeholder={0} />
      </Form.Item>
      <Form.Item label="Длительность экзамена" name="maxExamDuration" rules={[{type: 'number', min: 30}]}>
        <InputNumber placeholder={0} />
      </Form.Item>
      <Form.Item label="Экзамен не требуется" name="needExam" valuePropName="checked">
        <Switch />
      </Form.Item>
    </>
  );
};

export default PhaseForm;
