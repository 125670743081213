import { Checkbox, Form, Input } from "antd";
import JoditEditor from "jodit-react";
import React from "react";
import { JoditConfig, LOCALE } from "../../common";

const { Item } = Form;
const { QUESTIONS } = LOCALE;

const AnswerForm = () => {
	return (
		<>
			<Item name={"text"} label={QUESTIONS.ANSWER_TEXT}>
				<JoditEditor config={{ style: { height: 250 }, ...JoditConfig }} />
			</Item>
			<Item valuePropName='checked' name={"isRight"} label={QUESTIONS.IS_RIGHT}>
				<Checkbox />
			</Item>
		</>
	);
};

export default AnswerForm;
