import {Button, Typography} from 'antd';
import React from 'react';
import PageLayout from '../components/Layout/PageLayout';
import {useNavigate} from 'react-router-dom';

const Fallback = () => {
  const navigate = useNavigate();

  const handleToRegistyButtoon = () => {
    navigate('/');
  };
  return (
    <PageLayout>
      <Typography.Title>404 not found</Typography.Title>
      <Button type="primary" size="large" onClick={handleToRegistyButtoon}>
        В реестр
      </Button>
    </PageLayout>
  );
};

export default Fallback;
