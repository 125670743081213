import {Form, Input, Select, Space} from 'antd';
import React, {useEffect} from 'react';
import {LOCALE} from '../../common';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import {useDispatch, useSelector} from 'react-redux';
import {RegistySelectors} from '../../store/RegistryStore';
import {TEXT} from '../../common/ru_RU';

const {Item} = Form;
const {PLACEHOLDERS, COMMON} = LOCALE;

const NewListenerForm = () => {
  const programs = useSelector(RegistySelectors.listenerPrograms);
  const programsLoading = useSelector(RegistySelectors.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RegistyAPI.listeners.getPrograms());
  }, []);

  return (
    <>
      <Item name="surname" label={COMMON.SURNAME}>
        <Input placeholder={PLACEHOLDERS.SURNAME} />
      </Item>
      <Item name="firstname" label={COMMON.NAME}>
        <Input placeholder={PLACEHOLDERS.NAME} />
      </Item>
      <Item name="secondname" label={COMMON.SECOND_NAME}>
        <Input placeholder={PLACEHOLDERS.SECOND_NAME} />
      </Item>
      <Item name="jobPlace" label={COMMON.JOB_PLACE}>
        <Input placeholder="ОАО Газпром" />
      </Item>
      <Item name="profession" label={COMMON.PROFESSION}>
        <Input placeholder={PLACEHOLDERS.PROFESSION} />
      </Item>
      <Item name="trainingProgramId" label={TEXT.REGISTRY.COLUMNS.PROGRAMS}>
        <Select
          placeholder="ПТМ-14"
          filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
          showSearch
          loading={programsLoading}
          style={{width: '100%'}}>
          {programs.map((program) => (
            <Select.Option key={program.id} value={program.id}>
              {program.name}
            </Select.Option>
          ))}
        </Select>
      </Item>
    </>
  );
};

export default NewListenerForm;
