import React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import UseMessage from '../../components/hooks/useMessage';
import PageLayout from '../../components/Layout/PageLayout';
import VideoFormItems from '../../components/LearningMaterials/VideoFormItems';
import CustomForm from '../../components/UX/CustomForm';
import {EduActions, EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';
import EdumaterialsPhaseLink from '../../components/LearningMaterials/EdumaterialsPhaseLink';
import Norms from '../../components/SystemContentControl/Norms';

const layout = {
  labelCol: {
    sm: {span: 6},
    xl: {
      span: 4,
    },
  },
  wrapperCol: {
    xl: {
      span: 8,
    },
  },
};

const Video = () => {
  const {id, materialId} = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const loading = useSelector(EduSelectors.loading);
  const video = useSelector(EduSelectors.selectedMaterial);

  const {showSuccess, showError} = UseMessage();

  const fetchVideo = () => {
    dispatch(EduMaterialsAPI.videos.getById(id)).catch(() => {
      navigate('*');
    });
  };

  const goBack = () => navigate(-1);

  const handleLinkPhase = (values) => {
    dispatch(EduMaterialsAPI.linkLearningMaterialToPhase({id, data: values}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const updateVideo = (data) => {
    dispatch(EduMaterialsAPI.videos.update({id, data}))
      .then(() => {
        showSuccess();
        goBack();
      })
      .catch((e) => showError(e));
  };

  useEffect(() => {
    fetchVideo();
    return () => {
      dispatch(EduActions.clearSelectedMaterial());
    };
  }, []);

  return (
    <PageLayout>
      <CustomForm onOk={updateVideo} onCancel={goBack} loading={loading} initialValues={video} layout={layout}>
        <VideoFormItems />
      </CustomForm>
      <EdumaterialsPhaseLink id={id} refetch={fetchVideo} />
      <Norms id={materialId} itemType="educationMaterial" />
    </PageLayout>
  );
};

export default Video;
