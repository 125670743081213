import {useRef} from 'react';
import Input from 'antd/es/input/Input';
import {Button, Space} from 'antd';
import {SearchOutlined} from '@ant-design/icons';

const SearchColumnFilter = (dataIndex) => {
  const searchInput = useRef(null);
  return {
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button type="primary" onClick={confirm} icon={<SearchOutlined />} size="small">
            Поиск
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small">
            Сбросить
          </Button>
          {/*<Button type="link" size="small" onClick={close}>*/}
          {/*  Закрыть*/}
          {/*</Button>*/}
        </Space>
      </div>
    ),

    filterIcon: (filtered) => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  };
};

export default SearchColumnFilter;
