import {useCallback, useRef} from 'react';

const useDebounce = (callback, delay) => {
  const timer = useRef();

  return useCallback(
    (...args) => {
      if (timer.current != null) {
        clearTimeout(timer.current);
      }
      timer.current = window.setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );
};

export default useDebounce;
