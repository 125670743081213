import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Skeleton, Button, Form, Popconfirm, Space} from 'antd';

import {LOCALE} from '../../common';
import {AdminAPI} from '../../store/AdminStore/methods';
import {AdminSelectors} from '../../store/AdminStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';

import UseMessage from '../hooks/useMessage';
import PageLayout from '../Layout/PageLayout';
import CustomForm from '../UX/CustomForm';
import LinkedTypes from './LinkedTypes';
import MemberItems from './MemberItems';
import CommissionMemberLoading from './CommissionMemberLoading';

const {PAGE_TITLES, SYSTEM, MESSAGES} = LOCALE;

const CommissionMember = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const memberInfo = useSelector(AdminSelectors.memberInfo);
  const memberTypes = useSelector(AdminSelectors.memberTypes);
  const loading = useSelector(AdminSelectors.loading);

  const {showError, showSuccess} = UseMessage();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = () => {
    dispatch(AdminAPI.commissionMembers.getById(id)).catch(() => {
      navigate(-1);
    });
    dispatch(AdminAPI.commissionMembers.getTypes(id));
    dispatch(EduMaterialsAPI.programTypes.getAll());
  };

  const handleCancel = () => {
    navigate(`/settings/?key=members`);
  };

  const handleUpdate = (values) => {
    dispatch(AdminAPI.commissionMembers.update({id, data: values}))
      .then(() => {
        showSuccess();
        fetchInfo();
      })
      .catch((e) => showError(e));
  };

  const handleDelete = () => {
    dispatch(AdminAPI.commissionMembers.delete(id))
      .then(() => {
        showSuccess();
        navigate(`/settings/?key=members`);
      })
      .catch((e) => showError(e));
  };

  const handleDeleteAllTypes = () => {
    dispatch(AdminAPI.commissionMembers.removeAllTypes(id))
      .then(() => {
        fetchInfo();
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const handleDeleteType = (programType) => {
    dispatch(AdminAPI.commissionMembers.removeType({id, typeId: programType}))
      .then(() => {
        fetchInfo();
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  const handleCreateType = (values) => {
    dispatch(AdminAPI.commissionMembers.createType({id, data: values}))
      .then(() => {
        fetchInfo();
        showSuccess();
      })
      .catch((e) => showError(e));
  };

  return (
    <PageLayout pageTitle={PAGE_TITLES.COMMISSION_MEMBER} spaceProps={{style: {maxWidth: '600px', width: '100%'}}}>
      <CustomForm formInstance={form} onOk={handleUpdate} showButtons={false} initialValues={memberInfo}>
        {memberInfo ? <MemberItems /> : <CommissionMemberLoading />}
      </CustomForm>
      <Space style={{width: '100%'}} wrap>
        <Button type="primary" onClick={form.submit}>
          {SYSTEM.SAVE}
        </Button>
        <Popconfirm onConfirm={handleDelete} title={MESSAGES.CONFIRM}>
          <Button danger>{SYSTEM.DELETE}</Button>
        </Popconfirm>
        <Button onClick={handleCancel}>{SYSTEM.CANCEL}</Button>
      </Space>
      <LinkedTypes
        loading={loading}
        onDeleteAll={handleDeleteAllTypes}
        onDelete={handleDeleteType}
        onCreate={handleCreateType}
        types={memberTypes}
      />
    </PageLayout>
  );
};

export default CommissionMember;
