import {createSlice} from '@reduxjs/toolkit';

import {QuestionsAPI} from './methods';
import {AdminAPI} from '../AdminStore/methods';

const initialState = {
  questions: [],
  selectedQuestion: {text: '', note: ''},
  questionsTableCount: 0,
  answers: [],
  phases: [],
  isFiltered: false,
  loading: false,
  isNormsLoading: false,
};

const questions = createSlice({
  name: 'questions',
  initialState,
  reducers: {},
  extraReducers: {
    [QuestionsAPI.getAll.pending]: (state) => {
      state.questions = [];
      state.loading = true;
    },
    [QuestionsAPI.getAll.fulfilled]: (state, action) => {
      state.questions = action.payload.data;
      state.loading = false;
      state.isFiltered = false;
      state.questionsTableCount = action.payload.totalCount;
    },

    [QuestionsAPI.filter.pending]: (state) => {
      state.loading = true;
    },
    [QuestionsAPI.filter.fulfilled]: (state, action) => {
      state.questions = action.payload.data;
      state.loading = false;
      state.isFiltered = true;
      state.questionsTableCount = action.payload.totalCount;
    },
    [QuestionsAPI.filter.rejected]: (state, action) => {
      state.loading = false;
      state.isFiltered = false;
    },

    [QuestionsAPI.getById.rejected]: (state, action) => {
      state.selectedQuestion = null;
      state.loading = false;
    },
    [QuestionsAPI.getById.pending]: (state) => {
      state.loading = true;
    },
    [QuestionsAPI.getById.fulfilled]: (state, action) => {
      state.selectedQuestion = action.payload;
      state.loading = false;
    },

    ///get answers
    [QuestionsAPI.answers.getByQuestion.pending]: (state) => {
      state.loading = true;
    },
    [QuestionsAPI.answers.getByQuestion.fulfilled]: (state, action) => {
      state.answers = action.payload.data;
      state.loading = false;
    },
    [QuestionsAPI.answers.getByQuestion.rejected]: (state) => {
      state.loading = false;
    },

    //create new answer
    [QuestionsAPI.answers.create.pending]: (state) => {
      state.loading = true;
    },
    [QuestionsAPI.answers.create.fulfilled]: (state, action) => {
      state.answers = [...state.answers, action.payload];
      state.loading = false;
    },
    [QuestionsAPI.answers.create.rejected]: (state) => {
      state.loading = false;
    },

    //update answer
    [QuestionsAPI.answers.update.pending]: (state) => {
      state.loading = true;
    },
    [QuestionsAPI.answers.update.fulfilled]: (state, action) => {
      state.answers = state.answers.map((answer) => (answer.id === action.payload.id ? action.payload : answer));
      state.loading = false;
    },
    [QuestionsAPI.answers.update.rejected]: (state) => {
      state.loading = false;
    },

    //delete answer
    [QuestionsAPI.answers.delete.pending]: (state) => {
      state.loading = true;
    },
    [QuestionsAPI.answers.delete.fulfilled]: (state, action) => {
      state.answers = state.answers.filter((answer) => answer.id !== action.meta.arg);
      state.loading = false;
    },
    [QuestionsAPI.answers.delete.rejected]: (state) => {
      state.loading = false;
    },

    //create new question
    [QuestionsAPI.create.pending]: (state) => {
      state.loading = true;
    },
    [QuestionsAPI.create.rejected]: (state) => {
      state.loading = false;
    },
    [QuestionsAPI.create.fulfilled]: (state) => {
      state.loading = false;
    },

    //delete question
    [QuestionsAPI.delete.pending]: (state) => {
      state.loading = true;
    },
    [QuestionsAPI.delete.fulfilled]: (state, action) => {
      state.questions = state.questions.filter((question) => question.id !== action.meta.arg);
      state.loading = false;
    },
    [QuestionsAPI.delete.rejected]: (state) => {
      state.loading = false;
    },

    //update question
    [QuestionsAPI.update.pending]: (state) => {
      state.loading = true;
    },
    [QuestionsAPI.update.fulfilled]: (state, action) => {
      state.selectedQuestion = action.payload.data;
      state.loading = false;
    },
    [QuestionsAPI.update.rejected]: (state) => {
      state.loading = false;
    },

    //phases
    [QuestionsAPI.getPhases.pending]: (state) => {
      state.loading = true;
    },
    [QuestionsAPI.getPhases.fulfilled]: (state, action) => {
      state.phases = action.payload;
      state.loading = false;
    },
    [QuestionsAPI.getPhases.rejected]: (state) => {
      state.loading = false;
      state.phases = [];
    },

    [QuestionsAPI.addToPhase.pending]: (state) => {
      state.loading = true;
    },
    [QuestionsAPI.addToPhase.fulfilled]: (state, action) => {
      state.phases = [...state.phases, action.payload.data];
      state.loading = false;
    },
    [QuestionsAPI.addToPhase.rejected]: (state) => {
      state.loading = false;
    },

    [QuestionsAPI.removePhase.pending]: (state) => {
      state.loading = true;
    },
    [QuestionsAPI.removePhase.fulfilled]: (state, action) => {
      state.phases = state.phases.filter((phase) => phase.trainingProgramId !== action.payload.trainingProgramId);
      state.loading = false;
    },
    [QuestionsAPI.removePhase.rejected]: (state) => {
      state.loading = false;
      state.phases = [];
    },

    [QuestionsAPI.removeAllPhases.pending]: (state) => {
      state.loading = true;
    },
    [QuestionsAPI.removeAllPhases.fulfilled]: (state, action) => {
      state.phases = [];
      state.loading = false;
    },
    [QuestionsAPI.removeAllPhases.rejected]: (state) => {
      state.loading = false;
    },

    //content actuality
    [AdminAPI.systemContentControl.checkActualityList.pending]: (state, action) => {
      state.isNormsLoading = true;
    },
    [AdminAPI.systemContentControl.checkActualityList.fulfilled]: (state, action) => {
      const questionsWithNorms = action.payload.map((norm) => norm.itemId);
      state.questions = state.questions.map((question) => {
        if (questionsWithNorms.includes(question.id)) {
          const {contentExists, isAllNormsActual} = action.payload.find((norm) => norm.itemId === question.id);
          return {...question, contentExists, isAllNormsActual};
        }
        return question;
      });
      state.isNormsLoading = false;
    },
    [AdminAPI.systemContentControl.checkActualityList.rejected]: (state, action) => {
      state.isNormsLoading = false;
    },
  },
});

export const QuestionsActions = questions.actions;
export const QuestionsSelectors = {
  questions: (state) => state.questions.questions,
  phases: (state) => state.questions.phases,
  selectedQuestion: (state) => state.questions.selectedQuestion,
  questionsTableCount: (state) => state.questions.questionsTableCount,
  isFiltered: (state) => state.questions.isFiltered,
  loading: (state) => state.questions.loading,
  isNormsLoading: (state) => state.questions.isNormsLoading,
  answers: (state) => state.questions.answers,
  newAnswer: (state) => state.questions.newAnswer,
  newQuestion: (state) => state.questions.newQuestion,
  editAnswer: (state) => state.questions.editAnswer,
};

export default questions.reducer;
