import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Card, List, Popconfirm, Space, Table} from 'antd';
import {memo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useSearchParams} from 'react-router-dom';
import {compare, LOCALE, paramsToObject} from '../../common';
import {EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';
import UseMessage from '../hooks/useMessage';
import ModalForm from '../UX/ModalForm';
import ProgramTypesForm from './ProgramTypesForm';
import SearchColumnFilter from '../UX/SearchColumnFilter';

const {EDUCATION, COMMON, MESSAGES} = LOCALE;

const ProgramTypesTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const loading = useSelector(EduSelectors.loading);
  const programTypes = useSelector(EduSelectors.programTypes);
  const {showSuccess, showError} = UseMessage();

  useEffect(() => {
    dispatch(EduMaterialsAPI.programTypes.getAll());
  }, []);

  const {page = 1, limit = 10} = paramsToObject(searchParams);

  const deleteProgramType = (id) => {
    dispatch(EduMaterialsAPI.programTypes.delete(id))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const createProgramType = (values) => {
    dispatch(EduMaterialsAPI.programTypes.create(values))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const PricesRender = memo(({prices = []}) => {
    const firstFour = [...prices].sort((a, b) => a.peopleCount - b.peopleCount).slice(0, 4);
    return (
      <List
        size="small"
        style={{maxWidth: '520px', minWidth: '520px', margin: 'auto'}}
        grid={{column: 4, gutter: [16, 24]}}
        locale={{emptyText: EDUCATION.NO_PRICES}}
        dataSource={firstFour}
        renderItem={(item) => (
          <List.Item style={{margin: 0, padding: 0}} key={item.id}>
            <Card size="small" style={{width: '130px'}} title={`${EDUCATION.PEOPLE_COUNT}: ${item.peopleCount}`}>
              {`${COMMON.PRICE} ${item.cost} ₽`}
            </Card>
          </List.Item>
        )}
      />
    );
  });

  const columns = [
    {
      title: COMMON.TITLE,
      align: 'center',
      dataIndex: 'name',
      key: 'name',
      ...SearchColumnFilter('name'),
    },
    {
      title: COMMON.FULL_TITLE,
      align: 'center',
      labelWrap: true,
      scroll: {x: 'auto'},
      dataIndex: 'fullName',
      key: 'fullname',
      ellipsis: true,
      sorter: (a, b) => compare(a.fullName, b.fullName),
    },
    {
      title: EDUCATION.FIRST_FOUR_PRICES,
      dataIndex: 'prices',
      width: '600px',
      align: 'center',
      key: 'prices',
      render: (prices) => <PricesRender prices={prices ?? []} />,
    },
    {
      dataIndex: 'id',
      align: 'center',
      width: 100,
      title: (
        <ModalForm
          modalProps={{width: 650, colon: false}}
          initialValues={{expire: 0, isEnableKP: false, isHide: false}}
          onOk={createProgramType}
          title={EDUCATION.NEW_PROGRAM_TYPE}
          formItems={<ProgramTypesForm />}>
          <Button type="primary" icon={<PlusOutlined />} />
        </ModalForm>
      ),
      key: 'buttons',
      fixed: 'right',
      render: (id) => {
        return (
          <Space>
            <Link to={`/program-types/${id}`}>
              <Button icon={<EditOutlined />} />
            </Link>
            <Popconfirm placement="topRight" title={MESSAGES.CONFIRM} onConfirm={() => deleteProgramType(id)}>
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <Table
      rowKey="id"
      bordered
      scroll={{x: 'auto'}}
      loading={loading}
      dataSource={programTypes}
      columns={columns}
      pagination={{
        showSizeChanger: true,
        pageSize: Number(limit),
        current: Number(page),
        onChange: (page, limit) => setSearchParams({page, limit}, {replace: true}),
        onShowSizeChange: (page, limit) => setSearchParams({page, limit}, {replace: true}),
      }}
    />
  );
};

export default ProgramTypesTable;
