import {Form, Select} from 'antd';
import React from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LOCALE} from '../../common';
import {EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';

const {EDUCATION} = LOCALE;
const ProgramSelect = ({programItemName, phaseItemName, form}) => {
  const dispatch = useDispatch();
  const loading = useSelector(EduSelectors.loading);
  const programs = useSelector(EduSelectors.programs);
  const [selectedProgramPhases, setSelectedProgramPhases] = useState();

  useEffect(() => {
    dispatch(EduMaterialsAPI.programs.getAll());
  }, []);

  const handleSelect = (id) => {
    const program = programs.find((program) => program.id === id);
    const phases = [...program.phases].sort((a, b) => a.number - b.number);
    setSelectedProgramPhases(phases);
    form.resetFields();
  };

  return (
    <>
      <Form.Item name={programItemName && programItemName} label={EDUCATION.PROGRAM}>
        <Select
          filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
          showSearch
          onChange={handleSelect}
          loading={loading}
          style={{width: '100%'}}>
          {programs.map((program) => (
            <Select.Option value={program.id} key={program.id}>
              {program.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {selectedProgramPhases && (
        <Form.Item name={phaseItemName && phaseItemName} label={EDUCATION.PHASE}>
          <Select>
            {selectedProgramPhases.map((phase) => (
              <Select.Option key={phase.id} value={phase.id}>
                {phase.number}. {phase.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </>
  );
};

export default ProgramSelect;
