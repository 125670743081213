import {useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Col, Space, Spin, Typography} from 'antd';
import {SelectOutlined} from '@ant-design/icons';

import {CUSTOMER_TYPES, LOCALE} from '../../common';
import CustomerForm from '../../components/Customers/CustomerForm';
import PageLayout from '../../components/Layout/PageLayout';

import {AdminActions, AdminSelectors} from '../../store/AdminStore';
import {AdminAPI} from '../../store/AdminStore/methods';
import {useDispatch, useSelector} from 'react-redux';
import notableTypes from '../../common/notableTypes';
import Notes from '../../components/Notes';
import dayjs from 'dayjs';

const {PAGE_TITLES} = LOCALE;
const Customer = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customer = useSelector(AdminSelectors.customerInfo);

  useEffect(() => {
    dispatch(AdminAPI.customers.getById({id, ext: true})).catch(() => navigate('*'));
    return () => {
      dispatch(AdminActions.clearCustomer());
    };
  }, [id]);

  const customerTitle =
    customer?.customerTypeId === CUSTOMER_TYPES[1].id
      ? `${customer.surname} ${customer.name} ${customer?.secondName || ''}`
      : customer?.organizationName;

  return (
    <PageLayout pageTitle={customer ? customerTitle : PAGE_TITLES.CUSTOMER_INFO}>
      {customer ? (
        <>
          <Space>
            <Typography>Создан: {dayjs(customer?.['created_at']).format('DD.MM.YYYY')}</Typography>/
            <Typography>Обновлен: {dayjs(customer?.['updated_at']).format('DD.MM.YYYY')}</Typography>
          </Space>
          <Link to={`/users/${customer?.userId}`} component={Typography.Link} className="link">
            Посмотреть информацию о пользователе
            <SelectOutlined style={{marginLeft: '8px'}} />
          </Link>
          <Space wrap>
            Договора:
            {customer?.contracts.length
              ? customer?.contracts.map((contract, index) => {
                  return (
                    <Space key={index}>
                      <Link
                        to={`/registry/students/${contract.id}`}
                        component={Typography.Link}
                        style={{
                          backgroundColor: contract.color,
                          borderRadius: 4,
                          padding: 4,
                          textDecoration: contract?.isPayedOnline === true ? 'underline' : 'unset',
                        }}>
                        {contract.number}
                        {contract.inArchiveDate ? ' (арх.)' : null}
                      </Link>
                      {index === customer?.contracts.length - 1 ? null : <span>, </span>}
                    </Space>
                  );
                })
              : null}
          </Space>
          <CustomerForm id={id} />
          <Typography.Title level={3} style={{marginTop: '16px'}}>
            Замечания
          </Typography.Title>
          <Notes notableTypeId={notableTypes.customer} maxHeight={400} />
        </>
      ) : (
        <Spin />
      )}
    </PageLayout>
  );
};

export default Customer;
