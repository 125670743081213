import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row, Form, Input, Select, Switch, Space, Checkbox, Typography} from 'antd';
import {REGISTRY_CONTRACT_STATUS} from '../../common';
import {EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';

const ListenersFilters = ({form}) => {
  const dispatch = useDispatch();
  const loading = useSelector(EduSelectors.loading);
  const programs = useSelector(EduSelectors.programs);

  useEffect(() => {
    dispatch(EduMaterialsAPI.programs.getAll());
  }, []);

  const filterOption = (input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Row gutter={16}>
      <Col span={8}>
        <Form.Item name="surname" label="Фамилия" style={{marginBottom: 6}} labelCol={{span: 10}}>
          <Input placeholder="Иванов" />
        </Form.Item>
        <Form.Item name="firstname" label="Имя" style={{marginBottom: 6}} labelCol={{span: 10}}>
          <Input placeholder="Иван" />
        </Form.Item>
        <Form.Item name="secondname" label="Отчество" style={{marginBottom: 6}} labelCol={{span: 10}}>
          <Input placeholder="Иванович" />
        </Form.Item>
        <Form.Item name="email" label="Email" style={{marginBottom: 6}} labelCol={{span: 10}}>
          <Input placeholder="admin@mail.ru" />
        </Form.Item>
        <Form.Item name="blankNumber" label="Номер бланка" style={{marginBottom: 6}} labelCol={{span: 10}}>
          <Input placeholder="123456" />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="courseNumber" label="Номер курса" style={{marginBottom: 6}} labelCol={{span: 10}}>
          <Input placeholder="3105079" />
        </Form.Item>
        <Form.Item name="trainingProgramId" label="Программа обучения" style={{marginBottom: 6}} labelCol={{span: 10}}>
          <Select allowClear loading={loading} placeholder="БДД_01" showSearch filterOption={filterOption}>
            {programs.map((type) => (
              <Select.Option value={type.id} key={type.id}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="cardNumber" label="Номер удостоверения" style={{marginBottom: 6}} labelCol={{span: 10}}>
          <Input placeholder="1005279" />
        </Form.Item>
        <Form.Item name="jobPlace" label="Место работы" style={{marginBottom: 6}} labelCol={{span: 10}}>
          <Input placeholder="ПАО «Газпром»" />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="profession" label="Должность" style={{marginBottom: 6}} labelCol={{span: 10}}>
          <Input placeholder="Ген. директор" />
        </Form.Item>
        <Form.Item name="SNILS" label="СНИЛС" style={{marginBottom: 6}} labelCol={{span: 10}}>
          <Input placeholder="610-460-047 30" />
        </Form.Item>
        <Form.Item name="contractNumber" label="Номер договора" style={{marginBottom: 6}} labelCol={{span: 10}}>
          <Input placeholder="2019-40Д" />
        </Form.Item>
        <Form.Item name="contractStatusId" label="Статус договора" style={{marginBottom: 6}} labelCol={{span: 10}}>
          <Select placeholder="Доставка документов" allowClear>
            {REGISTRY_CONTRACT_STATUS.map((contract) => (
              <Select.Option key={contract.value} value={contract.value}>
                {contract.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Row>
        <Col span={8}></Col>
        <Space align="baseline" size={'large'}>
          <Form.Item
            label="Искать активные"
            style={{alignItems: 'center'}}
            name="contractIsActive"
            valuePropName="checked">
            <Switch />
          </Form.Item>

          <Form.Item
            label="Контракт архивный"
            style={{alignItems: 'center'}}
            name="contractInArchive"
            valuePropName="checked">
            <Switch />
          </Form.Item>

          <Form.Item
            label="Учитывать удаленных слушателей"
            style={{alignItems: 'center'}}
            name="isDeleted"
            valuePropName="checked">
            <Switch />
          </Form.Item>
        </Space>
      </Row>
    </Row>
  );
};

export default ListenersFilters;
