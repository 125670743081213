import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Input, InputNumber, Switch} from 'antd';

import {LOCALE, removeEmptyFields} from '../../common';
import {AdminSelectors} from '../../store/AdminStore';
import {AdminAPI} from '../../store/AdminStore/methods';

import CustomForm from '../UX/CustomForm';
import StringDatePicker from '../UX/StringDatePicker';
import StringTimePicker from '../UX/StringTimePicker';

import UseMessage from '../hooks/useMessage';

const {Item} = Form;
const {SETTINGS} = LOCALE;

const SystemSettings = () => {
  const dispatch = useDispatch();
  const settings = useSelector(AdminSelectors.companySettings);
  const {showSuccess, showError} = UseMessage();

  useEffect(() => {
    dispatch(AdminAPI.companySettings.getAll());
  }, []);

  const handleUpdate = (values) => {
    const data = removeEmptyFields(values);
    dispatch(AdminAPI.companySettings.update(data))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  return (
    <CustomForm onOk={handleUpdate} initialValues={settings} layout={{labelCol: {span: 10}}}>
      <Item name="bonusesPercent" label={SETTINGS.BONUSES_PERCENT} rules={[{type: 'number', min: 0}]}>
        <InputNumber min={0} />
      </Item>
      <Item name="deadSoulCheck" label={SETTINGS.DEADSOULS_CHECK}>
        <StringDatePicker format={'DD.MM.YYYY HH:mm'} />
      </Item>
      <Item name="deadSoulMail" label={SETTINGS.DEADSOULS_MAIL}>
        <StringDatePicker format={'DD.MM.YYYY HH:mm'} />
      </Item>
      <Item name="bitrixSyncContacts" label={SETTINGS.BITRIX_SYNC_CONTACTS} valuePropName="checked">
        <Switch />
      </Item>
      <Item name="bitrixSyncCompanies" label={SETTINGS.BITRIX_SYNC_COMPANIES} valuePropName="checked">
        <Switch />
      </Item>
      <Item name="bitrixSyncLastTime" label={SETTINGS.BITRIX_SYNC_LASTDATE}>
        <StringDatePicker format={'DD.MM.YYYY HH:mm'} showTime={{format: 'HH:mm'}} />
      </Item>
      <Item name="mailSync" label={SETTINGS.MAIL_SYNC_DATE}>
        <StringDatePicker format={'DD.MM.YYYY HH:mm'} showTime={{format: 'HH:mm'}} />
      </Item>
      <Item name="startNewWorkDayTime" label={SETTINGS.NEW_WORKDAY_TIME} valuePropName="value">
        <StringTimePicker allowClear />
      </Item>
      <Item name="officeIP" label={SETTINGS.OFFICE_IP}>
        <Input type={'text'} />
      </Item>
      <Item name="emailToNotifyAdmins" label={SETTINGS.EMAIL_NOTIFICATION} rules={[{type: 'email'}]}>
        <Input type="email" />
      </Item>
      <Item name="roistat_enable" label={SETTINGS.ROISTAT} valuePropName="checked">
        <Switch />
      </Item>
      <Item name="skkHost" label={SETTINGS.SKKHOST}>
        <Input placeholder="http://skk.nord.su" />
      </Item>
      <Item name="skkPrivateKey" label={SETTINGS.SKKPRIVATEKEY}>
        <Input placeholder="gXqdkJ32DSQmK7tzn8jMjwqOg5rT34lDt51W1ijoB" />
      </Item>
      <Item name="kpComment" label={SETTINGS.KP_COMMENT}>
        <Input />
      </Item>
      <Item name="showMinTrudButtons" label={SETTINGS.MINTRUD} valuePropName="checked">
        <Switch />
      </Item>
    </CustomForm>
  );
};

export default SystemSettings;
