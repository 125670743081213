import {useState} from 'react';
import {Button, List, Popconfirm} from 'antd';

const DocumentButton = ({action, title, isSync = false, confirmText = null, disabled}) => {
  const [loading, setLoading] = useState(false);

  const onClickPromise = () => {
    setLoading(true);
    action().then(() => setLoading(false));
  };
  const onClickSync = () => {
    action();
  };

  return (
    <List.Item>
      {confirmText ? (
        <Popconfirm
          title={confirmText}
          onConfirm={isSync ? onClickSync : onClickPromise}
          disabled={disabled}
          overlayStyle={{width: '350px'}}
          okText="Да"
          cancelText="Нет">
          <Button loading={loading} disabled={disabled}>
            {title}
          </Button>
        </Popconfirm>
      ) : (
        <Button loading={loading} onClick={isSync ? onClickSync : onClickPromise} disabled={disabled}>
          {title}
        </Button>
      )}
    </List.Item>
  );
};

export default DocumentButton;
