import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {RegistySelectors} from '../../store/RegistryStore';
import {RegistyAPI} from '../../store/RegistryStore/methods';

import {LOCALE} from '../../common';
import DeliveryForm from '../../components/Delivery/DeliveryForm';
import TracksTable from '../../components/Delivery/TracksTable';
import PageLayout from '../../components/Layout/PageLayout';
import UseMessage from '../../components/hooks/useMessage';

const DeliveryInfo = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const deliveryInfo = useSelector(RegistySelectors.delivery);
  const loading = useSelector(RegistySelectors.loading);

  const {showError} = UseMessage();

  const fetchDelivery = useCallback(() => {
    dispatch(RegistyAPI.delivery.getById(id)).catch((e) => {
      showError('Ошибка получения информации');
      navigate(-1);
    });
  }, [dispatch, id]);

  useEffect(() => {
    fetchDelivery();
  }, [fetchDelivery]);

  return (
    <PageLayout pageTitle={LOCALE.PAGE_TITLES.DELIVERY_INFO}>
      <DeliveryForm data={{...deliveryInfo, id}} />
      <TracksTable
        fetchDelivery={fetchDelivery}
        loading={loading}
        data={deliveryInfo.trackNumbers}
        deliveryTypeId={deliveryInfo?.deliveryType?.id}
      />
    </PageLayout>
  );
};

export default DeliveryInfo;
