import {EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Space, Table} from 'antd';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {LOCALE} from '../../common';
import {ROUTES} from '../../common/routes';
import {AdminSelectors} from '../../store/AdminStore';
import {AdminAPI} from '../../store/AdminStore/methods';
import UseMessage from '../hooks/useMessage';
import ModalForm from '../UX/ModalForm';
import MemberItems from './MemberItems';

const {COMMON, SETTINGS} = LOCALE;

const CommissionMembers = () => {
  const commissionMembers = useSelector(AdminSelectors.commissionMembers);
  const {showError, showSuccess} = UseMessage();
  const loading = useSelector(AdminSelectors.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = () => dispatch(AdminAPI.commissionMembers.getAll());
  const redirectToEdit = (id) => navigate(`/commission-member/${id}`);

  const handleCreate = (values) => {
    dispatch(AdminAPI.commissionMembers.create(values))
      .then(() => {
        showSuccess();
        fetchMembers();
      })
      .catch((e) => showError(e));
  };

  const columns = [
    {
      dataIndex: 'name',
      title: COMMON.FULL_NAME,
      align: 'center',
    },
    {
      dataIndex: 'profession',
      title: COMMON.PROFESSION,
      align: 'center',
    },
    {
      dataIndex: 'id',
      title: (
        <ModalForm
          modalProps={{width: 600}}
          formItems={<MemberItems />}
          formProps={{colon: false}}
          onOk={handleCreate}
          title={SETTINGS.NEW_COMMISSION_MEMBER}>
          <Button type="primary" icon={<PlusOutlined />} />
        </ModalForm>
      ),
      align: 'center',
      render: (id) => {
        return (
          <Space>
            <Button onClick={() => redirectToEdit(id)} icon={<EditOutlined />} />
          </Space>
        );
      },
    },
  ];

  return <Table pagination={false} columns={columns} dataSource={commissionMembers} loading={loading} rowKey="id" />;
};

export default CommissionMembers;
