import React from 'react';
import {ADMIN_RIGHTS, LOCALE} from '../../common';

import OperatorsTable from '../../components/Operators/OperatorsTable';
import {Typography} from 'antd';
import Analytics from './Analytics';
import {useSelector} from 'react-redux';
import {AdminSelectors} from '../../store/AdminStore';

const {PAGE_TITLES} = LOCALE;

const Operators = () => {
  const adminInfo = useSelector(AdminSelectors.adminInfo);

  const haveOperatorsAccess = adminInfo.adminRights.includes(ADMIN_RIGHTS.operatorManagement.id);
  const haveOperatorsAnalyticsAccess = adminInfo.adminRights.includes(ADMIN_RIGHTS.operatorWorkingAnalytics.id);

  return (
    <>
      {haveOperatorsAccess && (
        <>
          <Typography.Title level={2}>{PAGE_TITLES.OPERATORS}</Typography.Title>
          <OperatorsTable />
        </>
      )}
      {haveOperatorsAnalyticsAccess && (
        <>
          <Typography.Title level={3}>{PAGE_TITLES.OPERATORS_ANALYTICS}</Typography.Title>
          <Analytics />
        </>
      )}
    </>
  );
};

export default Operators;
