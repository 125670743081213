import {Form, Input, Typography} from 'antd';

const CardItem = ({name, label, placeholder, value, disabled, Component = Input, componentProps}) => {
  return disabled ? (
    <Form.Item style={{marginBottom: 0}} label={label}>
      <Typography>{value}</Typography>
    </Form.Item>
  ) : (
    <Form.Item style={{marginBottom: 5}} name={name} label={label}>
      <Component placeholder={placeholder} {...componentProps} />
    </Form.Item>
  );
};

export default CardItem;
