import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import UseMessage from '../../components/hooks/useMessage';
import PageLayout from '../../components/Layout/PageLayout';
import OtherFormItems from '../../components/LearningMaterials/OtherFormItems';
import CustomForm from '../../components/UX/CustomForm';
import {EduActions, EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';
import EdumaterialsPhaseLink from '../../components/LearningMaterials/EdumaterialsPhaseLink';

const layout = {
  labelCol: {
    sm: {span: 6},
    xl: {
      span: 4,
    },
  },
  wrapperCol: {
    xl: {
      span: 8,
    },
  },
};

const Other = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const other = useSelector(EduSelectors.selectedMaterial);
  const {showSuccess, showError} = UseMessage();
  const loading = useSelector(EduSelectors.loading);
  const navigate = useNavigate();

  const fetchOther = () => {
    dispatch(EduMaterialsAPI.other.getById(id)).catch(() => {
      navigate('*');
    });
  };

  const goBack = () => navigate(-1);

  const handleLinkPhase = (values) => {
    dispatch(EduMaterialsAPI.linkLearningMaterialToPhase({id, data: values}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const updateOther = (data) => {
    dispatch(EduMaterialsAPI.other.update({id, data}))
      .then(() => {
        showSuccess();
        goBack();
      })
      .catch((e) => showError(e));
  };

  useEffect(() => {
    fetchOther();
    return () => {
      dispatch(EduActions.clearSelectedMaterial());
    };
  }, []);

  return (
    <PageLayout>
      <CustomForm onOk={updateOther} onCancel={goBack} loading={loading} initialValues={other} layout={layout}>
        <OtherFormItems />
      </CustomForm>
      <EdumaterialsPhaseLink id={id} refetch={fetchOther} />
    </PageLayout>
  );
};
export default Other;
