import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import App from './App';
import {store} from './store';
import {BrowserRouter} from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import ru_RU from 'antd/es/locale/ru_RU';
import {ConfigProvider} from 'antd';
import ThemeProvider from './components/Layout/ThemeProvider';

import './global.css';

dayjs.locale('ru');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ConfigProvider locale={ru_RU}>
    <Provider store={store}>
      <ThemeProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </ConfigProvider>
);
