import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {LOCALE} from '../../common';
import {ROUTES} from '../../common/routes';
import UseMessage from '../../components/hooks/useMessage';
import PageLayout from '../../components/Layout/PageLayout';
import ProgramForm from '../../components/Programs/ProgramForm';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';

const {PAGE_TITLES} = LOCALE;

const CreateProgram = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {showError, showSuccess} = UseMessage();

  const createProgram = (values) => {
    dispatch(EduMaterialsAPI.programs.create(values))
      .then((response) => {
        showSuccess();
        window.scrollTo(0, 0);
        navigate(`/programs/${response.payload.id}`);
      })
      .catch((e) => showError(e));
  };

  return (
    <PageLayout pageTitle={PAGE_TITLES.NEW_PROGRAM}>
      <ProgramForm onOk={createProgram} />
    </PageLayout>
  );
};

export default CreateProgram;
