import {Form, Input, InputNumber, Select, Typography, DatePicker} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import {LOCALE, PROMOCODE_TYPES} from '../../common';
import StringDatePicker from '../UX/StringDatePicker';

const {Item} = Form;
const {PROMOCODES, COMMON, DATES} = LOCALE;
const PromocodeForm = ({form, redact}) => {
  Form.useWatch('promocodeTypeId', form);

  const selectedType = form.getFieldValue('promocodeTypeId');
  const startDate = form.getFieldValue('startDate');

  return (
    <>
      <Item label={PROMOCODES.CODE} name="code" rules={[{required: true}]}>
        <Input placeholder={PROMOCODES.CODE} autoFocus />
      </Item>
      <Item label={PROMOCODES.PROMOCODE_TYPE} name="promocodeTypeId" rules={[{required: true}]}>
        <Select disabled={redact}>
          {PROMOCODE_TYPES.map((type) => (
            <Select.Option key={type.value} value={type.value}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Item>
      {selectedType !== '76d3fb30-4fc2-4c28-b984-828b77870732' && (
        <Item label={PROMOCODES.DISCOUNT_SIZE} name="discount" rules={[{required: true}]}>
          <InputNumber placeholder={COMMON.PRICE} />
        </Item>
      )}
      <Item label={COMMON.NOTE} name="note">
        <TextArea style={{resize: 'none', height: '150px'}} />
      </Item>
      <Item label={DATES.START_ACTION} name="startDate" rules={[{required: true}]}>
        <StringDatePicker format={'DD.MM.YYYY'} />
      </Item>
      <Item label={DATES.END_ACTION} name="endDate">
        <StringDatePicker format={'DD.MM.YYYY'} disabledDate={(d) => !d || d.isBefore(startDate)} />
      </Item>
      {!redact && selectedType === '76d3fb30-4fc2-4c28-b984-828b77870732' && (
        <Typography.Text>{PROMOCODES.AVAILABILITY_OF_PRICES}</Typography.Text>
      )}
    </>
  );
};

export default PromocodeForm;
