import {Button, Form, Space} from 'antd';

const FISForm = ({saveReport, name, label, children}) => {
  return (
    <Form onFinish={saveReport}>
      <Space>
        <Form.Item name={name} label={label}>
          {children}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Скачать
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};

export default FISForm;
