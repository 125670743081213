const COLORS = [
  {label: 'Neon Carrot', value: '#FF983B'},
  {label: 'Medium Carmine', value: '#AA3A39'},
  {label: 'Sunset Orange', value: '#FE4E4E'},
  {label: 'Brilliant Rose', value: '#EE5397'},
  {label: 'Affair', value: '#634490'},
  {label: 'Azure', value: '#3466A5'},
  {label: `Robin's Egg Blue`, value: '#00C4D4'},
  {label: `Blue Dianne`, value: '#26465F'},
  {label: `Bermuda Gray`, value: '#7885A5'},
  {label: `Spindle`, value: '#B3C2E8'},
];

export default COLORS;
