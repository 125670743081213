import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {AutoComplete, Button, Checkbox, Col, Form, Input, Modal, Radio, Row, Select, Space, Typography} from 'antd';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import {RegistySelectors} from '../../store/RegistryStore';
import UseMessage from '../hooks/useMessage';
import ModalForm from '../UX/ModalForm';
import PopConfirmButton from '../UX/PopConfirmButton';
import CustomAutoComplete from '../UX/AutoComplete';
import CustomerItem from '../Customers/CustomerItem';
import Column from 'antd/lib/table/Column';

const addressOptionsLabel = (options = []) => {
  return options.map((option) => {
    return {label: option.unrestricted_value, value: option.unrestricted_value};
  });
};

const ContractRelation = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {id: relationId, contracts} = useSelector(RegistySelectors.relatedContracts);
  const relatedContractsFull = useSelector(RegistySelectors.relatedContractsFull);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState(null);

  const [form] = Form.useForm();
  const relationContractId = Form.useWatch('relationContractId', form);

  const [customValue, setCustomValue] = useState('');
  const [customRecipient, setCustomRecipient] = useState('');

  const {showError, showSuccess} = UseMessage();

  useEffect(() => {
    dispatch(RegistyAPI.contractRelations.checkRelations(id));
    dispatch(RegistyAPI.contractRelations.availableRelations(id));
  }, []);

  const addContractToRelation = ({relationContractId}) => {
    let data = {
      deliveryAddress: selectedDeliveryAddress,
      recipient: selectedRecipient,
    };
    if (selectedDeliveryAddress === 'newAddress') {
      data = {
        deliveryAddress: customValue,
        recipient: customRecipient,
      };
    }
    dispatch(RegistyAPI.contractRelations.addContractToRelation({id, relationContractId, ...data}))
      .then(() => {
        dispatch(RegistyAPI.contractRelations.checkRelations(id));
        showSuccess('Договор успешно привязан');
      })
      .catch((err) => showError('Вначале отвяжите договор от текущей связки'));
  };
  const deleteContractFromRelation = () => {
    dispatch(RegistyAPI.contractRelations.deleteContractFromRelation(id))
      .then(() => {
        showSuccess('Договор из связки успешно удален');
        dispatch(RegistyAPI.contractRelations.checkRelations(id));
      })
      .catch((err) => showError('Связка не найдена'));
  };

  const deleteRelation = () => {
    dispatch(RegistyAPI.contractRelations.deleteRelation(id))
      .then(() => {
        showSuccess('Связка успешно удалена');
        dispatch(RegistyAPI.contractRelations.checkRelations(id));
      })
      .catch((err) => showError('Связка не найдена'));
  };

  const options = relatedContractsFull.map((contract) => ({
    value: contract.relationContractId,
    label: contract.number,
  }));

  const submitButtonDisabled = () => {
    if (!relationContractId || !selectedDeliveryAddress) return true;
    else if (selectedDeliveryAddress === 'newAddress' && !customValue) return true;
    else return false;
  };

  return (
    <Space size="middle" align="center" wrap>
      {relationId ? (
        <>
          <Space>
            {contracts?.map((contract, index) => {
              return (
                <Link
                  reloadDocument
                  key={contract.id}
                  to={`/registry/students/${contract.id}`}
                  style={{
                    backgroundColor: contract?.color,
                    borderRadius: 4,
                    padding: 4,
                    textDecoration: contract?.isPayedOnline === true ? 'underline' : 'unset',
                  }}>
                  {contract.number} {index < contracts.length - 1 ? ', ' : null}
                </Link>
              );
            })}
          </Space>
          <PopConfirmButton
            type="primary"
            danger
            title="Удалить?"
            onClick={deleteContractFromRelation}
            button=" Удалить из связки"
          />
          <PopConfirmButton danger title="Удалить?" onClick={deleteRelation} button="Удалить целиком" />
        </>
      ) : (
        <Space>
          <Typography>Связанные договора отсутствуют</Typography>
          <ModalForm
            formInstance={form}
            formItems={
              <Space direction="vertical" style={{width: '100%'}}>
                <Form.Item name="relationContractId" style={{width: '100%'}}>
                  <Select options={options} placeholder="2023-00Д" allowClear />
                </Form.Item>
                {relationContractId && (
                  <Space direction="vertical" style={{width: '100%'}}>
                    <Typography.Title level={5}>Выберите адрес доставки для договоров</Typography.Title>
                    <Typography.Paragraph>Адрес будет присвоен всем договорам в связке</Typography.Paragraph>
                    {relatedContractsFull
                      ?.find((relation) => relation.relationContractId === relationContractId)
                      ?.contracts?.map((contract) => (
                        <Space direction="vertical">
                          <Space>
                            <Checkbox
                              id={contract.number}
                              value={contract.deliveryAddress}
                              checked={contract.deliveryAddress && selectedDeliveryAddress === contract.deliveryAddress}
                              onClick={() => {
                                setSelectedDeliveryAddress(contract.deliveryAddress);
                                setSelectedRecipient(contract.recipient ?? '');
                              }}
                              disabled={!contract.deliveryAddress}
                            />
                            <label htmlFor={contract.number} style={{cursor: 'pointer'}}>
                              <Typography style={{fontWeight: 'bold'}}>{contract.number}</Typography>
                            </label>
                          </Space>
                          <Space style={{paddingLeft: 25}} direction="vertical">
                            <Typography>Адрес: {contract?.deliveryAddress || 'отсутствует'}</Typography>
                            <Typography>Получатель: {contract?.recipient || 'отсутствует'}</Typography>
                          </Space>
                        </Space>
                      ))}
                    <Row style={{width: '100%', flex: 1}} gutter={10} align="middle">
                      <Col>
                        <Checkbox
                          id="newAddress"
                          value={'newAddress'}
                          checked={selectedDeliveryAddress === 'newAddress'}
                          onClick={() => setSelectedDeliveryAddress('newAddress')}
                        />
                      </Col>
                      <Col flex={1}>
                        <label htmlFor="newAddress" style={{cursor: 'pointer'}}>
                          <Typography style={{fontWeight: 'bold'}}>Указать вручную</Typography>
                        </label>
                      </Col>
                    </Row>
                    <Row style={{width: '100%', flex: 1}} gutter={10} align="middle">
                      <Col span={6}>
                        <Typography>Адрес: </Typography>
                      </Col>
                      <Col flex={1}>
                        <CustomAutoComplete
                          type="address"
                          optionsMap={addressOptionsLabel}
                          style={{width: '100%'}}
                          onSelectOption={(value) => setCustomValue(value?.value ?? '')}
                          onChange={(value) => setCustomValue(value)}
                          disabled={selectedDeliveryAddress !== 'newAddress'}
                        />
                      </Col>
                    </Row>
                    <Row style={{width: '100%', flex: 1}} gutter={10} align="middle">
                      <Col span={6}>
                        <Typography>Получатель: </Typography>
                      </Col>
                      <Col flex={1}>
                        <Input
                          style={{width: '100%', flex: 1, flexShrink: 0}}
                          value={customRecipient}
                          onChange={(e) => setCustomRecipient(e.target.value)}
                          disabled={selectedDeliveryAddress !== 'newAddress'}
                        />
                      </Col>
                    </Row>
                  </Space>
                )}
              </Space>
            }
            modalProps={{
              okButtonProps: {
                disabled: submitButtonDisabled(),
              },
            }}
            onOk={addContractToRelation}
            title="Доступные договора для связки">
            <Button type="primary">Добавить договор в связку</Button>
          </ModalForm>
        </Space>
      )}
    </Space>
  );
};
export default ContractRelation;
