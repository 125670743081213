import { Button, Upload } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { RegistyAPI } from "../../store/RegistryStore/methods";
import UseMessage from "../hooks/useMessage";

const UploadTracks = () => {
	const dispatch = useDispatch();
	const { showSuccess, showError } = UseMessage();
	const uploadTrackNumbers = (file) => {
		const formData = new FormData();
		formData.append("trackNumbers", file);

		dispatch(RegistyAPI.trackNumbers.upload(formData))
			.then(() => {
				showSuccess();
			})
			.catch((e) => showError(e));
	};

	return (
		<Upload
			accept="text/csv"
			showUploadList={false}
			beforeUpload={(file) => {
				
				uploadTrackNumbers(file);
				return false;
			}}
		>
			<Button>Загрузить трек номера</Button>
		</Upload>
	);
};

export default UploadTracks;
