import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Button, Image, List, Popconfirm, Space, Typography} from 'antd';
import React from 'react';
import {DeleteOutlined, MenuOutlined} from '@ant-design/icons';
import {LOCALE} from '../../common';

const {COMMON, MESSAGES} = LOCALE;

const SortableItem = ({id, sort, link, deleteSlide}) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id: id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <List.Item style={style}>
      <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
        <Button icon={<MenuOutlined />} type="text" {...attributes} {...listeners} ref={setNodeRef} />
        <Typography.Text>{sort}</Typography.Text>
        <Image width={360} src={link}>
          {COMMON.LINK}
        </Image>
        <Popconfirm title={MESSAGES.CONFIRM} onConfirm={deleteSlide}>
          <Button size="large" icon={<DeleteOutlined />} danger />
        </Popconfirm>
      </div>
    </List.Item>
  );
};

export default SortableItem;
