import {createAsyncThunk} from '@reduxjs/toolkit';
import {http} from '../../common';

const path = (str) => `admin/${str}`;
const managementPath = (str) => `admin/registry/management/${str}`;

export const RegistyAPI = {
  registry: {
    postReport: createAsyncThunk('registry/postReport', async (data) => {
      return (await http.put(path(`registry/postReport`), data)).data;
    }),
    postData: createAsyncThunk('registry/postData', async ({id, data}) => {
      return (await http.patch(path(`registry/postData/` + id), data)).data;
    }),
    getAll: createAsyncThunk('registry/getAll', async (data) => {
      data.status = JSON.stringify(data.status);
      return (await http.put(path(`registry`), data)).data;
    }),
    getCountContractByFilter: createAsyncThunk('registry/getCount', async (filter) => {
      return (await http.post(path(`registry/filter-count`), {filter: filter})).data;
    }),
    create: createAsyncThunk('registry/create', async (data) => {
      return (await http.post(path(`registry`), data)).data;
    }),
    getById: createAsyncThunk('registry/getById', async (data) => {
      return (await http.get(path(`registry/${data.id}`))).data;
    }),
    update: createAsyncThunk('registry/update', async (data) => {
      return (await http.put(path(`registry/${data.id}`), data)).data;
    }),
    delete: createAsyncThunk('registry/delete', async (id) => {
      return (await http.delete(path(`registry/${id}`))).data;
    }),
    inArchive: createAsyncThunk('registry/inArchive', async ({id, data}) => {
      return (await http.put(path(`registry/archive/${id}`), data)).data;
    }),
    availabilityOfDocuments: createAsyncThunk('registry/availabilityOfDocuments', async ({id, data}) => {
      return (await http.put(path(`registry/availabilityOfDocuments/${id}`), data)).data;
    }),
  },

  source: {
    getAll: createAsyncThunk('source/getAll', async (data) => {
      return (await http.get(path(`registry/source`), data)).data;
    }),
    update: createAsyncThunk('source/update', async (data = {id: '', sourceTypeId: ''}) => {
      return (await http.put(path(`registry/source/${data.id}`), data)).data;
    }),
  },

  print: createAsyncThunk('registry/print', async ({id, data}) => {
    return (await http.put(path(`registry/print/${id}`), data)).data;
  }),

  registryMintrud: createAsyncThunk('registryMintrud', async ({id, data}) => {
    return (await http.put(`admin/registry/mintrudUpdate/${id}`, data)).data;
  }),

  folder: createAsyncThunk('registry/folder', async (id) => {
    return (await http.get(path(`folder/${id}`))).data;
  }),

  documentsZip: createAsyncThunk('registry/documentsZip', async (id) => {
    return (await http.get(path(`zipDocuments/${id}`))).data;
  }),

  operator: {
    set: createAsyncThunk('operator/set', async (data) => {
      return (await http.put(`admin/registry/operator/${data.id}`, data)).data;
    }),
    uploadHandMade: createAsyncThunk('operator/uploadChangedDocument', async (data) => {
      return (await http.post(`admin/registry/management/handmade-documents`, data)).data;
    }),
    getAllChangedDocuments: createAsyncThunk('operator/getAllChangedDocuments', async (contract) => {
      return (await http.get(`admin/registry/management/handmade-documents/all/${contract}`)).data;
    }),
    updateHandMade: createAsyncThunk('operator/updateChangedDocument', async ({id, data}) => {
      return (await http.post(`admin/registry/management/handmade-documents/${id}`, data)).data;
    }),
    getChangedDocument: createAsyncThunk('operator/getChangedDocument', async (id) => {
      return await http.get(`admin/registry/management/handmade-documents/${id}`);
    }),
    deleteChangedDocument: createAsyncThunk('operator/deleteChangedDocument', async (id) => {
      return await http.delete(`admin/registry/management/handmade-documents/${id}`);
    }),
  },

  score: {
    getByContractId: createAsyncThunk('score/getAll', async (data) => {
      return (await http.get(path(`registry/score/${data.id}`), data)).data;
    }),
    update: createAsyncThunk('score/update', async (data = {id: ''}) => {
      return (await http.put(path(`registry/score/${data.id}`), data)).data;
    }),
  },

  note: {
    update: createAsyncThunk('note/update', async (data = {id: '', note: ''}) => {
      return (await http.put(path(`registry/note/${data.id}`), data.note)).data;
    }),
  },

  delivery: {
    getById: createAsyncThunk('delivery/getById', async (id) => {
      return (await http.get(path(`registry/delivery/${id}`))).data;
    }),
    update: createAsyncThunk('delivery/update', async ({data, id}) => {
      return (await http.put(`admin/registry/delivery/${id}`, data)).data;
    }),
  },

  trackNumbers: {
    upload: createAsyncThunk('trackNumbers/upload', async (data) => {
      return (await http.post(path(`registry/trackNumbers/`), data)).data;
    }),
    create: createAsyncThunk('trackNumbers/create', async ({id, data}) => {
      return (await http.post(path(`registry/delivery/trackNumber/${id}`), data)).data;
    }),
    delete: createAsyncThunk('trackNumbers/delete', async ({id}) => {
      return (await http.delete(path(`registry/delivery/trackNumber/${id}`))).data;
    }),
    sendToEmail: createAsyncThunk('trackNumbers/sendToEmail', async (id) => {
      return (await http.get(path(`registry/delivery/sendTrackNumber/${id}`))).data;
    }),
    edit: createAsyncThunk('trackNumbers/edit', async ({id, data}) => {
      return (await http.put(path(`registry/delivery/trackNumber/${id}`), data)).data;
    }),
    sendPostData: createAsyncThunk('postData', async ({id, data}) => {
      return await http.patch(path(`registry/postData/${id}`), data);
    }),
  },

  documents: {
    getAvailable: createAsyncThunk('functions/getAvailable', async (id) => {
      return (await http.get(managementPath(`functions/` + id))).data;
    }),
    getAll: createAsyncThunk('documents/getAll', async (id) => {
      return (await http.get(managementPath(`invoice/` + id))).data;
    }),
    sendToAnorherLK: createAsyncThunk('documents/send-to-lk', async ({id, data}) => {
      return (await http.put(managementPath(`transferInOtherLK/` + id), data)).data;
    }),
    sendPrimaryDocs: createAsyncThunk('documents/send-primary', async (id) => {
      return (await http.get(managementPath(`invoice/primary/send/` + id))).data;
    }),
    removeSecondaryDocs: createAsyncThunk('documents/remove-secondary', async (id) => {
      return (await http.get(managementPath(`invoice/secondary/remove/` + id))).data;
    }),
    sendSecondaryDocs: createAsyncThunk('documents/send-secondary', async (id) => {
      return (await http.get(managementPath(`invoice/secondary/send/` + id))).data;
    }),
    calcPrimaryDocs: createAsyncThunk('documents/calc-primary', async (id) => {
      return (await http.get(managementPath(`invoice/primary/calc/` + id))).data;
    }),
    calcSecondaryDocs: createAsyncThunk('documents/calc-secondary', async (id) => {
      return (await http.get(`admin/registry/management/invoice/secondary/calc/${id}`)).data;
    }),
    transferInCurrentDate: createAsyncThunk('documents/transferInCurrentDate', async (id) => {
      return (await http.get(`admin/registry/management/transferInCurrentDate/${id}`)).data;
    }),
    infoBonuses: createAsyncThunk('documents/infoBonuses', async (id) => {
      return (await http.get(`admin/registry/management/infoBonuses/${id}`)).data;
    }),
    openDocumentWithoutSign: createAsyncThunk('documents/open', async ({id, documentTypeId, outType}) => {
      return (await http.get(`admin/invoice/${id}/${documentTypeId}/${outType}?nosign`, {responseType: 'blob'})).data;
    }),
  },

  listeners: {
    contractInfo: createAsyncThunk('contract/info', async (id) => {
      return (await http.get(`admin/registry/management/invoice/${id}`)).data;
    }),
    getAll: createAsyncThunk('listeners/getAll', async (id) => {
      return (await http.get(managementPath(`students/` + id))).data;
    }),
    getById: createAsyncThunk('listeners/getById', async (id) => {
      return (await http.get(`admin/students/${id}/get-basic-info`)).data;
    }),
    create: createAsyncThunk('listeners/create', async ({id, data}) => {
      return (await http.post(managementPath(`students/` + id), data)).data;
    }),
    delete: createAsyncThunk('listeners/delete', async (id) => {
      return (await http.delete(managementPath(`students/` + id))).data;
    }),
    update: createAsyncThunk('listeners/update', async ({id, data}) => {
      return (await http.put(managementPath(`students/update/` + id), data)).data;
    }),
    getProgramsByType: createAsyncThunk('listener-programs/getByType', async (id) => {
      return (await http.get(path(`registry/programs/` + id))).data;
    }),
    getPrograms: createAsyncThunk('listener-programs/getAll', async () => {
      return (await http.put(`admin/edu-program`)).data;
    }),
    updateProgram: createAsyncThunk('listener-programs/update', async ({id, data}) => {
      return (await http.put(managementPath(`students/studentTrainings/update/` + id), data)).data;
    }),
    deleteProgram: createAsyncThunk('listener-programs/delete', async (id) => {
      return (await http.delete(managementPath(`students/training/` + id))).data;
    }),
    addProgram: createAsyncThunk('listener-programs/add', async ({studentId, trainingProgramId}) => {
      return (await http.post(managementPath(`students/${studentId}/studentTrainingsAdd`), trainingProgramId)).data;
    }),
    resetAttemptCount: createAsyncThunk('listener-programs/reset', async ({studentTrainingId}) => {
      return await http.get(`admin/registry/management/students/resetAttemptCounter/${studentTrainingId}`).data;
    }),
    testResults: createAsyncThunk('student/test-result', async ({studentTrainingId}) => {
      return (
        await http.get(`admin/registry/management/testResults/${studentTrainingId}`, {
          responseType: 'blob',
        })
      ).data;
    }),
    fullInformation: createAsyncThunk('listener/full-info', async (id) => {
      return (await http.get(`admin/registry/contracts/${id}/contractInfo`)).data;
    }),
    sendEmailAndPasswords: createAsyncThunk('listener/sendEmailAndPasswords', async (id) => {
      return (await http.get(`admin/registry/management/emailAccounts/${id}`)).data;
    }),
  },

  testingResults: {
    getById: createAsyncThunk('listener-programs/getByType', async (id) => {
      return await http.get(managementPath(`testResults/` + id), {
        responseType: 'blob',
      });
    }),
  },

  contractRelations: {
    checkRelations: createAsyncThunk(
      'getContractRelations',
      async (id) => await http.get(`admin/contractRelations/check/${id}`)
    ),
    availableRelations: createAsyncThunk(
      'availableRelations',
      async (id) => await http.get(`admin/contractRelations/checkFull/${id}`)
    ),
    deleteContractFromRelation: createAsyncThunk(
      'deleteContractFromRelation',
      async (id) => await http.delete(`admin/contractRelations/${id}`)
    ),
    addContractToRelation: createAsyncThunk(
      'addContractToRelation',
      async ({id, relationContractId, ...data}) =>
        await http.patch(`admin/contractRelations/${id}`, {relationContractId, ...data})
    ),
    deleteRelation: createAsyncThunk(
      'deleteRelation',
      async (id) => await http.delete(`admin/contractRelations/full/${id}`)
    ),
  },

  conclusion: {
    get: createAsyncThunk('conclusionCertificate', async () => await http.get('admin/conclusion')),
  },

  color: {
    get: createAsyncThunk('getContractColor', async (id) => await http.get(`admin/registry/get-color/${id}`)),
  },

  promocode: {
    getAll: createAsyncThunk('getAllPromoCodes', async (data) => (await http.post('admin/promocodes/list', data)).data),
    apply: createAsyncThunk('applyPromoCode', async (data) => await http.post('admin/promocodes/applyPromocode', data)),
  },

  bonuses: {
    cancelWriteOff: createAsyncThunk(
      'cancelWriteOff',
      async (contractId) =>
        await http.post('admin/contracts/changeContractBonuses', {contractId: contractId, bonusCount: 0})
    ),
    changeWriteoffBonuses: createAsyncThunk(
      'changeWriteoffBonuses',
      async (data, {rejectWithValue, fulfillWithValue}) => {
        try {
          const res = await http.post('admin/contracts/changeContractBonuses', data);
          return fulfillWithValue(res.data);
        } catch (error) {
          throw new Error(error?.response?.data?.userMessage);
        }
      }
    ),
  },
};
//  async (data) => await http.post('admin/contracts/changeContractBonuses', data)
