import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {LOCALE, paramsToObject, removeEmptyFields} from '../../common';
import PageLayout from '../../components/Layout/PageLayout';
import QuestionsTable from '../../components/Questions/QuestionsTable';
import FilterPanel from '../../components/FilterPanel';
import QuestionsFilter from '../../components/Questions/QuestionsFilter';

import {QuestionsAPI} from '../../store/QuestionsStore/methods';
import UseMessage from '../../components/hooks/useMessage';
import {AdminAPI} from '../../store/AdminStore/methods';
import {questionsFilters} from '../../components/filters';

const {PAGE_TITLES} = LOCALE;

const resetFilters = {
  trainingProgramTypeId: null,
  trainingProgramId: null,
  phaseNumber: null,
  text: null,
  withDeleted: false,
  limit: 10,
  offset: 0,
  page: 1,
};

const Questions = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const {showSuccess, showError} = UseMessage();

  const savedFilters = localStorage.getItem('questionsFilters');
  const initialFilters = {...resetFilters, ...JSON.parse(savedFilters), ...paramsToObject(searchParams)};
  const filteredData = removeEmptyFields(initialFilters);

  useEffect(() => {
    dispatch(QuestionsAPI.filter(filteredData))
      .then((res) => {
        const data = {idArray: res.payload.data.map((question) => question.id)};
        dispatch(AdminAPI.systemContentControl.checkActualityList(data));
      })
      .catch((e) => {
        showError(e.message);
      });
  }, [searchParams]);

  return (
    <PageLayout pageTitle={PAGE_TITLES.QUESTIONS}>
      <FilterPanel
        initialFilters={initialFilters}
        resetFilters={resetFilters}
        formLayout={{labelCol: {span: 8}}}
        filterLabels={questionsFilters}
        name="questionsFilters">
        <QuestionsFilter />
      </FilterPanel>
      <QuestionsTable />
    </PageLayout>
  );
};

export default Questions;
