import { Select } from "antd";
import dayjs from "dayjs";
import React from "react";
import { LOCALE } from "../../common";

const { DATES, PLACEHOLDERS } = LOCALE;
const PeriodSelect = ({ periods = [], value, onChange, ...props }) => {

	const options = periods.map((period, index) => {
		const dateFrom = dayjs(period.dateFrom).format("DD MMMM YYYY");
		const dateTo =
			index === periods.length -1
				? DATES.TODAY
				: dayjs(periods[index + 1].dateFrom).format("DD MMMM YYYY");
		return {
			id: period.id,
			name: `${DATES.FROM} ${dateFrom} ${DATES.TO} ${dateTo}`,
			value: period.id,
		};
	});
	return (
		<Select
			placeholder={!options.length && PLACEHOLDERS.NO_PERIODS}
			disabled={!options.length}
			value={value}
			onChange={onChange}
			{...props}
		>
			{options.map((option) => (
				<Select.Option key={option.id} value={option.id}>
					{option.name}
				</Select.Option>
			))}
		</Select>
	);
};

export default PeriodSelect;
