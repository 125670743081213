import {Form} from 'antd';
import StringDatePicker from '../UX/StringDatePicker';

const RatingForm = () => {
  return (
    <>
      <Form.Item
        name="start"
        rules={[{required: true, message: 'Пожалуйста укажите дату начала периода'}]}
        label="Дата начала периода">
        <StringDatePicker format="DD.MM.YYYY" style={{width: '100%'}} />
      </Form.Item>
      <Form.Item
        name="end"
        rules={[{required: true, message: 'Пожалуйста укажите дату окончания периода'}]}
        label="Дата окончания периода">
        <StringDatePicker format="DD.MM.YYYY" style={{width: '100%'}} />
      </Form.Item>
    </>
  );
};
export default RatingForm;
