import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import PageLayout from '../../components/Layout/PageLayout';
import UserCard from '../../components/Users/UserCard';
import UseMessage from '../../components/hooks/useMessage';

import {LOCALE} from '../../common';
import {AdminSelectors} from '../../store/AdminStore';
import {AdminAPI} from '../../store/AdminStore/methods';
import {Typography} from 'antd';
import Notes from '../../components/Notes';
import notableTypes from '../../common/notableTypes';

const {PAGE_TITLES} = LOCALE;

const User = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userInfo = useSelector(AdminSelectors.userInfo);
  const loading = useSelector(AdminSelectors.loading);

  const {showError} = UseMessage();

  useEffect(() => {
    dispatch(AdminAPI.users.getById({id, ext: true})).catch((e) => {
      navigate(-1);
      showError(e);
    });
  }, [id]);

  const PageTitle = () => {
    if (!userInfo) return <>{PAGE_TITLES.USER_INFO}...</>;
    return (
      <>
        {PAGE_TITLES.USER_INFO} <span style={{textDecoration: 'underline'}}>{userInfo.shortName}</span>
      </>
    );
  };

  return (
    <PageLayout pageTitle={<PageTitle />}>
      <UserCard data={userInfo} loading={loading} id={id} />
      <Typography.Title level={3} style={{marginTop: '16px'}}>
        Замечания
      </Typography.Title>
      <Notes notableTypeId={notableTypes.user} maxHeight={400} />
    </PageLayout>
  );
};

export default User;
