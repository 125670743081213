import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Form, Input, Popconfirm, Select, Space, Table} from 'antd';
import dayjs from 'dayjs';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LOCALE} from '../../common';
import {AdminSelectors} from '../../store/AdminStore';
import {AdminAPI} from '../../store/AdminStore/methods';
import {EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';
import UseMessage from '../hooks/useMessage';
import ModalForm from '../UX/ModalForm';
import StringDatePicker from '../UX/StringDatePicker';

const {SETTINGS, EDUCATION, MESSAGES} = LOCALE;

const OrdersTable = () => {
  const orders = useSelector(AdminSelectors.orders);
  const programTypes = useSelector(EduSelectors.programTypes);
  const loading = useSelector(AdminSelectors.loading);
  const dispatch = useDispatch();
  const {showError, showSuccess} = UseMessage();

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = () => {
    dispatch(AdminAPI.orders.getAll());
    dispatch(EduMaterialsAPI.programTypes.getAll());
  };

  const handleDelete = (id) => {
    dispatch(AdminAPI.orders.delete(id))
      .then(() => {
        showSuccess();
        fetchOrders();
      })
      .catch((e) => showError(e));
  };

  const handleCreate = (values) => {
    dispatch(AdminAPI.orders.create(values))
      .then(() => {
        showSuccess();
        fetchOrders();
      })
      .catch((e) => showError(e));
  };

  const handleUpdate = (id, values) => {
    dispatch(AdminAPI.orders.update({id, data: values}))
      .then(() => {
        showSuccess();
        fetchOrders();
      })
      .catch((e) => showError(e));
  };

  const OrderItems = () => {
    return (
      <>
        <Form.Item name={'number'} rules={[{required: true}]} label={SETTINGS.ORDER_NUMBER}>
          <Input />
        </Form.Item>
        <Form.Item name={'fromDate'} rules={[{required: true}]} label={SETTINGS.ORDER_DATE}>
          <StringDatePicker />
        </Form.Item>
        <Form.Item name={'trainingProgramTypeId'} rules={[{required: true}]} label={EDUCATION.PROGRAM_TYPE}>
          <Select>
            {programTypes.map((type) => (
              <Select.Option value={type.id}>{type.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </>
    );
  };

  const columns = [
    {
      title: SETTINGS.ORDER_NUMBER,
      dataIndex: 'number',
      align: 'center',
    },
    {
      title: SETTINGS.ORDER_DATE,
      dataIndex: 'fromDate',
      align: 'center',
      render: (date) => dayjs(date).format('DD.MM.YYYY'),
    },
    {
      title: EDUCATION.PROGRAM_TYPE,
      dataIndex: 'trainingProgramTypeId',
      align: 'center',
      render: (id) => programTypes.find((type) => type.id === id)?.name,
    },
    {
      title: (
        <ModalForm title={SETTINGS.NEW_ORDER} onOk={handleCreate} formItems={<OrderItems />}>
          <Button type="primary" icon={<PlusOutlined />} />
        </ModalForm>
      ),
      dataIndex: 'id',
      align: 'center',
      render: (id, record) => (
        <Space>
          <ModalForm
            title={SETTINGS.EDIT_ORDER}
            initialValues={record}
            onOk={(values) => handleUpdate(id, values)}
            formItems={<OrderItems />}>
            <Button icon={<EditOutlined />} />
          </ModalForm>
          <Popconfirm onConfirm={() => handleDelete(id)} title={MESSAGES.CONFIRM}>
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return <Table pagination={{size: 'small'}} rowKey={'id'} loading={loading} columns={columns} dataSource={orders} />;
};

export default OrdersTable;
