import PageLayout from '../../components/Layout/PageLayout';
import {TEXT} from '../../common/ru_RU';
import FISForm from '../../components/Reports/FISForm';
import {AdminAPI} from '../../store/AdminStore/methods';
import dayjs from 'dayjs';
import {useDispatch} from 'react-redux';
import UseMessage from '../../components/hooks/useMessage';
import {DatePicker} from '../../components/UX';

const Reports = () => {
  const dispatch = useDispatch();

  const {showError} = UseMessage();

  const downloadReport = (res, name) => {
    const url = URL.createObjectURL(res.payload);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  };

  const getPKPP = ({month}) => {
    dispatch(AdminAPI.reports.pkpp(dayjs(month).format('YYYY-MM')))
      .then((res) => {
        downloadReport(res, `Отчет ПК и ПП от ${res.meta.arg}.zip`);
      })
      .catch((err) => {
        if (err.code === 'ERR_BAD_REQUEST') {
          return showError('Отсутствуют обучения за данный период');
        }
        showError(err);
      });
  };

  const getPO = ({date}) => {
    const dates = {
      start: dayjs(date[0]).format('YYYY-MM-DD'),
      end: dayjs(date[1]).format('YYYY-MM-DD'),
    };

    dispatch(AdminAPI.reports.po(dates))
      .then((res) => downloadReport(res, `Отчет ПО c ${dates.start} по ${dates.end}.zip`))
      .catch((err) => {
        if (err.code === 'ERR_BAD_REQUEST') {
          return showError('Отсутствуют обучения за данный период');
        }
        showError(err);
      });
  };

  return (
    <PageLayout pageTitle={TEXT.PAGE_TITLES.FIS_REPORTS}>
      <FISForm saveReport={getPKPP} name="month" label="Получение ФИС отчета ПК и ПП">
        <DatePicker picker="month" format="MM.YYYY" />
      </FISForm>
      <FISForm saveReport={getPO} name="date" label="Получение ФИС отчета ПО">
        <DatePicker.RangePicker format="DD.MM.YYYY" />
      </FISForm>
    </PageLayout>
  );
};

export default Reports;
