import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, InputNumber, Space, Spin, Switch} from 'antd';
import Modal from 'antd/es/modal/Modal';
import {RegistyAPI} from '../../store/RegistryStore/methods';
import {RegistySelectors} from '../../store/RegistryStore';

import UseMessage from '../hooks/useMessage';
import useToggle from '../hooks/useToggle';

const PostData = ({id, postData}) => {
  const [isModalOpen, toggleModal] = useToggle(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isLoading = useSelector(RegistySelectors.loading);
  const {showError, showSuccess} = UseMessage();

  const initialValues = {
    ...postData,
    additionalSheets: postData?.additionalSheets ? postData.additionalSheets : 0,
  };

  const sendPostData = () => {
    form
      .validateFields()
      .then((data) => {
        dispatch(RegistyAPI.trackNumbers.sendPostData({id, data}))
          .then((res) => {
            showSuccess('Доп. вложения успешно добавлены');
            toggleModal();
          })
          .catch((err) => showError(err.message));
      })
      .catch((info) => {
        showError('Validate Failed:', info);
      });
  };

  return (
    <>
      <Space>
        <Button onClick={toggleModal}>Доп. вложения</Button>
        <Modal
          title="Дополнительные вложения"
          open={isModalOpen}
          onOk={sendPostData}
          onCancel={toggleModal}
          confirmLoading={isLoading}
          okText="Отправить">
          <Spin spinning={isLoading}>
            <Form form={form} initialValues={initialValues} labelCol={{span: 12}} labelAlign="left">
              <Form.Item name="oneSide" valuePropName="checked" label="1 сторона" style={{marginBottom: 6}}>
                <Switch />
              </Form.Item>
              <Form.Item name="license" valuePropName="checked" label="Лицензия" style={{marginBottom: 6}}>
                <Switch />
              </Form.Item>
              <Form.Item name="accreditation" valuePropName="checked" label="Аккредитация" style={{marginBottom: 6}}>
                <Switch />
              </Form.Item>
              <Form.Item
                name="copyCardPK"
                valuePropName="checked"
                label="Копия удостоверения по ПК"
                style={{marginBottom: 6}}>
                <Switch />
              </Form.Item>
              <Form.Item
                name="coverDiplomPP"
                valuePropName="checked"
                label="Обложка для диплома"
                style={{marginBottom: 6}}>
                <Switch />
              </Form.Item>
              <Form.Item name="program" valuePropName="checked" label="Программа" style={{marginBottom: 6}}>
                <Switch />
              </Form.Item>
              {/*<Form.Item name="sheetDiagramGift" valuePropName="checked" label="Лист-схема в подарок">*/}
              {/*  <Switch />*/}
              {/*</Form.Item>*/}
              <Form.Item name="calendar" valuePropName="checked" label="Календарь" style={{marginBottom: 6}}>
                <Switch />
              </Form.Item>
              {/*<Form.Item name="notificationMail" valuePropName="checked" label="Письмо уведомление">*/}
              {/*  <Switch />*/}
              {/*</Form.Item>*/}
              {/*<Form.Item name="manualElB" valuePropName="checked" label="Пособие по ЭЛБ">*/}
              {/*  <Switch />*/}
              {/*</Form.Item>*/}
              {/*<Form.Item name="manualOPP" valuePropName="checked" label="Пособие по ОПП">*/}
              {/*  <Switch />*/}
              {/*</Form.Item>*/}
              <Form.Item name="manualRnV" valuePropName="checked" label="Пособие по РнВ" style={{marginBottom: 6}}>
                <Switch />
              </Form.Item>
              {/*<Form.Item name="manualPTM" valuePropName="checked" label="Пособие по ПТМ">*/}
              {/*  <Switch />*/}
              {/*</Form.Item>*/}
              {/*<Form.Item name="journalGO" valuePropName="checked" label="Журнал по ГО и ЧС">*/}
              {/*  <Switch />*/}
              {/*</Form.Item>*/}
              {/*<Form.Item name="journalPTM" valuePropName="checked" label="Журнал по ПТМ">*/}
              {/*  <Switch />*/}
              {/*</Form.Item>*/}
              <Form.Item name="additionalSheets" label="Дополнительные листы">
                <InputNumber min={0} />
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </Space>
    </>
  );
};

export default PostData;
