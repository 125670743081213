import React, {useState} from 'react';
import {ExclamationOutlined} from '@ant-design/icons';
import {Button, Col, Form, Popover, Row, Space, Typography} from 'antd';

import {LOCALE} from '../../common';

const {Item} = Form;
const {Text} = Typography;
const {SYSTEM} = LOCALE;

const CustomerItem = ({
  children,
  label,
  name,
  form,
  propName,
  rules,
  cloneElement = null,
  isEditing = true,
  manualValue,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const isChangeQueryActive = form.getFieldValue('changeQueryActive') && form.getFieldValue(['changeQueryJson', name]);

  const childrenValue = manualValue ? manualValue : form.getFieldValue(name);

  const ModalRender = () => {
    const changeQuery = form.getFieldValue('changeQueryJson');
    const cloneChildren = React.cloneElement(cloneElement || children, {
      value: changeQuery[name],
      readOnly: true,
    });

    const handleAcceptQuery = () => {
      const changeQueryValue = form.getFieldValue(['changeQueryJson', name]);
      form.setFieldValue(name, changeQueryValue || null);
      delete changeQuery[name];
      form.setFieldValue('changeQueryJson', changeQuery);
      setOpen(false);
    };

    return (
      <Space align="center">
        <Text>{`Предлагается заменить на :`}</Text>
        {isEditing ? (
          <>
            <Item style={{width: '100%'}} valuePropName={propName} noStyle>
              {cloneChildren}
            </Item>
            <Button type="primary" onClick={handleAcceptQuery}>
              {SYSTEM.ACCEPT}
            </Button>
          </>
        ) : (
          <Typography>{cloneChildren.props.value}</Typography>
        )}
      </Space>
    );
  };

  return isChangeQueryActive ? (
    <Item label={label} required style={{marginBottom: 5}}>
      <Row gutter={[16, 24]} justify="space-between" align="middle">
        <Col style={{flexGrow: 1}}>
          {isEditing ? (
            <Item valuePropName={propName} name={name} rules={[{required: true, ...rules}]} noStyle>
              {children}
            </Item>
          ) : (
            <Typography>{childrenValue}</Typography>
          )}
        </Col>
        <Col>
          <Popover open={open} onOpenChange={setOpen} content={<ModalRender />} trigger="click">
            <Button danger type="primary" icon={<ExclamationOutlined />} />
          </Popover>
        </Col>
      </Row>
    </Item>
  ) : isEditing ? (
    <Item
      style={{marginBottom: 5}}
      valuePropName={propName}
      name={name}
      label={label}
      rules={[{required: true, ...rules}]}
      {...props}>
      {children}
    </Item>
  ) : (
    <Item style={{marginBottom: 0}} valuePropName={propName} name={name} label={label} {...props}>
      {childrenValue ? <Typography>{childrenValue}</Typography> : <Typography>Отсутствует</Typography>}
    </Item>
  );
};

export default CustomerItem;
