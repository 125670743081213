import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {CloseOutlined, DeleteOutlined, PlusOutlined, SaveOutlined} from '@ant-design/icons';
import {Button, InputNumber, Space, Table} from 'antd';
import {LOCALE} from '../../common';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';
import UseMessage from '../hooks/useMessage';

const {COMMON, EDUCATION} = LOCALE;

const PricesTable = ({data, loading, id}) => {
  const [newValues, setNewValues] = useState(null);
  const [editedRow, setEditedRow] = useState(false);
  const dispatch = useDispatch();
  const {showError, showSuccess} = UseMessage();

  const handleUpdate = (key, value) => {
    setNewValues({...newValues, [key]: value});
  };

  const updatePrice = (values) => {
    dispatch(EduMaterialsAPI.prices.update({id: values.id, data: {peopleCount: values.peopleCount, cost: values.cost}}))
      .then(() => {
        showSuccess();
        setEditedRow(false);
      })
      .catch((e) => showError(e));
  };

  const createPrice = () => {
    dispatch(EduMaterialsAPI.prices.create(id))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const deletePrice = (id) => {
    dispatch(EduMaterialsAPI.prices.delete(id))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const handleSave = () => {
    updatePrice(newValues);
  };

  const handleEdit = (record) => {
    setEditedRow(record);
    setNewValues(record);
  };

  const handleCancel = () => {
    setEditedRow(false);
    setNewValues(null);
  };

  const columns = [
    {
      dataIndex: 'peopleCount',
      title: EDUCATION.PEOPLE_COUNT,
      align: 'center',
      render: (count, record) => {
        return (
          <InputNumber
            min={0}
            disabled={editedRow && editedRow.id !== record.id}
            onFocus={() => !editedRow && handleEdit(record)}
            onChange={(val) => handleUpdate('peopleCount', val)}
            value={editedRow && editedRow.id === record.id ? newValues.peopleCount : count}
          />
        );
      },
    },
    {
      dataIndex: 'cost',
      title: COMMON.PRICE,
      align: 'center',
      render: (cost, record) => {
        return (
          <InputNumber
            min={0}
            disabled={editedRow && editedRow.id !== record.id}
            onFocus={() => !editedRow && handleEdit(record)}
            onChange={(val) => handleUpdate('cost', val)}
            value={editedRow && editedRow.id === record.id ? newValues.cost : cost}
          />
        );
      },
    },
    {
      dataIndex: 'id',
      title: <Button type="primary" onClick={createPrice} icon={<PlusOutlined />} />,
      align: 'center',
      width: 200,
      render: (id) => {
        return (
          <>
            {editedRow.id !== id ? (
              <Button onClick={() => deletePrice(id)} danger icon={<DeleteOutlined />} />
            ) : (
              <Space>
                <Button onClick={() => handleSave(newValues)} type="primary" icon={<SaveOutlined />} />
                <Button danger onClick={handleCancel} icon={<CloseOutlined />} />
              </Space>
            )}
          </>
        );
      },
    },
  ];

  return <Table pagination={false} rowKey="id" dataSource={data} loading={loading} columns={columns} />;
};

export default PricesTable;
