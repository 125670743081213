import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Input, Select} from 'antd';

import {LOCALE} from '../../common';
import {EduSelectors} from '../../store/EducationStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';

export const learningResetFilters = {
  limit: 10,
  offset: 0,
  page: 1,
  trainingProgramTypeId: null,
  trainingProgramId: null,
  phaseNumber: null,
  eduName: null,
  url: null,
};

const {EDUCATION, COMMON, PLACEHOLDERS} = LOCALE;

const LearningMaterialsFilters = ({form, isVideo = false}) => {
  const dispatch = useDispatch();
  const programTypes = useSelector(EduSelectors.programTypes);
  const programs = useSelector(EduSelectors.programs);
  const loading = useSelector(EduSelectors.loading);

  const phase = Form.useWatch('phaseNumber', form);
  const programType = Form.useWatch('trainingProgramTypeId', form);
  const program = Form.useWatch('trainingProgramId', form);

  const modules = programs.find((item) => item.id === program)?.phases ?? [];

  Form.useWatch(['trainingProgramTypeId'], form);

  useEffect(() => {
    dispatch(EduMaterialsAPI.programTypes.getAll());
  }, []);

  useEffect(() => {
    if (programType) {
      dispatch(EduMaterialsAPI.programs.getByType({filters: {trainingProgramTypeId: programType}}));
      form.setFieldValue('trainingProgramId', null);
    } else {
      dispatch(EduMaterialsAPI.programs.getAll());
    }
  }, [programType]);

  return (
    <>
      <Form.Item label={EDUCATION.PROGRAM_TYPE} name="trainingProgramTypeId">
        <Select allowClear placeholder="БДД для водителей">
          {programTypes.map((type) => (
            <Select.Option value={type.id} key={type.id}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={EDUCATION.PROGRAM} rules={[{required: phase}]} name="trainingProgramId">
        <Select allowClear loading={loading} placeholder="БДД_01">
          {programs.map((type) => (
            <Select.Option value={type.id} key={type.id}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="phaseNumber" label="Модуль">
        <Select showSearch allowClear placeholder="Модуль 1">
          {modules.map((module) => (
            <Select.Option key={module.id} value={module.number}>
              {module.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={COMMON.NAME} name="eduName">
        <Input allowClear placeholder="03_3 Отчетность об образовании" />
      </Form.Item>
      {isVideo && (
        <Form.Item label={COMMON.LINK} name="url">
          <Input allowClear placeholder={PLACEHOLDERS.LINK} />
        </Form.Item>
      )}
    </>
  );
};

export default LearningMaterialsFilters;
