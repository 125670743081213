import {Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {ROUTES} from '../common/routes';
import {AdminSelectors} from '../store/AdminStore';
import Fallback from '../pages/Fallback';

const Router = () => {
  const adminInfo = useSelector(AdminSelectors.adminInfo);
  const routerKeys = Object.keys(ROUTES);

  return (
    <Routes>
      {routerKeys.map((key) =>
        adminInfo.adminRights.includes(ROUTES[key].key)
          ? ROUTES[key].routes.map((route) => <Route key={route.path} path={route.path} element={route.element} />)
          : null
      )}

      <Route path="*" element={<Fallback />} />
    </Routes>
  );
};

export default Router;
