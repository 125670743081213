import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Form, Row, Space, Typography, Card, Image, List} from 'antd';
import JoditEditor from 'jodit-react';

import PhaseLinkingModal from '../LearningMaterials/PhaseLinkingModal';
import PhasesList from '../PhasesList';
import CustomForm from '../UX/CustomForm';

import {JoditConfig, LOCALE, QUESTION_TYPES} from '../../common';
import {QuestionsSelectors} from '../../store/QuestionsStore';
import {QuestionsAPI} from '../../store/QuestionsStore/methods';

import UseMessage from '../hooks/useMessage';

const {SYSTEM, QUESTIONS, COMMON} = LOCALE;
const {Item} = Form;

const EditQuestionForm = () => {
  const {id} = useParams();
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const phases = useSelector(QuestionsSelectors.phases);
  const loading = useSelector(QuestionsSelectors.loading);
  const question = useSelector(QuestionsSelectors.selectedQuestion);

  const [isEditing, setIsEditing] = useState(false);

  const {showError, showSuccess} = UseMessage();

  const questionType = QUESTION_TYPES.find((questionType) => questionType.value === question?.questionTypeId);

  useEffect(() => {
    dispatch(QuestionsAPI.getById(id)).catch(() => navigate('*'));
    dispatch(QuestionsAPI.getPhases(id));
  }, []);

  const updateQuestion = (values) => {
    dispatch(QuestionsAPI.update({id, data: values}))
      .then(() => {
        showSuccess();
        setIsEditing(false);
      })
      .catch(() => showError());
  };

  const addToPhase = (values) => {
    dispatch(QuestionsAPI.addToPhase({id, phaseId: values.trainingProgramsPhaseId}))
      .then(() => showSuccess('Успешно привязано'))
      .catch((e) => showError('Ошибка' + e));
  };

  const removePhase = (phaseId) => {
    dispatch(QuestionsAPI.removePhase({id, phaseId}))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };
  const removeAllPhases = () => {
    dispatch(QuestionsAPI.removeAllPhases(id))
      .then(() => showSuccess())
      .catch((e) => showError(e));
  };

  const parser = new DOMParser();
  const note = parser.parseFromString(question.note, 'text/html').documentElement.textContent;

  const content = parser.parseFromString(question.text, 'text/html');
  const images = content.getElementsByTagName('img');

  return (
    <>
      <Row align="middle" justify="start">
        <Typography.Title level={4}>Тип вопроса:</Typography.Title>
        <Typography.Text italic style={{margin: '0 10px 5px'}}>
          {questionType?.name}
        </Typography.Text>
      </Row>

      {isEditing ? (
        <CustomForm
          onOk={updateQuestion}
          formInstance={form}
          loading={loading}
          align="vertical"
          showButtons={false}
          initialValues={question}>
          <Item name="text" label={QUESTIONS.QUESTION_TEXT}>
            <JoditEditor config={{style: {height: 150}, ...JoditConfig}} />
          </Item>
          <Item name="note" label={COMMON.NOTE}>
            <JoditEditor config={{style: {height: 250}, ...JoditConfig}} />
          </Item>
        </CustomForm>
      ) : (
        <Space direction="vertical" style={{textAlign: 'justify', fontSize: 14, marginBottom: 20, marginRight: 20}}>
          <Typography.Text strong>
            <b style={{fontSize: 18, fontWeight: 600}}>Вопрос:</b>{' '}
            {images.length > 0 ? (
              <Row>
                <Image width={300} src={images[0].src} />
                <p>{content.body.textContent}</p>
              </Row>
            ) : (
              <Row>
                <p>{content.body.textContent}</p>
              </Row>
            )}
          </Typography.Text>
          <Typography>
            <b style={{fontSize: 18, fontWeight: 600}}>Комментарий:</b> {note}
          </Typography>
        </Space>
      )}

      <Space>
        {isEditing ? (
          <>
            <Button type="primary" onClick={form.submit}>
              {SYSTEM.SAVE}
            </Button>
            <PhasesList loading={loading} phases={phases} onDelete={removePhase} onDeleteAll={removeAllPhases} />
            <PhaseLinkingModal phaseItemName={'trainingProgramsPhaseId'} onOk={addToPhase} />
            <Button danger onClick={() => setIsEditing(false)}>
              {SYSTEM.CANCEL}
            </Button>
          </>
        ) : (
          <>
            <Button type="primary" onClick={() => setIsEditing(true)}>
              {SYSTEM.EDIT}
            </Button>
            <Button danger onClick={() => navigate(-1)}>
              {SYSTEM.BACK}
            </Button>
          </>
        )}
      </Space>
      <Row style={{marginTop: 20}}>
        <List
          bordered
          dataSource={phases}
          loading={loading}
          renderItem={(linkedPhase) => {
            return (
              <List.Item>
                <Space
                  align="center"
                  style={{
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>
                  <Typography.Text>
                    <b>{linkedPhase.trainingProgramName}</b>
                    {` : ${linkedPhase.phaseName || 'Модуль'}`}
                  </Typography.Text>
                </Space>
              </List.Item>
            );
          }}
        />
      </Row>
    </>
  );
};

export default EditQuestionForm;
