import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import dayjs from 'dayjs';

import {AdminAPI} from '../../store/AdminStore/methods';
import {LOCALE, paramsToObject} from '../../common';

import PageLayout from '../../components/Layout/PageLayout';
import AnalyticsTable from '../../components/Analytics/AnalyticsTable';
import FilterPanel from '../../components/FilterPanel';
import AnalyticsFilters from '../../components/Analytics/AnalyticsFilter';
import {analyticFilters} from '../../components/filters';

const statuses = ['new', 'repeat', 'adv', 'call', 'agent', 'other', 'pik', 'manual', 'exclude'];

dayjs.locale('ru_RU');

const defaultFilters = {
  trainingProgramType: null,
  month: dayjs(),
  counter: 'sum',
  contractStatus: 'primary',
  new: true,
  repeat: true,
  adv: true,
  call: true,
  agent: true,
  other: false,
  pik: false,
  manual: false,
  exclude: false,
};

const layout = {
  // labelCol: {xxl: {span: 10}, xl: {span: 14}, xs: {span: 14}},
  // wrapperCol: {xxl: {span: 10}, xl: {span: 8}, xs: {span: 8}},
};

const Analytics = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const savedFilters = localStorage.getItem('analyticFilters');

  const getMonth = () => {
    if (searchParams.get('month')) {
      return dayjs(searchParams.get('month'));
    } else if (JSON.parse(savedFilters)?.month) {
      return dayjs(JSON.parse(savedFilters).month);
    } else {
      return dayjs();
    }
  };
  const month = getMonth();
  const initialFilters = {...defaultFilters, ...JSON.parse(savedFilters), ...paramsToObject(searchParams), month};

  const dispatch = useDispatch();

  useEffect(() => {
    const {repeat, adv, call, agent, other, pik, manual, exclude} = initialFilters;
    initialFilters.month = dayjs(initialFilters.month).format('YYYY-MM');
    initialFilters.statuses = {repeat, new: initialFilters.new, adv, call, agent, other, pik, manual, exclude};

    statuses.forEach((status) => {
      delete initialFilters[status];
    });

    dispatch(AdminAPI.analytic.getAnalytic(initialFilters));
  }, [initialFilters]);

  return (
    <PageLayout pageTitle={LOCALE.PAGE_TITLES.ANALYTICS}>
      <FilterPanel
        initialFilters={initialFilters}
        resetFilters={defaultFilters}
        formLayout={layout}
        filterLabels={analyticFilters}
        params={paramsToObject(searchParams)}
        name="analyticFilters">
        <AnalyticsFilters />
      </FilterPanel>

      <AnalyticsTable />
    </PageLayout>
  );
};

export default Analytics;
