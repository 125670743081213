import {Card, Col, Form, Row} from 'antd';
import dayjs from 'dayjs';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {LOCALE, PROMOCODE_TYPES} from '../../common';
import UseMessage from '../../components/hooks/useMessage';
import PageLayout from '../../components/Layout/PageLayout';
import PromocodeForm from '../../components/Promocodes/PromocodeForm';
import PromocodePrices from '../../components/Promocodes/PromocodePrices';
import CustomForm from '../../components/UX/CustomForm';
import {AdminSelectors} from '../../store/AdminStore';
import {AdminAPI} from '../../store/AdminStore/methods';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';

const {PROMOCODES, COMMON} = LOCALE;

const Promocode = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const loading = useSelector(AdminSelectors.loading);
  const promocodeInfo = useSelector(AdminSelectors.promocodeInfo);
  const promocodePrices = useSelector(AdminSelectors.promocodePrices);

  const initialFormValues = {
    ...promocodeInfo,
    startDate: promocodeInfo.startDate ? dayjs(promocodeInfo.startDate).format('DD.MM.YYYY') : null,
    endDate: promocodeInfo.endDate ? dayjs(promocodeInfo.endDate).format('DD.MM.YYYY') : null,
  };

  const [form] = Form.useForm();
  const {showError, showSuccess} = UseMessage();

  Form.useWatch('promocodeTypeId', form);

  useEffect(() => {
    fetchPromocode();
  }, []);

  const fetchPromocode = () => {
    dispatch(AdminAPI.promocodes.getById(id))
      .then(() => {
        dispatch(AdminAPI.promocodes.prices.getAll(id));
      })
      .catch(() => {
        navigate('*');
      });
    dispatch(EduMaterialsAPI.programTypes.getAll());
  };

  const updatePromocode = (values) => {
    const keys = Object.keys(values);
    keys.map((key) => values[key] === null && delete values[key]);
    if (values['endDate'] && dayjs(values['endDate']).isBefore(values['startDate'])) {
      showError('Конечная дата меньше начальной');
      return;
    }

    dispatch(
      AdminAPI.promocodes.update({id, data: {...values, discount: values.discount && parseInt(values.discount)}})
    )
      .then(() => {
        showSuccess();
        fetchPromocode();
      })
      .catch((e) => showError(e));
  };

  const onCancel = () => navigate(-1);

  return (
    <PageLayout pageTitle={PROMOCODES.REDACT_PROMOCODE}>
      <Row style={{width: '100%'}} justify="space-around" gutter={[16, 24]}>
        <Col xl={11} sm={24}>
          <Card style={{height: '100%'}}>
            <CustomForm
              formInstance={form}
              loading={loading}
              initialValues={initialFormValues}
              onOk={updatePromocode}
              onCancel={onCancel}>
              <PromocodeForm redact={true} />
            </CustomForm>
          </Card>
        </Col>
        <Col xl={12} sm={24}>
          {form.getFieldValue('promocodeTypeId') === PROMOCODE_TYPES[2].value && (
            <Card title={COMMON.PRICES} style={{height: '100%'}}>
              <PromocodePrices id={id} data={promocodePrices} loading={loading} />
            </Card>
          )}
        </Col>
      </Row>
    </PageLayout>
  );
};

export default Promocode;
