import React, {useEffect} from 'react';
import {Button, Form, Space} from 'antd';

import {LOCALE} from '../../common';

const defaultLayout = {
  labelCol: {
    sm: {span: 6},
  },
  // wrapperCol: {
  // 	xl: {
  // 		span: 10,
  // 	},
  // },
};

const CustomForm = ({
  formInstance,
  children,
  onOk = () => {},
  loading = false,
  onCancel = () => {},
  layout,
  align,
  initialValues,
  showButtons = true,
  ...props
}) => {
  const [form] = Form.useForm(formInstance);
  const {SYSTEM} = LOCALE;

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const submitHandler = () => {
    form
      .validateFields()
      .then((result) => {
        onOk(result);
      })
      .catch((err) => console.log(err));
  };

  const cancelHandler = () => {
    form.resetFields();
    onCancel();
  };

  const childrenWithForm =
    children &&
    (Array.isArray(children)
      ? children.map(
          (child) => React.isValidElement(child) && React.cloneElement(child, {form: form, key: Math.random()})
        )
      : React.cloneElement(children, {form: form}));

  return (
    <Form
      scrollToFirstError
      colon={false}
      labelWrap
      form={form}
      initialValues={initialValues}
      onFinish={submitHandler}
      validateMessages={{required: LOCALE.MESSAGES.REQUIRE}}
      labelAlign="left"
      layout={align}
      {...defaultLayout}
      {...layout}
      {...props}>
      {childrenWithForm || children}
      {showButtons && (
        <Form.Item>
          <Space style={{justifyContent: 'center'}}>
            <Button loading={loading} onClick={form.submit} type="primary">
              {SYSTEM.SAVE}
            </Button>
            <Button onClick={cancelHandler}>{SYSTEM.CANCEL}</Button>
          </Space>
        </Form.Item>
      )}
    </Form>
  );
};

export default CustomForm;
