import {Typography} from 'antd';
import {LOCALE} from '../../common';
import PageLayout from '../../components/Layout/PageLayout';
import AnswersTable from '../../components/Questions/AnswersTable';
import EditQuestionForm from '../../components/Questions/EditQuestionForm';
import Norms from '../../components/SystemContentControl/Norms';
import {useParams} from 'react-router-dom';

const {PAGE_TITLES, QUESTIONS} = LOCALE;

const Question = () => {
  const {id} = useParams();

  return (
    <PageLayout spaceProps={{style: {maxWidth: '1000px', width: '100%'}}} pageTitle={PAGE_TITLES.EDIT_QUESTION}>
      <EditQuestionForm />
      <Typography.Title level={2}>{QUESTIONS.ANSWERS}</Typography.Title>
      <AnswersTable />
      <Norms id={id} itemType="question" />
    </PageLayout>
  );
};

export default Question;
