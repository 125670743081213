import { Form, Input, Select } from "antd";
import JoditEditor from "jodit-react";
import React from "react";
import { JoditConfig, QUESTION_TYPES } from "../../common";

const { Item } = Form;
const { Option } = Select;

const NewQuestionForm = () => {
	return (
		<>
			<Item name='text' label='Текст вопроса'  rules={[{ required: true }]}>
				{/* <Input.TextArea autoFocus style={{ resize: "none" }} /> */}
				<JoditEditor config={{ style: { height: 350 }, ...JoditConfig }} />
			</Item>
			<Item name='questionTypeId' label='Тип вопроса' rules={[{ required: true }]}>
				<Select>
					{QUESTION_TYPES.map((type) => (
						<Option value={type.value} key={type.value}>
							{type.name}
						</Option>
					))}
				</Select>
			</Item>
			<Item name='note' label='Комментарий'>
				<Input.TextArea style={{ resize: "none" }} />
			</Item>
			{/* <Item name='imageLink' label='Ссылка на изображение'>
				<Input />
			</Item> */}
		</>
	);
};

export default NewQuestionForm;
