import {useState} from 'react';
import {Button, Modal} from 'antd';

import {LOCALE, http} from '../../common';

import Dragndrop from '../UX/Dragndrop';
import {AdminAPI} from '../../store/AdminStore/methods';
import {useDispatch} from 'react-redux';
import UseMessage from '../hooks/useMessage';

const {REGISTRY} = LOCALE;

const MintrudNumbersModal = () => {
  const [visible, setVisible] = useState();
  const [uploadedFile, setUploadedFile] = useState(null);

  const dispatch = useDispatch();

  const {showSuccess, showError} = UseMessage();

  const handleCancel = () => setVisible(false);

  const showModal = () => setVisible(true);

  const uploadFiles = () => {
    const formData = new FormData();

    formData.append('report', uploadedFile);

    dispatch(AdminAPI.documentation.mintrudNumbers(formData))
      .then((response) => {
        showSuccess(response.payload.status);
        setVisible(false);
        setUploadedFile(null);
      })
      .catch((e) => showError(e));
  };

  return (
    <>
      <Button onClick={showModal}>{REGISTRY.DOWNLOAD_MINTRUD_NUMBERS}</Button>
      <Modal onOk={uploadFiles} open={visible} onCancel={handleCancel}>
        <Dragndrop
          onChange={(e) => setUploadedFile(e.file)}
          draggerProps={{
            beforeUpload: () => {
              return false;
            },
            maxCount: 1,
            multiple: false,
            style: {maxWidth: '500px', maxHeight: '200px', margin: 'auto', marginTop: '20px'},
            accept: '.xlsx',
          }}
        />
      </Modal>
    </>
  );
};

export default MintrudNumbersModal;
