import {Button, Popconfirm} from 'antd';
import useToggle from '../hooks/useToggle';

const PopConfirmButton = ({
  title,
  button,
  icon,
  isLoading,
  onClick = () => {},
  type = 'default',
  okText,
  cancelText,
  danger,
}) => {
  const [open, togglePopcon] = useToggle(false);

  const handleOk = () => {
    onClick();
    togglePopcon();
  };

  return (
    <Popconfirm
      title={title}
      open={open}
      okText={okText}
      cancelText={cancelText}
      onConfirm={handleOk}
      okButtonProps={{loading: isLoading}}
      onCancel={togglePopcon}>
      <Button type={type} onClick={togglePopcon} icon={icon} danger>
        {button}
      </Button>
    </Popconfirm>
  );
};

export default PopConfirmButton;
