import {LOCALE} from '../../common';
import AdminsTable from '../../components/Admins/AdminsTable';
import PageLayout from '../../components/Layout/PageLayout';

const Admins = () => {
  return (
    <PageLayout pageTitle={LOCALE.PAGE_TITLES.ADMINS}>
      <AdminsTable />
    </PageLayout>
  );
};

export default Admins;
