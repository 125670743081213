import DeliveryInfo from '../pages/Registry/DeliveryInfo';
import ListenersInfo from '../pages/Registry/ListenersInfo';
import PaymentInfo from '../pages/Registry/PaymentInfo';
import Customer from '../pages/Customers/Customer';
import Customers from '../pages/Customers/Customers';
import Registry from '../pages/Registry/Registry';

import Promocodes from '../pages/Promocodes/Promocodes';
import Users from '../pages/Users/Users';
import User from '../pages/Users/User';
import Operators from '../pages/Operators/Operators';
import ProgramTypes from '../pages/Programs/ProgramTypes';
import ProgramType from '../pages/Programs/ProgramType';
import Programs from '../pages/Programs/Programs';
import Program from '../pages/Programs/Program';
import CreateProgram from '../pages/Programs/CreateProgram';
import LearningMaterials from '../pages/LearningMaterials/LearningMaterials';
import Lection from '../pages/LearningMaterials/Lection';
import Video from '../pages/LearningMaterials/Video';
import Presentation from '../pages/LearningMaterials/Presentation';
import Questions from '../pages/Questions';
import Question from '../pages/Questions/Question';
import Settings from '../pages/Settings';
import Adlink from '../pages/LearningMaterials/Adlink';
import Other from '../pages/LearningMaterials/Other';
import CommissionMember from '../components/Settings/CommissionMember';
import Promocode from '../pages/Promocodes/Promocode';
import Diploma from '../pages/Diploms';
import TestingResults from '../pages/TestResults/TestingResults';
import Admins from '../pages/Admins';
import CommercialProposes from '../pages/CommercialProposes';
import CommercialProposeInfo from '../pages/CommercialProposes/Info';
import Analytics from '../pages/Analytic';
import {ADMIN_RIGHTS} from './constants';
import Reports from '../pages/Reports';
import Listeners from '../pages/Listeners';
import CustomersJobTitle from '../pages/CustomersJobTitle';
import NewUser from '../pages/Users/NewUser';

export const ROUTES = {
  registry: {
    key: ADMIN_RIGHTS.menuShowRegistry.id,
    routes: [
      {link: '/', path: '/', element: <Registry />},
      {path: `/registry/payment/:id`, element: <PaymentInfo />},
      {path: `/registry/students/:id`, element: <ListenersInfo />},
      {path: `/registry/delivery/:id`, element: <DeliveryInfo />},
      {path: 'diploma/:id/:studentId', element: <Diploma />},
    ],
  },
  customers: {
    key: ADMIN_RIGHTS.menuShowCustomers.id,
    routes: [
      {path: 'customers', element: <Customers />},
      {path: `/customer/:id`, element: <Customer />},
    ],
  },
  promocodes: {
    key: ADMIN_RIGHTS.menuShowPromocodes.id,
    routes: [
      {path: 'promocodes', element: <Promocodes />},
      {path: '/promocodes/:id', element: <Promocode />},
    ],
  },
  users: {
    key: ADMIN_RIGHTS.menuShowUsers.id,
    routes: [
      {path: 'users', element: <Users />},
      {path: '/users/:id', element: <User />},
      {path: '/users/new', element: <NewUser />},
    ],
  },
  operators: {
    key: ADMIN_RIGHTS.menuShowOperators.id,
    routes: [{path: 'operators', element: <Operators />}],
  },
  admins: {
    key: ADMIN_RIGHTS.menuShowAdmins.id,
    routes: [{path: 'admins', element: <Admins />}],
  },
  listeners: {
    key: ADMIN_RIGHTS.menuShowUsers.id,
    routes: [{path: 'listeners', element: <Listeners />}],
  },
  programTypes: {
    key: ADMIN_RIGHTS.menuShowTrainingProgramTypes.id,
    routes: [
      {path: 'program-types', element: <ProgramTypes />},
      {path: '/program-types/:id', element: <ProgramType />},
    ],
  },
  programs: {
    key: ADMIN_RIGHTS.menuShowTrainingPrograms.id,
    routes: [
      {path: 'programs', element: <Programs />},
      {path: '/programs/:id', element: <Program />},
      {path: '/new-programs/', element: <CreateProgram />},
    ],
  },
  learningMaterials: {
    key: ADMIN_RIGHTS.menuShowEducationMaterials.id,
    routes: [
      {path: '/learning-materials/', element: <LearningMaterials />},
      {path: 'learning-materials/lection/:id/:materialId', element: <Lection />},
      {path: 'learning-materials/video/:id/:materialId', element: <Video />},
      {path: 'learning-materials/presentation/:id/:materialId', element: <Presentation />},
      {path: 'learning-materials/adlink/:id', element: <Adlink />},
      {path: 'learning-materials/other/:id', element: <Other />},
    ],
  },
  questions: {
    key: ADMIN_RIGHTS.menuShowQuestions.id,
    routes: [
      {path: 'questions/:id', element: <Question />},
      {path: 'questions', element: <Questions />},
    ],
  },
  analytic: {
    key: ADMIN_RIGHTS.menuShowAnalytic.id,
    routes: [{path: 'analytics', element: <Analytics />}],
  },
  headPosts: {
    key: ADMIN_RIGHTS.menuShowHeadPosts.id,
    routes: [{path: 'job_title', element: <CustomersJobTitle />}],
  },
  settings: {
    key: ADMIN_RIGHTS.menuShowSettings.id,
    routes: [
      {path: 'settings', element: <Settings />},
      {path: 'commission-member/:id', element: <CommissionMember />},
    ],
  },
  commercialProposes: {
    key: ADMIN_RIGHTS.menuShowCommercialOffers.id,
    routes: [
      {path: 'commercial-proposes', element: <CommercialProposes />},
      {path: 'commercial-proposes/:id', element: <CommercialProposeInfo />},
    ],
  },
  reports: {
    key: ADMIN_RIGHTS.FISFRDOUnloading.id,
    routes: [{path: 'reports', element: <Reports />}],
  },

  // testingResults: {
  //   link: (id) => `/testingResults/${id}`,
  //   path: 'testingResults/:id',
  //   element: <TestingResults />,
  // },
  // algorythm: "algorythm",
  // settings: "settings",
  // templates: "templates",
  // more: "more",
};
