import {Button, Form, Space, Typography} from 'antd';
import React, {memo} from 'react';
import {useState} from 'react';
import {LOCALE} from '../../common';
import styles from './EditableCell.module.css';
import {formatSNILS} from '../../utils/formatSNILS';

const EditableCell = ({
  initialValue,
  onOk = () => {},
  valueName,
  renderValue,
  children,
  record,
  maxWidth,
  propName,
  onToggle = () => {},
  enableEllipsis = true,
}) => {
  const {SYSTEM} = LOCALE;
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);

  const handleFinish = (val) => {
    onOk(val);
    toggleEdit(false);
  };

  const toggleEdit = (bool) => {
    onToggle(bool);
    setEdit(bool);
  };

  const handleCanel = () => {
    toggleEdit(false);
    form.resetFields();
  };

  const childrenWithProps =
    children &&
    React.cloneElement(children, {
      record: record,
    });

  const outputText = valueName === 'SNILS' ? formatSNILS(renderValue) : renderValue;

  return !edit ? (
    <Space className={styles.cell} onClick={() => toggleEdit(true)}>
      <div style={{maxWidth: maxWidth && maxWidth}}>
        {renderValue ? (
          <Typography.Paragraph
            style={{marginBottom: 0, paddingRight: 5}}
            ellipsis={enableEllipsis && {rows: 3, expandable: false, tooltip: {title: renderValue, color: 'grey'}}}>
            {outputText}
          </Typography.Paragraph>
        ) : (
          <span style={{color: 'gray'}}>-</span>
        )}
      </div>
    </Space>
  ) : (
    <Form onFinish={handleFinish} form={form} initialValues={initialValue} layout="vertical" style={{marginBottom: 8}}>
      <Space direction="vertical" style={{maxWidth: maxWidth && maxWidth, width: '100%'}}>
        <Form.Item name={valueName} valuePropName={propName} noStyle>
          {childrenWithProps}
        </Form.Item>

        <Space>
          <Button type="primary" onClick={form.submit}>
            {SYSTEM.SAVE}
          </Button>
          <Button onClick={handleCanel}>{SYSTEM.CANCEL}</Button>
        </Space>
      </Space>
    </Form>
  );
};

export default EditableCell;
