import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Checkbox, Divider, Form, Input, Popconfirm, Popover, Select, Space, Tooltip, Typography} from 'antd';

import CustomForm from '../UX/CustomForm';
import StringDatePicker from '../UX/StringDatePicker';
import CustomAutoComplete from '../UX/AutoComplete';
import CustomerItem from './CustomerItem';
import CustomerSelect from './CustomerSelect';

import {AdminSelectors} from '../../store/AdminStore';
import {BASIS_TYPES, BUDGET_TYPE, CUSTOMER_TYPES, LOCALE} from '../../common';
import {AdminAPI} from '../../store/AdminStore/methods';
import UseMessage from '../hooks/useMessage';
import dayjs from 'dayjs';
import {FormContext} from 'antd/lib/form/context';

const {CUSTOMER, COMMON, DOCUMENTS, MESSAGES, SYSTEM} = LOCALE;

const layout = {
  labelCol: {sm: {span: 6}, xxl: {span: 4}},
  wrapperCol: {xl: {span: 14}},
};

const CustomerForm = ({id}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(AdminSelectors.loading);
  const customer = useSelector(AdminSelectors.customerInfo);

  const [isEditing, setIsEditing] = useState(false);
  const [isCopyPressed, setIsCopyPressed] = useState(false);
  const [isBasisDateInvalid, setIsBasisDateInvalid] = useState(false);

  const {showSuccess, showError} = UseMessage();

  const [form] = Form.useForm();
  const isBudget = Form.useWatch('isBudget', form);
  const customerTypeIdFormState = Form.useWatch('customerTypeId', form);
  const basisDate = Form.useWatch('basisDate', form);

  const customerTypeId = isEditing ? customerTypeIdFormState : customer?.customerTypeId;

  const deleteHandler = () => {
    dispatch(AdminAPI.customers.delete(id)).then(() => navigate('/customers'));
  };

  const okHandler = (values) => {
    if (values?.passportIssuedDate) {
      values.passportIssuedDate = formatDate(values.passportIssuedDate);
    }
    console.log(basisDate);
    if (values?.basisDate) {
      values.basisDate = formatDate(values.basisDate);
    }
    if (isCopyPressed) {
      return copyCustomer(values);
    }
    const isChangeQueryUsed =
      customer['changeQueryJson'] && values['changeQueryJson'] !== customer['changeQueryJson']
        ? false
        : values['changeQueryActive'];

    dispatch(AdminAPI.customers.update({id, data: {...values, changeQueryActive: isChangeQueryUsed}}))
      .then(() => {
        showSuccess();
        setIsEditing(false);
      })
      .catch((e) => showError(e));
  };

  const copyCustomer = (values) => {
    const newCustomer = {...values, userId: customer?.userId};

    Object.keys(newCustomer).map((key) => {
      if (newCustomer[key] === null) delete newCustomer[key];
    });

    dispatch(AdminAPI.customers.copy(newCustomer))
      .then((res) => {
        showSuccess('Заказчик успешно скопирован');
        dispatch(AdminAPI.customers.update({id, data: {changeQueryActive: false}}))
          .then(() => {
            setIsEditing(false);
            if (res?.payload?.id) navigate(`/customer/${res?.payload?.id}`);
            else navigate('/customers');
          })
          .catch((e) => showError(e));
      })
      .catch((e) => showError(e));
  };

  const Buttons = () => {
    return (
      <Form.Item>
        <Space>
          {isEditing ? (
            <>
              <Button type="primary" onClick={form.submit} loading={loading}>
                {SYSTEM.SAVE}
              </Button>
              <Button
                type="secondary"
                onClick={() => {
                  setIsCopyPressed(true);
                  form.submit();
                }}
                loading={loading}>
                Сохранить как копию
              </Button>
              <Button
                onClick={() => {
                  setIsEditing(false);
                  form.resetFields();
                }}>
                {SYSTEM.CANCEL}
              </Button>
              <Popconfirm title={MESSAGES.CONFIRM} okButtonProps={{loading: loading}} onConfirm={deleteHandler}>
                <Button danger>{SYSTEM.DELETE}</Button>
              </Popconfirm>
            </>
          ) : (
            <>
              <Button type="primary" onClick={() => setIsEditing(true)}>
                {SYSTEM.EDIT}
              </Button>
              <Button onClick={() => navigate(-1)}>{SYSTEM.BACK}</Button>
            </>
          )}
        </Space>
      </Form.Item>
    );
  };

  const nameOptionsLabel = (options = []) => {
    return options.map((option) => {
      return {label: `${option.value} инн: ${option.data.inn}`, value: option.value};
    });
  };
  const addressOptionsLabel = (options = []) => {
    return options.map((option) => {
      return {label: option.unrestricted_value, value: option.unrestricted_value};
    });
  };

  const handleAutocomplete = (data) => {
    const fullName = data?.data.management ? data?.data.management.name.split(' ') : '';
    const profession = data?.data.management ? data?.data.management.post : '';
    const date = dayjs(data?.data.ogrn_date).format('DD.MM.YYYY');

    switch (data?.data.type) {
      case 'LEGAL':
        form.setFieldValue('inn', data?.data.inn, {shouldValidate: true});
        form.setFieldValue('kpp', data?.data.kpp, {shouldValidate: true});
        form.setFieldValue('directorSurname', fullName[0], {shouldValidate: true});
        form.setFieldValue('directorName', fullName[1], {shouldValidate: true});
        form.setFieldValue('directorSecondName', fullName[2], {shouldValidate: true});
        form.setFieldValue('directorProfession', profession, {shouldValidate: true});
        form.setFieldValue('basisDate', date, {shouldValidate: true});
        form.setFieldValue('legalAddress', data?.data.address.unrestricted_value, {shouldValidate: true});
        break;
      case 'INDIVIDUAL':
        form.setFieldValue('inn', data?.data.inn, {shouldValidate: true});
        form.setFieldValue('ogrn', data?.data.ogrn, {shouldValidate: true});
        form.setFieldValue('directorSurname', data?.data.fio.surname, {shouldValidate: true});
        form.setFieldValue('directorName', data?.data.fio.name, {shouldValidate: true});
        form.setFieldValue('directorSecondName', data?.data.fio?.patronymic, {shouldValidate: true});
        break;
      default:
        return null;
    }
  };

  const formatDate = (date) => {
    if (dayjs(date, 'DD.MM.YYYY').isValid()) {
      return dayjs(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
    } else if (dayjs(date).isValid()) {
      return dayjs(date).format('DD.MM.YYYY');
    } else {
      return date;
    }
  };

  useEffect(() => {
    if (dayjs(basisDate, 'DD.MM.YYYY').isValid()) {
      setIsBasisDateInvalid(false);
    } else if (dayjs(basisDate).isValid()) {
      setIsBasisDateInvalid(false);
    } else {
      setIsBasisDateInvalid(true);
    }
  }, []);
  // console.log(dayjs('2006-03-15T21:00:00.000Z', {locale: 'ru'}).toDate());

  if (customerTypeId === CUSTOMER_TYPES[0].id) {
    return (
      <CustomForm onOk={okHandler} formInstance={form} showButtons={false} layout={layout} initialValues={customer}>
        <Divider orientation="left">{CUSTOMER.INFORMATION}</Divider>
        <CustomerItem
          name={'customerTypeId'}
          label={CUSTOMER.CUSTOMER_TYPES}
          isEditing={isEditing}
          manualValue={CUSTOMER_TYPES[0].name}>
          <CustomerSelect style={{flexGrow: 1}} />
        </CustomerItem>
        <CustomerItem
          name={'organizationName'}
          label={COMMON.ORGANIZATION_NAME}
          cloneElement={<Input />}
          isEditing={isEditing}>
          <CustomAutoComplete type="party" optionsMap={nameOptionsLabel} onSelectOption={handleAutocomplete} />
        </CustomerItem>
        <CustomerItem name={'legalAddress'} label={COMMON.ADDRESS} cloneElement={<Input />} isEditing={isEditing}>
          <CustomAutoComplete type="address" optionsMap={addressOptionsLabel} />
        </CustomerItem>
        <CustomerItem name={'postAddress'} label={CUSTOMER.POST_ADDRESS} cloneElement={<Input />} isEditing={isEditing}>
          <CustomAutoComplete type="address" optionsMap={addressOptionsLabel} />
        </CustomerItem>
        <CustomerItem name={'inn'} rules={{min: 10, max: 12}} label={DOCUMENTS.INN} isEditing={isEditing}>
          <Input />
        </CustomerItem>
        <CustomerItem name={'kpp'} label={DOCUMENTS.KPP} rules={{len: 9}} isEditing={isEditing}>
          <Input />
        </CustomerItem>
        <CustomerItem
          propName={'checked'}
          name={'isBudget'}
          rules={{required: false}}
          label={CUSTOMER.IS_BUDGET}
          isEditing={isEditing}
          manualValue={customer.isBudget ? 'Да' : 'Нет'}>
          <Checkbox defaultChecked={false} />
        </CustomerItem>
        {isBudget && (
          <CustomerItem
            name={'budgetTypeId'}
            label={CUSTOMER.BUDGET_TYPE}
            isEditing={isEditing}
            rules={{required: isBudget ? true : false}}
            manualValue={
              customer.budgetTypeId ? BUDGET_TYPE.find((type) => customer.budgetTypeId === type.id).label : null
            }>
            <Select style={{width: '100%'}}>
              {BUDGET_TYPE.map((type) => (
                <Select.Option value={type.id} key={type.id}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </CustomerItem>
        )}
        {isBudget && (
          <CustomerItem
            name={'personalAccount'}
            label={DOCUMENTS.PERSONAL_ACCOUNT}
            isEditing={isEditing}
            rules={{required: false}}>
            <Input />
          </CustomerItem>
        )}
        {isBudget && (
          <CustomerItem
            name={'departmentTitle'}
            label={DOCUMENTS.DEPARTMENT_TITLE}
            isEditing={isEditing}
            rules={{required: false}}
            style={{marginBottom: 4}}>
            <Input />
          </CustomerItem>
        )}
        {isBudget && (
          <CustomerItem
            name={'treasuryAccount'}
            label={DOCUMENTS.TREASURY_ACCOUNT}
            isEditing={isEditing}
            rules={{required: false}}>
            <Input />
          </CustomerItem>
        )}
        {isBudget && (
          <CustomerItem
            name={'mainAccount'}
            label={DOCUMENTS.MAIN_ACCOUNT}
            isEditing={isEditing}
            rules={{required: false}}>
            <Input />
          </CustomerItem>
        )}

        <CustomerItem name={'phone'} label={COMMON.PHONE} isEditing={isEditing} rules={{required: false}}>
          <Input />
        </CustomerItem>
        <CustomerItem
          name={'email'}
          label={COMMON.EMAIL}
          cloneElement={<Input />}
          isEditing={isEditing}
          rules={{required: false, type: 'email', message: MESSAGES.INVALID}}>
          <CustomAutoComplete type="email" />
        </CustomerItem>
        <Divider orientation="left">{DOCUMENTS.BANK_ACCOUNT}</Divider>
        <CustomerItem
          name={'bank'}
          label={COMMON.BANK}
          cloneElement={<Input />}
          isEditing={isEditing}
          rules={{required: false}}>
          <CustomAutoComplete type="bank" />
        </CustomerItem>
        <CustomerItem name={'bik'} label={DOCUMENTS.BIK} isEditing={isEditing} rules={{required: false}}>
          <Input />
        </CustomerItem>
        <CustomerItem
          name={'kAccount'}
          label={DOCUMENTS.CORRESPONDER_ACCOUNT}
          isEditing={isEditing}
          rules={{required: false}}>
          <Input />
        </CustomerItem>
        <CustomerItem name={'rAccount'} rules={{required: false}} label={DOCUMENTS.BANK_ACCOUNT} isEditing={isEditing}>
          <Input />
        </CustomerItem>
        <Divider orientation="left">{CUSTOMER.DIRECTOR}</Divider>
        <CustomerItem
          name={'directorProfession'}
          label={CUSTOMER.DIRECTOR_PROFESSION}
          isEditing={isEditing}
          rules={{required: false}}>
          <Input />
        </CustomerItem>
        <CustomerItem
          name={'basisTypeId'}
          label={COMMON.ACTING_ON_BASIS}
          isEditing={isEditing}
          rules={{required: true}}
          manualValue={
            customer.basisTypeId ? BASIS_TYPES.find((type) => customer.basisTypeId === type.value).name : null
          }>
          <Select style={{width: '100%'}}>
            {BASIS_TYPES.map((type) => (
              <Select.Option value={type.value} key={type.value}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </CustomerItem>
        <CustomerItem
          name={'basisNumber'}
          label={
            <Tooltip title="Номер документа, на основании которого действует юр. лицо">
              <span>{COMMON.BASIS_NUMBER}</span>
            </Tooltip>
          }
          isEditing={isEditing}
          rules={{required: false}}>
          <Input />
        </CustomerItem>

        <CustomerItem
          name={'basisDate'}
          manualValue={basisDate !== null ? formatDate(basisDate) : <Typography>Отсутствует</Typography>}
          label={
            <Tooltip title="Дата документа, на основании которого действует юр. лицо">
              <span>{COMMON.BASIS_DATE}</span>
            </Tooltip>
          }
          isEditing={isEditing}
          required={isBasisDateInvalid}
          rules={{required: isBasisDateInvalid}}>
          <StringDatePicker value={basisDate} format={'DD.MM.YYYY'} allowClear={true} shouldFormat />
        </CustomerItem>
        {isBasisDateInvalid && (
          <Form.Item label=" ">
            <Typography>{formatDate(basisDate)}</Typography>
          </Form.Item>
        )}

        <CustomerItem name={'directorSurname'} label={COMMON.SURNAME} cloneElement={<Input />} isEditing={isEditing}>
          <CustomAutoComplete type="fio" />
        </CustomerItem>
        <CustomerItem name={'directorName'} label={COMMON.NAME} cloneElement={<Input />} isEditing={isEditing}>
          <CustomAutoComplete type="fio" />
        </CustomerItem>
        <CustomerItem
          name={'directorSecondName'}
          label={COMMON.SECOND_NAME}
          cloneElement={<Input />}
          isEditing={isEditing}
          rules={{required: false}}>
          <CustomAutoComplete type="fio" />
        </CustomerItem>
        <Buttons />
      </CustomForm>
    );
  }
  if (customerTypeId === CUSTOMER_TYPES[1].id) {
    return (
      <CustomForm onOk={okHandler} formInstance={form} showButtons={false} layout={layout} initialValues={customer}>
        <Divider orientation="left">{CUSTOMER.INFORMATION}</Divider>
        <CustomerItem
          name={'customerTypeId'}
          label={CUSTOMER.CUSTOMER_TYPES}
          isEditing={isEditing}
          manualValue={CUSTOMER_TYPES[1].name}>
          <CustomerSelect style={{flexGrow: 1}} />
        </CustomerItem>
        <CustomerItem name={'surname'} label={COMMON.SURNAME} cloneElement={<Input />} isEditing={isEditing}>
          <CustomAutoComplete type="fio" />
        </CustomerItem>
        <CustomerItem name={'name'} label={COMMON.NAME} cloneElement={<Input />} isEditing={isEditing}>
          <CustomAutoComplete type="fio" />
        </CustomerItem>
        <CustomerItem
          name={'secondName'}
          label={COMMON.SECOND_NAME}
          cloneElement={<Input />}
          isEditing={isEditing}
          rules={{required: false}}>
          <CustomAutoComplete type="fio" />
        </CustomerItem>
        <CustomerItem name={'passportSerial'} label={COMMON.PASSPORT_SERIAL} isEditing={isEditing}>
          <Input />
        </CustomerItem>
        <CustomerItem name={'passportNumber'} label={COMMON.PASSPORT_NUMBER} isEditing={isEditing}>
          <Input />
        </CustomerItem>
        <CustomerItem
          name={'passportIssued'}
          label={COMMON.PASSPORT_ISSUED}
          cloneElement={<Input />}
          isEditing={isEditing}>
          <CustomAutoComplete type="fms_unit" />
        </CustomerItem>
        <CustomerItem name={'passportIssuedDate'} label={COMMON.PASSPORT_ISSUED_DATE} isEditing={isEditing}>
          <StringDatePicker format={'DD.MM.YYYY'} />
        </CustomerItem>
        <CustomerItem name={'phone'} label={COMMON.PHONE} isEditing={isEditing}>
          <Input />
        </CustomerItem>
        <CustomerItem
          name="email"
          rules={{type: 'email', message: MESSAGES.INVALID}}
          label={COMMON.EMAIL}
          cloneElement={<Input />}
          isEditing={isEditing}>
          <CustomAutoComplete type="email" />
        </CustomerItem>
        {/* <CustomerItem
          name={'legalAddress'}
          label={COMMON.ADDRESS}
          cloneElement={<Input />}
          isEditing={isEditing}
          rules={{required: false}}>
          <CustomAutoComplete type="address" optionsMap={addressOptionsLabel} />
        </CustomerItem> */}
        <CustomerItem name={'postAddress'} label={CUSTOMER.POST_ADDRESS} cloneElement={<Input />} isEditing={isEditing}>
          <CustomAutoComplete type="address" optionsMap={addressOptionsLabel} />
        </CustomerItem>
        <Buttons />
      </CustomForm>
    );
  }
  if (customerTypeId === CUSTOMER_TYPES[2].id) {
    return (
      <CustomForm onOk={okHandler} formInstance={form} showButtons={false} layout={layout} initialValues={customer}>
        <Divider orientation="left">{CUSTOMER.INFORMATION}</Divider>
        <CustomerItem
          name={'customerTypeId'}
          label={CUSTOMER.CUSTOMER_TYPES}
          isEditing={isEditing}
          manualValue="Индивидуальный предприниматель">
          <CustomerSelect style={{flexGrow: 1}} />
        </CustomerItem>
        <CustomerItem
          name={'organizationName'}
          label={COMMON.ORGANIZATION_NAME}
          cloneElement={<Input />}
          isEditing={isEditing}>
          <CustomAutoComplete type="party" optionsMap={nameOptionsLabel} onSelectOption={handleAutocomplete} />
        </CustomerItem>
        <CustomerItem name={'directorSurname'} label={COMMON.SURNAME} cloneElement={<Input />} isEditing={isEditing}>
          <CustomAutoComplete type="fio" />
        </CustomerItem>
        <CustomerItem name={'directorName'} label={COMMON.NAME} cloneElement={<Input />} isEditing={isEditing}>
          <CustomAutoComplete type="fio" />
        </CustomerItem>
        <CustomerItem
          name={'directorSecondName'}
          label={COMMON.SECOND_NAME}
          cloneElement={<Input />}
          isEditing={isEditing}
          rules={{required: false}}>
          <CustomAutoComplete type="fio" />
        </CustomerItem>
        <CustomerItem name={'postAddress'} label={CUSTOMER.POST_ADDRESS} cloneElement={<Input />} isEditing={isEditing}>
          <CustomAutoComplete type="address" optionsMap={addressOptionsLabel} />
        </CustomerItem>
        <CustomerItem name={'inn'} rules={{min: 10, max: 12}} label={DOCUMENTS.INN} isEditing={isEditing}>
          <Input />
        </CustomerItem>
        <CustomerItem name={'ogrn'} label={DOCUMENTS.OGRN} isEditing={isEditing} rules={{required: false}}>
          <Input />
        </CustomerItem>
        <CustomerItem name={'phone'} label={COMMON.PHONE} isEditing={isEditing} rules={{required: false}}>
          <Input />
        </CustomerItem>
        <CustomerItem
          name={'email'}
          label={COMMON.EMAIL}
          cloneElement={<Input />}
          isEditing={isEditing}
          rules={{required: false, type: 'email', message: MESSAGES.INVALID}}>
          <CustomAutoComplete type="email" />
        </CustomerItem>
        <Divider orientation="left">{DOCUMENTS.BANK_ACCOUNT}</Divider>
        <CustomerItem
          name={'bank'}
          label={COMMON.BANK}
          cloneElement={<Input />}
          isEditing={isEditing}
          rules={{required: false}}>
          <CustomAutoComplete type="bank" />
        </CustomerItem>
        <CustomerItem name={'bik'} label={DOCUMENTS.BIK} rules={{required: false}} isEditing={isEditing}>
          <Input />
        </CustomerItem>
        <CustomerItem
          name={'kAccount'}
          rules={{required: false}}
          label={DOCUMENTS.CORRESPONDER_ACCOUNT}
          isEditing={isEditing}>
          <Input />
        </CustomerItem>
        <CustomerItem name={'rAccount'} rules={{required: false}} label={DOCUMENTS.BANK_ACCOUNT} isEditing={isEditing}>
          <Input />
        </CustomerItem>
        <Buttons />
      </CustomForm>
    );
  }
};

export default CustomerForm;
