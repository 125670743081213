import PageLayout from '../../components/Layout/PageLayout';
import {LOCALE} from '../../common';
import CustomersJobTitleTable from '../../components/CustomersJobTitle/CustomersJobTitleTable';

const CustomersJobTitle = () => {
  return (
    <PageLayout pageTitle={LOCALE.PAGE_TITLES.CUSTOMERSJOBTITLE}>
      <CustomersJobTitleTable />
    </PageLayout>
  );
};

export default CustomersJobTitle;
