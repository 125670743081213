import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {LOCALE, paramsToObject} from '../../common';
import CustomersFilters from '../../components/Customers/CustomersFilters';
import CustomersTable from '../../components/Customers/CustomersTable';
import FilterPanel from '../../components/FilterPanel';
import PageLayout from '../../components/Layout/PageLayout';
import {AdminAPI} from '../../store/AdminStore/methods';
import {customersFilters} from '../../components/filters';

const {PAGE_TITLES} = LOCALE;

const defaultFilters = {
  limit: 10,
  offset: 0,
  page: 1,
  customerTypeId: null,
  complexName: null,
  inn: null,
  ogrn: null,
  bik: null,
  email: null,
  phone: null,
  contractNumber: null,
  userChangeQueryActive: null,
};

const layout = {
  labelCol: {xxl: {span: 6}, xs: {span: 8}},
  wrapperCol: {xl: {span: 8}, xxl: {span: 6}, xs: {span: 10}},
};
const Customers = () => {
  const [searchParams] = useSearchParams();
  const savedFilters = localStorage.getItem('customersFilters');
  const initialFilters = {...defaultFilters, ...JSON.parse(savedFilters), ...paramsToObject(searchParams)};
  const dispatch = useDispatch();

  useEffect(() => {
    const filter = initialFilters;
    Object.keys(filter).forEach((k) => (filter[k] == null || filter[k] === '') && delete filter[k]);

    const limit = filter.limit ? Number(filter.limit) : 10;
    const offset = filter.offset ? Number(filter.offset) : 0;

    delete filter.limit;
    delete filter.offset;
    delete filter.page;

    dispatch(AdminAPI.customers.getAll({pagination: {limit, offset}, filter}));
  }, [searchParams]);

  return (
    <PageLayout pageTitle={PAGE_TITLES.CUSTOMERS}>
      <FilterPanel
        initialFilters={initialFilters}
        resetFilters={defaultFilters}
        formLayout={layout}
        filterLabels={customersFilters}
        name="customersFilters">
        <CustomersFilters />
      </FilterPanel>
      <CustomersTable />
    </PageLayout>
  );
};

export default Customers;
