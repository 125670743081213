import {Form, Input} from 'antd';
import React from 'react';
import {LOCALE} from '../../common';

const {Item} = Form;
const {MESSAGES, PLACEHOLDERS, COMMON} = LOCALE;

const VideoFormItems = () => {
  return (
    <>
      <Item name="eduName" label={COMMON.TITLE} rules={[{required: true}]}>
        <Input placeholder={PLACEHOLDERS.TITLE} autoFocus />
      </Item>

      <Item name="link" label={COMMON.LINK} rules={[{required: true}, {type: 'url', message: MESSAGES.INVALID_URL}]}>
        <Input placeholder={PLACEHOLDERS.LINK} />
      </Item>
    </>
  );
};

export default VideoFormItems;
