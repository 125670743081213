import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {AdminAPI} from '../../store/AdminStore/methods';
import {LOCALE, paramsToObject} from '../../common';
import FilterPanel from '../../components/FilterPanel';
import PageLayout from '../../components/Layout/PageLayout';
import UsersTable from '../../components/Users/UsersTable';
import UsersFilter from '../../components/Users/UsersFilter';
import {usersFilters} from '../../components/filters';

const {PAGE_TITLES} = LOCALE;
const resetFilters = {
  limit: 10,
  offset: 0,
  page: 1,
  firstname: null,
  secondname: null,
  surname: null,
  email: null,
  phone: null,
  contractNumber: null,
  withDeleted: false,
};

const Users = () => {
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const savedFilters = localStorage.getItem('usersFilters');
  const initialFilters = {...resetFilters, ...JSON.parse(savedFilters), ...paramsToObject(searchParams)};

  useEffect(() => {
    delete initialFilters.page;
    dispatch(AdminAPI.users.filter(initialFilters));
  }, [searchParams]);

  return (
    <PageLayout pageTitle={PAGE_TITLES.USERS_INFO}>
      <FilterPanel
        initialFilters={initialFilters}
        resetFilters={resetFilters}
        formLayout={{labelCol: {span: 9}}}
        filterLabels={usersFilters}
        name="usersFilters">
        <UsersFilter />
      </FilterPanel>
      <UsersTable />
    </PageLayout>
  );
};

export default Users;
