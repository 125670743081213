import {closestCenter, DndContext, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {List} from 'antd';
import React from 'react';
import SortableItem from './SortableItem';

const SotrableList = ({dragEnd, items = [], loading, listProps, deleteSlide}) => {
  const sensors = useSensors(useSensor(PointerSensor));

  return (
    <DndContext onDragEnd={dragEnd} sensors={sensors} collisionDetection={closestCenter}>
      <List loading={loading} {...listProps}>
        <SortableContext disabled={!dragEnd} items={items} strategy={verticalListSortingStrategy}>
          {items.map((item, index) => (
            <SortableItem
              key={item.id}
              id={item.id}
              sort={index + 1}
              link={item.link}
              deleteSlide={() => deleteSlide(item.id)}
            />
          ))}
        </SortableContext>
      </List>
    </DndContext>
  );
};

export default SotrableList;
