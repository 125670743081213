export const NATIONALITY_TYPE = [
  {
    id: '924e3029-a8d2-4db8-a292-b329635aa35b',
    label: 'РОССИЯ',
  },
  {
    id: '89f06a88-d4c9-4280-8881-b67dd344e026',
    label: 'АЗЕРБАЙДЖАН',
  },
  {
    id: 'ad0ab51d-fb3f-4fd3-b444-704524fe3790',
    label: 'АРМЕНИЯ',
  },
  {
    id: 'c8f07603-10bf-4150-897a-cd4c9173d94d',
    label: 'БЕЛАРУСЬ',
  },
  {
    id: 'af61be75-514f-482b-8c24-3d721c1327b2',
    label: 'ГРУЗИЯ',
  },
  {
    id: '11bb3f00-5c56-4ee1-ad63-29f789dd6b70',
    label: 'КАЗАХСТАН',
  },
  {
    id: '4e1aaabc-80c5-4e9a-8a8c-3d75902e08b5',
    label: 'КИРГИЗИЯ',
  },
  {
    id: 'c5aef99f-bfcc-4706-9f9d-275edb408128',
    label: 'ЛАТВИЯ',
  },
  {
    id: '24950622-7d76-4341-8f68-17cc401ffb7b',
    label: 'ЛИТВА',
  },
  {
    id: '6628cda6-c908-4fb8-b128-989cc844fd85',
    label: 'МОЛДОВА, РЕСПУБЛИКА',
  },
  {
    id: 'f3db0243-b97d-4ddd-ab01-bb1141f44b2b',
    label: 'ТАДЖИКИСТАН',
  },
  {
    id: 'c32028e6-a97f-4519-9acd-e9e051a602be',
    label: 'ТУРКМЕНИЯ',
  },
  {
    id: 'ae7064ea-9940-4d81-ae52-a1e9fc79adf3',
    label: 'УЗБЕКИСТАН',
  },
  {
    id: '0600c054-7bfd-476d-89e2-e92b7ebb4d48',
    label: 'УКРАИНА',
  },
  {
    id: '3cd5dc68-42fc-494f-b890-10a46049d1b4',
    label: 'ЭСТОНИЯ',
  },
  {
    id: '3b3edb65-ce80-4a33-946e-aacb624290f5',
    label: 'АБХАЗИЯ',
  },
  {
    id: '13fe4113-e54d-4b7f-846b-c08c275d588d',
    label: 'АВСТРАЛИЯ',
  },
  {
    id: '92d86c8d-71e1-49f9-8a4c-29b53ad10873',
    label: 'АВСТРИЯ',
  },
  {
    id: 'aeea1570-5bb3-4fad-be65-905c4ee93f94',
    label: 'АЛБАНИЯ',
  },
  {
    id: '0969930c-1eff-458c-ac65-d8a2caeedabd',
    label: 'АЛЖИР',
  },
  {
    id: 'ef46015f-c825-4e5a-a633-52f80536494d',
    label: 'АМЕРИКАНСКОЕ САМОА',
  },
  {
    id: '0279c06a-87bb-48bf-a7a8-626278d18903',
    label: 'АНГИЛЬЯ',
  },
  {
    id: '1361e0f3-87b0-495c-b60c-261d7e295bef',
    label: 'АНГОЛА',
  },
  {
    id: '9d5d79f1-0feb-463a-a10d-be1c62d8d9cc',
    label: 'АНДОРРА',
  },
  {
    id: '19d94996-cc4f-4ef8-9a7f-483a2350f9da',
    label: 'АНТАРКТИДА',
  },
  {
    id: 'adb0ea79-99d7-4da0-bd8d-b74508f80c21',
    label: 'АНТИГУА И БАРБУДА',
  },
  {
    id: '5bc98476-be22-41d9-bbf0-160ff20d122d',
    label: 'АРГЕНТИНА',
  },
  {
    id: 'd0dbcfdf-b135-44fa-a704-9ef940f96c7b',
    label: 'АРУБА',
  },
  {
    id: '7eb56003-e9f3-482f-959f-40efcc56d8ab',
    label: 'АФГАНИСТАН',
  },
  {
    id: 'd4f390ff-0429-4a48-8ecc-96da005fcf9d',
    label: 'БАГАМЫ',
  },
  {
    id: 'ac69ffa8-b447-4303-84ab-86eeaa3a780d',
    label: 'БАНГЛАДЕШ',
  },
  {
    id: '18524776-4e0e-4442-b794-0eb62b58e8b8',
    label: 'БАРБАДОС',
  },
  {
    id: '7d3feeba-20d3-4bb5-a067-47422606d20d',
    label: 'БАХРЕЙН',
  },
  {
    id: 'f6fdc0ce-09b9-47fb-add1-e954bb053184',
    label: 'БЕЛИЗ',
  },
  {
    id: '178971cc-f668-4ce3-8872-e809b23753fa',
    label: 'БЕЛЬГИЯ',
  },
  {
    id: 'b0807aef-a03d-44de-8a04-2923fa5f66d2',
    label: 'БЕНИН',
  },
  {
    id: 'f429c965-5f92-48ee-8b79-56738b89cd2f',
    label: 'БЕРМУДЫ',
  },
  {
    id: '147c0115-3bc7-44ba-8d96-6f2c1f110b86',
    label: 'БОЛГАРИЯ',
  },
  {
    id: '5f6235bf-2175-493a-bc71-a6c7d2bdb5a4',
    label: 'БОЛИВИЯ',
  },
  {
    id: 'f164d246-61e0-4161-b61b-17a8a6653d92',
    label: 'БОНЭЙР, СИНТ-ЭСТАТИУС И САБА',
  },
  {
    id: 'adbc374a-07b7-46f2-8046-ed540ca7f919',
    label: 'БОСНИЯ И ГЕРЦЕГОВИНА',
  },
  {
    id: '3ed503ff-ec95-470b-b2be-fdabcf50ed1e',
    label: 'БОТСВАНА',
  },
  {
    id: 'fe65852d-1fa4-4442-af8c-d6fd9fcfa5c6',
    label: 'БРАЗИЛИЯ',
  },
  {
    id: 'adf548a3-160c-474a-a996-745eefaf7153',
    label: 'БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ',
  },
  {
    id: '2125b546-cfd6-43b4-8f21-381e23b2bb1e',
    label: 'БРУНЕЙ-ДАРУССАЛАМ',
  },
  {
    id: 'bb2a3683-4445-4c9f-84de-d54ee3bbba45',
    label: 'БУРКИНА-ФАСО',
  },
  {
    id: '4666a2d6-23ef-42ac-a434-c0ac8cc70c8c',
    label: 'БУРУНДИ',
  },
  {
    id: '22d42efa-abbd-484d-9b44-26aa4591f097',
    label: 'БУТАН',
  },
  {
    id: '9507b66d-5459-492f-8ae2-ddeafee573f9',
    label: 'ВАНУАТУ',
  },
  {
    id: '1acb796e-0f62-4348-a019-ca251edb3263',
    label: 'ВЕНГРИЯ',
  },
  {
    id: '1020033c-21dc-4a02-824d-afc9316b04f1',
    label: 'ВЕНЕСУЭЛА',
  },
  {
    id: '948f241f-4034-4173-a68c-9db633aec4f4',
    label: 'ВИРГИНСКИЕ ОСТРОВА, БРИТАНСКИЕ',
  },
  {
    id: 'be99c10c-92bb-4451-902f-57dfa62aa63a',
    label: 'ВИРГИНСКИЕ ОСТРОВА, США',
  },
  {
    id: 'ffaaf3a7-1d59-4dd6-b863-bbbe633ecdf8',
    label: 'ВЬЕТНАМ',
  },
  {
    id: '337031e3-c36c-4545-ae68-1d2426a9e226',
    label: 'ГАБОН',
  },
  {
    id: 'a12e7be7-55b5-41cd-87f2-32a41520f276',
    label: 'ГАИТИ',
  },
  {
    id: '3088a6b7-55a0-4474-8018-e1ab1ee442a9',
    label: 'ГАЙАНА',
  },
  {
    id: 'c94f6efe-3f23-4caf-8389-e32b8c82120d',
    label: 'ГАМБИЯ',
  },
  {
    id: 'd04a7807-fe71-427d-9310-8775499253ca',
    label: 'ГАНА',
  },
  {
    id: 'fc679528-d687-4ecc-ada6-f40396b2c7c0',
    label: 'ГВАДЕЛУПА',
  },
  {
    id: 'f542be5f-6c65-4760-888f-11a6c0cda6c3',
    label: 'ГВАТЕМАЛА',
  },
  {
    id: '0177ec87-2a04-4d91-9411-63bb88e27b40',
    label: 'ГВИНЕЯ',
  },
  {
    id: '61c4163e-f66a-4af9-971b-5ce4d2d12689',
    label: 'ГВИНЕЯ-БИСАУ',
  },
  {
    id: '6aadaf91-5948-4fc2-8024-db776829a62b',
    label: 'ГЕРМАНИЯ',
  },
  {
    id: '0f5a46da-971f-4d03-a90d-1457a2e5d4cd',
    label: 'ГЕРНСИ',
  },
  {
    id: 'f6347dba-74e3-4a7b-bd9e-2f8ad4961bf0',
    label: 'ГИБРАЛТАР',
  },
  {
    id: '92f3abde-5c31-47db-bdf4-5fccf442c1ce',
    label: 'ГОНДУРАС',
  },
  {
    id: 'c93cea94-be46-4517-b429-bd952cf80c2c',
    label: 'ГОНКОНГ',
  },
  {
    id: 'ad0de3b8-4827-4803-87b7-9468ac025893',
    label: 'ГРЕНАДА',
  },
  {
    id: '1d446e15-c6fb-46e1-9796-d473886bbe69',
    label: 'ГРЕНЛАНДИЯ',
  },
  {
    id: 'df9fd87f-2b94-4685-934e-434c8a6eec12',
    label: 'ГРЕЦИЯ',
  },
  {
    id: 'dfee61d1-50a0-4e72-912b-efa521b1606e',
    label: 'ГУАМ',
  },
  {
    id: '5af731ce-ad77-4b57-a607-8c0529a2d75c',
    label: 'ДАНИЯ',
  },
  {
    id: 'b039d77d-ac5e-4bf1-a6ea-7c0596b32e03',
    label: 'ДЖЕРСИ',
  },
  {
    id: '5119b81e-d872-4321-b77d-a2584e6cd38a',
    label: 'ДЖИБУТИ',
  },
  {
    id: '28dee631-dc47-45c5-82ab-3dbc6946afcf',
    label: 'ДОМИНИКА',
  },
  {
    id: '0e9e828b-9733-47ed-bfa3-1d664d29b53f',
    label: 'ДОМИНИКАНСКАЯ РЕСПУБЛИКА',
  },
  {
    id: '6c4569b0-77fb-40a8-b925-5c83faa674fa',
    label: 'ЕГИПЕТ',
  },
  {
    id: 'd5d58b92-63ea-40a8-917e-2f6bab99c275',
    label: 'ЗАМБИЯ',
  },
  {
    id: 'a3952288-9f8e-4143-b7f7-5b27db171fd6',
    label: 'ЗАПАДНАЯ САХАРА',
  },
  {
    id: '6ec487c5-3967-456c-86b0-b9c63eff4f78',
    label: 'ЗИМБАБВЕ',
  },
  {
    id: '38db177a-57bc-4545-826b-309998dae352',
    label: 'ИЗРАИЛЬ',
  },
  {
    id: 'bdf859ad-9b26-4a9a-a220-5cc04161c0cf',
    label: 'ИНДИЯ',
  },
  {
    id: '5940c937-50c9-4b77-871f-051c7bf3cd22',
    label: 'ИНДОНЕЗИЯ',
  },
  {
    id: '986fb302-bebb-49ce-81e5-91e60bd451b1',
    label: 'ИОРДАНИЯ',
  },
  {
    id: '9667d70b-c280-4f40-a7bc-9a91ccb9cf2a',
    label: 'ИРАК',
  },
  {
    id: 'cd2e1fdf-0603-4cbb-b407-a8326c99174f',
    label: 'ИРАН, ИСЛАМСКАЯ РЕСПУБЛИКА',
  },
  {
    id: '881bb645-0912-4d37-a2d6-ae8597b3e753',
    label: 'ИРЛАНДИЯ',
  },
  {
    id: '4f836308-60d6-462d-b81b-e81a89d35a4b',
    label: 'ИСЛАНДИЯ',
  },
  {
    id: 'da913782-b518-4f31-b2c2-f3c45f44fcdc',
    label: 'ИСПАНИЯ',
  },
  {
    id: '19e70eb3-4759-40ec-a348-2862d87ce768',
    label: 'ИТАЛИЯ',
  },
  {
    id: '795252dd-229c-43dd-ad7c-6a250a16117f',
    label: 'ЙЕМЕН',
  },
  {
    id: '09145e77-0e87-4e5f-b6b0-b3e0af619a93',
    label: 'КАБО-ВЕРДЕ',
  },
  {
    id: 'bf197b3a-fa9d-4f07-a3d7-56f6f597866c',
    label: 'КАМБОДЖА',
  },
  {
    id: '4e6a9c0a-ad92-4203-973f-ea7ff1253ba9',
    label: 'КАМЕРУН',
  },
  {
    id: '35a22767-d730-4b44-beba-39e29a92e9fb',
    label: 'КАНАДА',
  },
  {
    id: 'e77e1f85-b31a-4b2d-9b2d-02e39fa8e643',
    label: 'КАТАР',
  },
  {
    id: '5aab313f-4dca-4484-abd3-a3945486f45d',
    label: 'КЕНИЯ',
  },
  {
    id: '892e1215-d3dc-4255-ad94-bf1eb7bf0cfb',
    label: 'КИПР',
  },
  {
    id: '4f34bd2f-51d7-47e3-b22e-6c6dff4becfb',
    label: 'КИРИБАТИ',
  },
  {
    id: '1d9b1ee1-88e2-4fa5-ba01-41722fa3ccf7',
    label: 'КИТАЙ',
  },
  {
    id: 'fe05ba98-1426-4942-9473-59c308f3b4c5',
    label: 'КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА',
  },
  {
    id: '480dfa31-58f0-4a92-8bf7-3aedf69078b0',
    label: 'КОЛУМБИЯ',
  },
  {
    id: '395ff821-8b86-4c53-823c-3c49550a60f4',
    label: 'КОМОРЫ',
  },
  {
    id: 'd82e7b41-9043-4c71-955b-c08bca81b743',
    label: 'КОНГО',
  },
  {
    id: 'd1560595-2d36-41bd-81bc-b44a150fabb2',
    label: 'КОНГО, ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
  },
  {
    id: 'd4da9855-818a-4047-9821-fcad33c7c0cc',
    label: 'КОРЕЯ, НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
  },
  {
    id: 'ec2cc423-548e-4778-a244-d3bdeafbfdfe',
    label: 'КОРЕЯ, РЕСПУБЛИКА',
  },
  {
    id: '25997be2-0e34-4f17-a636-780a0cc9a35c',
    label: 'КОСТА-РИКА',
  },
  {
    id: '1b63055f-d2b6-4acf-a1f5-d34124dbddbf',
    label: "КОТ Д'ИВУАР",
  },
  {
    id: 'c20ff0fc-d523-4e42-8bd4-0a9622998090',
    label: 'КУБА',
  },
  {
    id: 'ac7d7de6-da60-4974-89a4-fbd584e27903',
    label: 'КУВЕЙТ',
  },
  {
    id: '21edf3b8-f537-459e-9a24-5c850222e8d7',
    label: 'КЮРАСАО',
  },
  {
    id: 'c3c27f72-1c6f-4895-8d41-6e6f7c2b69bf',
    label: 'ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
  },
  {
    id: '645ecc56-857a-495b-b21a-bf1701f15266',
    label: 'ЛЕСОТО',
  },
  {
    id: '8e3bcda5-6e28-4811-be82-86e1ab815245',
    label: 'ЛИБЕРИЯ',
  },
  {
    id: 'cbffdce6-f65c-405c-b9ca-d99d621ad9c0',
    label: 'ЛИВАН',
  },
  {
    id: '69246142-b55c-49c1-bd32-4d6e3547215d',
    label: 'ЛИВИЯ',
  },
  {
    id: 'c68cebbe-62b7-4cc4-bf79-f3eb06492458',
    label: 'ЛИХТЕНШТЕЙН',
  },
  {
    id: '0affdf06-7779-4d0a-9f51-6f481118d9b1',
    label: 'ЛЮКСЕМБУРГ',
  },
  {
    id: '2905f654-0587-46b9-9c7a-76edbccb5b35',
    label: 'МАВРИКИЙ',
  },
  {
    id: 'c04fe7fb-13be-4754-b54c-c8576670acdc',
    label: 'МАВРИТАНИЯ',
  },
  {
    id: '55ee74e6-6acf-483e-8e13-915e6b8148b7',
    label: 'МАДАГАСКАР',
  },
  {
    id: '08320090-c257-4094-b936-3bb0de3c29af',
    label: 'МАЙОТТА',
  },
  {
    id: 'ff02bbf5-750f-4c3c-b9fd-be6f2673cbd6',
    label: 'МАКАО',
  },
  {
    id: 'c427a28e-99be-4e17-9697-4b409d4b1119',
    label: 'МАЛАВИ',
  },
  {
    id: '42db5ecf-bc04-4334-bf7e-1ffa025c74de',
    label: 'МАЛАЙЗИЯ',
  },
  {
    id: '51b45fea-e1b2-43ef-8fc6-f370b24dabc9',
    label: 'МАЛИ',
  },
  {
    id: 'bcf3fc37-de9c-41b8-9127-bd2e815f52c3',
    label: 'МАЛЫЕ ТИХООКЕАНСКИЕ ОТДАЛЕННЫЕ ОСТРОВА',
  },
  {
    id: '33aca8e8-dbd0-46ae-9685-fbfe3f3b3ec4',
    label: 'МАЛЬДИВЫ',
  },
  {
    id: '235cea32-7076-486a-9396-f384b192d9e4',
    label: 'МАЛЬТА',
  },
  {
    id: 'f7ef3c9e-7a0b-43a0-bcba-0630a9b5fa6a',
    label: 'МАРОККО',
  },
  {
    id: '0e9a4a5b-95cf-43be-9a0f-25cbc5cbbcf1',
    label: 'МАРТИНИКА',
  },
  {
    id: '6c07fa8a-fa25-470b-9339-fa04eaec1b3d',
    label: 'МАРШАЛЛОВЫ ОСТРОВА',
  },
  {
    id: '02337826-3873-41e4-afc4-8524d690a792',
    label: 'МЕКСИКА',
  },
  {
    id: '6347f263-ce20-4c06-bbd4-2b5578c632be',
    label: 'МИКРОНЕЗИЯ, ФЕДЕРАТИВНЫЕ ШТАТЫ',
  },
  {
    id: '85ebf864-8275-45c7-8efc-7fda3e2b2a4d',
    label: 'МОЗАМБИК',
  },
  {
    id: '1e0f1e31-1df3-4baf-82ae-60ca117a3314',
    label: 'МОНАКО',
  },
  {
    id: '36791823-504e-488e-9ae4-f09eb1494370',
    label: 'МОНГОЛИЯ',
  },
  {
    id: 'eb1ac47d-7a15-4f46-8f83-fb39b51d0875',
    label: 'МОНТСЕРРАТ',
  },
  {
    id: '3ab39a60-e741-4941-8130-9b85b9da9cc7',
    label: 'МЬЯНМА',
  },
  {
    id: 'cfd2a228-db97-4196-a912-dfbae672beda',
    label: 'НАМИБИЯ',
  },
  {
    id: 'e9e35118-5038-4be5-bf13-9e675624a090',
    label: 'НАУРУ',
  },
  {
    id: '93e9dec3-cede-44eb-9feb-d57635f3afe8',
    label: 'НЕПАЛ',
  },
  {
    id: 'c9bf2a39-28f9-418e-8a5b-16e70ab307cb',
    label: 'НИГЕР',
  },
  {
    id: 'db1aab42-d00a-400c-9147-833206e5cec0',
    label: 'НИГЕРИЯ',
  },
  {
    id: '0c6525d9-27a0-433f-91dd-4abaa8bcf498',
    label: 'НИДЕРЛАНДЫ',
  },
  {
    id: '2a278d3e-a6d1-4065-829f-54ea2b81d4d7',
    label: 'НИКАРАГУА',
  },
  {
    id: 'd2be95e9-24ad-4b29-9938-8cec85f19f52',
    label: 'НИУЭ',
  },
  {
    id: '7e3439c3-acd5-4981-820c-dac60f552ac4',
    label: 'НОВАЯ ЗЕЛАНДИЯ',
  },
  {
    id: '591e064c-d5ea-4cbf-a1a8-d9428441ae64',
    label: 'НОВАЯ КАЛЕДОНИЯ',
  },
  {
    id: 'f8de2646-1723-4d8b-b572-d992f11d2615',
    label: 'НОРВЕГИЯ',
  },
  {
    id: '8aaa8621-dbf3-40b0-91c5-0ca222f989d2',
    label: 'ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ',
  },
  {
    id: '197e0d71-4462-49f4-b027-94d1132558fe',
    label: 'ОМАН',
  },
  {
    id: '5397974e-dafd-4727-9548-7f2bfd08d54e',
    label: 'ОСТРОВ БУВЕ',
  },
  {
    id: 'b3d36e9c-d94a-4286-8c60-3181083cf54a',
    label: 'ОСТРОВ МЭН',
  },
  {
    id: '6fda0337-9dda-49ae-bb33-ef8d9407d383',
    label: 'ОСТРОВ НОРФОЛК',
  },
  {
    id: '05a0c6de-5209-4457-8f8b-b2af94704490',
    label: 'ОСТРОВ РОЖДЕСТВА',
  },
  {
    id: '11bd6804-38a3-4a3f-b173-92386dadf560',
    label: 'ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД',
  },
  {
    id: 'c256f72c-4da7-4cd9-a9c6-d73176a07a40',
    label: 'ОСТРОВА КАЙМАН',
  },
  {
    id: '6d89805c-5203-4430-a47b-74d1413dee34',
    label: 'ОСТРОВА КУКА',
  },
  {
    id: '89683ccb-2e66-469a-8917-bbd0ca67348f',
    label: 'ОСТРОВА ТЕРКС И КАЙКОС',
  },
  {
    id: 'dd0dab57-f099-4db7-8af0-07fa239fe1b0',
    label: 'ПАКИСТАН',
  },
  {
    id: '42bf0a5e-232d-4eb7-ab24-83cf2b79ad1b',
    label: 'ПАЛАУ',
  },
  {
    id: '1c786290-d5ae-4acc-be32-41248986eb15',
    label: 'ПАЛЕСТИНА, ГОСУДАРСТВО',
  },
  {
    id: '392dbd9d-1517-4d5c-a975-d3d823ac473b',
    label: 'ПАНАМА',
  },
  {
    id: '55da3c78-58d8-4585-8c13-9b7356742d94',
    label: 'ПАПСКИЙ ПРЕСТОЛ (ГОСУДАРСТВО - ГОРОД)',
  },
  {
    id: 'd6698449-2cbe-421d-b3d1-5748ffa8911e',
    label: 'ПАПУА НОВАЯ ГВИНЕЯ',
  },
  {
    id: 'a0d7daf0-735e-42f7-bc24-7f748476c6ce',
    label: 'ПАРАГВАЙ',
  },
  {
    id: '8087e891-8309-437b-ba02-186dd9adcc1b',
    label: 'ПЕРУ',
  },
  {
    id: '7e349b26-ab00-448d-9282-875b2f0fc700',
    label: 'ПИТКЕРН',
  },
  {
    id: 'e8c7427e-e8a4-4c7b-8e15-d2f8f47dc92f',
    label: 'ПОЛЬША',
  },
  {
    id: '046aeb6b-f614-4830-a85a-54e77fbd1f7a',
    label: 'ПОРТУГАЛИЯ',
  },
  {
    id: 'af0736be-671d-4a4a-8dd1-0512f60b15c2',
    label: 'ПУЭРТО-РИКО',
  },
  {
    id: 'ccc866b1-bb07-48ac-b133-704a90d25fdc',
    label: 'РЕЮНЬОН',
  },
  {
    id: 'f0a1a642-ca35-4639-aa1a-50049dbd69ef',
    label: 'РУАНДА',
  },
  {
    id: '9920a375-5d90-4293-8288-50a8469d6edc',
    label: 'РУМЫНИЯ',
  },
  {
    id: '54c4d6ad-1c1a-45f4-964c-1e24a67c10e7',
    label: 'САМОА',
  },
  {
    id: '161d2933-a2f9-4552-ab1d-84e59e4974cf',
    label: 'САН-МАРИНО',
  },
  {
    id: 'db945d06-1b60-452b-811d-80acfef42ff4',
    label: 'САН-ТОМЕ И ПРИНСИПИ',
  },
  {
    id: '1499168d-159a-402c-a426-6717ac5b8fa7',
    label: 'САУДОВСКАЯ АРАВИЯ',
  },
  {
    id: 'de309e08-9c6b-4041-994c-73b21aab5ac6',
    label: 'СВЯТАЯ ЕЛЕНА, ОСТРОВ ВОЗНЕСЕНИЯ',
  },
  {
    id: '37fec7f4-f740-440f-bd39-9dc1201be72d',
    label: 'СЕВЕРНАЯ МАКЕДОНИЯ',
  },
  {
    id: '434f2955-0267-4902-b4d0-1c65ea94b807',
    label: 'СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА',
  },
  {
    id: 'ae4a0de3-7a8c-4e41-ab57-cc63037b18ea',
    label: 'СЕЙШЕЛЫ',
  },
  {
    id: '1678bb80-f4d0-4148-aeac-3776257047e2',
    label: 'СЕН-БАРТЕЛЕМИ',
  },
  {
    id: 'e13005c4-e7d8-4ebe-8b39-2058c1cd69d5',
    label: 'СЕН-МАРТЕН (нидерландская часть)',
  },
  {
    id: '597b656b-b3fc-4026-8e9d-84c0ddee68fe',
    label: 'СЕН-МАРТЕН (французская часть)',
  },
  {
    id: '1c6f4d22-1a4c-449e-a5de-037f6f20f83f',
    label: 'СЕНЕГАЛ',
  },
  {
    id: '4e7a248d-8f59-4253-af0c-fcb0cd3f45a3',
    label: 'СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ',
  },
  {
    id: 'f0c5618b-50e8-4688-a651-b1abdeb87c82',
    label: 'СЕНТ-КИТС И НЕВИС',
  },
  {
    id: '50370f09-c193-44cd-a1d9-6ab18b61bb24',
    label: 'СЕНТ-ЛЮСИЯ',
  },
  {
    id: 'b4ec8b3b-d867-4eb3-81d5-c352e8303f26',
    label: 'СЕНТ-ПЬЕР И МИКЕЛОН',
  },
  {
    id: '0bfbe821-28fe-4412-8937-618ee1d0cd78',
    label: 'СЕРБИЯ',
  },
  {
    id: '85af092f-416c-4d07-bd64-3689d80f132e',
    label: 'СИНГАПУР',
  },
  {
    id: '8d53d976-7396-475d-999e-dcd536ff55f6',
    label: 'СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА',
  },
  {
    id: 'fb9c7f5c-172e-4061-9cd8-6e7757afc792',
    label: 'СЛОВАКИЯ',
  },
  {
    id: 'feb2f4c2-03dc-46ff-892d-999e8b130726',
    label: 'СЛОВЕНИЯ',
  },
  {
    id: '5ce636fd-ccea-456e-ae90-3e394aeba687',
    label: 'СОЕДИНЕННОЕ КОРОЛЕВСТВО',
  },
  {
    id: '66107bc5-54d3-494e-b065-d32ee5891df4',
    label: 'СОЕДИНЕННЫЕ ШТАТЫ',
  },
  {
    id: '5de4fd6b-ebeb-481f-88c9-3c8d0353db45',
    label: 'СОЛОМОНОВЫ ОСТРОВА',
  },
  {
    id: '289a6559-b5f8-417e-a6ca-3b132a455e34',
    label: 'СОМАЛИ',
  },
  {
    id: 'd6ebd61c-1cc3-410e-9d93-fb22c13c960d',
    label: 'СУДАН',
  },
  {
    id: 'd953bf3b-3f6a-4034-975c-36b1989d3ec2',
    label: 'СУРИНАМ',
  },
  {
    id: '8043b0a3-ad9f-427f-abab-0e5742b7cb71',
    label: 'СЬЕРРА-ЛЕОНЕ',
  },
  {
    id: '81ee2c82-2cd6-421b-b23b-173a09769cd6',
    label: 'ТАИЛАНД',
  },
  {
    id: '14e089b5-8761-4717-b1a1-adaf4221cb6d',
    label: 'ТАЙВАНЬ (КИТАЙ)',
  },
  {
    id: 'e9643a78-8d4c-48e7-8df0-48fb4e3ad6cd',
    label: 'ТАНЗАНИЯ, ОБЪЕДИНЕННАЯ РЕСПУБЛИКА',
  },
  {
    id: '5db48c62-ae44-48ac-a394-6afe22f54c0d',
    label: 'ТИМОР-ЛЕСТЕ',
  },
  {
    id: '6d4bed30-2624-47d7-88a3-418a08c1814f',
    label: 'ТОГО',
  },
  {
    id: 'e6877bcd-a7fd-401f-b826-16c9433dcacb',
    label: 'ТОКЕЛАУ',
  },
  {
    id: '17acdcb2-5419-455a-a778-c20a6eda5b6d',
    label: 'ТОНГА',
  },
  {
    id: '5a138f74-41ad-43b6-b40f-caaca78c48a0',
    label: 'ТРИНИДАД И ТОБАГО',
  },
  {
    id: '4e37f4d4-3bf4-4d43-b7ce-42d609e54e47',
    label: 'ТУВАЛУ',
  },
  {
    id: 'd0c131fb-9202-4a50-800b-37d76d0b1766',
    label: 'ТУНИС',
  },
  {
    id: '7355541c-7e1b-4a4f-af21-1f20aa32f123',
    label: 'ТУРЦИЯ',
  },
  {
    id: '3b12558f-6ae8-42cd-8bb7-a33f7def0f81',
    label: 'УГАНДА',
  },
  {
    id: '2004035c-308d-4a76-af0f-77802acc2c8a',
    label: 'УОЛЛИС И ФУТУНА',
  },
  {
    id: '613bdebb-9308-4851-9831-ddab4be72bed',
    label: 'УРУГВАЙ',
  },
  {
    id: '5daa6c25-1956-4697-8767-77edaf14f6b4',
    label: 'ФАРЕРСКИЕ ОСТРОВА',
  },
  {
    id: '573399ee-b75a-4074-90d6-99a702fcf812',
    label: 'ФИДЖИ',
  },
  {
    id: '81709d41-2647-404b-b8e5-2d77159f3263',
    label: 'ФИЛИППИНЫ',
  },
  {
    id: '5713d08e-fbeb-41e5-acf5-12ae859ea36f',
    label: 'ФИНЛЯНДИЯ',
  },
  {
    id: '48d86625-77aa-419d-bb5b-c864789d8746',
    label: 'ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)',
  },
  {
    id: 'e28f6403-e1b6-45b2-b5eb-49a23b90559f',
    label: 'ФРАНЦИЯ',
  },
  {
    id: '2fbdbb41-da89-4a71-8868-5ddded542893',
    label: 'ФРАНЦУЗСКАЯ ГВИАНА',
  },
  {
    id: 'd8d31054-2a00-4ecb-9325-213631836d3d',
    label: 'ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ',
  },
  {
    id: 'fe7cb77e-7772-418c-8d50-cba9f26d7a76',
    label: 'ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ',
  },
  {
    id: '71dabe4f-c5d1-4103-b8bd-4fcdfbc454f1',
    label: 'ХОРВАТИЯ',
  },
  {
    id: 'b0bbd394-43f7-4713-b896-5cc405ac83fc',
    label: 'ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА',
  },
  {
    id: 'c93f6532-3797-4af1-a634-356e5e86fef9',
    label: 'ЧАД',
  },
  {
    id: '6b5af464-2149-469b-8f82-e91dafc8ebc3',
    label: 'ЧЕРНОГОРИЯ',
  },
  {
    id: '54e90a42-b17b-4ec7-aa14-2a4d1960aa45',
    label: 'ЧЕХИЯ',
  },
  {
    id: '12b4b167-9c73-4bcc-acbd-9bfd77363531',
    label: 'ЧИЛИ',
  },
  {
    id: '2fc177c0-680e-4c6f-8715-12bf723d4505',
    label: 'ШВЕЙЦАРИЯ',
  },
  {
    id: '8941bd29-28e0-4d14-936c-9dff60a3a2f2',
    label: 'ШВЕЦИЯ',
  },
  {
    id: 'e72fae6d-ac42-497f-9620-10eb90739dd6',
    label: 'ШПИЦБЕРГЕН И ЯН МАЙЕН',
  },
  {
    id: '268f28d9-1441-434f-b507-ce9f7f43649d',
    label: 'ШРИ-ЛАНКА',
  },
  {
    id: '0529feb5-c6d6-43c7-ad17-e1011b6e2757',
    label: 'ЭКВАДОР',
  },
  {
    id: '20dd495d-6a0f-4655-92a1-a4068886e715',
    label: 'ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ',
  },
  {
    id: '3acad1b7-4448-4bb9-a95e-242c68ed278d',
    label: 'ЭЛАНДСКИЕ ОСТРОВА',
  },
  {
    id: 'd59cad8a-0d45-48af-92ca-3fe2c2e3365f',
    label: 'ЭЛЬ-САЛЬВАДОР',
  },
  {
    id: '22b40085-15e2-442e-8972-2a2916cd332c',
    label: 'ЭРИТРЕЯ',
  },
  {
    id: '8aba3433-6486-41cd-bb53-d6de2e207622',
    label: 'ЭСВАТИНИ',
  },
  {
    id: 'b31731d5-814a-419b-b116-340218927755',
    label: 'ЭФИОПИЯ',
  },
  {
    id: 'e4ba0d3c-f313-419f-b84e-8dcc78b63257',
    label: 'ЮЖНАЯ АФРИКА',
  },
  {
    id: '487e84b8-b24c-4549-a2f5-48a03ada2dd4',
    label: 'ЮЖНАЯ ДЖОРДЖИЯ И ЮЖНЫЕ САНДВИЧЕВЫ',
  },
  {
    id: '8954c2f9-96af-4908-b479-b0ba6c9aaa79',
    label: 'ЮЖНАЯ ОСЕТИЯ',
  },
  {
    id: 'be4dedac-597b-4e6f-8d4e-773547862510',
    label: 'ЮЖНЫЙ СУДАН',
  },
  {
    id: 'f93daa18-d49e-46f5-b176-dc90f11378dd',
    label: 'ЯМАЙКА',
  },
  {
    id: '27dd26fc-5bf7-4ca5-b94d-ccbb604875a4',
    label: 'ЯПОНИЯ',
  },
];
