export const bonusesType = {
  // Сгорание бонусов  -
  Expiration: '171fecae-db92-4b1e-a803-2469cd3c9bc6',
  // Списание из otb-market.ru -
  OTBMarketWriteOff: '1a2ff0fc-d4cc-4c3b-b976-05b496c9f2f0',
  // Дополнительное согласованное начисление +
  ExtraAgreedCharge: '5493dc96-32eb-4c2c-bf28-42a698af06a7',
  //Тестовое начисление  +
  TestCharge: 'a403bfb9-7e70-4403-8771-60d593c9a9e1',
  // Передача бонусов на другой email -
  TransferToOtherEmail: 'afe7cb4f-d23c-42f4-8a5f-e6cbbdfe404d',
  // Бонусы за отзыв  +
  ForFeedback: 'e6a9eec4-61c5-4937-96fe-3d885af8c618',
  //  Личный кабинет
  PersonalAccount: 'eea28354-7d4d-4752-95a3-1e47e04f0432',
};
