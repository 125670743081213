import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import admin from './AdminStore';
import registry from './RegistryStore';
import questions from './QuestionsStore';
import education from './EducationStore';
import publicStore from './PublicStore';

const rootReducer = combineReducers({
  admin,
  registry,
  questions,
  education,
  publicStore,
});

const throwMiddleware = () => (next) => (action) => {
  next(action);
  if (action?.error) {
    throw action.error;
  }
};
const middleware = [thunk, throwMiddleware];

// if (process.env.NODE_ENV !== "production") {
// 	middleware.push(createLogger());
// }

export const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
});
