import {Select} from 'antd';
import React from 'react';
import {CUSTOMER_TYPES} from '../../common';

const CustomerSelect = ({disabled, ...props}) => {
  return (
    <Select disabled={disabled} {...props}>
      {CUSTOMER_TYPES.map((type) => (
        <Select.Option key={type.id} value={type.id}>
          {type.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CustomerSelect;
