import {Form, Select, Switch} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import COLORS from '../../common/colors';

const NoteForm = () => {
  return (
    <>
      <Form.Item name="text" label="Замечание">
        <TextArea />
      </Form.Item>
      <Form.Item name="important" label="Важное" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item name="color" label="Цвет">
        <Select placeholder="Красный">
          {COLORS.map((color) => (
            <Select.Option key={color.value} value={color.value} style={{backgroundColor: color.value, color: '#FFF'}}>
              {color.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default NoteForm;
