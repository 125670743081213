import {useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Button, Popconfirm, Space, Spin, Table} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';

import {compare, LOCALE, paramsToObject} from '../../common';
import ModalForm from '../UX/ModalForm';
import {QuestionsSelectors} from '../../store/QuestionsStore';
import NormsActuality from '../SystemContentControl/NormsActuality';

const {COMMON, EDUCATION, MESSAGES} = LOCALE;

const MaterialsTable = ({data, loading, deleteHandler, createHandler, addEditItems, redirect, totalCount, type}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isNormsLoading = useSelector(QuestionsSelectors.isNormsLoading);

  const filters = paramsToObject(searchParams);

  const titles = {
    lections: EDUCATION.LECTURE,
    videos: EDUCATION.VIDEOS,
    presentations: EDUCATION.PRESENTATION,
    adlinks: EDUCATION.ADLINK,
  };

  const columns = [
    {
      title: COMMON.NAME,
      align: 'center',
      dataIndex: 'eduName',
      key: 'name',
      sorter: (a, b) => compare(a.eduName, b.eduName),
    },
    {
      title: EDUCATION.PROGRAM,
      align: 'center',
      dataIndex: 'trainingPrograms',
      maxWidth: 1200,
      key: 'trainingPrograms',
      render: (programs) => {
        return programs.map(
          (program, programIndex) =>
            `${program.name} (${program.phases.map(
              (phase, index) => `${phase.number}${index + 1 === program.phases.length ? '' : ','}`
            )})${programIndex + 1 === programs.length ? '' : ', '}`
        );
      },
    },
    {
      dataIndex: 'id',
      key: 'buttons',
      align: 'end',
      title: (
        <ModalForm onOk={createHandler} formItems={addEditItems} title={titles[type]}>
          <Button type="primary" icon={<PlusOutlined />} />
        </ModalForm>
      ),
      width: 50,
      render: (id, record) => {
        return (
          <Space align="center">
            {isNormsLoading ? (
              <Spin />
            ) : (
              <NormsActuality contentExists={record.contentExists} isAllNormsActual={record.isAllNormsActual} />
            )}
            <Button onClick={() => redirect(id, record.educationMaterialId)} icon={<EditOutlined />} />
            <Popconfirm placement="topRight" title={MESSAGES.CONFIRM} onConfirm={() => deleteHandler(id)}>
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      pagination={{
        total: totalCount,
        current: Number(filters?.page ?? 1),
        pageSize: Number(filters?.limit ?? 10),
        onChange: (page, limit) => setSearchParams({...filters, page, limit, offset: (page - 1) * limit}),
        onShowSizeChange: (page, limit) => setSearchParams({...filters, page, limit, offset: (page - 1) * limit}),
        pageSizeOptions: [10, 20, 50, 100, 250, 500],
      }}
      bordered
      rowKey={'id'}
      columns={columns}
      dataSource={data}
      loading={loading}
    />
  );
};

export default MaterialsTable;
