import PageLayout from '../../components/Layout/PageLayout';
import {LOCALE} from '../../common';
import CommercialProposesTable from '../../components/CommercialProposes/Table';

const CommercialProposes = () => {
  return (
    <PageLayout pageTitle={LOCALE.PAGE_TITLES.COMMERCIAL_PROPOSES}>
      <CommercialProposesTable />
    </PageLayout>
  );
};

export default CommercialProposes;
